import React, { Component } from 'react';
// import Footer from '../components/Commons/Footer';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import logo from '../assets/images/logo_white.png';
import ErrorPagePic from "../assets/images/pageNotFound.png"
import '../assets/styles/Commons.css';

class ErrorPage extends Component {
  constructor(){
    super();
    this.state = {
      isMobileScreen: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    let screen_width = window.innerWidth;
    if(screen_width < 500) {
        this.setState({ isMobileScreen: true });
    } else {
        this.setState({ isMobileScreen: false });
    }
  }

  preventDefault(e) {
    e.preventDefault();
  }

  preventDefaultForScrollKeys(e) {
    var keys = {9: 1, 38: 1, 40: 1, 32: 1, 33: 1, 34: 1, 35: 1, 36: 1};
    if (keys[e.keyCode]) {
      e.preventDefault();
      return false;
    }
  }

  disableScrolling(wheelOpt, wheelEvent) {
    window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
    window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  enableScrolling(wheelOpt, wheelEvent) {
    window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
    window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
    window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
    window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  componentDidUpdate() {   
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    if(this.state.authModalOpen && !this.state.isMobileScreen){
      this.disableScrolling(wheelOpt, wheelEvent);
    } else {
      this.enableScrolling(wheelOpt, wheelEvent);
    }
  }

  componentWillUnmount(){
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    this.enableScrolling(wheelOpt, wheelEvent);
  }

  toggleAuthModal() {
    this.setState({
      authModalOpen: true
    })
  }

  setActiveForm(form){
    if (this.state.activeForm !== form){
      this.setState({
        activeForm: form
      })
    }
  }

  closeAuthModal(){
    if (this.state.authModalOpen){
      this.setState({
        authModalOpen: false
      })
    }
  }

  render() {
    return (
      <div>
        <Header
          toggleAuthModal={this.toggleAuthModal}
          setActiveForm={this.setActiveForm}
          authModalOpen={this.state.authModalOpen}
        />
        {this.state.isMobileScreen ? 
        <Grid container spacing={0} style={{paddingTop:'5px', paddingLeft:'10px', paddingRight:'10px'}}>
            <Grid item xs={12}>
                <div className="topText1">
                    <span style={{color: "#3356AB"}}>Oops!</span>
                </div>
                <div className="ContentDescriptionText1">
                    <span>We can't seem to find the page<br/> you are looking for.</span>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <img src={ErrorPagePic} alt="Error Page" className="pageNotFoundImage" style={{width:"100%"}}/>
                </div>
            </Grid>
        </Grid> 
        :
        <Grid container spacing={0} style={{paddingTop:'5px', paddingLeft:'10px', paddingRight:'10px'}}>
        <Grid item xs={6}>
            <div className="topText1" style={{paddingTop:"140px"}}>
                <span style={{color: "#3356AB"}}>Oops!</span>
            </div>
            <div className="ContentDescriptionText1">
                <span>We can't seem to find the page<br/> you are looking for.</span>
            </div>
        </Grid>
        <Grid item xs={6}>
            <div>
                <img src={ErrorPagePic} alt="Error Page" className="pageNotFoundImage" style={{width:"85%"}}/>
            </div>
        </Grid>
    </Grid>
        
        
        }
        {/* <Footer/> */}
      </div>
    );
  }
}

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    getMobileComponents(isMobileScreen, headerStyle){
        return (
            <div style={headerStyle} className="Header">
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="stretch">
                    <Logo/>
                </Grid>
            </div> 
        )
    }

   render() {
    var headerStyle = {background: "#F9D543"};
    return (
        this.getMobileComponents(this.state.isMobileScreen, headerStyle)
    )
   }
}

// Website Logo
class Logo extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        
        if (this.state.isMobileScreen){
            return (
                <div className="Logo LogoHome">
                    <Link  to={'/'}><img style={{cursor: "pointer", marginTop: "10px", marginLeft: "5px"}} src={logo} alt="" width={100} /></Link> 
                </div>
            )
        } else {
            return (
                <Grid container item xs={4}>
                    <React.Fragment>
                        <Grid item xs={12}>
                            <div style={{marginTop: "10px"}} className="Logo LogoHome">
                                <Link  to={'/'}><img style={{cursor: "pointer"}} className="LogoImage LogoImageMargin" src={logo} alt="logo"/></Link>
                            </div>
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }

    }
}

export default withRouter(ErrorPage);