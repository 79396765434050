import React, {Component} from "react";
import { withRouter } from 'react-router';
// import ScheduleIcon from '@material-ui/icons/Schedule';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import PopSound from '../../assets/media/ding.mp3';
import LocationOnIcon from '@material-ui/icons/LocationOn';

class NewOrdersCard extends Component {
    constructor(props){
        super(props);
        this.timeRemaining = this.timeRemaining.bind(this);
        this.state = {
            orderPlacedAt: "1990-01-01T00:00:00.000000+05:30",
            orderAcceptedIn: 0,
            remainingTime: "99:99",
            audio: new Audio(PopSound),
        }
    }

    componentDidMount() {
        // console.log(this.props.order)
        if('status_update_logs' in this.props.order && this.props.order.order_status === "ORDERED"){
            this.setState({
                orderPlacedAt : this.props.order.ordered_at,
                orderAcceptedIn : 2
            });
        }
        else{
            this.setState({
                orderPlacedAt : "1990-01-01T00:00:00.000000+05:30",
                orderAcceptedIn: 0
            });
        }
        this.countDownTimer();
    }

    countDownTimer(){
        var interval = setInterval(() => {
            if(this.state.remainingTime === "00:00"){
                clearInterval(interval);
            }
            else{
                if(this.state.remainingTime === "1:00"){
                    this.playPause();
                }
                this.setState({
                    remainingTime : this.timeRemaining()
                })
            }
        }, 1000);
    }

    playPause = () => {
        if (this.state.isPlaying) {
          this.state.audio.pause();
        } else {
          this.state.audio.play();
        }
        this.setState({ isPlaying: !this.state.isPlaying });
    }
    
    timeRemaining(){
        var momentTz = require('moment-timezone');
        var date = momentTz.tz(this.state.orderPlacedAt, 'Asia/Kolkata').format();

        var a = momentTz.tz(momentTz(), 'Asia/Kolkata').format();
        var b = date;
        var timeDifference = (new Date(a) - new Date(b))/1000;

        var tempCurrDate = a.split("T");
        var tempAcceptedDate = b.split("T");

        // console.log("CurrentDateTime :" + a)
        // console.log("orderPlacedAt :" + date)
        // console.log((new Date(a) - new Date(b))/1000)

        // console.log("Current Date : " + tempCurrDate[0])
        // console.log("Accepted Date : " + tempAcceptedDate[0])

        if((tempCurrDate[0] === tempAcceptedDate[0]) && (timeDifference < (this.state.orderAcceptedIn * 60))){
            var timeLeft = (this.state.orderAcceptedIn * 60) - timeDifference;
            var d = Number(timeLeft);
            var m = Math.floor(d / 60);
            var s = ('0' + Math.floor(d % 60)).slice(-2);
            return m + ":" + s; 
        }
        else{
            return "00:00"
        }
    }

    isLessThanFiveMins(){
        var timing = this.state.remainingTime.split(":")
        if(Number(timing[0]) === 0 && Number(timing[1]) === 0)
            return 0;
        else if(Number(timing[0]) === 0 && Number(timing[1]) > 0)
            return 5;
    }

    render() {
        return(
            <div key={this.props.index} className={this.isLessThanFiveMins() === 0 ? "orderCards lapsedCards" : this.isLessThanFiveMins() === 5 ? "orderCards warningCards" : "orderCards"}>
                <div style={{float:'right'}}><ZoomOutMapIcon className="orderInfoBtn" onClick={() => this.props.setOrder(this.props.order)}/></div>
                <div style={{padding:'10px'}}>
                    <div style={{marginTop:'18px'}}>
                        <span className="orderLabel1">{this.props.order.order_id}</span>
                    </div>
                    <div style={{display: "flex"}}>
                        <div style={{color:"#EA5B2F", flex: "1"}}>
                            <LocationOnIcon style={{fontSize:'15px', marginLeft:'-2px', transform: 'translateY(2px)'}} /> 
                            <span className="orderLabel1">{this.props.order.delivery_address === null ? 'Address Details' : this.props.order.delivery_address.address}</span>
                        </div>
                        <div>
                            <span className="orderTime" style={{float:'right'}}>{this.props.getOrderTime(this.props.order)}</span>
                        </div>
                    </div>
                    <Divider style={{marginTop: "3px"}}/>
                    {this.props.getOrderItems(this.props.order)}
                    {this.props.getTotalCharge(this.props.order)}
                    <Divider />
                    <div style={{padding: '10px 0px 2px 0px'}} className="infoText">Set Food Preparation Time</div>
                    <Grid style={{paddingBottom:'5px'}} container spacing={0}>
                        <Grid item xs={4}>
                            <div onClick={() => this.props.changeFoodPreparationTime('decrease', this.props.index, "NEW_ORDER")} className="leftBtn">-</div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="centerBlock">{this.props.order.data.food_preparation_time} mins</div>
                        </Grid>
                        <Grid item xs={4}>
                            <div  onClick={() => this.props.changeFoodPreparationTime('increase', this.props.index, "NEW_ORDER")} className="rightBtn">+</div>
                        </Grid>
                    </Grid>
                    { this.props.order.delivery_mode !== '' ? <Grid style={{paddingBottom:'5px'}} container spacing={0}>
                        <Grid item xs={6}>
                            <div style={{fontFamily: 'Poppins-SemiBold'}} className="orderTime">DELIVERY MODE:</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{textAlign: "right"}} className="orderTime">
                                <span>{this.props.order.delivery_mode}</span>
                            </div>
                        </Grid>
                    </Grid> : null}
                    <Grid style={{padding:'5px 0px'}} container spacing={1}>
                        <Grid item xs={6}>
                            <div className="rejectBtn" onClick={() => this.props.showRejectCard(this.props.index)}>Reject</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div onClick={() => this.props.changeOrderStatus(
                                this.props.index, 'UNDER_PREPARATION', {'preparation_time': this.props.orders[this.props.index].data.food_preparation_time})} 
                            className="acceptBtn">Accept({this.state.remainingTime})</div>
                        </Grid>
                    </Grid>
                </div>
                {(this.props.rejectCardIndex && !this.props.rejectReasonIndex3) && <div className="rejectCard">
                    <Grid container spacing={1} style={{paddingTop:'5px', paddingLeft:'10px', paddingRight:'10px'}}>
                        <Grid item xs={12}>
                            <span className="orderLabel1" 
                            style={{top:'-3px', color:"#EA5B2F"}}
                            >Select Reason for Rejection</span>
                        </Grid>
                        <Grid item xs={12}>
                            {this.props.rejectReasonIndex1 ?
                             <div className="selectedDeclineReason">Out of Stock</div>:
                             <div className="declineReason" onClick={() => this.props.selectedDeclineReason(this.props.index, 'Out of Stock', 0)}>Out of Stock</div> }
                            {this.props.rejectReasonIndex2 ?
                             <div className="selectedDeclineReason">Address out of reach</div>:
                             <div className="declineReason" onClick={() => this.props.selectedDeclineReason(this.props.index, 'Address out of reach', 1)}>Address out of reach</div> }
                            {this.props.rejectReasonIndex3 ?
                             <div className="selectedDeclineReason">Other</div>:
                             <div className="declineReason" onClick={() => this.props.selectedDeclineReason(this.props.index, 'Other', 2)}>Other</div> }
                        </Grid>
                        <Grid container spacing={1} style={{position:"absolute", bottom:'5px', paddingRight:"15px"}}>
                            <Grid item xs={6}>
                                <div className="rejectBtn" onClick={() => this.props.backToOrderCard(this.props.index)}>Cancel</div>
                            </Grid>
                            <Grid item xs={6}>
                                <div onClick={() => this.props.handleDeclineOrder(this.props.index)} className="submitBtn">Submit</div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>}
                {this.props.rejectReasonIndex3 && <div className="rejectCard">
                <Grid container spacing={1} style={{paddingTop:'5px', paddingLeft:'10px', paddingRight:'10px'}}>
                    <Grid item xs={12}>
                        <span className="orderLabel1" 
                        style={{top:'-3px', color:"#EA5B2F"}}
                        >Reason for Rejection</span>
                    </Grid>
                    <div style={{marginTop:'10px', marginBottom: '5px', marginLeft: '5px'}}>
                        <span className="orderLabel1">Other</span>
                    </div>
                    <Grid item xs={12}>
                        <textarea value={this.props.orders[this.props.index].data.decline_reason} onChange={(event) => {
                            let orders = this.props.orders;
                            let newOrder = orders[this.props.index];
                            newOrder.data.decline_reason = event.target.value;
                            orders[this.props.index] = newOrder;
                            this.props.setOthersDeclineReason(orders);
                        }} type="text" style={{width:'98%'}} className="restoDescTextArea" rows={5} placeholder='Enter Reason'/>
                    </Grid>
                    <Grid container spacing={1} style={{position:"absolute", bottom:'5px', paddingRight:"15px"}}>
                        <Grid item xs={6}>
                            <div className="rejectBtn" onClick={() => this.props.backToOrderCard(this.props.index)}>Cancel</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div onClick={() => this.props.handleDeclineOrder(this.props.index)} className="submitBtn">Submit</div>
                        </Grid>
                    </Grid>
                </Grid>
                </div>}
            </div>
        )
    }
}

export default withRouter(NewOrdersCard);