import React, { Component, createRef } from 'react';
import { Grid } from '@material-ui/core';
import '../../assets/styles/Home.css';
import '../../assets/styles/PartnerWithUs.css';
import Dropzone from 'react-dropzone'
import "croppie/croppie.css";
import Croppie from "croppie";
import ArrowBack from '@material-ui/icons/ArrowBack';

class ContactFormMobile extends Component {
    constructor(props) {
        super(props);
        this.dishImageRef = createRef(null);
        this.state = {
            dishImage: null,
            dishImageError: "",
            currentDishUploadStep: 1,
            dishImageName: ""
        };
        this.onFileInputChange = this.onFileInputChange.bind(this);
        this.handleBrowseFileClick = this.handleBrowseFileClick.bind(this);
        this.cropImage = this.cropImage.bind(this);
        this.handleCroppedImage = this.handleCroppedImage.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    handleChange = address => {
        console.log(address);
        var lenDiff = address.length - this.state.address.length;
        console.log(lenDiff < -1);
        if (lenDiff < -1){
            this.setState({
                clearUserSuggestions: true
            })
        } else {
            this.setState({
                clearUserSuggestions: false
            })
        }
        this.setState({
            address: address,
            shortAddress: "",
            latitude: 0.0,
            longitude: 0.0
        })
    };

    setMobileScreen(type){
        this.setState({
            isMobileScreen: type
        })
    }

    contactFormHeader(){
        return(
            <div>
                <span onClick={() => window.history.back()} className="PartnerWithUsBackContainer"><ArrowBack className="PartnerWithUsBackIcon"/> Back</span>
                <div style={{marginTop:"15px"}} className="PartnerWithUsHeader">
                    <div>Upload <span style={{color: "#EB622F"}}>Images</span></div>
                </div>
            </div>
        )
    }

    onFileInputChange(event, type){
        if (!this.state.contactDetailsSubmitting){
            var file;
            if (type === "select"){
                if (event.target.files === null){
                    return;
                } else {
                    file = event.target.files[0];
                }
            } else {
                if (event === null){
                    return;
                } else {
                    file = event[0];
                }
            }
            const reader = new FileReader();
            reader.onload = function(){
                const output = document.getElementById('output_dish_image');
                output.src = reader.result;
                window.dish_image.bind({ url: reader.result });
            }
            reader.readAsDataURL(file);
            this.setState({
                currentDishUploadStep: 2,
                dishImageName: file.name
            }, () => {
                window.dish_image = new Croppie(document.getElementById("output_dish_image"), {
                    enableExif: true,
                    showZoomer : false,
                    url : 'DSCN3409.JPG',
                    viewport: {
                        width: 300,
                        height: 300,
                        type: 'square'
                    },
                    boundary: {}
                });
            })
        }
    }

    handleCroppedImage(resp, type){
        this.setState({
            dishImage: resp
        })
        if (type === "upload"){
            this.setState({
                currentDishUploadStep: 3
            })
        } else {
            this.props.toggleDishCardPreview({
                id: 1,
                name: 'Test Name',
                description: 'Test Description',
                image: resp,
                no_rating: 1,
                rating: 5,
                restaurantId: -1,
                restaurant: 'Test Restaurant',
                cost: 100,
                imageHover: false
            });
        }
    }

    cropImage(type) {
        const resImgWidth = 600; /*Output Image Width*/
        const resImgHeight = 600;
        window.dish_image.result({
        type: 'base64',
        size: {
            width: resImgWidth,
            height: resImgHeight
        },
        format : 'png',
        quality : 0
        }).then((resp) => this.handleCroppedImage(resp, type));
    }

    getUploadImageHeader(){
        if (this.state.currentDishUploadStep !== 3){
            return (
                <div className="UploadImageHeader">Upload Your <span style={{color: "#EB622F"}}>Best Dish</span> Image here</div>
            )
        } else {
            return (
                <div className="UploadImageHeader UploadImageSuccessHeader">Image Successfully Uploaded!</div>
            )
        }
    }

    uploadImagesForm(){
        return (
            <div className="PartnerWithUsFieldSuperContainer">
                {this.getUploadImageHeader()}
                {this.decideDishUploadForm()}
            </div>
        )
    }

    handleBrowseFileClick(event){
        event.stopPropagation();
        if (!this.state.contactDetailsSubmitting){
            this.dishImageRef.current.click();
        }
    }

    decideDishUploadForm(){
        if (this.state.currentDishUploadStep === 1){
            return(
                <div onClick={(event) => this.handleBrowseFileClick(event)} style={{cursor: "pointer"}} className="UploadImageFormContainer">
                    <Dropzone onDrop={files => this.onFileInputChange(files, "drop")}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div onClick={(event) => this.handleBrowseFileClick(event)} style={{fontSize: "13px", fontFamily: "Poppins-Regular", textAlign: "center"}}>Drag & Drop your file here</div>
                                <div onClick={(event) => this.handleBrowseFileClick(Event)} style={{fontSize: "13px", fontFamily: "Poppins-Regular", margin: "5px 0px", textAlign: "center"}}>Or</div>
                                <div style={{textAlign: "center", marginTop: "20px"}}>
                                    <span onClick={(event) => this.handleBrowseFileClick(event)} style={{padding: "8px 20px", fontSize: "13px", fontFamily: "Poppins-Regular", borderRadius: "5px", color: "white", backgroundColor: "#2B2624", cursor: "pointer"}}>Browse Files</span>
                                </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <input onChange={(event) => this.onFileInputChange(event, "select")} ref={this.dishImageRef} style={{display: "none"}} type="file" className="PartnerWithUsField" accept="image/png, image/gif, image/jpeg"/>
                </div>
            )
        } else if (this.state.currentDishUploadStep === 2) {
            return (
                <div>
                    <div className="UploadImageFormContainer UploadImageFormCropContainer">
                        <div id="output_dish_image"></div>
                    </div>
                    <div style={{margin: "6px auto", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center"}}>Drag the image to the portion you need</div>
                    <div style={{textAlign: "center"}}>
                        <span onClick={() => this.cropImage("preview")} style={{fontSize: "12px", fontFamily: "Poppins-Regular", color: "#EA5B30", borderBottom: "1px solid #EA5B30", cursor: "pointer"}}>Preview</span>
                    </div>
                    <div style={{textAlign: "center", marginTop: "20px"}}>
                        <div onClick={() => this.cropImage("upload")} style={{display: "inline-block", padding: "6px 30px", borderRadius: "5px", fontSize: "12px", fontFamily: "Poppins-Regular", backgroundColor: "#F8D542", cursor: "pointer"}}>
                            Upload
                        </div>
                    </div>
                    <div style={{margin: "15px", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center", color: "#3356AA", cursor: "pointer"}} onClick={() => this.setState({currentDishUploadStep: 1, dishImage: null, dishImageName: ""})}>Re-Upload</div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="DishPreviewImageContainer">
                        <img style={{width: "300px", height: "300px"}} src={this.state.dishImage} alt="Dish Card"/>
                    </div>
                    <div style={{margin: "15px", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center", color: "#3356AA", cursor: "pointer"}} onClick={() => this.setState({currentDishUploadStep: 1, dishImage: null, dishImageName: ""})}>Re-Upload</div>
                    <div onClick={() => window.history.back()} className="PartnerWithUsSubmitButtonContainer">
                        <div className="AuthFormSubmitButton">{this.state.imageUploading ? "Proceeding.." : "Proceed"}</div>
                    </div>
                </div>
            )
        }
    }

    render(){
        console.log(this.state.isMobileScreen);
        if (this.state.isMobileScreen) {
            return (
                <Grid container item xs={12} direction="column" justify="center">
                    <div className="PartnerWithUsFormContainer">
                        {this.contactFormHeader()}
                        {this.uploadImagesForm()}
                    </div>
                </Grid>
            )
        } else {
            return null;
        }
    }
}

export default ContactFormMobile;