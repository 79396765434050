import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import home_burger from '../../assets/images/partner_with_us_illustration.png';
import '../../assets/styles/Home.css';
import { withRouter } from 'react-router';
import logo from '../../assets/images/logo_white.png';
import logo_mobile from '../../assets/images/logo_mobile.png';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import '../../assets/styles/PartnerWithUs.css';
import "croppie/croppie.css";
import CONSTANTS from '../../config/constants';
import axios from 'axios';

// Top Header which contains logo, auth options, burger icon and header description
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
        this.toggleDishCardPreviewHandler = this.toggleDishCardPreviewHandler.bind(this);
        this.toggleRestaurantCardPreviewHandler = this.toggleRestaurantCardPreviewHandler.bind(this);
    }

    toggleDishCardPreviewHandler(dish){
        this.props.toggleDishCardPreview(dish);
    }

    toggleRestaurantCardPreviewHandler(dish){
        this.props.toggleRestaurantCardPreview(dish);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    getMobileComponents(isMobileScreen, headerStyle){
        if (isMobileScreen){
            return (
                <div style={headerStyle} className="Header">
                    {/* <div style={{position: "fixed", top: "0px", left: "0px", zIndex: "10000", width: "100vw"}}> */}
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <Logo/>
                        </div>
                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                            <BurgerImage/>
                        </Grid>
                    {/* </div> */}
                    {/* <div style={{marginTop: "84px"}}> */}
                    {/* </div>   */}
                </div> 
            )
        } else {
            return (
                <div style={headerStyle} className="Header">
                    {/* <div style={{position: "fixed", top: "0px", left: "0px", zIndex: "10000", width: "100vw"}}> */}
                        <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                            <Logo/>
                        </Grid>
                        <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                            <BurgerImage/>
                            <ContactForm
                                authModalOpen={this.props.authModalOpen}
                                history={this.props.history}
                                toggleDishCardPreview={this.toggleDishCardPreviewHandler}
                                toggleRestaurantCardPreview={this.toggleRestaurantCardPreviewHandler}
                            />
                        </Grid> 
                    {/* </div> */}
                    {/* <div style={{marginTop: "84px"}}> */}
                    {/* </div>   */}
                </div> 
            )
        }
    }

   render() {
    var headerStyle = {};
    if (this.state.isMobileScreen){
        headerStyle = {background: "linear-gradient(60deg, #FFFFFF 50%, #F9E841 50%)"};
    } else {
        headerStyle = {background: "linear-gradient(100deg, #F9E841 40%, #FFFFFF 40%)", height: "100vh"};
    }
    return (
        this.getMobileComponents(this.state.isMobileScreen, headerStyle)
    )
   }
}

// Website Logo
class Logo extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        
        if (this.state.isMobileScreen){
            return (
                <div className="Logo LogoHome">
                    {/* <Link  to={''} style={{ textDecoration: 'none' }}>
                        <img style={{cursor: "pointer", marginTop: "10px", marginLeft: "5px"}} src={logo_mobile} alt="" width={40} height={40}/>  
                    </Link> */}
                    <img style={{cursor: "pointer", marginTop: "10px", marginLeft: "5px"}} src={logo_mobile} alt="" width={40} height={40}/>  
                </div>
            )
        } else {
            return (
                <Grid container item xs={4}>
                    <React.Fragment>
                        <Grid item xs={12}>
                            <div className="Logo LogoHome">
                                {/* <Link  to={''} style={{ textDecoration: 'none' }}>
                                    <img style={{marginTop: "10px"}} className="LogoImage" src={logo} alt="logo"/>  
                                </Link> */}
                                <img style={{marginTop: "10px"}} className="LogoImage" src={logo} alt="logo"/>  
                            </div>
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }

    }
}

// Burger Image in the Header
class BurgerImage extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        var burgerContainerStyle = {};
        var burgerStyle = {};
        if (this.state.isMobileScreen){
            burgerContainerStyle = {paddingTop: "0px", paddingBottom: "20px", transform: "translateX(-5vw)"};
            burgerStyle = {width: '70vw', transform: 'translateX(-5vw)'};
        } else {
            burgerContainerStyle = {paddingTop: "20px", paddingBottom: "20px", paddingLeft: "0vw", transform: "translateY(-2vh)"};
            burgerStyle = {marginLeft: "0vw", width: "36vw"}
        }
        return (
            <Grid style={burgerContainerStyle} className="PartnerWithUsLogoContainer" container item direction="column" justifyContent="center" alignItems="center" xs={6}>
                <img style={burgerStyle} className="Burger" src={home_burger} alt="burger_img"/>
            </Grid>
        )
    }
}

// Description present in header
class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeScreen: "contact-details",
            restaurantEmail: "",
            restaurantEmailError: "",
            passwordValue: "",
            passwordError: "",
            passwordVisibility: "hidden",
            detailsSubmitting: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    togglePasswordVisibility(){
        if (this.state.passwordVisibility === "hidden"){
            this.setState({
                passwordVisibility: "visible"
            });
        } else {
            this.setState({
                passwordVisibility: "hidden"
            });
        }
    }

    passwordToggleIcon(type){
        if(this.state.passwordVisibility === "hidden"){
            return(
                <div style={{transform: "translateY(-5px)"}}>
                    <VisibilityIcon
                        fontSize="small"
                        style={{color: "black"}}
                    />
                </div>
            )
        } else {
            return(
                <div style={{transform: "translateY(-5px)"}}>
                    <VisibilityOffIcon
                        fontSize="small"
                        style={{color: "black"}}
                    />
                </div>
            )
        }
    }

    handleContactDetailsSubmit() {
        this.setState({
            restaurantEmailError: "",
            passwordError: "",
            detailsSubmitting: true
        })
        var isError = false;
        let email = this.state.restaurantEmail;
        let password = this.state.passwordValue;
        if (email === ''){
            this.setState({
                restaurantEmailError: "Please enter the restaurant username"
            }) 
            isError = true;
        }
        if (password === ''){
            this.setState({
                passwordError: "Please enter the password"
            }) 
            isError = true;
        }
        // var emailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        // if (!emailValidator.exec(email)){
        //     this.setState({
        //         restaurantEmailError: "Please enter a valid username"
        //     }) 
        //     isError = true;
        // }
        if (!isError){
            let url = CONSTANTS.hostURL + "api/resto-app/login/";
            let headers = {}
            let req = {
                username: this.state.restaurantEmail,
                password: this.state.passwordValue
            }
            axios.post(url, req,  {
                headers: headers
            }).then((response) => {
                // console.log(response);
                let loginResponse = response;
                if (response.data.status === 1){
                    let firebaseUrl = CONSTANTS.hostURL + "api/accounts/fcm/register/resto/";
                    if (localStorage.getItem('device_token')){
                        let firebaseReq = { 
                            'token': localStorage.getItem('device_token')
                        }
                        let firebaseHeaders = {
                            'Authorization': 'Bearer ' + response.data.data.access
                        }
                        axios.post(firebaseUrl, firebaseReq,  {
                            headers: firebaseHeaders
                        }).then((response) => {
                            // console.log(response);
                            let toProceed = true;
                            if (response.data.status === -1 && response.data.errors.code !== 'DUPLICATE'){  
                                toProceed = false;
                            }
                            if (toProceed === true){
                                localStorage.setItem('restaurant_id', loginResponse.data.data.restaurant.id);
                                localStorage.setItem('restaurant_details', JSON.stringify(loginResponse.data.data.restaurant));
                                localStorage.setItem('access_token', loginResponse.data.data.access);
                                localStorage.setItem('refresh_token', loginResponse.data.data.refresh);
                                this.props.history.replace('/restoApplication');
                            } else {
                                this.setState({
                                    passwordError: response.data.errors.message,
                                    detailsSubmitting: false
                                })
                            }
                        }).catch((error) => this.setState({
                            passwordError: error,
                            detailsSubmitting: false
                        }));
                    } else {
                        localStorage.setItem('restaurant_id', loginResponse.data.data.restaurant.id);
                        localStorage.setItem('restaurant_details', JSON.stringify(loginResponse.data.data.restaurant));
                        localStorage.setItem('access_token', loginResponse.data.data.access);
                        localStorage.setItem('refresh_token', loginResponse.data.data.refresh);
                        this.props.history.replace('/restoApplication');
                    }
                } else {
                    this.setState({
                        passwordError: response.data.message,
                        detailsSubmitting: false
                    })
                }
            }).catch((error) => this.setState({
                passwordError: error,
                detailsSubmitting: false
            }));
        } else {
            this.setState({
                detailsSubmitting: false
            })
        }
    }

    contactDetailsForm(){
        var passwordType;
        var passwordStyle;
        var passwordErrorStyle;
        if (this.state.passwordVisibility === "hidden"){
            passwordType = "password";
        } else {
            passwordType = "text";
        }
        if (this.state.passwordError){
            passwordStyle = {border: "1px solid red"};
            passwordErrorStyle = {display: "block"};
        } else {
            passwordStyle = {};
            passwordErrorStyle = {display: "none"};
        }
        return (
            <div className="PartnerWithUsFieldSuperContainer">
                <div className="PartnerWithUsFieldError">{this.state.contactDetailsError}</div>
                <div className="PartnerWithUsFieldContainer">
                    <div className="PartnerWithUsFieldName">
                        Username
                    </div>
                    <input style={{border: "#EBEBEB 1px solid"}} value={this.state.restaurantEmail} onChange={(event) => this.setState({restaurantEmail: event.target.value})} className="PartnerWithUsField" type="text" placeholder="Enter Restaurant Username Here"></input>
                    <div className="PartnerWithUsFieldError">{this.state.restaurantEmailError}</div>
                </div>
                <div className="PartnerWithUsFieldContainer">
                    <div className="PartnerWithUsFieldName">
                        Password
                    </div>
                    <div style={{position: "relative"}}>
                        <input id="password" style={passwordStyle} value={this.state.passwordValue} onChange={(event) => this.setState({passwordValue: event.target.value})} type={passwordType} className="PartnerWithUsField" placeholder="Password"></input>
                        <span onClick={() => this.togglePasswordVisibility()} className="PasswordToggle">{this.passwordToggleIcon("register")}</span>
                    </div>
                    <div style={passwordErrorStyle} className="PartnerWithUsFieldError">{this.state.passwordError}</div>
                </div>
                <div onClick={() => this.handleContactDetailsSubmit()} className="PartnerWithUsSubmitButtonContainer">
                    <div className="AuthFormSubmitButton">{this.state.detailsSubmitting ? "Submitting.." : "Submit"}</div>
                </div>
            </div>
        )
    }

    render(){
        // console.log(this.state.isMobileScreen);
        if (!this.state.isMobileScreen) {
            return (
                <Grid container item xs={6} direction="column" justifyContent="center" style={{height: "95vh", transform: "translateY(-5vh)"}}>
                    <div className="PartnerWithUsFormContainer">
                    <div className="PartnerWithUsHeader">
                        Welcome to  <span style={{color: "#EB622F"}}>Daidish</span>
                    </div>
                    <div className="AuthFormContainer">
                        <div style={{fontFamily: "Bree-SemiBold", display: "block", marginBottom: "2vh"}} className="AuthFormButtons LoginWelcomeOTP">Login to Continue</div>
                    </div>
                    {this.contactDetailsForm()}
                    </div>
                </Grid>
            )
        } else {
            return null;
        }
    }
}

export default withRouter(Header);