import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router';
import MenuBar from '../components/RestoApp/MenuBar';
import '../assets/styles/RestoApp.css';
import CONSTANTS from '../config/constants';
import DaiDishLoading1 from '../assets/images/DaiDishLoading1.gif';
import LogoutConfirm from '../components/RestoApp/LogoutConfirm';
import InformationBox from '../components/RestoApp/InformationBox';
import { setDeviceToken } from '../services/notification_handler';
import NewOrderPopup from '../components/RestoApp/NewOrderPopup';
import PopSound from '../assets/media/ding.mp3';
import TaxBreakdown from '../components/RestoApp/TaxBreakdown';

class RestoApp extends Component {

    constructor(props){
        super(props);
        this.toggleLogoutModal = this.toggleLogoutModal.bind(this);
        this.toggleNewOrderModal = this.toggleNewOrderModal.bind(this);
        this.setNewOrder = this.setNewOrder.bind(this);
        this.toggleOrderInfoClicked = this.toggleOrderInfoClicked.bind(this);
        this.updateRestoDetails = this.updateRestoDetails.bind(this);
        this.toggleInformationBox = this.toggleInformationBox.bind(this);
        this.informationBox = this.informationBox.bind(this);
        this.updateBestDishDetails = this.updateBestDishDetails.bind(this);
        this.getOrderList = this.getOrderList.bind(this);
        this.updateOrderStatus = this.updateOrderStatus.bind(this);
        this.toggleNewOrderPushed = this.toggleNewOrderPushed.bind(this);
        this.handleOrderFulfilledNotification = this.handleOrderFulfilledNotification.bind(this);
        this.toggleTaxBreakdown = this.toggleTaxBreakdown.bind(this);
        this.setTaxBreakdown = this.setTaxBreakdown.bind(this);
        this.state = {
          authModalOpen: false,
          activeForm: "",
          isMobileScreen: false,
          descriptionOverlayStyle: {display: 'none'},
          dishLoading : true,
          logoutModal: false,
          informationBox: false,
          restaurant: {},
          infoText: "",
          restoDetailsUpdated: false,
          bestDishes: [],
          newOrderModal: false,
          newOrderId: -1,
          orderInfoClicked: false,
          orders: [],
          newOrderPushed: false,
          newOrderText: '',
          audio: new Audio(PopSound),
          isPlaying: false,
          taxBreakdown: {},
          showTaxBreakdown: false
        }
    }

    componentDidMount() {
        // this.playPause();
        window.scrollTo(0, 0);
        if (localStorage.getItem("restaurant_id") === null){
          this.props.history.replace("/");
        } else {
            this.setState({
              restaurant: JSON.parse(localStorage.getItem("restaurant_details"))
            })
        }
        setDeviceToken();
        this.getOrderList();
        navigator.serviceWorker.addEventListener('message', event => {
            console.log(event.data.data);
            if ('data' in event.data.data){
                var orderDetails = JSON.parse(event.data.data.data);
                var orderEvent = event.data.data.event;
                console.log('orderEvent', orderEvent);
                if(orderDetails.resto_id === JSON.parse(localStorage.getItem("restaurant_details")).id){
                    if (orderEvent === 'new_order'){
                        this.playPause(2);
                        this.setState({
                            newOrderModal: true,
                            newOrderId: orderDetails.order_id,
                            newOrderText: orderEvent === 'new_order' ? 'A new order has arrived.' : 'Order ID : ' + orderDetails.daidish_order_id + ' has been fulfilled.'
                        })
                    }
                    else if(orderEvent === 'restaurant_closing_soon'){
                        this.playPause(1);
                        this.informationBox("Restaurant closing in 30mins.")
                    }
                    else if (orderEvent === 'order_fulfilled'){
                        // this.playPause(1);
                        this.handleOrderFulfilledNotification(orderDetails.daidish_order_id);
                    }
                    // else{
                    //     this.playPause(1);
                    // }
                } 
            }
        });
    }

    handleOrderFulfilledNotification(daidish_order_id){
        let orders = this.state.orders;
        let orderIndex = -1;
        for (var i=0; i<orders.length; i++){
            if (orders[i].order_id === daidish_order_id){
                orderIndex = i;
                break;
            }
        }
        if (orderIndex !== -1){
            this.updateOrderStatus(orderIndex, 'FULFILLED', true);
        }
    }

    playPause = (times) => {
        this.state.audio.play();
        setTimeout(() => {
            if(times > 1){
                times = times -1;
                this.playPause(times);
            }
        }, 2000);
    }

    getOrderList() {
        let url = CONSTANTS.hostURL + "api/resto-app/orders/active-orders/";
        let headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
          }
        axios.get(url, {
            headers: headers
          }).then(res => {
            if (res.status !== 401 && res.data.status !== -1){
                let orderList = res.data.data.orders;
                let orderIdList = [];
                Object.keys(orderList).map(function(key, index) {
                    orderIdList.push.apply(orderIdList, orderList[key]);
                    return true;
                });
                if (orderIdList.length > 0){
                    let ordersUrl = CONSTANTS.hostURL + "api/resto-app/orders/get-by-ids/?order_ids=" + orderIdList.join(",");
                    axios.get(ordersUrl, {
                        headers: headers
                    }).then(res => {
                        if (res.status !== 401 && res.data.status !== -1){
                            this.setState({
                                orders : res.data.data.orders
                            });
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            } else {
                let url = CONSTANTS.hostURL + "api/resto-app/login/refresh-session/";
                let refreshReq = {
                    'refresh' : localStorage.getItem('refresh_token')
                }
                axios.post(url, refreshReq,  {
                    headers: {}
                }).then((response) => {
                    if (response.data.status === 1){
                    localStorage.setItem('access_token', response.data.data.access);
                    localStorage.setItem('refresh_token', response.data.data.refresh);
                    this.getOrderList();
                }  else {
                    localStorage.removeItem("restaurant_id");
                    localStorage.removeItem("restaurant_details");
                    localStorage.removeItem("access_token");
                    localStorage.removeItem("refresh_token");
                    this.props.history.replace("/");
                }}).catch(error => {
                    localStorage.removeItem("restaurant_id");
                    localStorage.removeItem("restaurant_details");
                    localStorage.removeItem("access_token");
                    localStorage.removeItem("refresh_token");
                    this.props.history.replace("/");
                });
            }
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 401){
                let url = CONSTANTS.hostURL + "api/resto-app/login/refresh-session/";
                let refreshReq = {
                  'refresh' : localStorage.getItem('refresh_token')
                }
                axios.post(url, refreshReq,  {
                  headers: {}
                }).then((response) => {
                  if (response.data.status === 1){
                    localStorage.setItem('access_token', response.data.data.access);
                    localStorage.setItem('refresh_token', response.data.data.refresh);
                    this.getOrderList();
                  } else {
                    localStorage.removeItem("restaurant_id");
                    localStorage.removeItem("restaurant_details");
                    localStorage.removeItem("access_token");
                    localStorage.removeItem("refresh_token");
                    this.props.history.replace("/");
                  }
                }).catch((error) => {
                  localStorage.removeItem("restaurant_id");
                  localStorage.removeItem("restaurant_details");
                  localStorage.removeItem("access_token");
                  localStorage.removeItem("refresh_token");
                  this.props.history.replace("/");
                });
              }
            }
          });
    }

    componentDidUpdate() { 
        if(this.state.isOpen || this.state.logoutModal ){
          this.disableScrolling();
          document.getElementById("main-content").style.overflow = "hidden";
          document.getElementById("main-content").style.height = "100vh";
        } else {
          this.enableScrolling();
          document.getElementById("main-content").style.overflow = "scroll";
          document.getElementById("main-content").style.height = "auto";
        }
    }

    updateRestoDetails(restaurant){
        this.setState({
            restaurant: restaurant,
            restoDetailsUpdated: true
        })
    }

    updateBestDishDetails(bestDishes){
        this.setState({
            bestDishes: bestDishes
        })
    }

    disableScrolling() {// mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
    }

    enableScrolling() {
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
    }

    toggleLogoutModal() {
        this.setState({
          logoutModal: !this.state.logoutModal
        })
    }

    toggleNewOrderModal() {
        this.setState({
          newOrderModal: !this.state.newOrderModal
        })
    }

    setNewOrder(order) {
        let orders = this.state.orders;
        let orderIndex = -1;
        for (var i=0; i<orders.length; i++){
            if (orders[i].id === order.id){
                orderIndex = i;
                break;
            }
        }
        if (orderIndex !== -1){
            this.updateOrderStatus(orderIndex, 'FULFILLED');
            return;
        }
        orders.splice(0, 0, order);
        this.setState({
            orders: orders,
            newOrderPushed: true
        })
    }

    updateOrderStatus(index, status, fulfilledNotification){
        let orders = this.state.orders;
        let newOrder = orders[index];
        newOrder.order_status = status;
        orders[index] = newOrder;
        this.setState({
            orders: orders
        }, () => {
            if (fulfilledNotification === true){
                this.setState({
                    newOrderPushed: true
                })
            }
        })
    }

    toggleOrderInfoClicked() {
        this.setState({
          orderInfoClicked: !this.state.orderInfoClicked
        })
    }
    
    toggleInformationBox() {
        this.setState({
            informationBox: !this.state.informationBox
        })
    }

    toggleTaxBreakdown() {
        this.setState({
            showTaxBreakdown: !this.state.showTaxBreakdown
        })
    }

    setTaxBreakdown(taxBreakdown) {
        this.setState({
            taxBreakdown: taxBreakdown
        }) 
    }

    informationBox(question){
        this.setState({
            infoText: question
        });
        this.toggleInformationBox();
    }

    updateLoadingScreen = (newtext) => {
        setTimeout(
            () => this.setState({ dishLoading: newtext }), 
            1000
        );
    }

    toggleNewOrderPushed(){
        this.setState({
            newOrderPushed: false
        })
    }

    render() {
        // const { restaurant_id } = this.props.match.params;
        const restaurant_id = localStorage.getItem("restaurant_id") === null ? -1 : localStorage.getItem("restaurant_id");
        if (restaurant_id === -1){
            return null;
        } else {
            return (
                <div id="main-content">
                    <div className="loadingGif" style={this.state.dishLoading || this.state.moreDishesLoading || this.state.dishTypeLoading ? {visibility:"visible", opacity: '0.7'} : {visibility:"hidden", opacity: '0'}}>
                        <img src={DaiDishLoading1} alt="DaiDish Loading..." style={this.state.isMobileScreen ? {width:"100%", marginTop:"25%"} : {width:"40%", marginTop:"0%"}}/>
                    </div>
                    <MenuBar 
                        restaurant_id = {restaurant_id}
                        updateLoadingScreen = {this.updateLoadingScreen}
                        toggleLogoutModal={this.toggleLogoutModal}
                        restaurant={this.state.restaurant}
                        updateRestoDetails={this.updateRestoDetails}
                        updateBestDishDetails={this.updateBestDishDetails}
                        informationBox={this.informationBox}
                        history={this.props.history}
                        restoDetailsUpdated={this.state.restoDetailsUpdated}
                        bestDishes={this.state.bestDishes}
                        newOrderId={this.state.newOrderId}
                        setNewOrder={this.setNewOrder}
                        toggleNewOrderModal={this.toggleNewOrderModal}
                        orderInfoClicked={this.state.orderInfoClicked}
                        toggleOrderInfoClicked={this.toggleOrderInfoClicked}
                        orders={this.state.orders}
                        updateOrderStatus={this.updateOrderStatus}
                        newOrderPushed={this.state.newOrderPushed}
                        toggleNewOrderPushed={this.toggleNewOrderPushed}
                        getOrderList={this.getOrderList}
                        toggleTaxBreakdown={this.toggleTaxBreakdown}
                        setTaxBreakdown={this.setTaxBreakdown}
                    />
                    <LogoutConfirm
                        showLogoutModal={this.state.logoutModal}
                        toggleLogoutModal={this.toggleLogoutModal}
                    />
                    <NewOrderPopup
                        showNewOrderModal={this.state.newOrderModal}
                        newOrderId={this.state.newOrderId}
                        toggleOrderInfoClicked={this.toggleOrderInfoClicked}
                        newOrderText={this.state.newOrderText}
                    />
                    <InformationBox
                        infoText={this.state.infoText}
                        showInformationBox={this.state.informationBox}
                        toggleInformationBox={this.toggleInformationBox}
                    />
                    <TaxBreakdown
                        taxBreakdown={this.state.taxBreakdown}
                        showTaxBreakdown={this.state.showTaxBreakdown}
                        toggleTaxBreakdown={this.toggleTaxBreakdown}
                    />
                </div>
            );
        }
    }
}

export default withRouter(RestoApp);
