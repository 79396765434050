import React, {Component} from "react";
import '../../assets/styles/Commons.css';
import new_order from '../../assets/images/new_order.svg';

class NewOrderPopup extends Component {
    constructor(props){
        super(props);
        this.popupContainer = this.popupContainer.bind(this);
    }

    handleModalClick(e) {
        e.stopPropagation();
    }

    popupContainer() {
        return (
            <div className="RatingInformationContainer">
                <div className="RatingInformationTop" style={{backgroundColor: '#FAF0C8'}}>
                    <div className="RatingInformationImagesContainer">
                        <img style={{display: 'block', margin: 'auto', width: '150px'}} className="RatingInformationImage CenterImage" src={new_order} alt="Rating Information 1"/>
                    </div>
                </div>
                <div style={{fontFamily: 'Bree-SemiBold'}} className="RatingInformationHeader">
                    New Order!
                </div>
                <div className="RatingInformationSubHeader">
                    You have just received a new order
                </div>
                <div style={{flex: "1"}}></div>
                <div onClick={() => {
                    this.props.toggleOrderInfoClicked();
                }} className="DishRateModalSubmitButtonContainer" style={{margin: "4vh auto", backgroundColor: '#FCD844', color: 'black', padding: '15px 100px', borderRadius: '10px'}}>
                    View Details
                </div>
            </div>
        )
    }
    
    render() {
        if(!this.props.showNewOrderModal){
            return null;
        }
        return(
            <div className="RatingModal">
                <div style={{margin: "auto", maxWidth: "600px", width: "92vw"}} onClick={(e) => this.handleModalClick(e)} className="">
                    {this.popupContainer()}
                </div>
            </div>
        )
    }
}

export default NewOrderPopup;