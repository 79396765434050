import React, { Component } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CONSTANTS from '../../config/constants';
import DaidishWebLogo from '../../assets/images/logo_black.png'
import DaidishMobileLogo from '../../assets/images/logo_mobile.png'
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import ManageResto from './ManageResto';
import ManageMenu from './ManageMenu';
import InsightsScreen from './InsightsScreen'
import OrderScreen from './OrderScreen'
import { RiLogoutBoxLine } from "react-icons/ri";
import { MdRestaurantMenu } from "react-icons/md";
import { MdOutlineHome } from "react-icons/md";
import { VscGraphLine } from "react-icons/vsc";
import { FiLayers } from "react-icons/fi";
import { MdHistory } from 'react-icons/md';
import OrderInfo from './OrderInfo';
import OrderHistory from './OrderHistory';
import ProfileScreen from './ProfileScreen'
import { IoMdSettings } from "react-icons/io";

class MenuBar extends Component {
  constructor(props){
    super();
    this.toggleMenuDimension = this.toggleMenuDimension.bind(this);
    this.handleMenuSelectionClick = this.handleMenuSelectionClick.bind(this);
    this.getRestaurantSubMenus = this.getRestaurantSubMenus.bind(this);
    // this.getDishCount = this.getDishCount.bind(this);
    this.updateRestoDetailsHandler = this.updateRestoDetailsHandler.bind(this);
    this.updateSubMenuListHandler = this.updateSubMenuListHandler.bind(this);
    this.toggleOrderInfo = this.toggleOrderInfo.bind(this);
    this.setOrder = this.setOrder.bind(this);
    this.updateBestDishDetailsHandler = this.updateBestDishDetailsHandler.bind(this);
    this.handleOnlineSwitch = this.handleOnlineSwitch.bind(this);
    this.handleDeliverySwitch = this.handleDeliverySwitch.bind(this);
    this.updateOrderStatusHandler = this.updateOrderStatusHandler.bind(this);
    this.toggleNewOrderPushedHandler = this.toggleNewOrderPushedHandler.bind(this);
    this.toggleOnlineStateChanged= this.toggleOnlineStateChanged.bind(this);
    this.state = {
      authModalOpen: false,
      activeForm: "",
      isMobileScreen: false,
      descriptionOverlayStyle: {display: 'none'},
      expandedMenu: true,
      leftMenuWidth: 2,
      rightMenuWidth: 10,
      menuArrow: "buttonFlip",
      selectedMenu: 0,
      sub_menus_id: [],
      sub_menus_name: [],
      showOrderInfo: false,
      order: {},
      isOnline: false,
      isDelivery: false,
      restoDetailsFetched: false,
      onlineStateChanged: false,
      popularDishesCount: 0,
      itemsConfig : [
        {
          icon: MdOutlineHome,
          text: "Manage Resto",
        },
        {
          icon: MdRestaurantMenu,
          text: "Manage Menu",
        },
        {
          icon: VscGraphLine,
          text: "Insights",
        },
        {
          icon: MdHistory,
          text: "Order History",
        },
        {
          icon: FiLayers,
          text: "Orders",
        },
        {
          icon: IoMdSettings,
          text: "Profile",
        },
      ]
    }
  }

  updateOrderStatusHandler(index, status){
    this.props.updateOrderStatus(index, status);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.getRestaurantSubMenus();
    if (localStorage.getItem('dish_image_id') !== null){
      this.setState({
        selectedMenu: 1
      })
    }
  }

  componentDidUpdate(){
    if (this.props.restaurant.id !== null && this.props.restoDetailsUpdated && this.state.restoDetailsFetched === false){
      this.setState({
        restoDetailsFetched: true
      })
      let restaurant_details = this.props.restaurant;
      this.setState({
        isOnline: restaurant_details.is_accepting_orders === true ? true : false,
        isDelivery: restaurant_details.is_delivery_available === true ? true : false,
      });
    }
    if (this.props.orderInfoClicked === true){
      let menuIndex = -1;
      for (var i=0; i<this.state.itemsConfig.length; i++){
        if (this.state.itemsConfig[i].text === 'Orders'){
          menuIndex = i;
          break;
        }
      }
      if (this.state.selectedMenu !== menuIndex){
        this.setState({
          selectedMenu: menuIndex
        });
      } else {
        // handle order notification if already in orders screen
        // console.log('New Order ID received');
        // console.log(this.props.newOrderId);
      }
      this.props.toggleNewOrderModal();
      this.props.toggleOrderInfoClicked();
      let url = CONSTANTS.hostURL + "api/resto-app/orders/get-by-ids/?order_ids=" + this.props.newOrderId;
      let headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
      axios.get(url, {
        headers: headers
      }).then(res => {
        if (res.data.status === 1){
          // console.log('order details received');
          // console.log(res.data);
          this.props.setNewOrder(res.data.data.orders[0]);
        }
      });
    }
  }

  updateDimensions() {
    let screen_width = window.innerWidth;
    if(screen_width < 500) {
        this.setState({ isMobileScreen: true });
    } else {
        this.setState({ isMobileScreen: false });
    }
  }

  updateBestDishDetailsHandler(bestDishes){
    this.props.updateBestDishDetails(bestDishes);
  }

  getRestaurantSubMenus(){
    // console.log('restaurant id');
    // console.log(this.props.restaurant_id);
    let urlForSubMenus = CONSTANTS.hostURL + "api/resto-app/restaurant/sub-menu";
    this.props.updateLoadingScreen(true);
    let headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    }
    axios.get(urlForSubMenus, {
      headers: headers
    }).then(res => {
      if (res.status !== 401 && res.data.status !== -1){
        let result = res.data.data.sub_menus;
        let sub_menus_name = [];
        for (var i=0; i<result.length; i++){
          sub_menus_name.push({
            "id": result[i].id, 
            "name": result[i].name, 
            "count": result[i].dish_count, 
            "avl": result[i].is_visible
          });
        }
        this.setState({sub_menus_name : sub_menus_name});
        let restoUrl = CONSTANTS.hostURL + "api/resto-app/restaurant/get-details";
        axios.get(restoUrl, {
          headers: headers
        }).then(res => {
          if (res.data.status === 1){
            localStorage.setItem('restaurant_details', JSON.stringify(res.data.data.restaurant));
            this.setState({popularDishesCount: JSON.parse(localStorage.getItem('restaurant_details')).popular_dishes.length})
            this.props.updateRestoDetails(res.data.data.restaurant);
            let bestDishUrl = CONSTANTS.hostURL + "api/resto-app/best-dish/";
            axios.get(bestDishUrl, {
              headers: headers
            }).then(res => {
              if (res.data.status === 1){
                this.props.updateBestDishDetails(res.data.data);
              } else {
                this.props.informationBox("Network or server error. Please log out and re-login again.");
              }
            }).catch((error) => this.props.informationBox("Network or server error. Please log out and re-login again."));
          } else {
            this.props.informationBox("Network or server error. Please log out and re-login again.");
          }
        }).catch((error) => this.props.informationBox("Network or server error. Please log out and re-login again."));
      } else {
        let url = CONSTANTS.hostURL + "api/resto-app/login/refresh-session/";
        let refreshReq = {
          'refresh' : localStorage.getItem('refresh_token')
        }
        axios.post(url, refreshReq,  {
          headers: {}
        }).then((response) => {
          if (response.data.status === 1){
            localStorage.setItem('access_token', response.data.data.access);
            localStorage.setItem('refresh_token', response.data.data.refresh);
            this.getRestaurantSubMenus();
          } else {
            localStorage.removeItem("restaurant_id");
            localStorage.removeItem("restaurant_details");
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            this.props.history.replace("/");
          }
        }).catch(error => {
          localStorage.removeItem("restaurant_id");
          localStorage.removeItem("restaurant_details");
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          this.props.history.replace("/");
        });
      }
    },this.props.updateLoadingScreen(false)
    ).catch((error) => {
      if (error.response) {
        if (error.response.status === 401){
          let url = CONSTANTS.hostURL + "api/resto-app/login/refresh-session/";
          let refreshReq = {
            'refresh' : localStorage.getItem('refresh_token')
          }
          axios.post(url, refreshReq,  {
            headers: {}
          }).then((response) => {
            if (response.data.status === 1){
              localStorage.setItem('access_token', response.data.data.access);
              localStorage.setItem('refresh_token', response.data.data.refresh);
              this.getRestaurantSubMenus();
            } else {
              localStorage.removeItem("restaurant_id");
              localStorage.removeItem("restaurant_details");
              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");
              this.props.history.replace("/");
            }
          }).catch((error) => {
            localStorage.removeItem("restaurant_id");
            localStorage.removeItem("restaurant_details");
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            this.props.history.replace("/");
          });
        }
      }
    });
  }

  toggleMenuDimension(){
    if(this.state.expandedMenu){
      this.setState({
        expandedMenu: false,
        leftMenuWidth: 1,
        rightMenuWidth: 11,
        menuArrow: "buttonStraight",
      });
    }
    else{
      this.setState({
        expandedMenu: true,
        leftMenuWidth: 2,
        rightMenuWidth: 10,
        menuArrow: "buttonFlip",
      })
    }
  }

  handleMenuSelectionClick = (event, index) => {
    this.setState({
      selectedMenu: index
    });
  };

  handleOnlineSwitch() {
    this.setState({
      onlineStateChanged: true
    })
    let req = {};
    if (this.state.isOnline === true){
      req = {"is_accepting_orders": false};
    } else {
      req = {"is_accepting_orders": true};
    }
    if (this.state.isOnline){
      this.setState({
        isOnline: false,
        isDelivery: false
      });
    } else {
      this.setState({
        isOnline: true
      });
    }
    let url = CONSTANTS.hostURL + "api/resto-app/restaurant/update-serviceability/";
    let headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    }
    axios.post(url, req,  {
      headers: headers
    }).then((response) => {
      if (response.data.status === 1){
        let restaurant_details = this.props.restaurant; 
        restaurant_details.is_accepting_orders = this.state.isOnline;
        localStorage.setItem('restaurant_details', JSON.stringify(restaurant_details));
        this.props.updateRestoDetails(restaurant_details);
      } else {
        if (!this.state.isOnline){
          this.setState({
            isOnline: true,
            isDelivery: true
          });
        } else {
          this.setState({
            isOnline: false
          });
        }
        this.props.informationBox("Network or server error. Please try again later");
      }
    }).catch((err) => {
      if (!this.state.isOnline){
        this.setState({
          isOnline: true,
          isDelivery: true
        });
      } else {
        this.setState({
          isOnline: false
        });
      }
      this.setState({
        onlineStateChanged: true
      })
      this.props.informationBox("Network or server error. Please try again later");
    });
  }

  toggleOnlineStateChanged(){
    this.setState({
      onlineStateChanged: !this.state.onlineStateChanged
    })
  }

  handleDeliverySwitch() {
    if (this.state.isDelivery === false && this.state.isOnline === false){
      this.props.informationBox("Please make the restaurant online first.");
    } else {
      let req = {};
      if (this.state.isDelivery === true){
        req = {"is_delivery_available": false};
      } else {
        req = {"is_delivery_available": true};
      }
      let url = CONSTANTS.hostURL + "api/resto-app/restaurant/update-deliverability/";
      let headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
      this.setState({
        isDelivery: !this.state.isDelivery
      });
      axios.post(url, req,  {
        headers: headers
      }).then((response) => {
        if (response.data.status === 1){
          let restaurant_details = this.props.restaurant; 
          restaurant_details.is_delivery_available = this.state.isDelivery;
          localStorage.setItem('restaurant_details', JSON.stringify(restaurant_details));
          this.props.updateRestoDetails(restaurant_details);
        } else {
          this.setState({
            isDelivery: !this.state.isDelivery
          });
          this.props.informationBox("Network or server error. Please try again later");
        }
      }).catch((err) => {
        this.setState({
          isDelivery: !this.state.isDelivery
        });
        this.props.informationBox("Network or server error. Please try again later");
      });
    }
  }

  RestoCompletionStatus(){
    const BorderLinearProgress = withStyles((theme) => ({
      root: {
        height: 10,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: '#FFF1B4',
      },
      bar: {
        borderRadius: 5,
        backgroundColor: '#EA5B2F',
      },
    }))(LinearProgress);


    return(
      <div>
        <Grid container spacing={2} className="restoCompletionStatus">
          <Grid item xs={12}>
            <span className="restoName">{this.props.restaurant.id === undefined ? 'Daidish Resto App' : this.props.restaurant.name}</span>
          </Grid>
          <Grid item xs={12}>
            <div style={{display: "flex", flexDirection: "row"}}>
              <div style={{flex: "1"}}>
                <span style={{fontFamily: "Poppins-SemiBold", fontSize: "14px", color: "#EB622F"}}>Online</span>
              </div>
              <div style={{flex: "1", textAlign: "right", paddingRight: "10px"}}>
                <span className="dishAvailabilitySwitch">
                  <span className="inStockButton" onClick={() => this.handleOnlineSwitch()}>
                    <span className="inStockSlider" style={this.state.isOnline === true ? {left:'11px', boxShadow: '2px 0px 2px 0.5px #aaaaaa', background: '#0C950C'} : {left:'-11px', boxShadow: '-2px 0px 2px 0.5px #aaaaaa', background: '#DEE8FF'}}></span>
                  </span>
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{display: "flex", flexDirection: "row"}}>
              <div style={{flex: "1"}}>
                <span style={{fontFamily: "Poppins-SemiBold", fontSize: "14px", color: "#EB622F"}}>Delivery</span>
              </div>
              <div style={{flex: "1", textAlign: "right", paddingRight: "10px"}}>
                <span className="dishAvailabilitySwitch">
                  <span className="inStockButton" onClick={() => this.handleDeliverySwitch()}>
                    <span className="inStockSlider" style={this.state.isDelivery === true ? {left:'11px', boxShadow: '2px 0px 2px 0.5px #aaaaaa', background: '#0C950C'} : {left:'-11px', boxShadow: '-2px 0px 2px 0.5px #aaaaaa', background: '#DEE8FF'}}></span>
                  </span>
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="infoTags">Complete Resto Setup</div>
            <BorderLinearProgress variant="determinate" value={20} />
            <div className="infoTags2">5 steps left</div>
          </Grid>
        </Grid>
        <hr style={{borderTop:"1px solid #bbb"}}/>
      </div>
    );
  }

  RestoMenuWebView(){
    const { selectedMenu } = this.state;
    return(
      <div style={{padding: "20px 0px"}}>
        <Grid container spacing={1}>
        {this.state.itemsConfig.map((item, index) => {
          const Icon = item.icon;
          return(
            <Grid key={index} item xs={12}>
              <div 
                onClick={event => this.handleMenuSelectionClick(event, index)}
                className={selectedMenu === index ? "menuItemsSelected" : "menuItems"}><Icon className="menuIcons" style={{ fontSize: 25 }}/><span className="menuText" style={this.state.expandedMenu ? {display:"inline"} : {display:"none"}}>{item.text}</span>
              </div>
            </Grid>
          );
        })}
          <Grid item xs={12}>
            <div 
              onClick={() => this.props.toggleLogoutModal()}
              className="menuItems"
              ><RiLogoutBoxLine className="menuIcons" style={{ fontSize: 25 }}/><span className="menuText" style={this.state.expandedMenu ? {display:"inline"} : {display:"none"}}>Logout</span>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  updateRestoDetailsHandler(restaurant) {
    this.props.updateRestoDetails(restaurant);
  }

  updateSubMenuListHandler(subMenuNames){
    this.setState({
      sub_menus_name: subMenuNames
    })
    this.getRestaurantSubMenus();
  }

  toggleOrderInfo() {
    this.setState({
      showOrderInfo: !this.state.showOrderInfo
    })
  }

  setOrder(order){
    this.setState({
      order: order
    })
    this.toggleOrderInfo();
  }

  toggleNewOrderPushedHandler(){
    this.props.toggleNewOrderPushed();
  }

  render() {
    return(
      <div>
      <AppBar position="static" style={{backgroundColor:"#FFF"}}>
        <Toolbar>
            <img src={!this.state.isMobileScreen ? DaidishWebLogo : DaidishMobileLogo} className={!this.state.isMobileScreen ? "daidishWebLogo" : "daidishMobileLogo"} alt="Daidish" />
        </Toolbar>
      </AppBar>
      <Grid container spacing={0}>
        <Grid item xs={this.state.leftMenuWidth} className="leftMenuPane">
          {this.RestoCompletionStatus()}
          {this.RestoMenuWebView()}
          {/* <span className="buttonStyle" onClick={this.toggleMenuDimension} >
            <KeyboardArrowRightIcon className={this.state.menuArrow} style={{ fontSize: 35 }} />
          </span> */}
        </Grid>
        <Grid item xs={this.state.rightMenuWidth} className="rightMenuPane" style={{overflow:"auto"}}>
          {this.state.selectedMenu === 0 && <ManageResto 
            updateRestoDetails={this.updateRestoDetailsHandler} 
            restaurant={this.props.restaurant}
            informationBox={this.props.informationBox}
            restoDetailsUpdated={this.props.restoDetailsUpdated}
            handleDeliverySwitch={this.handleDeliverySwitch}
            isDelivery={this.state.isDelivery}/>}
          {this.state.selectedMenu === 1 && 
            <ManageMenu 
              restaurant_id = {this.props.restaurant_id}
              sub_menus_name = {this.state.sub_menus_name}
              updateSubMenuList={this.updateSubMenuListHandler}
              informationBox={this.props.informationBox}
              bestDishes={this.props.bestDishes}
              restaurant={this.props.restaurant}
              updateBestDishDetails={this.updateBestDishDetailsHandler}
              getRestaurantSubMenus={this.getRestaurantSubMenus}
              popularDishesCount={this.state.popularDishesCount}
            />
          }
          {this.state.selectedMenu === 2 && <InsightsScreen
            restaurant={this.props.restaurant}
            setOrder={this.setOrder}
            toggleTaxBreakdown={this.props.toggleTaxBreakdown}
            setTaxBreakdown={this.props.setTaxBreakdown}
          />}
          {this.state.selectedMenu === 3 && <OrderHistory
            restaurant={this.props.restaurant}
            setOrder={this.setOrder}
            informationBox={this.props.informationBox}
          />}
          {this.state.selectedMenu === 4 && <OrderScreen
            setOrder={this.setOrder} 
            orders={this.props.orders}
            updateOrderStatus={this.updateOrderStatusHandler}
            toggleNewOrderPushed={this.toggleNewOrderPushedHandler}
            newOrderPushed={this.props.newOrderPushed}
            handleOnlineSwitch={this.handleOnlineSwitch}
            restaurant={this.props.restaurant}
            toggleOnlineStateChanged={this.toggleOnlineStateChanged}
            onlineStateChanged={this.state.onlineStateChanged}
            getOrderList={this.props.getOrderList}
            updateRestoDetails={this.updateRestoDetailsHandler}
            informationBox={this.props.informationBox}
          />}
          {this.state.selectedMenu === 5 && <ProfileScreen />}
        </Grid>
      </Grid>
      <OrderInfo 
        order={this.state.order}
        showOrderInfo={this.state.showOrderInfo}
        toggleOrderInfo={this.toggleOrderInfo}
      />
    </div>
    );
  }
}

export default MenuBar;
