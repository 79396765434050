import React, { Component } from 'react';
import Header from '../components/Login/Header';
import { withRouter } from 'react-router';
import { setDeviceToken } from '../services/notification_handler';

// Initial Landing Page
class Login extends Component {
  
  constructor(){
    super();
    this.toggleAuthModal = this.toggleAuthModal.bind(this);
    this.setActiveForm = this.setActiveForm.bind(this);
    this.closeAuthModal = this.closeAuthModal.bind(this);
    this.state = {
      authModalOpen: false,
      activeForm: "",
      isMobileScreen: false,
      descriptionOverlayStyle: {display: 'none'},
      intervalId: ''
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (localStorage.getItem("restaurant_id") !== null){
      this.props.history.replace("/restoApplication");
    }
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    setDeviceToken();
  }

  updateDimensions() {
    let screen_width = window.innerWidth;
    if(screen_width < 500) {
        this.setState({ isMobileScreen: true });
    } else {
        this.setState({ isMobileScreen: false });
    }
  }

  preventDefault(e) {
    e.preventDefault();
  }

  preventDefaultForScrollKeys(e) {
    var keys = {9: 1, 38: 1, 40: 1, 32: 1, 33: 1, 34: 1, 35: 1, 36: 1};
    if (keys[e.keyCode]) {
      e.preventDefault();
      return false;
    }
  }

  disableScrolling(wheelOpt, wheelEvent) {
    window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
    window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  enableScrolling(wheelOpt, wheelEvent) {
    window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
    window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
    window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
    window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  componentDidUpdate() {   
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    if(this.state.authModalOpen && !this.state.isMobileScreen){
      this.disableScrolling(wheelOpt, wheelEvent);
    } else {
      this.enableScrolling(wheelOpt, wheelEvent);
    }
  }

  componentWillUnmount(){
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    this.enableScrolling(wheelOpt, wheelEvent);
    if (this.state.intervalId !== ''){
      clearInterval(this.state.intervalId);
    }
  }

  toggleAuthModal() {
    this.setState({
      authModalOpen: true
    })
  }

  setActiveForm(form){
    if (this.state.activeForm !== form){
      this.setState({
        activeForm: form
      })
    }
  }

  closeAuthModal(){
    if (this.state.authModalOpen){
      this.setState({
        authModalOpen: false
      })
    }
  }

  render() {
    return (
      <div>
        <Header
          toggleAuthModal={this.toggleAuthModal}
          setActiveForm={this.setActiveForm}
          authModalOpen={this.state.authModalOpen}
          toggleDishCardPreview={this.toggleDishCardPreview}
          toggleRestaurantCardPreview={this.toggleRestaurantCardPreview}
        />
      </div>
    );
  }
}

export default withRouter(Login);
