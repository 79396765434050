import React, {Component} from "react";
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { IoTriangleSharp } from 'react-icons/io5';
import { LocationOnOutlined } from "@material-ui/icons";
import { RiInformationLine } from "react-icons/ri";

class OrderInfo extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this);
    }

    closeModalHandler() {
        this.props.toggleOrderInfo();
    }

    handleModalClick(e) {
        e.stopPropagation();
    }

    getOrderDetails(){
        return <>
            {
                Object.keys(this.props.order.items).map((key, itemIndex) => 
                    this.getOrderItemsNameList(this.props.order.items[key], this.props.order.items[key].name, this.props.order.items[key].choice)
                ) 
            }
        </>;
    }

    getOrderItemsNameList(item, name, choice){
        console.log('item');
        console.log(item);
        return <>
            {
                item.customizations.map((customization, itemIndex) => 
                    <>
                        {this.getCustomisation(customization.combination, customization.quantity, name, choice)}
                        {this.getOrderItemsPrice(customization.sub_total)}
                    </>
            )} 
        </>;
    }

    getOrderItems(){
        return <Grid className="OrderItemsScroll" style={{maxHeight: 'calc(100vh - 500px)', overflow: 'scroll', paddingRight: '20px'}} spacing={0} alignItems="start" container>
            {this.getOrderDetails()}
        </Grid>;
    }

    getCustomisation(combination, quantity, name, choice){
        let customText = '';
        let combinationsList = [];
        if (combination !== null){
            for(var j=0; j<Object.keys(combination).length; j++){
                let combinationText = combination[Object.keys(combination)[j]].name + ': ';
                let modifications = combination[Object.keys(combination)[j]].modifications;
                let modificationsList = [];
                // console.log('modification');
                // console.log(modifications);
                for(var k=0; k<Object.keys(modifications).length; k++){
                    modificationsList.push(modifications[Object.keys(modifications)[k]].name);
                }
                combinationText += modificationsList.join(", ")
                combinationsList.push(combinationText);
            }
            customText += combinationsList.join('; ');
        }
        // console.log(customText);
        return <Grid spacing={0} item xs={9} style={{paddingTop:'5px'}}>
            <div className="order-details-left">
                {choice === 'V' ?
                    <FiberManualRecordIcon style={{ fontSize: 14, marginRight: '8px', color: 'green', transform: 'translateY(3px)', border: '1px solid green'}}/>
                :
                    <IoTriangleSharp style={{ fontSize: 14, marginRight: '8px', color: '#CB1E33', transform: 'translateY(3px)', border: '1px solid #CB1E33'}}/>
                }          
                {quantity} x {name}
            </div>
            {customText !== '' && <div className="ddCustomisationText">{customText}</div>}
        </Grid>;
    }
    
    getOrderItemsPrice(sub_total){
        return <Grid spacing={0} item xs={3} style={{textAlign:'right', paddingTop:'5px'}}>
            <div className="order-details-right">
                Rs. {sub_total}
            </div>
        </Grid>;
    }

    itemsCount(){
        var itemCount = 0
        var itemVal = Object.values(this.props.order.items)
        for(var i=0; i<itemVal.length; i++){
          itemCount = itemCount + itemVal[i].quantity
        }
        return itemCount;
    }
    
    render() {
        if(!this.props.showOrderInfo){
            return null;
        }
        return(
            <div className="RatingModal" onClick={this.closeModalHandler}>
                <div style={{height: "unset", margin: "auto"}} onClick={(e) => this.handleModalClick(e)} className="orderInfoModalContainer">
                   <Grid container spacing={1} style={{paddingTop:'5px', paddingLeft: '30px', paddingRight: '30px'}}>
                        <Grid style={{marginBottom: '10px'}} item xs={12}>
                            <div className="OrderTitle">#{this.props.order.order_id}</div>
                            <div className="OrderCount">{this.itemsCount()} ITEMS</div>
                        </Grid>  
                    </Grid>
                    <Grid container spacing={2} style={{paddingLeft: '30px', paddingRight: '30px'}}>
                        {this.getOrderItems()}
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <span className="summary-header">Summary</span><br/>
                        </Grid>
                        <Grid item xs={7} style={{paddingTop:'0%', paddingBottom: '20px'}}>
                            <div style={{fontFamily: 'Poppins-SemiBold'}}  className="summary-details-left">SubTotal</div>
                            <div className="summary-details-left">Delivery charges</div>
                            <div className="summary-details-left">
                                Taxes & Charges
                                <div className="tooltip"><RiInformationLine className="ddInfoIcon"/>
                                    <span className="tooltiptext">
                                    <div>
                                        <span>Packing Charges</span>
                                        <span style={{float:"right"}}>Rs. {this.props.order.other_charges.other_charges_breakdown.packaging_charges}</span>
                                    </div>
                                    <div>
                                        <span>Restaurant GST</span>
                                        <span style={{float:"right"}}>Rs. {this.props.order.other_charges.other_charges_breakdown.taxes}</span>
                                    </div>
                                    </span>
                                </div>
                            </div>
                            <div style={{color: '#EA2886'}} className="summary-details-left">Resto Discount</div>
                            {/* <div style={{color: '#0014FF'}} className="summary-details-left">VOUCHER -(PROMOCODE)</div> */}
                        </Grid>
                        <Grid item xs={5} style={{textAlign:'right', paddingTop:'0%', paddingBottom:'20px'}}>
                            <div style={{fontFamily: 'Poppins-SemiBold'}} className="summary-details-right">{this.props.order.total_cost}</div>
                            <div className="summary-details-right">{this.props.order.other_charges.delivery_charge}</div>
                            <div className="summary-details-right">Rs {this.props.order.other_charges.other_charges.toFixed(2)}</div>
                            <div style={{color: '#EA2886'}} className="summary-details-right">-Rs. {this.props.order.total_discount}</div>
                            {/* <div style={{color: '#0014FF'}} className="summary-details-right">-{this.props.order.total_discount}</div> */}
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className='OrderGrandTotal'>
                        <Grid item xs={9} style={{paddingTop:'0%', marginBottom: '15px'}}>
                            <span className="OrderGrandTotalHeader">GRAND TOTAL</span>
                        </Grid>
                        <Grid item xs={3} style={{textAlign:'right', paddingTop:'0%', marginBottom: '15px'}}>
                        <span className="OrderGrandTotalHeader">{this.props.order.net_total}</span>
                        </Grid>
                        <Grid style={{padding: '10px 0px'}} item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid style={{padding: '10px 0px'}} item xs={12}>
                            <span className="summary-header">Customer Details</span><br/>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop:'0%', marginBottom: '2px'}}>
                            <span className="CustomerName">{this.props.order.user.name}</span>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop:'0%', textAlign: 'right', marginBottom: '2px'}}>
                            <LocationOnOutlined style={{fontSize:'15px', color: '#EA5B2F', transform: 'translateY(3px)'}} /> 
                            <span className="CustomerLabel">{this.props.order.delivery_address === null ? 'Address Tag' : this.props.order.delivery_address.tag}</span>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop:'0%', marginBottom: '10px'}}>
                            <span className="CustomerContact">{this.props.order.user.phone_number}</span>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop:'0%', textAlign: 'right', fontStyle:"bold"}}>
                            <span className="CustomerAddress">{this.props.order.delivery_address === null ? 'Address Details' : this.props.order.delivery_address.address}</span>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default withRouter(OrderInfo);