import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import CONSTANTS from '../../config/constants';
import axios from 'axios';
import { AiFillCheckCircle } from "react-icons/ai";
import { MdOutlineError } from "react-icons/md";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import BtnLoading from '../../assets/images/btnLoading.gif';

class InsightsScreen extends Component {
    constructor(props){
      super();
      this.state = {
        authModalOpen: false,
        activeForm: "",
        isMobileScreen: false,
        descriptionOverlayStyle: {display: 'none'},
        expandedMenu: true,
        leftMenuWidth: 2,
        rightMenuWidth: 10,
        menuArrow: "buttonFlip",
        selectedMenu: 0,
        fssaiNumber: "",
        gstNumber: "",
        panNumber: "",
        fssaiError: "",
        gstError: "",
        panError: "",
        fssaiUploadError: "",
        gstUploadError: "",
        panUploadError: "",
        fssaiFile: "",
        gstFile: "",
        panFile: "",
        fssaiFileError: "",
        gstFileError: "",
        panFileError: "",
        fssaiSubmitErrors: true,
        gstPanSubmitErrors: true,
        bankSubmitErrors: true,
        profileTab : "Account",
        restaurantName: "",
        restaurantNameError: "",
        restoAddress: "",
        restoAddressError: "",
        pinCode: "",
        pinCodeError: "",
        ownerName: "",
        ownerNameError: "",
        ownerContactNum: "",
        ownerContactNumError: "",
        ownerEmail: "",
        ownerEmailError: "",
        ifsc: "",
        ifscError: "",
        accountNumber: "",
        accountNumberError: "",
        reEnterAccountNumber: "",
        reEnterAccountNumberError: "",
        bankName: "",
        bankNameError: "",
        branchName: "",
        branchNameError: "",
        accHoldersName: "",
        accHoldersNameError: "",
        cancelledChequeFile: "",
        cancelledChequeFileError: "",
        fssai_number: "",
        gst_number: "",
        pan_number: "",
        ifsc_num: "",
        account_number: "",
        bank_name: "",
        branch_name: "",
        beneficiary_name: "",
        fssai_verified: false,
        gst_verified: false,
        pan_verified: false,
        bankDetails_verified: false,
        gstrate: 0,
        gstrateerror: "",
        gst_rate: "",
        dataLoading : false,
        profileDataError: false,
        bankDataError: false
      }
    }
  
    componentDidMount() {
      window.scrollTo(0, 0);
      this.updateDimensions();
      window.addEventListener("resize", this.updateDimensions.bind(this));
      this.getRestoDetails();
      this.getProfile();
      this.getBankDetails();
    }

    getRestoDetails(){
      var url = CONSTANTS.hostURL + "api/resto-app/profile/basic/";
      var headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
      axios.get(url, {
        headers: headers
      }).then((response) => {
        if (response.data.status === 1){
          var resData = response.data.data.info;
          this.setState({
            restaurantName: resData.name,
            restoAddress: resData.address,
            pinCode: resData.pincode,
            ownerName: resData.owner_name,
            ownerContactNum: resData.owner_phone,
            ownerEmail: resData.owner_email,
            
            restoDataError: false
          })
        }
        else{
          if(response.data.errors.message.includes("profile doesn't exist")){
            this.setState({
              restoDataError: false
            })
          }
          else{
            this.setState({
              restoDataError: true
            })
          }
        }
      }).catch((error) => {
        this.setState({
          restoDataError: true
        })
      });
    }

    getProfile(){
      var url = CONSTANTS.hostURL + "api/resto-app/profile/";
      var headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
      axios.get(url, {
        headers: headers
      }).then((response) => {
        if (response.data.status === 1){
          var resData = response.data.data;
          this.setState({
            fssai_number: resData.fssai_number !== null ? resData.fssai_number : "",
            gst_number: resData.gst_number !== null ? resData.gst_number : "",
            gst_rate: resData.gst_rate !== null ? resData.gst_rate : "",
            pan_number: resData.pan_number !== null ? resData.pan_number : "",
            
            fssai_document_url: resData.fssai_document_url !== null ? true : false,
            gst_document_url: resData.gst_document_url !== null ? true : false,
            pan_document_url: resData.pan_document_url !== null ? true : false,
            
            fssai_verified: resData.fssai_verified !== null ? resData.fssai_verified : false,
            gst_verified: resData.gst_verified !== null ? resData.gst_verified : false,
            pan_verified: resData.pan_verified !== null ? resData.pan_verified : false,
            
            profileDataError: false
          })
        }
        else{
          if(response.data.errors.message.includes("profile doesn't exist")){
            this.setState({
              profileDataError: false
            })
          }
          else{
            this.setState({
              profileDataError: true
            })
          }
        }
      }).catch((error) => {
        this.setState({
          profileDataError: true
        })
      });
    }

    getBankDetails(){
      var url = CONSTANTS.hostURL + "api/resto-app/profile/bank/";
      var headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
      axios.get(url, {
        headers: headers
      }).then((response) => {
        if (response.data.status === 1){
          var resData = response.data.data;
          // console.log(resData)
          this.setState({
            ifsc_num: resData.ifsc !== null ? resData.ifsc : "",
            account_number: resData.account_number !== null ? resData.account_number : "",
            bank_name: resData.bank_name !== null ? resData.bank_name : "",
            branch_name: resData.branch_name !== null ? resData.branch_name : "",
            beneficiary_name: resData.beneficiary_name !== null ? resData.beneficiary_name : "",
            bankDetails_verified: resData.verified !== null ? resData.verified : false,
            bankDataError: false
          })
        }
        else{
          if(response.data.errors.message.includes("doesn't exist")){
            this.setState({
              bankDataError: false
            })
          }
          else{
            this.setState({
              bankDataError: true
            })
          }
        }
      }).catch((error) => {
        this.setState({bankDataError: true})
      });
    }
  
    updateDimensions() {
      let screen_width = window.innerWidth;
      if(screen_width < 500) {
          this.setState({ isMobileScreen: true });
      } else {
          this.setState({ isMobileScreen: false });
      }
    }

    bankSubmitDocs(){
      var ifscRegex = /[A-Z]{4}0[A-Z0-9]{6}/
      let error = 0
      this.setState({bankDataSubmit : true})
      if(this.state.ifsc.trim() === ""){
        this.setState({ifscError : "Please enter the Bank IFSC Code"})
        error = error + 1
      }
      else if(this.state.ifsc.trim().length < 11 || (!ifscRegex.exec(this.state.ifsc.trim()))){
        this.setState({ifscError : "Incorrect IFSC Code"})
        error = error + 1
      }
      else{
        this.setState({ifscError : "", bankSubmitErrors: false})
      }

      if(this.state.accountNumber.trim() === ""){
        this.setState({accountNumberError : "Please enter the Account Number"})
        error = error + 1
      }
      else{
        this.setState({accountNumberError : "", bankSubmitErrors: false})
      }

      if(this.state.accountNumber.length === this.state.reEnterAccountNumber.length){
        if(this.state.accountNumber === this.state.reEnterAccountNumber){
          this.setState({
            reEnterAccountNumberError: ""
          })
        }
        else{
          this.setState({
            reEnterAccountNumberError: "Account number mismatch"
          })
          error = error + 1
        }
      }
      else{
        this.setState({
          reEnterAccountNumberError: "Account number mismatch"
        })
        error = error + 1
      }


      if(this.state.bankName.trim() === ""){
        this.setState({bankNameError : "Please enter the Bank Name"})
        error = error + 1
      }
      else{
        this.setState({bankNameError : "", bankSubmitErrors: false})
      }

      if(this.state.branchName.trim() === ""){
        this.setState({branchNameError : "Please enter the Branch Name"})
        error = error + 1
      }
      else{
        this.setState({branchNameError : "", bankSubmitErrors: false})
      }

      if(this.state.accHoldersName.trim() === ""){
        this.setState({accHoldersNameError : "Please enter the Account Holder's Name"})
        error = error + 1
      }
      else{
        this.setState({accHoldersNameError : "", bankSubmitErrors: false})
      }

      if(this.state.cancelledChequeFile !== undefined && this.state.cancelledChequeFile !== ""){
        if(this.state.cancelledChequeFile.name.trim() === ""){
          this.setState({cancelledChequeFileError : "Select an Image or PDF file"})
          error = error + 1
        }
        else if(this.state.cancelledChequeFile.name.trim().includes('.pdf') || this.state.cancelledChequeFile.name.trim().includes('.png') || this.state.cancelledChequeFile.name.trim().includes('.jpeg')){
            this.setState({cancelledChequeFileError : "", bankSubmitErrors: false})
        }
        else{
          this.setState({cancelledChequeFileError : "Select a .pdf/.png/.jpeg file"})
          error = error + 1
        }
      }
      else{
        this.setState({cancelledChequeFileError : "Select an Image or PDF file"})
        error = error + 1
      }

      if((error === 0) && (localStorage.getItem('access_token') !== null)){
        let url = CONSTANTS.hostURL + "api/resto-app/profile/bank/update/";
        
        let form_data = new FormData();
        form_data.append('beneficiary_name', this.state.accHoldersName);
        form_data.append('account_number', this.state.accountNumber);
        form_data.append('ifsc', this.state.ifsc);
        form_data.append('branch_name', this.state.bankName);
        form_data.append('bank_name', this.state.bankName);
        form_data.append('cancelled_cheque', this.state.cancelledChequeFile, this.state.cancelledChequeFile.name);

        let headers = {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
        axios.post(url, form_data,  {
          headers: headers
        }).then((response) => {
          if (response.data.status === 1){
            this.getBankDetails();
            alert("Bank Details Saved Successfully!!");
            this.setState({bankDataSubmit : false})
          } else {
            alert("Bank Details Saving Failed!!")
            this.setState({bankDataSubmit : false})
          }
        }).catch((error) => {
          alert("Bank Details Saving Failed!!")
          this.setState({bankDataSubmit : false})
        });
      }
      else{
        this.setState({bankDataSubmit : false})
      }
    }

    gstPanSubmitDocs(){
      let gstRegex = /[0-3]{1}[1-9]{1}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
      let panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
      this.setState({gstPanDataSubmit : true})

      let error = 0;
      
      //GST Number Error Check
      if(this.state.gst_number !== ""){
        this.setState({gstError : "", gstPanSubmitErrors: false})
      }
      else if(this.state.gstNumber.trim() === ""){
        this.setState({gstError : "Please enter the GST Number"})
        error = error + 1
      }
      else if(this.state.gstNumber.trim().length < 15 || (!gstRegex.exec(this.state.gstNumber.trim()))){
        this.setState({gstError : "Incorrect GST Number"})
        error = error + 1
      }
      else{
        this.setState({gstError : "", gstPanSubmitErrors: false})
      }

      //GST Rate Error Check
      if(this.state.gst_rate !== "" && this.state.gst_number !== ""){
        this.setState({gstrateerror : "", gstPanSubmitErrors: false})
      }
      else if(this.state.gstrate === 0){
        this.setState({gstrateerror : "Please select the GST slab"})
        error = error + 1
      }
      else{
        this.setState({gstrateerror : "", gstPanSubmitErrors: false})
      }

      //GST File Error Check
      if((this.state.gst_number === "" && this.state.gst_rate === "" && !this.state.gst_document_url) ||
         (this.state.gst_number === "" && this.state.gst_rate === "" && this.state.gst_document_url) ||
         (this.state.gst_number === "" && this.state.gst_rate !== "" && !this.state.gst_document_url) ||
         (this.state.gst_number === "" && this.state.gst_rate !== "" && this.state.gst_document_url) ||
         (this.state.gst_number !== "" && this.state.gst_rate === "" && !this.state.gst_document_url) ||
         (this.state.gst_number !== "" && this.state.gst_rate !== "" && !this.state.gst_document_url)){
        if(this.state.gstFile !== undefined && this.state.gstFile !== ""){
          if(this.state.gstFile.name.trim() === ""){
            this.setState({gstFileError : "Select an Image or PDF file"})
            error = error + 1
          }
          else if(this.state.gstFile.name.includes(".pdf") || this.state.gstFile.name.includes(".png") || this.state.gstFile.name.includes(".jpeg")){
              this.setState({gstFileError : "", gstPanSubmitErrors: false})
          }
          else{
            this.setState({gstFileError : "Select a .pdf/.png/.jpeg file"})
            error = error + 1
          }
        }
        else{
          this.setState({gstFileError : "Select an Image or PDF file"})
          error = error + 1
        }
      }
      else{
        this.setState({gstFileError : "", gstPanSubmitErrors: false})
      }

      //PAN Number Error Check
      if(this.state.pan_number !== ""){
        this.setState({panError : "", gstPanSubmitErrors: false})
      }
      else if(this.state.panNumber.trim() === ""){
        this.setState({panError : "Please enter the PAN Number"})
        error = error + 1
      }
      else if(this.state.panNumber.trim().length < 10 || (!panRegex.exec(this.state.panNumber.trim()))){
        this.setState({panError : "Incorrect PAN Number"})
        error = error + 1
      }
      else{
        this.setState({panError : "", gstPanSubmitErrors: false})
      }

      //PAN File Error Check
      if((this.state.pan_number === "" && !this.state.pan_document_url) || 
         (this.state.pan_number === "" && this.state.pan_document_url) || 
         (this.state.pan_number !== "" && !this.state.pan_document_url)){
        if(this.state.panFile !== undefined && this.state.panFile !== ""){
          if(this.state.panFile.name.trim() === ""){
            this.setState({panFileError : "Select an Image or PDF file"})
            error = error + 1
          }
          else if(this.state.panFile.name.trim().includes('.pdf') || this.state.panFile.name.trim().includes('.png') || this.state.panFile.name.trim().includes('.jpeg')){
              this.setState({panFileError : "", gstPanSubmitErrors: false})
          }
          else{
            this.setState({panFileError : "Select a .pdf/.png/.jpeg file"})
            error = error + 1
          }
        }
        else{
          this.setState({panFileError : "Select an Image or PDF file"})
          error = error + 1
        }
      }
      else{
        this.setState({panFileError : "", gstPanSubmitErrors: false})
      }

      if((error === 0) && (localStorage.getItem('access_token') !== null)){
        let url = CONSTANTS.hostURL + "api/resto-app/profile/gst-pan-upload/";
        this.setState({dataLoading : true})
        let form_data = new FormData();
        if((this.state.pan_number === "" && !this.state.pan_document_url) || 
           (this.state.pan_number === "" && this.state.pan_document_url)){
          form_data.append('pan', this.state.panNumber);
          form_data.append('pan_document', this.state.panFile, this.state.panFile.name);
        }
        else if(this.state.pan_number !== "" && !this.state.pan_document_url){
          form_data.append('pan', this.state.pan_number);
          form_data.append('pan_document', this.state.panFile, this.state.panFile.name);
        }

        if((this.state.gst_number === "" && this.state.gst_rate === "" && !this.state.gst_document_url) ||
           (this.state.gst_number === "" && this.state.gst_rate === "" && this.state.gst_document_url) ||
           (this.state.gst_number === "" && this.state.gst_rate !== "" && !this.state.gst_document_url) ||
           (this.state.gst_number === "" && this.state.gst_rate !== "" && this.state.gst_document_url)){
          form_data.append('gst', this.state.gstNumber);
          form_data.append('gst_rate', this.state.gstrate);
          form_data.append('gst_document', this.state.gstFile, this.state.gstFile.name);
        }
        else if(this.state.gst_number !== "" && this.state.gst_rate === "" && !this.state.gst_document_url){
          form_data.append('gst', this.state.gst_number);
          form_data.append('gst_rate', this.state.gstrate);
          form_data.append('gst_document', this.state.gstFile, this.state.gstFile.name);
        }
        else if(this.state.gst_number !== "" && this.state.gst_rate !== "" && !this.state.gst_document_url){
          form_data.append('gst', this.state.gst_number);
          form_data.append('gst_rate', this.state.gst_rate);
          form_data.append('gst_document', this.state.gstFile, this.state.gstFile.name);
        }
        else if(this.state.gst_number !== "" && this.state.gst_rate === "" && this.state.gst_document_url){
          form_data.append('gst', this.state.gst_number);
          form_data.append('gst_rate', this.state.gstrate);
        }

        let headers = {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
        axios.post(url, form_data,  {
          headers: headers
        }).then((response) => {
          if (response.data.status === 1){
            this.getProfile();
            alert("PAN & GST File Upload Complete!!")
            this.setState({gstPanDataSubmit : false})
          } else {
            alert("PAN & GST File Upload Failed!!")
            this.setState({gstPanDataSubmit : false})
          }
        }).catch((error) => {
          alert("PAN & GST File Upload Failed!!")
          this.setState({gstPanDataSubmit : false})
        });
      }
      else{
        this.setState({gstPanDataSubmit : false})
      }
    }

    fssaiSubmitDocs(){
      this.setState({ fssaiDataSubmit : true })
      let error = 0
      
      if(this.state.fssai_number !== ""){
        this.setState({fssaiError : "", fssaiSubmitErrors: false})
      }
      else if(this.state.fssaiNumber.trim() === ""){
        this.setState({fssaiError : "Please enter the FSSAI Number"})
        error = error + 1
      }
      else if(this.state.fssaiNumber.trim().length < 14){
        this.setState({fssaiError : "FSSAI number must be 14 digits"})
        error = error + 1
      }
      else{
        this.setState({fssaiError : "", fssaiSubmitErrors: false})
      }

      if((this.state.fssai_number === "" && !this.state.fssai_document_url) || 
         (this.state.fssai_number !== "" && !this.state.fssai_document_url) || 
         (this.state.fssai_number === "" && this.state.fssai_document_url)){
        if(this.state.fssaiFile !== undefined && this.state.fssaiFile !== ""){
          if(this.state.fssaiFile.name.trim() === ""){
            this.setState({fssaiFileError : "Select an Image or PDF file"})
            error = error + 1
          }
          else if(this.state.fssaiFile.name.trim().includes('.pdf') || this.state.fssaiFile.name.trim().includes('.png') || this.state.fssaiFile.name.trim().includes('.jpeg')){
              this.setState({fssaiFileError : "", fssaiSubmitErrors: false})
          }
          else{
            this.setState({fssaiFileError : "Select a .pdf/.png/.jpeg file"})
            error = error + 1
          }
        }
        else{
          this.setState({fssaiFileError : "Select an Image or PDF file"})
          error = error + 1
        }
      }
      else{
        this.setState({fssaiFileError : "", fssaiSubmitErrors: false})
      }

      if((error === 0) && (localStorage.getItem('access_token') !== null)){
        let url = CONSTANTS.hostURL + "api/resto-app/profile/fssai-upload/";
        
        var form_data = new FormData();
        
        if((this.state.fssai_number === "" && !this.state.fssai_document_url) || 
           (this.state.fssai_number === "" && this.state.fssai_document_url)){
          form_data.append('fssai', this.state.fssaiNumber);
          form_data.append('fssai_document', this.state.fssaiFile, this.state.fssaiFile.name);
        }
        else if(this.state.fssai_number !== "" && !this.state.fssai_document_url){
          form_data.append('fssai', this.state.fssai_number);
          form_data.append('fssai_document', this.state.fssaiFile, this.state.fssaiFile.name);
        }

        let headers = {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
        axios.post(url, form_data,  {
          headers: headers
        }).then((response) => {
          if (response.data.status === 1){
            this.getProfile();
            alert("FSSAI File Upload Complete!!")
            this.setState({ fssaiDataSubmit : false })
          } else {
            alert("FSSAI File Upload Failed!!")
            this.setState({ fssaiDataSubmit : false })
          }
        }).catch((error) => {
          alert("FSSAI File Upload Failed!!")
          this.setState({ fssaiDataSubmit : false })
        });
      }
      else{
        this.setState({ fssaiDataSubmit : false })
      }
    }

    accountTab(){
      return(
        <Grid container spacing={0}>
          <Grid item xs={12} >
            <div className="textBoxLabel">Restaurant Name</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <input type="text" readOnly={true} placeholder="Restaurant Name" className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} value={this.state.restaurantName} onChange={(event) => this.setState({restaurantName: event.target.value})}/>
              </Grid>
            </Grid>
            {this.state.restaurantNameError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.restaurantNameError}</div>}
          </Grid>

          <Grid item xs={12} >
            <div className="textBoxLabel">Address</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <input type="text" readOnly={true} placeholder="Address" className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} value={this.state.restoAddress} onChange={(event) => this.setState({restoAddress: event.target.value})}/>
              </Grid>
            </Grid>
            {this.state.restoAddressError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.restoAddressError}</div>}
          </Grid>

          <Grid item xs={12} >
            <div className="textBoxLabel">Pincode</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <input type="number" readOnly={true} placeholder="Pincode" className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} value={this.state.pinCode} onChange={(event) => this.setState({pinCode: event.target.value.slice(0, 6)})}/>
              </Grid>
            </Grid>
            {this.state.pinCodeError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.pinCodeError}</div>}
          </Grid>
          
          <Grid item xs={12} >
            <div className="textBoxLabel">Owner's Name</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <input type="text" readOnly={true} placeholder="Owner's Name" className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} value={this.state.ownerName} onChange={(event) => this.setState({ownerName: event.target.value})}/>
              </Grid>
            </Grid>
            {this.state.ownerNameError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.ownerNameError}</div>}
          </Grid>

          <Grid item xs={12} >
            <div className="textBoxLabel">Owner's Contact Number</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <input type="number" readOnly={true} placeholder="Owner's Contact Number" className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} value={this.state.ownerContactNum} onChange={(event) => this.setState({ownerContactNum: event.target.value.slice(0, 10)})}/>
              </Grid>
            </Grid>
            {this.state.ownerContactNumError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.ownerContactNumError}</div>}
          </Grid>

          <Grid item xs={12} >
            <div className="textBoxLabel">Owner's Email ID</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <input type="text" readOnly={true} placeholder="Owner's Email ID" className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} value={this.state.ownerEmail} onChange={(event) => this.setState({ownerEmail: event.target.value})}/>
              </Grid>
            </Grid>
            {this.state.ownerEmailError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.ownerEmailError}</div>}
          </Grid>
        </Grid>
      );
    }

    bankTab(){
      return(
        this.state.bankDataError ?
        <Grid container spacing={0}>
          <Grid item xs={12} style={{display:"inline-grid", justifyItems:"center", alignItems:"center"}}>
            <span title="Error With Bank Details" style={{color: "#EB622F", fontSize:"40px"}}><MdOutlineError/></span>
            <span className="textBoxLabel" style={{paddingTop:"0px"}}> Error while retrieving Bank details. Please try after sometime. If the issue still persist, please contact admin.</span>
          </Grid>
        </Grid>
        :
        this.state.account_number === "" ?  
        <Grid container spacing={0}>
          <Grid item xs={12} >
            <div className="textBoxLabel">Account Number</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <input type="number" readOnly={this.state.bankDataSubmit ? true : false} placeholder="Account Number" className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} value={this.state.accountNumber} onChange={(event) => this.setState({accountNumber: event.target.value})}/>
              </Grid>
            </Grid>
            {this.state.accountNumberError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.accountNumberError}</div>}
          </Grid>
          <Grid item xs={12} >
            <div className="textBoxLabel">Re-enter Account Number</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <input type="number" readOnly={this.state.bankDataSubmit ? true : false} placeholder="Re-enter Account Number" className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} value={this.state.reEnterAccountNumber} 
                  onChange={(event) => 
                    {
                      if(this.state.accountNumber.length > event.target.value.length){
                        this.setState({
                          reEnterAccountNumber: event.target.value,
                          reEnterAccountNumberError: ""
                        })
                      }
                      else if(this.state.accountNumber.length < event.target.value.length){
                        this.setState({
                          reEnterAccountNumber: event.target.value,
                          reEnterAccountNumberError: "Account number mismatch"
                        })
                      }
                      else if(this.state.accountNumber.length === event.target.value.length){
                        if(this.state.accountNumber === event.target.value){
                          this.setState({
                            reEnterAccountNumber: event.target.value,
                            reEnterAccountNumberError: ""
                          })
                        }
                        else{
                          this.setState({
                            reEnterAccountNumber: event.target.value,
                            reEnterAccountNumberError: "Account number mismatch"
                          })
                        }
                      }
                    }
                  }
                  onPaste={(e)=>{
                    e.preventDefault()
                    this.setState({
                      reEnterAccountNumberError: "Please Re-enter the Account number. You cannot paste any value here."
                    })
                    return false;
                  }} onCopy={(e)=>{
                    e.preventDefault()
                    this.setState({
                      reEnterAccountNumberError: "Please Re-enter the Account number. You cannot copy any value from here."
                    })
                    return false;
                  }}/>
              </Grid>
            </Grid>
            {this.state.reEnterAccountNumberError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.reEnterAccountNumberError}</div>}
          </Grid>
          <Grid item xs={12} >
            <div className="textBoxLabel">IFSC Code</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <input type="text" readOnly={this.state.bankDataSubmit ? true : false} placeholder="IFSC Code" className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} value={this.state.ifsc} onChange={(event) => {
                  this.setState({ifsc: event.target.value.slice(0, 11).toUpperCase()})
                  if(event.target.value.slice(0, 11).length === 11){
                    axios.get("https://ifsc.razorpay.com/"+event.target.value.slice(0, 11)).then((response) => {
                      this.setState({
                        bankName: response.data.BANK,
                        branchName: response.data.BRANCH,
                        ifscError: ""
                      })
                    }).catch((error) => {
                      this.setState({
                        bankName: "",
                        branchName: "",
                        ifscError : "Incorrect IFSC Code"
                      })
                    });
                  }
                  else{
                    this.setState({
                      bankName: "",
                      branchName: "",
                    })
                  }
                }}/>
              </Grid>
            </Grid>
            {this.state.ifscError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.ifscError}</div>}
          </Grid>
          <Grid item xs={12} >
            <div className="textBoxLabel">Bank Name</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <input type="text" readOnly={this.state.bankDataSubmit ? true : false} placeholder="Bank Name" className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} value={this.state.bankName} onChange={(event) => this.setState({bankName: event.target.value})}/>
              </Grid>
            </Grid>
            {this.state.bankNameError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.bankNameError}</div>}
          </Grid>
          <Grid item xs={12} >
            <div className="textBoxLabel">Branch Name</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <input type="text" readOnly={this.state.bankDataSubmit ? true : false} placeholder="Branch Name" className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} value={this.state.branchName} onChange={(event) => this.setState({branchName: event.target.value})}/>
              </Grid>
            </Grid>
            {this.state.branchNameError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.branchNameError}</div>}
          </Grid>
          <Grid item xs={12} >
            <div className="textBoxLabel">Account Holder's Name</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <input type="text" readOnly={this.state.bankDataSubmit ? true : false} placeholder="Account Holder's Name" className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} value={this.state.accHoldersName} onChange={(event) => this.setState({accHoldersName: event.target.value})}/>
              </Grid>
            </Grid>
            {this.state.accHoldersNameError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.accHoldersNameError}</div>}
          </Grid>
 
          <Grid item xs={12} >
            <div className="textBoxLabel">Upload Cancelled Cheque (Image or PDF)</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <input type="file" readOnly={this.state.bankDataSubmit ? true : false} className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} onChange={(event) => this.setState({cancelledChequeFile: event.target.files[0]})}/>
              </Grid>
              {/* <span className="discountSticker" style={{top:"8.5px", left:"-110px"}}>Upload Complete</span> */}
            </Grid>
            {this.state.cancelledChequeFileError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.cancelledChequeFileError}</div>}
          </Grid>

          <Grid item xs={12} >
            <div className="DishCardActionsText" style={{textAlign:"left"}}>
                {!this.state.bankDataSubmit ? 
                  <span 
                    className="ReviewText" 
                    style={{cursor: "pointer", backgroundColor: "#FCD844", padding: "12px 36px", borderRadius: "50px", top: "10px", position:"relative"}} 
                    onClick={() => this.bankSubmitDocs()}
                  >Save</span>
                  :
                  <img src={BtnLoading} alt="IFSC Updating Animation" style={this.state.isMobileScreen ? {width:"100%", marginTop:"25%"} : {width:"20%", marginTop:"0%"}}/>}
            </div>
          </Grid>
        </Grid>
        :
        <Grid container spacing={0}>
          <Grid item xs={12} >
            <div className="textBoxLabel">Account Number</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <span className="ddprofileAvailableData">{this.state.account_number}</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} >
            <div className="textBoxLabel">IFSC Code</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <span className="ddprofileAvailableData">{this.state.ifsc_num}</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} >
            <div className="textBoxLabel">Bank Name</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <span className="ddprofileAvailableData">{this.state.bank_name}</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} >
            <div className="textBoxLabel">Branch Name</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <span className="ddprofileAvailableData">{this.state.branch_name}</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} >
            <div className="textBoxLabel">Account Holder's Name</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <span className="ddprofileAvailableData">{this.state.beneficiary_name}</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <span className="textBoxLabel">Cancelled Cheque Upload Status</span>
              </Grid>
              <Grid item xs={6}>
                <span title="File Upload Complete" style={{color:"#1F7E1B", fontSize:"20px"}}><AiFillCheckCircle/><span className="textBoxLabel" style={{top:"-5px", position:"relative"}}> Done</span></span>
              </Grid>

              <Grid item xs={6}>
                <span className="textBoxLabel">Bank details Verification Status</span>
              </Grid>
              <Grid item xs={6}>
                <span title={this.state.bankDetails_verified ? "Verification Complete" : "Verification Pending"} style={{color: this.state.bankDetails_verified ? "#1F7E1B" : "#EB622F", fontSize:"20px"}}>{this.state.bankDetails_verified ? <><AiFillCheckCircle /><span className="textBoxLabel" style={{top:"-5px", position:"relative"}}> Verified</span></> : <><MdOutlineError/><span className="textBoxLabel" style={{top:"-5px", position:"relative"}}> Approval Pending</span></>}</span>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      )
    }

    otherTab(){
      return(
        this.state.profileDataError ? 
        <Grid container spacing={0}>
          <Grid item xs={12} style={{display:"inline-grid", justifyItems:"center", alignItems:"center"}}>
            <span title="Error With GST & Pan Details" style={{color: "#EB622F", fontSize:"40px"}}><MdOutlineError/></span>
            <span className="textBoxLabel" style={{paddingTop:"0px"}}> Error while retrieving GST & PAN details. Please try after sometime. If the issue still persist, please contact admin.</span>
          </Grid>
        </Grid>
        :
        <Grid container spacing={0}>
          <Grid item xs={12} >
            <div className="textBoxLabel">GST Number</div>
            <Grid container spacing={0}>
              {this.state.gst_number === "" ?
                <Grid item xs={12}>
                  <input type="text" readOnly={this.state.gstPanDataSubmit ? true : false} placeholder="GST Number" className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} value={this.state.gstNumber} onChange={(event) => this.setState({gstNumber: event.target.value.slice(0, 15).toUpperCase()})}/>
                  {this.state.gstError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.gstError}</div>}
                </Grid>
                :
                <Grid item xs={12}>
                  <span className="ddprofileAvailableData">{this.state.gst_number}</span>
                </Grid>
              }
              {((this.state.gst_number === "" && this.state.gst_rate === "" && !this.state.gst_document_url) ||
                (this.state.gst_number === "" && this.state.gst_rate === "" && this.state.gst_document_url) ||
                (this.state.gst_number === "" && this.state.gst_rate !== "" && !this.state.gst_document_url) ||
                (this.state.gst_number === "" && this.state.gst_rate !== "" && this.state.gst_document_url) ||
                (this.state.gst_number !== "" && this.state.gst_rate === "" && !this.state.gst_document_url) ||
                (this.state.gst_number !== "" && this.state.gst_rate === "" && !this.state.gst_document_url) ||
                (this.state.gst_number !== "" && this.state.gst_rate === "" && this.state.gst_document_url)) ?
                <Grid item xs={12}>
                  <div className="textBoxLabel">Select GST Slab</div>
                  <FormControl>
                    <Select
                      readOnly={this.state.gstPanDataSubmit ? true : false} 
                      style={{width:"145px", height:"40px", fontFamily:"Poppins-regular", fontSize:"14px"}}
                      className="textBoxWithButtonStyle"
                      onChange={(e) => {
                        this.setState({
                          gstrate: Number(e.target.value)
                        })
                      }}
                    >
                      <MenuItem value={5}>5 %</MenuItem>
                      <MenuItem value={18}>18 %</MenuItem>
                    </Select>
                  </FormControl>
                  {this.state.gstrateerror !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.gstrateerror}</div>}
                </Grid>
                :
                <Grid item xs={12} >
                  <div className="textBoxLabel">GST Rate</div>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <span className="ddprofileAvailableData">{this.state.gst_rate} %</span>
                    </Grid>
                  </Grid>
                </Grid>
              }
              {((this.state.gst_number !== "" && this.state.gst_rate === "" && this.state.gst_document_url) ||
                (this.state.gst_number !== "" && this.state.gst_rate !== "" && this.state.gst_document_url)) ? 
                <Grid item xs={5}>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <span className="textBoxLabel">File Upload Status</span>
                    </Grid>
                    <Grid item xs={6}>
                      <span title="File Upload Complete" style={{color:"#1F7E1B", fontSize:"20px"}}><AiFillCheckCircle/><span className="textBoxLabel" style={{top:"-5px", position:"relative"}}> Done</span></span>
                    </Grid>

                    <Grid item xs={6}>
                      <span className="textBoxLabel">Verification Status</span>
                    </Grid>
                    <Grid item xs={6}>
                      <span title={this.state.gst_verified ? "Verification Complete" : "Verification Pending"} style={{color: this.state.gst_verified ? "#1F7E1B" : "#EB622F", fontSize:"20px"}}>{this.state.gst_verified ? <><AiFillCheckCircle /><span className="textBoxLabel" style={{top:"-5px", position:"relative"}}> Verified</span></> : <><MdOutlineError/><span className="textBoxLabel" style={{top:"-5px", position:"relative"}}> Approval Pending</span></>}</span>
                    </Grid>
                  </Grid>
                </Grid>
                :
                <Grid item xs={12} >
                  <div className="textBoxLabel">Upload GST document (Image or PDF)</div>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <input type="file" readOnly={this.state.gstPanDataSubmit ? true : false} className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} onChange={(event) => this.setState({gstFile: event.target.files[0]})}/>
                    </Grid>
                  </Grid>
                  {this.state.gstFileError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.gstFileError}</div>}
                </Grid>
              }
            </Grid>
            <div className="textBoxLabel">PAN Number</div>
            <Grid container spacing={0}>
              {this.state.pan_number === "" ?
                <Grid item xs={12}>
                  <input type="text" readOnly={this.state.gstPanDataSubmit ? true : false} placeholder="PAN Number" className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} value={this.state.panNumber} onChange={(event) => this.setState({panNumber: event.target.value.slice(0, 10).toUpperCase()})}/>
                  {this.state.panError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.panError}</div>}
                </Grid>
                :
                <Grid item xs={12}>
                  <span className="ddprofileAvailableData">{this.state.pan_number}</span>
                </Grid>
              }
              {(this.state.pan_document_url && this.state.pan_number !== "") ?
                <Grid item xs={5}>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <span className="textBoxLabel">File Upload Status</span>
                    </Grid>
                    <Grid item xs={6}>
                      <span title="File Upload Complete" style={{color:"#1F7E1B", fontSize:"20px"}}><AiFillCheckCircle/><span className="textBoxLabel" style={{top:"-5px", position:"relative"}}> Done</span></span>
                    </Grid>

                    <Grid item xs={6}>
                      <span className="textBoxLabel">Verification Status</span>
                    </Grid>
                    <Grid item xs={6}>
                      <span title={this.state.pan_verified ? "Verification Complete" : "Verification Pending"} style={{color: this.state.pan_verified ? "#1F7E1B" : "#EB622F", fontSize:"20px"}}>{this.state.pan_verified ? <><AiFillCheckCircle /><span className="textBoxLabel" style={{top:"-5px", position:"relative"}}> Verified</span></> : <><MdOutlineError/><span className="textBoxLabel" style={{top:"-5px", position:"relative"}}> Approval Pending</span></>}</span>
                    </Grid>
                  </Grid>
                </Grid>
                :
                <Grid item xs={12} >
                  <div className="textBoxLabel">Upload PAN Card Copy (Image or PDF)</div>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <input type="file" readOnly={this.state.gstPanDataSubmit ? true : false} className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}}  onChange={(event) => this.setState({panFile: event.target.files[0]})}/>
                    </Grid>
                  </Grid>
                  {this.state.panFileError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.panFileError}</div>}
                </Grid>
              }
            </Grid>
          </Grid>
          {(this.state.gst_number === "" || this.state.pan_number === "" || this.state.gst_rate === "" || !this.state.gst_document_url || !this.state.pan_document_url) && 
            <Grid item xs={12} >
              <div className="DishCardActionsText" style={{textAlign:"left"}}>
                {!this.state.gstPanDataSubmit ? 
                  <span 
                    className="ReviewText" 
                    style={{cursor: "pointer", backgroundColor: "#FCD844", padding: "12px 36px", borderRadius: "50px", top: "10px", position:"relative"}} 
                    onClick={() => this.gstPanSubmitDocs()}
                  >Save</span>
                  :
                  <img src={BtnLoading} alt="IFSC Updating Animation" style={this.state.isMobileScreen ? {width:"100%", marginTop:"25%"} : {width:"20%", marginTop:"0%"}}/>}
              </div>
            </Grid>
          }
        </Grid>   
      )
    }

    fssaiTab(){
      return(
        this.state.profileDataError ? 
        <Grid container spacing={0}>
          <Grid item xs={12} style={{display:"inline-grid", justifyItems:"center", alignItems:"center"}}>
            <span title="Error With FSSAI Details" style={{color: "#EB622F", fontSize:"40px"}}><MdOutlineError/></span>
            <span className="textBoxLabel" style={{paddingTop:"0px"}}> Error while retrieving FSSAI details. Please try after sometime. If the issue still persist, please contact admin.</span>
          </Grid>
        </Grid>
        :
        <Grid container spacing={0}>
          <Grid item xs={12} >
            <div className="textBoxLabel">FSSAI Number</div>
            {this.state.fssai_number === "" ? 
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <input readOnly={this.state.fssaiDataSubmit ? true : false} type="number" placeholder="FSSAI Number" className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} value={this.state.fssaiNumber} onChange={(event) => this.setState({fssaiNumber: event.target.value.slice(0, 14)})}/>
              </Grid>
              {this.state.fssaiError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.fssaiError}</div>}
            </Grid>
            :
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <span className="ddprofileAvailableData">{this.state.fssai_number}</span>
              </Grid>
            </Grid>}
            {(this.state.fssai_document_url && this.state.fssai_number !== "") ?
            <Grid item xs={5}>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <span className="textBoxLabel">File Upload Status</span>
                </Grid>
                <Grid item xs={6}>
                  <span title="File Upload Complete" style={{color:"#1F7E1B", fontSize:"20px"}}><AiFillCheckCircle/><span className="textBoxLabel" style={{top:"-5px", position:"relative"}}> Done</span></span>
                </Grid>

                <Grid item xs={6}>
                  <span className="textBoxLabel">Verification Status</span>
                </Grid>
                <Grid item xs={6}>
                  <span title={this.state.fssai_verified ? "Verification Complete" : "Verification Pending"} style={{color: this.state.fssai_verified ? "#1F7E1B" : "#EB622F", fontSize:"20px"}}>{this.state.fssai_verified ? <><AiFillCheckCircle /><span className="textBoxLabel" style={{top:"-5px", position:"relative"}}> Verified</span></> : <><MdOutlineError/><span className="textBoxLabel" style={{top:"-5px", position:"relative"}}> Approval Pending</span></>}</span>
                </Grid>
              </Grid>
            </Grid>
            :
            <Grid item xs={12} >
              <div className="textBoxLabel">Upload FSSAI document (Image or PDF)</div>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <input type="file" readOnly={this.state.fssaiDataSubmit ? true : false} className="textBoxWithButtonStyle" style={{width:"100%", borderRadius:"5px"}} 
                    onChange={(event) => this.setState({fssaiFile: event.target.files[0]})}/>
                </Grid>
              </Grid>
              {this.state.fssaiFileError !== "" && <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.fssaiFileError}</div>}
            </Grid>  
            } 
          </Grid>
          {(this.state.fssai_number === "" || !this.state.fssai_document_url) &&
            <Grid item xs={12} >
              <div className="DishCardActionsText" style={{textAlign:"left"}}>
                  {!this.state.fssaiDataSubmit ? <span 
                    className="ReviewText" 
                    style={{cursor: "pointer", backgroundColor: "#FCD844", padding: "12px 36px", borderRadius: "50px", top: "10px", position:"relative"}} 
                    onClick={() => this.fssaiSubmitDocs()}
                  >Save</span>
                  :
                  <img src={BtnLoading} alt="IFSC Updating Animation" style={this.state.isMobileScreen ? {width:"100%", marginTop:"25%"} : {width:"20%", marginTop:"0%"}}/>}
              </div>
            </Grid>
          }
        </Grid>
      )
    }

    render() {
      return(
        <div style={{padding: "20px"}}>
          <div style={{fontFamily: "Bree-Regular", fontSize: "24px"}}>Profile</div>
          <div style={{borderBottom:"#E6E6E6 1px solid", marginTop:"15px"}}>
            <span className={this.state.profileTab === "Account" ? "ddprofileTabSelected" : "ddprofileTab"} onClick={() => this.setState({profileTab : "Account"})}>Account</span>
            <span className={this.state.profileTab === "Bank Details" ? "ddprofileTabSelected" : "ddprofileTab"} onClick={() => this.setState({profileTab : "Bank Details"})}>Bank Details</span>
            <span className={this.state.profileTab === "FSSAI" ? "ddprofileTabSelected" : "ddprofileTab"} onClick={() => this.setState({profileTab : "FSSAI"})}>FSSAI</span>
            <span className={this.state.profileTab === "Other Documents" ? "ddprofileTabSelected" : "ddprofileTab"} onClick={() => this.setState({profileTab : "Other Documents"})}>Other Documents</span>
          </div>
          <div style={{marginTop: "15px", border:"#D9D9D9 1px solid", borderRadius:"5px", padding:"10px 20px 30px 20px"}}>
            {this.state.profileTab === "Account" && this.accountTab()}
            {this.state.profileTab === "Bank Details" && this.bankTab()}
            {this.state.profileTab === "FSSAI" && this.fssaiTab()}
            {this.state.profileTab === "Other Documents" && this.otherTab()}
          </div>
        </div>
      );
    }
}
export default InsightsScreen;