import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import BannerImage from "../../assets/images/vector3.png"
import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import AccordionActions from '@material-ui/core/AccordionActions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
// import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
// import AllInboxIcon from '@material-ui/icons/AllInbox';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import ForwardIcon from '@material-ui/icons/Forward';
import DeleteIcon from '@material-ui/icons/Delete';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import CONSTANTS from '../../config/constants';
import TimeInput from 'material-ui-time-picker';
import resto_banner from '../../assets/images/restaurant_cover.png';
import CloseIcon from '@material-ui/icons/Close';
import '../../assets/styles/Profile.css';
import ConfirmationBox from './ConfirmationBox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AiFillCheckCircle } from "react-icons/ai";
import { MdOutlineError } from "react-icons/md";

class ManageResto extends Component {
    constructor(props){
      super();
      this.handleTextChange = this.handleTextChange.bind(this);
      this.handleRestoDiscSwitchChange = this.handleRestoDiscSwitchChange.bind(this);
      this.handleRestoDeliverySwitchChange = this.handleRestoDeliverySwitchChange.bind(this);
      this.handleRestoPackagingSwitchChange = this.handleRestoPackagingSwitchChange.bind(this);
      this.restaurantRestoTimingPanel = this.restaurantRestoTimingPanel.bind(this);
      this.toggleDiscToPercentage = this.toggleDiscToPercentage.bind(this);
      this.toggleDiscToRupees = this.toggleDiscToRupees.bind(this);
      this.onTimeChange = this.onTimeChange.bind(this);
      this.saveTimeChange = this.saveTimeChange.bind(this);
      this.handleRestaurantDiscountSubmit = this.handleRestaurantDiscountSubmit.bind(this);
      this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
      this.handleDeleteRestoCuisine = this.handleDeleteRestoCuisine.bind(this);
      this.getSuggestions = this.getSuggestions.bind(this);
      this.setCodeImage = this.setCodeImage.bind(this);
      this.toggleConfirmationBox = this.toggleConfirmationBox.bind(this);
      this.onActionConfirm = this.onActionConfirm.bind(this);
      var now = new Date();
      this.state = {
        authModalOpen: false,
        activeForm: "",
        isMobileScreen: false,
        descriptionOverlayStyle: {display: 'none'},
        expandedMenu: true,
        leftMenuWidth: 2,
        rightMenuWidth: 10,
        menuArrow: "buttonFlip",
        selectedMenu: 0,
        expanded : false,
        restoDescText : "",
        step1Status : "Done",
        step2Status : "Done",
        step3Status : "Pending",
        step4Status : "Done",
        step5Status : "Done",
        step6Status : "Done",
        step7Status : "Done",
        discountType : "%",
        packagingInputShown: false,
        globalPackagingCharge: '',
        restoDiscSwitch : true,
        restoPackagingSwitch : false,
        deliveryRuleCount : 0,
        deliveryRules : [],
        codeImage: null,
        codeImageName: "",
        infoText: '',
        showConfirmationBox: false,
        actionType: '',
        restoDiscBtnStyle : {
          background : "#DEE8FF",
          color: "#425FA1",
          left: "0px",
          boxShadow: "-2px 0px 2px 0.5px #aaaaaa",
        },
        restoPackagingBtnStyle : {
          background : "#FFFFFF",
          color: "#EB622F",
          left: "0px",
          boxShadow: "-2px 0px 2px 0.5px #aaaaaa",
        },
        restoImage: resto_banner,
        cuisines : [],
        selectedCusineId: -1,
        cuisineTextValue: '',
        cuisineSuggestions: [],
        cuisineSelected: false,
        cuisineErrorPresent: false,
        cuisineErrorText: "",
        cuisineSuccessText: "",
        openingTime: new Date(),
        closingTime: new Date(),
        restaurantTimeEditDisplay: false,
        currentRestaurantDayEdited: "",
        closingOpeningTimes: {
          'Monday' : {
            'opening': new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10),
            'closing': new Date(now.getFullYear(), now.getMonth(), now.getDate(), 22)
          },
          'Tuesday' : {
            'opening': new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10),
            'closing': new Date(now.getFullYear(), now.getMonth(), now.getDate(), 22)
          },
          'Wednesday' : {
            'opening': new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10),
            'closing': new Date(now.getFullYear(), now.getMonth(), now.getDate(), 22)
          },
          'Thursday' : {
            'opening': new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10),
            'closing': new Date(now.getFullYear(), now.getMonth(), now.getDate(), 22)
          },
          'Friday' : {
            'opening': new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10),
            'closing': new Date(now.getFullYear(), now.getMonth(), now.getDate(), 22)
          },
          'Saturday' : {
            'opening': new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10),
            'closing': new Date(now.getFullYear(), now.getMonth(), now.getDate(), 22)
          },
          'Sunday' : {
            'opening': new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10),
            'closing': new Date(now.getFullYear(), now.getMonth(), now.getDate(), 22)
          }
        },
        restaurantSaveText: "Save",
        restaurantBusinessHoursErrorText: "",
        restaurantDiscount: "",
        restaurantDiscountError: "",
        restaurantDiscountLabel: "",
        restaurantDiscountLabelError: "",
        restaurantDiscountMin: "",
        restaurantDiscountMinError: "",
        restaurantDiscountSuccess: "",
        restoDetailsFetched: false,
        saveRuleText: "Save Rules",
        discountSubmitText: "Submit",
        discountEditable: true,
        codeImageUploaded: false,
        deliveryEditable: true,
        defaultDlvMode: null,
        dlvType: null,
        restaurantDiscountApproval: null,
        restoContribution: 0
      }
    }

    capitalizeWord(word){
      return word.charAt(0).toUpperCase() + word.slice(1);
    }

    getHour(time) {
      if (time.split(" ")[1] === "AM"){
        if (parseInt(time.split(":")[0]) !== 12){
          return parseInt(time.split(":")[0]);
        } else {
          return 0;
        }
      } else {
        if (parseInt(time.split(":")[0]) === 12){
          return parseInt(time.split(":")[0]);
        } else {
          return 12 + parseInt(time.split(":")[0]);
        }
      }
    }

    getMinute(time) {
      return parseInt(time.split(":")[1].split(" ")[0]);
    }

    componentDidUpdate(){
      if (this.props.restaurant.id !== null && this.props.restoDetailsUpdated && this.state.restoDetailsFetched === false){
        this.updateRestaurantTimings();
        this.setState({
          restoDetailsFetched: true,
          defaultDlvMode: this.props.restaurant.delivery_option,
          restoContribution: this.props.restaurant.restaurant_contribution ?? 0
        })
        let restaurant_details = this.props.restaurant;
        if (restaurant_details.profile_image_url !== null){
          this.setState({ restoImage : restaurant_details.profile_image_url});
        }
        if (restaurant_details.extra_data.discount_details !== undefined && restaurant_details.extra_data.discount_details !== null){
          this.setState({
            restaurantDiscount: restaurant_details.extra_data.discount_details.percentage,
            restaurantDiscountLabel: restaurant_details.extra_data.discount_details.label,
            restaurantDiscountMin: restaurant_details.extra_data.discount_details.min_order_value,
            restaurantDiscountApproval: (restaurant_details.extra_data.discount_details !== undefined && restaurant_details.extra_data.discount_details !== null) ? restaurant_details.extra_data.discount_details.approval_status : null,
            restoDiscSwitch: true,
            discountEditable: false,
            discountSubmitText: "Edit",
            restoDiscBtnStyle : {
              background : "#425FA1",
              color: "#DEE8FF",
              left: "27px",
              boxShadow: "2px 0px 2px 0.5px #aaaaaa",
            }
          });
        }
        if (restaurant_details.delivery_charges !== null){
          let delivery_charges = [];
          for (var i=0; i<restaurant_details.delivery_charges.length; i++){
            let current_charge = [];
            current_charge.push(restaurant_details.delivery_charges[i].start);
            current_charge.push(restaurant_details.delivery_charges[i].end);
            current_charge.push(restaurant_details.delivery_charges[i].charge);
            delivery_charges.push(current_charge);
            this.setState({
              ["deliveryA_"+i] : current_charge[0],
              ["deliveryAError_"+i] : false,
              ["deliveryB_"+i] : 1,
              ["deliveryBError_"+i] : false,
              ["deliverPriceText"+i]: current_charge[2]
            });
          }

          this.setState({
            deliveryRules: delivery_charges,
            deliveryRuleCount: restaurant_details.delivery_charges.length,
            deliveryEditable: false,
            saveRuleText: "Edit Rules",
          });
        }
        else{
          if(restaurant_details.delivery_option === "DAIDISH_ASSISTED_DELIVERY"){
            this.setState({
              restoContribution: restaurant_details.restaurant_contribution,
              deliveryEditable: false,
              saveRuleText: "Edit Rules",
            });
          }
          else{
            this.setState({
              restoContribution: 0,
              deliveryEditable: true,
              saveRuleText: "Save Rules",
            });
          }
        }
      }
    }

    restaurantCuisinesList(){
      if (this.props.restaurant.id !== undefined) {
        if (this.props.restaurant.cuisines.length > 0){
          return (
            <div className="TabLikesContainer ProfileCuisineItemContainer">
              {
                  this.props.restaurant.cuisines.map((cuisine, index) => {
                      return (this.cuisineItem(cuisine, index))
                  })
              }
            </div>
          )
        } else {
          return null;
        }
      } else {
        return null;
      }
    }

    handleDeleteRestoCuisine(cuisine){
      let req = {
        'existing_cuisines': []
      };
      let newCuisines = [];
      for (var i=0; i<this.props.restaurant.cuisines.length; i++){
        if (cuisine.id !== this.props.restaurant.cuisines[i].id){
          req.existing_cuisines.push(this.props.restaurant.cuisines[i].id);
          newCuisines.push(this.props.restaurant.cuisines[i]);
        }
      }
      let url = CONSTANTS.hostURL + "api/resto-app/restaurant/update-cuisines/";
      let headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
      axios.post(url, req,  {
        headers: headers
      }).then((response) => {
        if (response.data.status === 1){
          let restaurant_details = this.props.restaurant; 
          restaurant_details.cuisines = newCuisines;
          localStorage.setItem('restaurant_details', JSON.stringify(restaurant_details));
          this.props.updateRestoDetails(restaurant_details);
          this.setState({
            cuisineErrorText: "",
            cuisineSuccessText: ""
          });
          this.props.informationBox('Cuisine removed successfully.')
        } else {
          this.props.informationBox("Network or server error. Please try again later.");
        }
      }).catch((err) => {
        this.props.informationBox(err.response.data.message);
      });
    }

    cuisineItem(cuisine, index){
      return (
        <div key={index} className="ProfileCuisineItem">
            {cuisine.name}
            <div onClick={() => this.handleDeleteRestoCuisine(cuisine)} className="ProfileCuisineRemoveIcon">
                <CloseIcon style={{fontSize: "14px"}}/>
            </div>
        </div>
    )
    }

    updateRestaurantTimings() {
      let restaurant_details = this.props.restaurant;
      let business_hours = restaurant_details.business_hours;
      let closingOpeningTimes = this.state.closingOpeningTimes;
      if (business_hours !== null){
        for (var key in business_hours) {
          if (business_hours.hasOwnProperty(key)) {
            var openingValue = business_hours[key]["opens_at_str"];
            var closingValue = business_hours[key]["closes_at_str"];
            // console.log(this.capitalizeWord(key.toLowerCase()));
            var now = new Date();
            closingOpeningTimes[this.capitalizeWord(key.toLowerCase())]["opening"] = new Date(now.getFullYear(), now.getMonth(), now.getDate(), this.getHour(openingValue), this.getMinute(openingValue));
            closingOpeningTimes[this.capitalizeWord(key.toLowerCase())]["closing"] = new Date(now.getFullYear(), now.getMonth(), now.getDate(), this.getHour(closingValue), this.getMinute(closingValue));
          }
        }
        // console.log(this.state.closingOpeningTimes);
        this.setState({
          closingOpeningTimes: closingOpeningTimes
        });
      }
    }

    AddZero(num) {
      return (num >= 0 && num < 10) ? "0" + num : num + "";
    }

    makeAMPM(time) {
      if (time > 12){
        return time - 12;
      } else if (time === 0) {
        return 12;
      } else {
        return time;
      }
    }

    getDateFormat(now) {
      var strDateTime = [
        [this.AddZero(this.makeAMPM(now.getHours())), 
        this.AddZero(now.getMinutes())].join(":"), 
        now.getHours() >= 12 ? "PM" : "AM"].join(" ");
      return strDateTime;
    }

    onTimeChange(type, time) {
      // console.log(time);
      // console.log(type);
      if (type === "opening"){
        this.setState({
          openingTime: time
        }, () => console.log(this.state.openingTime));
      } else {
        this.setState({
          closingTime: time
        }, () => console.log(this.state.closingTime));
      }
    }

    getTimeValue(time){
      var hour = this.makeAMPM(time.getHours());
      var minute = this.AddZero(time.getMinutes());
      var am_pm = time.getHours() < 12 ? "AM" : "PM";
      return hour + ":" + minute + " " + am_pm;
    }

    saveTimeChange() {
      this.setState({
        restaurantSaveText: "Saving...",
        restaurantBusinessHoursErrorText: ""
      })
      var timeList = this.state.closingOpeningTimes;
      timeList[this.state.currentRestaurantDayEdited]["opening"] = this.state.openingTime;
      timeList[this.state.currentRestaurantDayEdited]["closing"] = this.state.closingTime;
      if (this.state.closingTime.getTime() > this.state.openingTime.getTime()){
        var newTimeList = {};
        for (var key in timeList) {
          if (timeList.hasOwnProperty(key)) {
            var openingValue = timeList[key]["opening"];
            var closingValue = timeList[key]["closing"];
            // console.log(this.capitalizeWord(key.toLowerCase()));
            newTimeList[key.toLowerCase()] = {
              "opens_at_str" : this.getTimeValue(openingValue),
              "closes_at_str": this.getTimeValue(closingValue)
            };
          }
        }
        let url = CONSTANTS.hostURL + "api/resto-app/restaurant/update-business-hours/";
        let headers = {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
        axios.post(url, newTimeList,  {
          headers: headers
        }).then((response) => {
          if (response.data.status === 1){
            let restaurant_details = this.props.restaurant; 
            restaurant_details.business_hours = response.data.data;
            localStorage.setItem('restaurant_details', JSON.stringify(restaurant_details));
            this.props.informationBox("Timings updated successfully.");
            this.props.updateRestoDetails(restaurant_details);
            this.setState({
              restaurantSaveText: "Save",
              restaurantTimeEditDisplay: false,
              openingTime: new Date(),
              closingTime: new Date(),
              currentRestaurantDayEdited: "",
              closingOpeningTimes: timeList
            })
          } else {
            this.props.informationBox("Network or server error. Please try again later.");
            this.setState({
              restaurantSaveText: "Save"
            });
          }
        }).catch((err) => {
          this.props.informationBox(err.response.data.message);
          this.setState({
            restaurantSaveText: "Save"
          });
        });
      } else {
        this.setState({
          restaurantSaveText: "Save"
        });
        this.props.informationBox("Opening time should be less than closing time.");
      }
    }
  
    componentDidMount() {
      window.scrollTo(0, 0);
      this.updateDimensions();
      window.addEventListener("resize", this.updateDimensions.bind(this));
    }
  
    updateDimensions() {
      let screen_width = window.innerWidth;
      if(screen_width < 500) {
          this.setState({ isMobileScreen: true });
      } else {
          this.setState({ isMobileScreen: false });
      }
    }
    
    handleChange = (panel) => (event, isExpanded) => {
      if(isExpanded)
        this.setState({ expanded : panel});
      else
        this.setState({ expanded : false});
    };

    handleTextChange(event) {
      this.setState({restoDescText: event.target.value});
    }

    handleRestoDiscSwitchChange(){
      if(this.state.restoDiscSwitch === false){
        this.setState({restoDiscSwitch: true});
        this.setState({restoDiscBtnStyle : {
          background : "#425FA1",
          color: "#DEE8FF",
          left: "27px",
          boxShadow: "2px 0px 2px 0.5px #aaaaaa",
        }});
      }
      else{
        this.setState({restoDiscSwitch: false});
        this.setState({restoDiscBtnStyle : {
          background : "#DEE8FF",
          color: "#425FA1",
          left: "0px",
          boxShadow: "-2px 0px 2px 0.5px #aaaaaa",
        }});
      }
    }

    handleRestoDeliverySwitchChange(){
      this.props.handleDeliverySwitch();
    }

    handleRestoPackagingSwitchChange(){
      if(this.state.restoPackagingSwitch === false){
        this.setState({restoPackagingSwitch: true});
        this.setState({restoPackagingBtnStyle : {
          background : "#EB622F",
          color: "#FFFFFF",
          left: "27px",
          boxShadow: "2px 0px 2px 0.5px #aaaaaa",
        }});
      }
      else{
        this.setState({restoPackagingSwitch: false});
        this.setState({restoPackagingBtnStyle : {
          background : "#FFFFFF",
          color: "#EB622F",
          left: "0px",
          boxShadow: "-2px 0px 2px 0.5px #aaaaaa",
        }});
      }
    }

    toggleDiscToPercentage(){
      this.setState({discountType : "%"});
    }

    toggleDiscToRupees(){
      this.setState({discountType : "Rs."});
    }

    restaurantDiscountPanel(){
      // let restoDiscount = 0;
      var minOrder = 0;
      let discountLabel = '';
      // if (this.props.restaurant != null && this.props.restaurant.resto_discount != null){
      //   restoDiscount = this.props.restaurant.resto_discount;
      // }
      if (this.props.restaurant != null && this.props.restaurant.extra_data !== undefined && this.props.restaurant.extra_data.discount_details !== undefined && this.props.restaurant.extra_data.discount_details !== null){
        minOrder = this.props.restaurant.extra_data.discount_details.min_order_value;
      }
      if (this.props.restaurant != null && this.props.restaurant.extra_data !== undefined && this.props.restaurant.extra_data.discount_details !== undefined && this.props.restaurant.extra_data.discount_details !== null){
        discountLabel = this.props.restaurant.extra_data.discount_details.label;
      }
      return(
        <AccordionDetails>
          {/* <div className="buttonOuterCoating" style={{width:"60px"}} onClick={this.handleRestoDiscSwitchChange}>
            <span className="buttonOnOff" style={this.state.restoDiscBtnStyle}>%</span>
          </div> */}
          <div>
            <Grid style={{marginLeft: "0px", paddingTop: "0px"}} container spacing={0} className="menuGridPosition">
              <Grid item xs={12} >
                <div className="textBoxLabel">Discount (in %)</div>
                <input disabled={!this.state.discountEditable} value={this.state.restaurantDiscount} onChange={(event) => this.setState({restaurantDiscount: event.target.value.slice(0, 2)})} type="number" placeholder={"Enter Discount in "+ this.state.discountType} className="textBoxStyle"/>
                
                {this.state.discountSubmitText === "Submit" ?
                <></>
                :                
                (this.state.restaurantDiscountApproval === "pending") ? 
                  <span style={{color: "#EB622F", fontSize:"20px", marginLeft:"10px", top:"5px", position:"relative"}}><MdOutlineError/><span className="textBoxLabel" style={{top:"-5px", position:"relative"}}> Approval Pending</span></span> 
                  :
                    (this.state.restaurantDiscountApproval === "declined") ? 
                      <span style={{color: "#CB1E33", fontSize:"20px", marginLeft:"10px", top:"5px", position:"relative"}}><MdOutlineError/><span className="textBoxLabel" style={{top:"-5px", position:"relative"}}> Declined</span></span> 
                      :
                        (this.state.restaurantDiscountApproval === null) ? 
                          null 
                        : 
                          <span style={{color: "#1F7E1B", fontSize:"20px", marginLeft:"10px", top:"5px", position:"relative"}}><AiFillCheckCircle /><span className="textBoxLabel" style={{top:"-5px", position:"relative"}}> Approved</span></span>
                }
                
                <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.restaurantDiscountError}</div>
                {/* <span className="discountSticker">
                  {this.state.discountType === "%" ? 
                    <><span onClick={this.toggleDiscToPercentage} className="discPercBtnSel">%</span>
                    <span onClick={this.toggleDiscToRupees} className="discPercBtn">Rs.</span></> :
                    <><span onClick={this.toggleDiscToPercentage}className="discPercBtn">%</span>
                    <span onClick={this.toggleDiscToRupees} className="discPercBtnSel">Rs.</span></>
                  }
                </span> */}
              </Grid>

              <Grid item xs={12} >
                <div className="textBoxLabel">Discount Label</div>
                <input disabled={!this.state.discountEditable} value={!this.state.discountEditable ? discountLabel : this.state.restaurantDiscountLabel} onChange={(event) => this.setState({restaurantDiscountLabel: event.target.value})} type="text" placeholder="Enter Discount Label, For Eg: Happy Hours Discount" className="textBoxStyle" style={{width:"100%"}}/>
                <div style={{marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.restaurantDiscountLabelError}</div>
              </Grid>
              <Grid item xs={12} >
                <div className="textBoxLabel">Minimum Order to Avail the Above Discount</div>
                <Grid container spacing={0}>
                  <Grid item xs={11}>
                    <input disabled={!this.state.discountEditable} value={!this.state.discountEditable ? minOrder : this.state.restaurantDiscountMin} onChange={(event) => this.setState({restaurantDiscountMin: event.target.value})} type="number" placeholder="Enter the Minimum Order Amount required to get a Discount, For Eg : 300" className="textBoxWithButtonStyle" style={{width:"100%"}}/>
                  </Grid>
                  <Grid item xs={1}>
                    <span className="discountSticker" style={{top:"8.5px"}}>Rs.</span>
                  </Grid>
                </Grid>
                <div style={{marginTop: "5px", marginBottom: "15px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.restaurantDiscountMinError}</div>
                <div style={{marginTop: "5px", marginBottom: "15px", fontSize: "12px", color: "green", fontFamily: "Poppins-Medium"}}>{this.state.restaurantDiscountSuccess}</div>
              </Grid>
              <Grid item xs={12} >
                  {(this.state.discountSubmitText === "Edit" && (this.state.restaurantDiscountApproval === "pending")) ? <></> : <span onClick={() => this.handleRestaurantDiscountSubmit()} className="packagingSticker" style={{cursor:"pointer", borderRadius: "8px"}}>{this.state.discountSubmitText}</span>}
                  {this.state.discountEditable && this.props.restaurant !== undefined && this.props.restaurant.extra_data !== undefined && this.props.restaurant.extra_data.discount_details !== undefined && this.props.restaurant.extra_data.discount_details !== null ? 
                  <span onClick={() => this.discountCancel()} className="packagingSticker" style={{cursor:"pointer", borderRadius: "8px", marginLeft: "10px"}}>Cancel</span> : null}
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      );
    }

    discountCancel(){
      let restaurant_details = this.props.restaurant;
      if (restaurant_details.extra_data.discount_details !== undefined && restaurant_details.extra_data.discount_details !== null){
        this.setState({
          restaurantDiscount: restaurant_details.resto_discount,
          restaurantDiscountLabel: restaurant_details.extra_data.discount_details.label,
          restaurantDiscountMin: restaurant_details.extra_data.discount_details.min_order_value,
          restaurantDiscountApproval: (restaurant_details.extra_data.discount_details !== undefined && restaurant_details.extra_data.discount_details !== null) ? restaurant_details.extra_data.discount_details.approval_status : null,
          restoDiscSwitch: true,
          discountEditable: false,
          discountSubmitText: "Edit",
          restoDiscBtnStyle : {
            background : "#425FA1",
            color: "#DEE8FF",
            left: "27px",
            boxShadow: "2px 0px 2px 0.5px #aaaaaa",
          }
        });
      }
    }

    handleDeliveryDropdownChangeA = tags => (e) => {
      let tempList = this.state.deliveryRules
      tempList[tags][0] = e.target.value
      this.setState({
        ["deliveryA_"+tags] : e.target.value,
        ["deliveryAError_"+tags] : false,
        deliveryRules : tempList
      })
      // console.log(this.state.deliveryRules)
    }

    handleDeliveryDropdownChangeB = tags => (e) => {
      // console.log("state check");
      // console.log(this.state);
      let tempList = this.state.deliveryRules
      tempList[tags][1] = e.target.value
      if (tags < this.state.deliveryRules.length - 1){
        tempList[tags + 1][0] = e.target.value;
      }
      this.setState({
        ["deliveryB_"+tags] : e.target.value,
        ["deliveryBError_"+tags] : false,
        deliveryRules : tempList
      });
      // console.log(this.state.deliveryRules)
    }

    generateMenuItems(startItem){
      const fields = [];
      let startVal = this.state.deliveryRules[startItem-1][1] + 1
      for (let i = startVal; i <= 10; i++) {
        fields.push(<MenuItem value={i}>{i}</MenuItem>);
      }
      return fields
    }

    handleDeliveryPriceChange = tags => (e) => {
      if(this.state.dlvType === "DAIDISH_ASSISTED_DELIVERY" || this.state.defaultDlvMode === "DAIDISH_ASSISTED_DELIVERY"){
        const val = Number(e.target.value) >= 0  ? false : true
        this.setState({  
          restoContribution : e.target.value,
          ["deliveryPriceError_"+tags] : val,
        });
      }
      else{
        let tempList = this.state.deliveryRules
        tempList[tags][2] = e.target.value
        const val = Number(e.target.value) >= 0  ? false : true
        this.setState({  
          ["deliverPriceText"+tags] : e.target.value,
          ["deliveryPriceError_"+tags] : val,
          deliveryRules : tempList
        });
        // console.log(this.state.deliveryRules)
      }
    }

    deliveryRule(){
      var rows = [];

      if(this.state.dlvType === "DAIDISH_ASSISTED_DELIVERY" || this.state.defaultDlvMode === "DAIDISH_ASSISTED_DELIVERY"){ //this.state.deliveryRuleCount > 0
        rows.push(
          <>
            <Grid item xs={2}>
              <Grid container spacing={0}>
                <Grid item xs={11}>
                  <input disabled={!this.state.deliveryEditable} style={{marginTop:"14px", width:"100%"}} type="text" placeholder="Price in Rs." className="textBoxStyle" 
                    value={this.state.restoContribution} 
                    onChange={this.handleDeliveryPriceChange(0)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <span className="packagingSticker" style={{top:"22px", left:"-5px"}}>Rs.</span>
                </Grid>
              </Grid>
              {this.state["deliveryPriceError_0"] && <div className="textBoxErrors">Enter a valid Price or 0 for free</div>}
            </Grid>
          </>
        )
      }
      else if (this.state.deliveryRuleCount > 0){
        rows.push(
          <>
              <Grid item xs={2}>
                <div className="deliveryHeader">Distance A</div> 
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <div className="deliveryHeader">{this.state.deliveryEditable ? "Select Distance B" : "Distance B" }</div> 
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <div className="deliveryHeader">{this.state.deliveryEditable ? this.state.defaultDlvMode === "DAIDISH_ASSISTED_DELIVERY" ? "Resto Contribution" : "Enter Price" : this.state.defaultDlvMode === "DAIDISH_ASSISTED_DELIVERY" ? "Resto Contribution" : "Price"}</div> 
              </Grid>
              <Grid item xs={4}></Grid>
          </>
        );
      }
      for (var i = 0; i < this.state.deliveryRuleCount; i++) {
         if(i === 0){
          rows.push(
            <>
              <Grid item xs={2}>
                <FormControl>
                  {/* <InputLabel id={"distA"+i}>Distance A</InputLabel> */}
                  <input disabled className="deliveryFixedInput" value={this.state.deliveryRules[0][0]}/>
                </FormControl>
                <span className="deliverySticker">Km</span>
                {this.state["deliveryAError_"+(i)] && <div className="textBoxErrors">Select Distance A</div>}
              </Grid>
              <Grid item xs={1}>
                <div className="textBoxLabel" style={{paddingTop:"20px", paddingLeft:"25%"}}>to</div>
              </Grid>
              <Grid item xs={2}>
                <FormControl>
                  {this.state.deliveryEditable ? <Select
                    labelId={"distB"+i}
                    id={"distB"+i+"-select"}
                    style={{width:"100px", transform: "translateY(18px)", textAlign: "center"}}
                    onChange={this.handleDeliveryDropdownChangeB(i)}
                    defaultValue={this.state.deliveryRules[i][1] === undefined ? "" : this.state.deliveryRules[i][1]}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                  </Select> : <input disabled className="deliveryFixedInput" value={this.state.deliveryRules[0][1]}/>}
                </FormControl>
                <span className="deliverySticker">Km</span>
                {this.state["deliveryBError_"+(i)] && <div className="textBoxErrors">Select Distance B</div>}
              </Grid>
              <Grid item xs={1}>
                <ForwardIcon style={{fontSize:"20px", marginTop:"23px", marginLeft:"25%"}}/>
              </Grid>
              <Grid item xs={2}>
                <Grid container spacing={0}>
                  <Grid item xs={11}>
                    <input disabled={!this.state.deliveryEditable} style={{marginTop:"14px", width:"100%"}} type="text" placeholder="Price in Rs." className="textBoxStyle" value={this.state["deliverPriceText"+i]} onChange={this.handleDeliveryPriceChange(i)}/>
                  </Grid>
                  <Grid item xs={1}>
                    <span className="packagingSticker" style={{top:"22px", left:"-5px"}}>Rs.</span>
                  </Grid>
                </Grid>
                {this.state["deliveryPriceError_"+(i)] && <div className="textBoxErrors">Enter a valid Price or 0 for free</div>}
              </Grid>
              {i === this.state.deliveryRuleCount-1 ? <Grid item xs={4}>
              {this.addDeliveryRuleBtn()}
              {this.state.deliveryEditable ? <IconButton 
                  aria-label="deleteSubMenu" 
                  style={{marginTop:"10px", left:"-50px", float: 'right'}} 
                  onClick={() => {
                    this.state.deliveryRules.pop()
                    // console.log(this.state.deliveryRules)
                    this.setState({
                      deliveryRuleCount : this.state.deliveryRuleCount-1,
                      ["deliveryA_"+(this.state.deliveryRuleCount-1)] : undefined,
                      ["deliveryB_"+(this.state.deliveryRuleCount-1)] : undefined,
                      ["deliveryAError_"+(this.state.deliveryRuleCount-1)] : false,
                      ["deliveryBError_"+(this.state.deliveryRuleCount-1)] : false,
                      ["deliverPriceText"+(this.state.deliveryRuleCount-1)] : "",
                      ["deliveryPriceError_"+(this.state.deliveryRuleCount-1)] : false,
                    })
                  }}
                >
                  <DeleteIcon />
                </IconButton> : null}
              </Grid>
              : <Grid item xs={4}></Grid>}
            </>
          );
         } 
         else{
          rows.push(
            <>
              <Grid item xs={2}>
                <FormControl>
                <input disabled className="deliveryFixedInput" value={this.state.deliveryRules[i][0]}/>
                </FormControl>
                <span className="deliverySticker">Km</span>
                {this.state["deliveryAError_"+(i)] && <div className="textBoxErrors">Select Distance A</div>}
              </Grid>
              <Grid item xs={1}>
                <div className="textBoxLabel" style={{paddingTop:"20px", paddingLeft:"25%"}}>to</div>
              </Grid>
              <Grid item xs={2}>
                <FormControl>
                  {this.state.deliveryEditable ? <Select
                    labelId={"distB"+i}
                    id={"distB"+i+"-select"}
                    style={{width:"100px", transform: "translateY(18px)", textAlign: "center"}}
                    onChange={this.handleDeliveryDropdownChangeB(i)}
                    defaultValue={this.state.deliveryRules[i][1] === undefined ? "" : this.state.deliveryRules[i][1]}
                  >
                    {this.generateMenuItems(i)}
                  </Select> : <input disabled className="deliveryFixedInput" value={this.state.deliveryRules[i][1]}/>}
                </FormControl>
                <span className="deliverySticker">Km</span>
                {this.state["deliveryBError_"+(i)] && <div className="textBoxErrors">Select Distance B</div>}
              </Grid>
              <Grid item xs={1}>
                <ForwardIcon style={{fontSize:"20px", marginTop:"23px", marginLeft:"25%"}}/>
              </Grid>
              <Grid item xs={2}>
                <Grid container spacing={0}>
                  <Grid item xs={11}>
                    <input disabled={!this.state.deliveryEditable} style={{marginTop:"14px", width:"100%"}} type="text" placeholder="Price in Rs." className="textBoxStyle" value={this.state["deliverPriceText"+i]} onChange={this.handleDeliveryPriceChange(i)}/>
                  </Grid>
                  <Grid item xs={1}>
                    <span className="packagingSticker" style={{top:"22px", left:"-5px"}}>Rs.</span>
                  </Grid>
                </Grid>
                {this.state["deliveryPriceError_"+(i)] && <div className="textBoxErrors">Enter a valid Price or 0 for free</div>}
              </Grid>
              {i === this.state.deliveryRuleCount-1 ? <Grid item xs={4}>
                {this.addDeliveryRuleBtn()}
                {this.state.deliveryEditable ? <IconButton 
                  aria-label="deleteSubMenu" 
                  style={{marginTop:"10px", left:"-50px", float: 'right'}}
                  onClick={() => {
                    this.state.deliveryRules.pop()
                    // console.log(this.state.deliveryRules)
                    this.setState({
                      deliveryRuleCount : this.state.deliveryRuleCount-1,
                      ["deliveryA_"+(this.state.deliveryRuleCount-1)] : undefined,
                      ["deliveryB_"+(this.state.deliveryRuleCount-1)] : undefined,
                      ["deliveryAError_"+(this.state.deliveryRuleCount-1)] : false,
                      ["deliveryBError_"+(this.state.deliveryRuleCount-1)] : false,
                      ["deliverPriceText"+(this.state.deliveryRuleCount-1)] : "",
                      ["deliveryPriceError_"+(this.state.deliveryRuleCount-1)] : false,
                    })
                  }}
                >
                  <DeleteIcon />
                </IconButton> : null}
              </Grid>
              :<Grid item xs={4}></Grid>}
            </>
          );
        }
      }
      return <>{rows}</>;
    }

    addDeliveryRuleBtn(){
      if (this.state.deliveryEditable) {
        return(
          <Grid item xs={12} >
            <span 
            className="viewButton" 
            style={{marginTop:"10px", float: 'right'}} 
            onClick={() => {
              if(this.state.deliveryRuleCount === 0){
                //alert("Stage 0 --> Count 0")
                let tempList = []
                tempList.push(0);
                tempList.push(this.state["deliveryB_"+(this.state.deliveryRuleCount)]);
                tempList.push(this.state["deliverPriceText"+this.state.deliveryRuleCount]);
                this.state.deliveryRules.push(tempList);
                this.setState({
                  deliveryRuleCount : this.state.deliveryRuleCount+1,
                  ["deliveryAError_"+this.state.deliveryRuleCount] : false,
                  ["deliveryA_"+(this.state.deliveryRuleCount)]: 0,
                });
              }
              else{
                let errorCount = 0
                if((this.state["deliveryA_"+(this.state.deliveryRuleCount-1)] !== undefined) && 
                  (this.state["deliveryA_"+(this.state.deliveryRuleCount-1)] !== null) && 
                  (this.state["deliveryA_"+(this.state.deliveryRuleCount-1)] !== "") && 
                  (this.state["deliveryB_"+(this.state.deliveryRuleCount-1)] !== undefined) && 
                  (this.state["deliveryB_"+(this.state.deliveryRuleCount-1)] !== null) && 
                  (this.state["deliveryB_"+(this.state.deliveryRuleCount-1)] !== "") && 
                  (this.state.deliveryRules[this.state.deliveryRules.length-1][1] < 10) && 
                  (this.state["deliverPriceText"+(this.state.deliveryRuleCount-1)] >= 0) &&
                  (this.state["deliverPriceText"+(this.state.deliveryRuleCount-1)] !== undefined) && 
                  (this.state["deliverPriceText"+(this.state.deliveryRuleCount-1)] !== null) && 
                  (this.state["deliverPriceText"+(this.state.deliveryRuleCount-1)] !== "")){
                    //alert("Stage 1 --> All Good")
                    let tempList = []
                    tempList.push(this.state.deliveryRules[this.state.deliveryRuleCount - 1][1]);
                    tempList.push(this.state["deliveryB_"+(this.state.deliveryRuleCount)]);
                    tempList.push(this.state["deliverPriceText"+this.state.deliveryRuleCount]);
                    this.state.deliveryRules.push(tempList);
                    // console.log(this.state.deliveryRules)
                    this.setState({
                      deliveryRuleCount : this.state.deliveryRuleCount+1,
                      ["deliveryAError_"+this.state.deliveryRuleCount] : false,
                      ["deliveryA_"+(this.state.deliveryRuleCount)]: this.state.deliveryRules[this.state.deliveryRuleCount-1][1],
                    });
                }
                else{
                  //alert("Stage 2 --> Something not correct")
                  if((this.state["deliveryA_"+(this.state.deliveryRuleCount-1)] === undefined) || (this.state["deliveryA_"+(this.state.deliveryRuleCount-1)] === null) || (this.state["deliveryA_"+(this.state.deliveryRuleCount-1)] === "")){
                    //alert("Stage 2.1 --> deliveryA Issue")
                    this.setState({
                      ["deliveryAError_"+(this.state.deliveryRuleCount-1)] : true,
                    });
                    errorCount++
                  }
                  if((this.state["deliveryB_"+(this.state.deliveryRuleCount-1)] === undefined) || (this.state["deliveryB_"+(this.state.deliveryRuleCount-1)] === null) || (this.state["deliveryB_"+(this.state.deliveryRuleCount-1)] === "")){
                    //alert("Stage 2.2 --> deliveryB Issue")
                    this.setState({
                      ["deliveryBError_"+(this.state.deliveryRuleCount-1)] : true,
                    });
                    errorCount++
                  }
                  if(isNaN(this.state["deliverPriceText"+(this.state.deliveryRuleCount-1)]) || this.state["deliverPriceText"+(this.state.deliveryRuleCount-1)] === "" || this.state["deliverPriceText"+(this.state.deliveryRuleCount-1)] === undefined || this.state["deliverPriceText"+(this.state.deliveryRuleCount-1)] === null){
                    //alert("Stage 2.3 --> Price not a Num/==Numm/==''/==null")
                    this.setState({
                      ["deliveryPriceError_"+(this.state.deliveryRuleCount-1)] : true,
                    });
                    errorCount++
                  }
                }
                if(errorCount === 0){
                  //alert("Stage 3 --> No Errors")
                  if(this.state.deliveryRules[this.state.deliveryRules.length-1][1] === 10){
                    //alert("Stage 3.1 --> deliveryB = 10")
                    this.props.informationBox("Max Kms range selected")
                  }
                }
              }
            }} >+ Add Delivery Rule</span>
          </Grid>
        );
      } else {
        return null;
      }
    }

    restaurantDeliveryPanel(){
      return(
        <AccordionDetails style={{display:"block"}}>
          <div>
            {this.state.defaultDlvMode === null ? 
            <RadioGroup row aria-label="DeliveryType" name="deliveryType" value={this.state.dlvType} onChange={(e) => this.setState({dlvType : e.target.value})}>
              <FormControlLabel value="DAIDISH_ASSISTED_DELIVERY" control={<Radio color="default"/>} /><span className="radioLabel">Assisted Delivery</span>
              <FormControlLabel value="DELIVERY" control={<Radio color="default"/>} /><span className="radioLabel">Restaurant Delivery</span>
            </RadioGroup>
            :
            <>
              <div className="textBoxLabel">Delivery Mode</div>
              <div className="radioLabel" style={{top:"0px", left:"0px"}}>{this.state.defaultDlvMode === "DAIDISH_ASSISTED_DELIVERY" ? "Daidish Assisted Delivery" : "Restaurant Delivery"}</div>
            </>
            }
          </div>
          {(this.state.defaultDlvMode !== null || this.state.dlvType !== null) && 
            <div style={{width: "100%"}}>
            <Grid container spacing={0} style={{marginLeft: '0px', paddingTop: '0px'}} className="menuGridPosition">
              <Grid item xs={12} >
                {this.state.dlvType === "DAIDISH_ASSISTED_DELIVERY" || this.state.defaultDlvMode === "DAIDISH_ASSISTED_DELIVERY" ? 
                  <div className="textBoxLabel">Add Restaurant Contribution</div>
                :
                  <div className="textBoxLabel">Add Delivery Charge Rules based on Distance</div>}
              </Grid>
              {this.state.dlvType === "DAIDISH_ASSISTED_DELIVERY" || this.state.defaultDlvMode === "DAIDISH_ASSISTED_DELIVERY" ?
                <>
                  <Grid item xs={2}>
                    <Grid container spacing={0}>
                      <Grid item xs={11}>
                        <input disabled={!this.state.deliveryEditable} style={{marginTop:"14px", width:"100%"}} type="text" placeholder="Price in Rs." className="textBoxStyle" 
                          value={this.state.restoContribution} 
                          onChange={this.handleDeliveryPriceChange(0)}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <span className="packagingSticker" style={{top:"22px", left:"-5px"}}>Rs.</span>
                      </Grid>
                    </Grid>
                    {this.state["deliveryPriceError_0"] && <div className="textBoxErrors">Enter a valid Price or 0 for free</div>}
                  </Grid>
                </>
                :
                <>
                  {this.deliveryRule()}
                  {this.state.deliveryRuleCount === 0 && this.addDeliveryRuleBtn()}
                </>
              }
            </Grid>
          </div>}
        </AccordionDetails>
      );
    }

    handlePackagingChargeSubmit(){
      if (isNaN(this.state.globalPackagingCharge) || this.state.globalPackagingCharge === ''){
        this.props.informationBox("Please enter a valid value for packaging charge.");
        return;
      }
      let url = CONSTANTS.hostURL + "api/resto-app/restaurant/update-global-packaging-charge/";
      let headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
      let req = {
        'packaging_charge': this.state.globalPackagingCharge
      }
      axios.post(url, req,  {
        headers: headers
      }).then((response) => {
        if (response.data.status === 1){
          this.setState({
            globalPackagingCharge: '',
            packagingInputShown: false
          });
          this.props.informationBox("Packaging charge updated successfully.");
        } else {
          this.props.informationBox("Network or server error. Please try again later.");
        }
      }).catch((err) => {
        this.props.informationBox(err.response.data.message);
      });
    }

    restaurantPackagePanel(){
      return(
        <AccordionDetails>
          <div style={{width: "100%"}}> 
            {this.state.packagingInputShown ? <div className="textBoxLabel">Packaging Charge Per Unit</div> : <Grid item xs={12}>
              <span onClick={() => this.setState({packagingInputShown: true})} className="packagingSticker" style={{cursor:"pointer", borderRadius: "8px"}}>Update packaging charge for all dishes</span>  
            </Grid>}
            {this.state.packagingInputShown ? <Grid style={{marginLeft: '0px', paddingTop: '0px'}} container spacing={0} className="menuGridPosition">
              <Grid item xs={4} >
                <input type="number" value={this.state.globalPackagingCharge} onChange={(event) => this.setState({globalPackagingCharge: event.target.value})} placeholder="Rs." className="textBoxWithButtonStyle" style={{width:"100%"}}/>
              </Grid>
              <Grid item xs={1} >
                <span className="discountSticker" style={{top:"9px"}}>Rs.</span>
              </Grid>
            </Grid> : null}
          </div>
        </AccordionDetails>
      );
    }

    openRestaurantTimeEdit(keyItem) {
      if (!this.state.restaurantTimeEditDisplay) {
        var openingTime = this.state.closingOpeningTimes[keyItem]["opening"];
        var closingTime = this.state.closingOpeningTimes[keyItem]["closing"];
        this.setState({
          currentRestaurantDayEdited: keyItem,
          restaurantTimeEditDisplay: true,
          openingTime: openingTime,
          closingTime: closingTime
        })
      }
    }
    
    closeRestaurantTimeEdit() {
      if (this.state.restaurantTimeEditDisplay) {
        let timeList = this.state.closingOpeningTimes;
        let restaurant_details = this.props.restaurant;
        let business_hours = restaurant_details.business_hours;
        let now = new Date();
        if (business_hours !== null){
          if (business_hours[this.state.currentRestaurantDayEdited] !== null){
            let openingValue = business_hours[this.state.currentRestaurantDayEdited.toUpperCase()]["opens_at_str"];
            let closingValue = business_hours[this.state.currentRestaurantDayEdited.toUpperCase()]["closes_at_str"];
            timeList[this.state.currentRestaurantDayEdited]["opening"] = new Date(now.getFullYear(), now.getMonth(), now.getDate(), this.getHour(openingValue), this.getMinute(openingValue));
            timeList[this.state.currentRestaurantDayEdited]["closing"] = new Date(now.getFullYear(), now.getMonth(), now.getDate(), this.getHour(closingValue), this.getMinute(closingValue));
          } else {
            timeList[this.state.currentRestaurantDayEdited]["opening"] = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10);
            timeList[this.state.currentRestaurantDayEdited]["closing"] = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10);
          }
        } else {
          timeList[this.state.currentRestaurantDayEdited]["opening"] = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10);
          timeList[this.state.currentRestaurantDayEdited]["closing"] = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10);
        }
        this.setState({
          currentRestaurantDayEdited: "",
          restaurantTimeEditDisplay: false,
          closingOpeningTimes: timeList,
          restaurantBusinessHoursErrorText: ""
        })
      }
    }

    timingContents(){
      let dayList = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
      const listItems = dayList.map((keyItem, index) =>
        <div key={index} className="tableContents">
          <Grid container spacing={0} >
            <Grid item xs={4}><span>{keyItem}</span></Grid>
            <Grid item xs={3}><span>{this.getDateFormat(this.state.closingOpeningTimes[keyItem]["opening"])}</span></Grid>
            <Grid item xs={3}><span>{this.getDateFormat(this.state.closingOpeningTimes[keyItem]["closing"])}</span></Grid>
            <Grid item xs={2}>
              <span onClick={() => this.openRestaurantTimeEdit(keyItem)}><EditIcon style={{ fontSize: 19 }}/></span>
            </Grid>
          </Grid>
        </div>
      );
      return(<>{listItems}</>);
    }


    restaurantRestoTimingPanel(){
      return(
        <AccordionDetails>
          <Grid container spacing={0}>
            <Grid item xs={12} >
              <div className="tableHeader">
                <Grid container spacing={0} >
                  <Grid item xs={4}>Day</Grid>
                  <Grid item xs={3}>Opening Time</Grid>
                  <Grid item xs={3}>Closing Time</Grid>
                  <Grid item xs={2}>Options</Grid>
                </Grid>
              </div>
              {this.timingContents()}
              {this.state.restaurantTimeEditDisplay ? <div><div className="newSubMenuOrDish">
                <Grid container spacing={0} >
                  <Grid item xs={4} style={{paddingTop:'12px'}} ><span>Update Timing for {this.state.currentRestaurantDayEdited}</span></Grid>
                  <Grid item xs={3}>
                    <div style={{marginTop: "6px"}}>
                      <TimeInput
                        mode='12h'
                        value={this.state.openingTime}
                        onChange={(time) => this.onTimeChange("opening", time)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div style={{marginTop: "6px"}}>
                      <TimeInput
                        mode='12h'
                        value={this.state.closingTime}
                        onChange={(time) => this.onTimeChange("closing", time)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <span onClick={() => this.saveTimeChange()} className="saveTimeButton" style={{top:'10px', left:'15px'}}>{this.state.restaurantSaveText}</span>
                    <IconButton aria-label="closeEdit" style={{float:'right'}} onClick={() => this.closeRestaurantTimeEdit()}>
                      <ClearIcon />
                    </IconButton>
                    <IconButton aria-label="copyTime" style={{float:'right'}}>
                      <FileCopyIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </div><div style={{marginLeft: "10px", marginTop: "5px", fontSize: "12px", color: "#EA5B2F", fontFamily: "Poppins-Medium"}}>{this.state.restaurantBusinessHoursErrorText}</div></div> : null}
            </Grid>
          </Grid>
        </AccordionDetails>
      );
    }

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','), mime = arr[0].exec(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
    }

    setCodeImage(codeImage, codeImageName, codeImageUploaded){
      this.setState({
        codeImage: codeImage,
        codeImageName: codeImageName,
        codeImageUploaded: codeImageUploaded
      })
    }

    handleSuggestionClick(suggestion) {
      let req = {
        'existing_cuisines': [suggestion.id]
      };
      let newCuisines = [suggestion]
      for (var i=0; i<this.props.restaurant.cuisines.length; i++){
        req.existing_cuisines.push(this.props.restaurant.cuisines[i].id);
        newCuisines.push(this.props.restaurant.cuisines[i]);
      }
      let url = CONSTANTS.hostURL + "api/resto-app/restaurant/update-cuisines/";
        let headers = {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
        axios.post(url, req,  {
          headers: headers
        }).then((response) => {
          if (response.data.status === 1){
            let restaurant_details = this.props.restaurant; 
            restaurant_details.cuisines = newCuisines;
            localStorage.setItem('restaurant_details', JSON.stringify(restaurant_details));
            this.props.updateRestoDetails(restaurant_details);
            this.setState({
              cuisineSuccessText: '',
              cuisineErrorText: ""
            });
            this.props.informationBox('Cuisine added successfully.')
          } else {
            this.props.informationBox("Network or server error. Please try again later.");
          }
        }).catch((err) => {
          this.props.informationBox(err.response.data.message);
        });
    }

    onChange = (event, { newValue }) => {
      let suggestions = this.state.cuisineSuggestions;
      let suggestionFound = false;
      let cuisineId = -1;
      let currentValue = newValue;
      for (var i=0; i<suggestions.length; i++){
        if (suggestions[i].name === newValue){
          suggestionFound = true;
          cuisineId = suggestions[i].id;
          currentValue = "";
          this.handleSuggestionClick(suggestions[i]);
          break;
        }
      }
      this.setState({
        cuisineTextValue: currentValue,
        selectedCusineId: cuisineId,
        cuisineSelected: suggestionFound,
        cuisineSuggestions: [],
        cuisineErrorText: "",
        cuisineSuccessText: ""
      }, () => this.getSuggestions(newValue, suggestionFound))
    };
  
    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ cuisineTextValue }) => {};
  
    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
      this.setState({
        cuisineSuggestions: []
      });
    };

    // Teach Autosuggest how to calculate suggestions for any given input value.
    getSuggestions(value, suggestionPresent){
      if (!suggestionPresent){
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        if (inputLength >= 3) {
          let headers = {
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token') 
          }
          let urlForSuggestions = CONSTANTS.hostURL + "api/resto-app/search/" + inputValue + "?entity=cuisine";
          axios.get(urlForSuggestions,  {
            headers: headers
          }).then((response) => {
              if (response.data.status === 1){
                // console.log(response.data);
                let results = [];
                for (var i=0; i<response.data.data.results.length; i++){
                  let cuisineFound = false;
                  for (var j=0; j<this.props.restaurant.cuisines.length; j++){
                    if (this.props.restaurant.cuisines[j].id === response.data.data.results[i].id){
                      cuisineFound = true;
                      break;
                    }
                  }
                  if (!cuisineFound){
                    results.push(response.data.data.results[i]);
                  }
                }
                if (!this.state.cuisineSelected){
                  this.setState({
                    cuisineSuggestions: results
                  })
                }
              }
          });
        } else {
          this.setState({
            cuisineSuggestions: []
          });
        }
      }
    };

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    getSuggestionValue = suggestion => suggestion.name;

    // Use your imagination to render suggestions.
    renderSuggestion = suggestion => (
      <div>
        {suggestion.name}
      </div>
    );

    handleSaveProceed(panelName){
      this.setState({
        expanded: panelName
      })
    }

    handleRestaurantDiscountSubmit() {
      if (!this.state.discountEditable){
        if (this.state.discountSubmitText !== "Submitting"){
          this.setState({
            discountEditable: true,
            discountSubmitText: "Submit"
          })
        }
      } else {
        this.setState({
          actionType: 'discount',
          showConfirmationBox: true,
          infoText: 'Are you sure you want to change the discount?'
        })
      }
    }

    handleRestoDeliverySubmit(){
      if (this.state.deliveryEditable){

        if(this.state.defaultDlvMode === null){
          let url = CONSTANTS.hostURL + 'api/resto-app/restaurant/update-delivery-option/';
          let req = {
            "mode": this.state.dlvType
          }
          let headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
          }
          axios.post(url, req,  {
            headers: headers
          }).then((response) => {
            if (response.data.status === 1){
              this.setState({defaultDlvMode: this.state.dlvType})
              if(this.state.dlvType === "DAIDISH_ASSISTED_DELIVERY" || this.state.defaultDlvMode === "DAIDISH_ASSISTED_DELIVERY"){
                if (this.state.restoContribution === undefined || this.state.restoContribution === null){
                  this.props.informationBox("Please enter a valid delivery charge.");
                }
                else if(this.state.restoContribution >= 0){
                  var req = {
                    'restaurant_contribution': this.state.restoContribution
                  }
                  let url = CONSTANTS.hostURL + 'api/resto-app/restaurant/update-delivery-charges/';
                  let headers = {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                  }
                  axios.post(url, req,  {
                    headers: headers
                  }).then((response) => {
                    if (response.data.status === 1){                
                      let restaurant_details = this.props.restaurant; 
                      restaurant_details.delivery_charges = req;
                      localStorage.setItem('restaurant_details', JSON.stringify(restaurant_details));
                      this.props.informationBox("Delivery charge successfully updated.");
                      this.props.updateRestoDetails(restaurant_details);
                      this.setState({
                        saveRuleText: "Edit Rules",
                        deliveryEditable: false
                      });
                    } else {
                      this.props.informationBox("Network or server error. Please try again later.");
                      this.setState({
                        saveRuleText: "Save Rules"
                      })
                    }
                  }).catch((err) => {
                    this.props.informationBox(err.response.data.message);
                    this.setState({
                      saveRuleText: "Save Rules"
                    });
                  });
                }
                else {
                  this.props.informationBox("Contributions must be greater than 0.");
                }
              }
              else if((this.state["deliveryA_"+(this.state.deliveryRuleCount-1)] !== undefined) && (this.state["deliveryA_"+(this.state.deliveryRuleCount-1)] !== null) && (this.state["deliveryA_"+(this.state.deliveryRuleCount-1)] !== "") && (this.state["deliveryB_"+(this.state.deliveryRuleCount-1)] !== undefined) && (this.state["deliveryB_"+(this.state.deliveryRuleCount-1)] !== null) && (this.state["deliveryB_"+(this.state.deliveryRuleCount-1)] !== "")){
                var deliveryCharge = this.state.deliveryRules[this.state.deliveryRules.length - 1][2];
                if (deliveryCharge === undefined){
                  this.props.informationBox("Please enter a valid delivery charge.");
                } else {
                  if (Number(deliveryCharge) >= 0){
                    this.setState({
                      saveRuleText: "Saving Rules.."
                    })
                    let req = [];
                    for (var i=0; i<this.state.deliveryRules.length; i++){
                      req.push({
                        'start': this.state.deliveryRules[i][0],
                        'end': this.state.deliveryRules[i][1],
                        'charge': this.state.deliveryRules[i][2]
                      })
                    }
                    
                    let url = CONSTANTS.hostURL + 'api/resto-app/restaurant/update-delivery-charges/';
                    let headers = {
                      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                    axios.post(url, req,  {
                      headers: headers
                    }).then((response) => {
                      if (response.data.status === 1){                  
                        let restaurant_details = this.props.restaurant; 
                        restaurant_details.delivery_charges = req;
                        localStorage.setItem('restaurant_details', JSON.stringify(restaurant_details));
                        this.props.informationBox("Delivery charge successfully updated.");
                        this.props.updateRestoDetails(restaurant_details);
                        this.setState({
                          saveRuleText: "Edit Rules",
                          deliveryEditable: false
                        });
                      } else {
                        this.props.informationBox("Network or server error. Please try again later.");
                        this.setState({
                          saveRuleText: "Save Rules"
                        })
                      }
                    }).catch((err) => {
                      this.props.informationBox(err.response.data.message);
                      this.setState({
                        saveRuleText: "Save Rules"
                      });
                    });
                  } else {
                    this.props.informationBox("Please enter a valid delivery charge.");
                  }     
                }
              }
              else{
                this.props.informationBox("Please select the values.");
              }
            }
            else{
              this.props.informationBox(response.data.message);
            }
          }).catch((err) => {
            this.props.informationBox(err.response.data.message);
            this.setState({
              saveRuleText: "Save Rules"
            });
          });
        }
        else{
          if(this.state.dlvType === "DAIDISH_ASSISTED_DELIVERY" || this.state.defaultDlvMode === "DAIDISH_ASSISTED_DELIVERY"){
            if (this.state.restoContribution === undefined || this.state.restoContribution === null){
              this.props.informationBox("Please enter a valid delivery charge.");
            }
            else if(this.state.restoContribution >= 0){
              var req = {
                'restaurant_contribution': this.state.restoContribution
              }
              let url = CONSTANTS.hostURL + 'api/resto-app/restaurant/update-delivery-charges/';
              let headers = {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
              }
              axios.post(url, req,  {
                headers: headers
              }).then((response) => {
                if (response.data.status === 1){                
                  let restaurant_details = this.props.restaurant; 
                  restaurant_details.delivery_charges = req;
                  localStorage.setItem('restaurant_details', JSON.stringify(restaurant_details));
                  this.props.informationBox("Delivery charge successfully updated.");
                  this.props.updateRestoDetails(restaurant_details);
                  this.setState({
                    saveRuleText: "Edit Rules",
                    deliveryEditable: false
                  });
                } else {
                  this.props.informationBox("Network or server error. Please try again later.");
                  this.setState({
                    saveRuleText: "Save Rules"
                  })
                }
              }).catch((err) => {
                this.props.informationBox(err.response.data.message);
                this.setState({
                  saveRuleText: "Save Rules"
                });
              });
            }
            else {
              this.props.informationBox("Contributions must be greater than 0.");
            }
          }
          else if((this.state["deliveryA_"+(this.state.deliveryRuleCount-1)] !== undefined) && (this.state["deliveryA_"+(this.state.deliveryRuleCount-1)] !== null) && (this.state["deliveryA_"+(this.state.deliveryRuleCount-1)] !== "") && (this.state["deliveryB_"+(this.state.deliveryRuleCount-1)] !== undefined) && (this.state["deliveryB_"+(this.state.deliveryRuleCount-1)] !== null) && (this.state["deliveryB_"+(this.state.deliveryRuleCount-1)] !== "")){
            var deliveryCharge = this.state.deliveryRules[this.state.deliveryRules.length - 1][2];
            if (deliveryCharge === undefined){
              this.props.informationBox("Please enter a valid delivery charge.");
            } else {
              if (Number(deliveryCharge) >= 0){
                this.setState({
                  saveRuleText: "Saving Rules.."
                })
                let req = [];
                for (var i=0; i<this.state.deliveryRules.length; i++){
                  req.push({
                    'start': this.state.deliveryRules[i][0],
                    'end': this.state.deliveryRules[i][1],
                    'charge': this.state.deliveryRules[i][2]
                  })
                }
                
                let url = CONSTANTS.hostURL + 'api/resto-app/restaurant/update-delivery-charges/';
                let headers = {
                  'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                }
                axios.post(url, req,  {
                  headers: headers
                }).then((response) => {
                  if (response.data.status === 1){                  
                    let restaurant_details = this.props.restaurant; 
                    restaurant_details.delivery_charges = req;
                    localStorage.setItem('restaurant_details', JSON.stringify(restaurant_details));
                    this.props.informationBox("Delivery charge successfully updated.");
                    this.props.updateRestoDetails(restaurant_details);
                    this.setState({
                      saveRuleText: "Edit Rules",
                      deliveryEditable: false
                    });
                  } else {
                    this.props.informationBox("Network or server error. Please try again later.");
                    this.setState({
                      saveRuleText: "Save Rules"
                    })
                  }
                }).catch((err) => {
                  this.props.informationBox(err.response.data.message);
                  this.setState({
                    saveRuleText: "Save Rules"
                  });
                });
              } else {
                this.props.informationBox("Please enter a valid delivery charge.");
              }     
            }
          }
          else{
            this.props.informationBox("Please select the values.");
          }
        }
      } else {
        this.setState({
          deliveryEditable: true,
          saveRuleText: "Save Rules"
        })
      }
    }

    onActionConfirm(type){
      if (type === 'discount'){
        if (!this.state.discountEditable){
          if (this.state.discountSubmitText !== "Submitting"){
            this.setState({
              discountEditable: true,
              discountSubmitText: "Submit"
            })
          }
        } else {
          this.setState({
            restaurantDiscountError: "",
            restaurantDiscountLabelError: "",
            restaurantDiscountMinError: "",
            restaurantDiscountSuccess: "",
            discountEditable: false,
            discountSubmitText: "Submitting.."
          });
          var req = {};
          let errorPresent = false;
          let errors = [];
          let discountErrorText = "";
          let labelErrorText = "";
          let minErrorText = "";
          if (this.state.restaurantDiscount === ""){
            discountErrorText = "Please enter a discount value."; 
            errorPresent = true;
          }
          if (discountErrorText !== ""){
            errors.push(discountErrorText);
          }
          if (this.state.restaurantDiscountLabel === ""){
            labelErrorText = "Please enter value for discount label.";
            errorPresent = true;
          }
          if (labelErrorText !== ""){
            errors.push(labelErrorText);
          }
          if (this.state.restaurantDiscountMin === ""){
            minErrorText = "Please enter minimum order value.";
            errorPresent = true;
          }
          if (minErrorText !== ""){
            errors.push(minErrorText);
          }
          if (!errorPresent){
            req = {
              "label": this.state.restaurantDiscountLabel,
              "discount_percentage": parseInt(this.state.restaurantDiscount),
              "min_order_value": parseInt(this.state.restaurantDiscountMin)
            }
            let url = CONSTANTS.hostURL + 'api/resto-app/restaurant/update-discount/';
            let headers = {
              'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
            axios.post(url, req,  {
              headers: headers
            }).then((response) => {
              if (response.data.status === 1){
                // let restaurant_details = this.props.restaurant; 
                // restaurant_details.extra_data.discount_details = req;
                // localStorage.setItem('restaurant_details', JSON.stringify(restaurant_details));
                // this.props.updateRestoDetails(restaurant_details);
                this.props.informationBox(response.data.message);
                this.setState({
                  discountSubmitText: "Edit",
                  restaurantDiscountApproval: "pending",
                  discountEditable: false
                })
              } else {
                this.props.informationBox("Network or server error. Please try again later.");
                this.setState({
                  discountSubmitText: "Submit",
                  discountEditable: true
                });
              }
            }).catch((err) => {
              this.props.informationBox(err.response.data.message);
              this.setState({
                discountSubmitText: "Submit",
                discountEditable: true,
              });
            });
          } else {
            this.props.informationBox(errors.join(" "));
            this.setState({
              discountSubmitText: "Submit",
              discountEditable: true
            });
          }
        }
      }
    }

    toggleConfirmationBox(){
      this.setState({
        showConfirmationBox: !this.state.showConfirmationBox
      })
    }

    render() {

      const value = this.state.cuisineTextValue;
      const cuisineSuggestions = this.state.cuisineSuggestions;

      const inputProps = {
        placeholder: 'Enter Here, For example, "Brewery" or "North Indian"',
        value,
        onChange: this.onChange
      };

        return(
        <div style={{padding: "0px 10px"}}>
            <Grid container spacing={0}>
              <Grid item xs={12} >
                <div className="infoRibbon">
                  <div className="infoBanner">
                  <img src={BannerImage} alt="BannerImage" className="bannerImage"/>
                  <span className="bannerText" style={{top: '-25px', paddingLeft: '20px'}}>Complete the steps below to manage restaurant with DaiDish</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} >
                <Container fixed className="accordionContainer">
                  <div style={{display: "flex", flexDirection: "row", padding: "5px 10px"}}>
                    <div className="stepMain">Steps to Get started</div>
                    <div className="stepStatus">Status</div>
                    <div className="stepEdit">Edit</div>
                  </div>
                  <Accordion className={this.state.expanded === 'panel1'? "accordionSelected" : "accordion"} expanded={this.state.expanded === 'panel1'} onChange={this.handleChange('panel1')}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon style={{color: "#EB622F"}} fontSize="large" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      className="stepHeadDimension"
                    >
                      <div style={{display:"flex", flexDirection:"column", width:"83%"}}>
                        <div className={this.state.expanded === 'panel1'? "stepText-selected" : "stepText"} style={{top:"0px"}}>Step 1</div>
                        <div className="stepHeader" style={{left:"0px", width:"100%", top:"-5px"}}>Update Restaurant Cuisine/s</div>
                      </div>
                      
                      {this.state.step1Status === "Done" ? <div className="doneIcon" style={{marginTop:"10px"}}><CheckCircleIcon className="doneIconPos" /><span className="doneIconText">Complete</span></div> : 
                      <div className="pendingIcon"><ErrorIcon className="doneIconPos" /><span className="doneIconText">Pending</span></div>}
                    </AccordionSummary>
                    <AccordionDetails>
                      <div style={{width:"100%"}}>
                        <div className="cuisineInfoText">
                          These cuisines will be displayed along with your restaurant name
                        </div>
                        <div className="errorText">{this.state.cuisineErrorText}</div>
                        <div className="successText">{this.state.cuisineSuccessText}</div>
                        <Autosuggest
                          suggestions={cuisineSuggestions}
                          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                          getSuggestionValue={this.getSuggestionValue}
                          renderSuggestion={this.renderSuggestion}
                          inputProps={inputProps}
                        />
                        {this.restaurantCuisinesList()}
                      </div>
                      {/* <textarea type="text" className="restoDescTextArea" rows={1} placeholder='Enter Here, For example, "Brewery" or "North Indian"' value={this.state.restoDescText} onChange={this.handleTextChange} /> */}
                    </AccordionDetails>
                    <AccordionActions>
                      <Button onClick={() => this.handleSaveProceed("panel2")} size="small" className="saveButton">
                        Save & Proceed
                      </Button>
                    </AccordionActions>
                  </Accordion>
                  <Accordion className={this.state.expanded === 'panel2'? "accordionSelected" : "accordion"} expanded={this.state.expanded === 'panel2'} onChange={this.handleChange('panel2')}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon style={{color: "#EB622F"}} fontSize="large" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                      className="stepHeadDimension"
                    >
                      <div style={{display:"flex", flexDirection:"column", width:"83%"}}>
                        <div className={this.state.expanded === 'panel2'? "stepText-selected" : "stepText"} style={{top:"0px"}}>Step 2</div>
                        <div className="stepHeader" style={{left:"0px", width:"100%", top:"-5px"}}>Update Restaurant Image</div>
                      </div>
                      
                      {this.state.step2Status === "Done" ? <div className="doneIcon" style={{marginTop:"10px"}}><CheckCircleIcon className="doneIconPos" /><span className="doneIconText">Complete</span></div> : 
                      <div className="pendingIcon"><ErrorIcon className="doneIconPos" /><span className="doneIconText">Pending</span></div>}
                    </AccordionSummary>
                    <AccordionDetails>
                    <div>
                      <img src={this.state.restoImage} alt="DefaultRestoCover" className="restoBanner"/>
                    </div>
                    </AccordionDetails>
                    <AccordionActions>
                    <Link to="/addRestaurantCover">
                      <Button size="small" className="saveButton" style={{top:"7px"}}>
                        Replace Image
                      </Button>
                    </Link>
                    <Button onClick={() => this.handleSaveProceed("panel3")} size="small" className="saveButton">
                      Save & Proceed
                    </Button>
                    </AccordionActions>
                  </Accordion>
                  <Accordion className={this.state.expanded === 'panel3'? "accordionSelected" : "accordion"} expanded={this.state.expanded === 'panel3'} onChange={this.handleChange('panel3')}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon style={{color: "#EB622F"}} fontSize="large" />}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                      className="stepHeadDimension"
                    >
                      <div style={{display:"flex", flexDirection:"column", width:"83%"}}>
                        <div className={this.state.expanded === 'panel3'? "stepText-selected" : "stepText"} style={{top:"0px"}}>Step 3</div>
                        <div className="stepHeader" style={{left:"0px", width:"100%", top:"-5px"}}>Update Restaurant Opening Time</div>
                      </div>
                      
                      {this.state.step3Status === "Done" ? <div className="doneIcon" style={{marginTop:"10px"}}><CheckCircleIcon className="doneIconPos" /><span className="doneIconText">Complete</span></div> : 
                      <div className="pendingIcon"><ErrorIcon className="doneIconPos" /><span className="doneIconText">Pending</span></div>}
                    </AccordionSummary>
                    {this.restaurantRestoTimingPanel()}
                    <AccordionActions>
                    <Button onClick={() => this.handleSaveProceed("panel4")} size="small" className="saveButton">
                        Save & Proceed
                      </Button>
                    </AccordionActions>
                  </Accordion>                  
                  <Accordion className={this.state.expanded === 'panel4'? "accordionSelected" : "accordion"} expanded={this.state.expanded === 'panel4'} onChange={this.handleChange('panel4')}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon style={{color: "#EB622F"}} fontSize="large" />}
                      aria-controls="panel4bh-content"
                      id="panel4bh-header"
                      className="stepHeadDimension"
                    >
                      <div style={{display:"flex", flexDirection:"column", width:"83%"}}>
                        <div className={this.state.expanded === 'panel4'? "stepText-selected" : "stepText"} style={{top:"0px"}}>Step 4</div>
                        <div className="stepHeader" style={{left:"0px", width:"100%", top:"-5px"}}>Update Restaurant Discount</div>
                      </div>
                      
                      {this.state.step4Status === "Done" ? <div className="doneIcon" style={{marginTop:"10px"}}><CheckCircleIcon className="doneIconPos" /><span className="doneIconText">Complete</span></div> : 
                      <div className="pendingIcon"><ErrorIcon className="doneIconPos" /><span className="doneIconText">Pending</span></div>}
                    </AccordionSummary>
                    {this.restaurantDiscountPanel()}
                    <AccordionActions>
                    <Button onClick={() => this.handleSaveProceed("panel5")} size="small" className="saveButton">
                        Save & Proceed
                      </Button>
                    </AccordionActions>
                  </Accordion>
                  <Accordion className={this.state.expanded === 'panel5'? "accordionSelected" : "accordion"} expanded={this.state.expanded === 'panel5'} onChange={this.handleChange('panel5')}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon style={{color: "#EB622F"}} fontSize="large" />}
                      aria-controls="panel5bh-content"
                      id="panel5bh-header"
                      className="stepHeadDimension"
                    >
                      <div style={{display:"flex", flexDirection:"column", width:"83%"}}>
                        <div className={this.state.expanded === 'panel5'? "stepText-selected" : "stepText"} style={{top:"0px"}}>Step 5</div>
                        <div className="stepHeader" style={{left:"0px", width:"100%", top:"-5px"}}>Update Delivery Options</div>
                      </div>
                      
                      {this.state.step5Status === "Done" ? <div className="doneIcon" style={{marginTop:"10px"}}><CheckCircleIcon className="doneIconPos" /><span className="doneIconText">Complete</span></div> : 
                      <div className="pendingIcon"><ErrorIcon className="doneIconPos" /><span className="doneIconText">Pending</span></div>}
                    </AccordionSummary>
                    {this.restaurantDeliveryPanel()}
                    <AccordionActions>
                      {this.state.deliveryEditable && this.props.restaurant !== undefined && this.props.restaurant.delivery_charges !== undefined && this.props.restaurant.delivery_charges !== null ? 
                      <Button size="small" className="saveButton" onClick={() => this.setState({deliveryEditable: false, saveRuleText: "Edit Rules"})}>
                        Cancel
                      </Button> : null}
                      <Button size="small" className="saveButton" onClick={() => this.handleRestoDeliverySubmit()}>
                        {this.state.saveRuleText}
                      </Button>
                      <Button onClick={() => this.handleSaveProceed("panel6")} size="small" className="saveButton">
                        Save & Proceed
                      </Button>
                    </AccordionActions>
                  </Accordion>
                  <Accordion className={this.state.expanded === 'panel6'? "accordionSelected" : "accordion"} expanded={this.state.expanded === 'panel6'} onChange={this.handleChange('panel6')}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon style={{color: "#EB622F"}} fontSize="large" />}
                      aria-controls="panel6bh-content"
                      id="panel6bh-header"
                      className="stepHeadDimension"
                    >
                      <div style={{display:"flex", flexDirection:"column", width:"83%"}}>
                        <div className={this.state.expanded === 'panel6'? "stepText-selected" : "stepText"} style={{top:"0px"}}>Step 6</div>
                        <div className="stepHeader" style={{left:"0px", width:"100%", top:"-5px"}}>Update Packaging Charges</div>
                      </div>
                      
                      {this.state.step6Status === "Done" ? <div className="doneIcon" style={{marginTop:"10px"}}><CheckCircleIcon className="doneIconPos" /><span className="doneIconText">Complete</span></div> : 
                      <div className="pendingIcon"><ErrorIcon className="doneIconPos" /><span className="doneIconText">Pending</span></div>}
                    </AccordionSummary>
                    {this.restaurantPackagePanel()}
                    <AccordionActions>
                    {this.state.packagingInputShown ? 
                      <Button size="small" className="saveButton" onClick={() => this.setState({packagingInputShown: false})}>
                        Cancel
                      </Button> : null}
                    <Button onClick={() => this.handlePackagingChargeSubmit()} size="small" className="saveButton">
                        Save & Proceed
                      </Button>
                    </AccordionActions>
                  </Accordion>
                </Container>
              </Grid>
            </Grid>
            <ConfirmationBox
              showConfirmationBox={this.state.showConfirmationBox}
              actionType={this.state.actionType}
              onActionConfirm={this.onActionConfirm}
              toggleConfirmationBox={this.toggleConfirmationBox}
              infoText={this.state.infoText}
            />
        </div>
        );
    }
}
export default ManageResto;