import axios from 'axios';
import React, { Component } from 'react';
import CONSTANTS from '../../config/constants';
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { withRouter } from 'react-router';
import '../../assets/styles/Insights.css';
import DaiDishLoading1 from '../../assets/images/DaiDishLoading1.gif';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';
import TodayIcon from '@material-ui/icons/Today';
import { FiFilter } from "react-icons/fi";
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import moment from 'moment';
import {
  DateRangePicker,
  LocalizationProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
// import no_orders from '../../assets/images/no_orders.svg';

class InsightsScreen extends Component {
  constructor(){
    super();
    this.orderSearch = this.orderSearch.bind(this);
    this.state = {
      isMobileScreen: false,
      orderList: [],
      tempOrderList: [],
      showOrderDetailsModal: false,
      searchBarText: "",
      orderCount: 0,
      filterType: 'Week',
      filterTypes: {
        'Today': [new Date().setDate(new Date().getDate() - 1), new Date().setDate(new Date().getDate() - 1)],
        'Week': [this.getFirstDateOfWeek(), new Date()],
        'Month': [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
        'Year': [new Date(new Date().getFullYear(), 0, 1), new Date()]
      },
      ordersFetching: true,
      detailsExpanded: true,
      grossRevenue: 0,
      deductions: 0,
      netReceivable: 0,
      dataLoading: true,
      breakdownInfo: {},
      insightsFetching: true,
      selectedDate: [this.getFirstDateOfWeek(), new Date()]
    }
  }

  getFirstDateOfWeek() {
    var curr = new Date(); // get current date
    console.log('getDay', curr.getDay());
    var first;
    if (curr.getDay() !== 0){
      first = curr.getDate() - curr.getDay();
    } else {
      first = curr.getDate() - 7;
    }
    console.log('first', first);
    return new Date(curr.setDate(first + 1)).toUTCString();
  }

  componentDidMount() {
    this.getGrossFinancials();
    this.getDetailedReport();
    this.getGrossFinancialsBreakdown();
  }

  getGrossFinancials() {
    let startDate = moment(this.state.selectedDate[0]).format('YYYY-MM-DD');
    let endDate = moment(this.state.selectedDate[1]).format('YYYY-MM-DD');
    let url = CONSTANTS.hostURL + `api/resto-app/insights/gross-financials/?start_date=${startDate}&end_date=${endDate}`;
    let headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    }
    axios.get(url, {
      headers: headers
    }).then(res => {
      if (res.data.status === 1){
        console.log(res.data);
        let data = res.data.data;
        this.setState({
          grossRevenue: data.gross_revenue,
          deductions: data.deductions,
          netReceivable: data.net_receivable,
          dataLoading: false
        })
      }
    });
  }

  getDetailedReport(pageNo=1) {
    let startDate = moment(this.state.selectedDate[0]).format('YYYY-MM-DD');
    let endDate = moment(this.state.selectedDate[1]).format('YYYY-MM-DD');
    let url = CONSTANTS.hostURL + `api/resto-app/insights/detailed-report/?start_date=${startDate}&end_date=${endDate}&page=${pageNo}`;
    let headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    }
    axios.get(url, {
      headers: headers
    }).then(res => {
      if (res.data.status === 1){
        console.log(res.data);
        let data = res.data.data;
        this.setState({
          orderList: pageNo === 1 ? data.results : [...this.state.orderList, ...data.results],
          tempOrderList: pageNo === 1 ? data.results : [...this.state.orderList, ...data.results],
          orderCount: data.count,
          insightsFetching: false
        })
        if (data.next){
          this.getDetailedReport(pageNo+1);
        }
      }
    });
  }

  getGrossFinancialsBreakdown() {
    let startDate = moment(this.state.selectedDate[0]).format('YYYY-MM-DD');
    let endDate = moment(this.state.selectedDate[1]).format('YYYY-MM-DD');
    let url = CONSTANTS.hostURL + `api/resto-app/insights/gross-financials-breakdown/?start_date=${startDate}&end_date=${endDate}`;
    let headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    }
    axios.get(url, {
      headers: headers
    }).then(res => {
      if (res.data.status === 1){
        console.log(res.data);
        let data = res.data.data;
        this.setState({
          breakdownInfo: data
        })
      }
    });
  }

  orderSearch(event){
    if(event.target.value === ""){
      this.setState({
        tempOrderList: this.state.orderList,
        searchBarText: event.target.value
      })
    }
    else{
      this.setState({
        searchBarText: event.target.value
      },() => {
        var searchedOrders = []
        for(let i=0; i<this.state.orderList.length; i++){
          if (this.state.orderList[i].order_id.toLowerCase().includes(this.state.searchBarText.toLowerCase())){
            searchedOrders.push(this.state.orderList[i])
          }
        }
        this.setState({
          tempOrderList: [...new Set(searchedOrders)]
        })
      })
    }
  }

  getFilterTabClassName(filterType){
    if (this.state.filterType === filterType){
      return 'ddFilterTabsSelected';
    } else {
      return 'ddFilterTabs';
    }
  }

  changeFilterType(filterType){
    if (this.state.filterType !== filterType){
      this.setState({
        insightsFetching: true,
        filterType: filterType,
        selectedDate: this.state.filterTypes[filterType],
        searchBarText: ""
      }, () => {
        this.getGrossFinancials();
        this.getDetailedReport();
        this.getGrossFinancialsBreakdown();
      });
    }
  }

  insightCards(){
    return <div style={{padding: '20px'}}>
      <div className='ddInsightsCardContainer'>
        <div className='ddInsightsCard'>
          <div style={{backgroundColor: '#FD5D5D'}} className='ddInsightsCardLeftLine'></div>
          <div style={{color: '#FD5D5D', backgroundColor: '#FFEBEB'}} className='ddInsightsCardLeftCol'>A</div>
          <div className='ddInsightsCardRightCol'>
            <div className='ddInsightsCardHeader'>Gross Revenue</div>
            <div className='ddInsightsCardInfo'>
              <div style={{flex: 1}}></div>
              <div className='ddInsightsCardSubHeader'>Rs. {this.state.grossRevenue}</div>
              <div className='ddInsightsCardOrdersInfo'>Number of orders : {this.state.orderCount}</div>
              <div style={{flex: 1}}></div>
              <div onClick={() => {
                this.props.toggleTaxBreakdown();
                this.props.setTaxBreakdown({
                  items: [
                    {
                      name: "Item Total",
                      value: `Rs. ${(this.state.breakdownInfo?.gross_revenue?.customer_payable_breakdown?.item_total??0).toFixed(2)}`
                    },
                    {
                      name: "Delivery Charge",
                      value: `Rs. ${(this.state.breakdownInfo?.gross_revenue?.customer_payable_breakdown?.delivery_charge??0).toFixed(2)}`
                    },
                    // {
                    //   name: "Net Bill Value",
                    //   value: `Rs. ${(this.state.breakdownInfo?.gross_revenue?.customer_payable_breakdown?.net_bill_value??0).toFixed(2)}`
                    // },
                    {
                      name: "Packaging Charges",
                      value: `Rs. ${(this.state.breakdownInfo?.gross_revenue?.customer_payable_breakdown?.packaging_charges??0).toFixed(2)}`
                    },
                    {
                      name: "Taxes",
                      value: `Rs. ${(this.state.breakdownInfo?.gross_revenue?.customer_payable_breakdown?.taxes??0).toFixed(2)}`
                    },
                    {
                      name: "Total Discount",
                      value: `- Rs. ${(this.state.breakdownInfo?.gross_revenue?.customer_payable_breakdown?.total_discount??0).toFixed(2)}`
                    }
                  ],
                  title: 'Gross Revenue',
                  netAmount: `Rs. ${this.state.grossRevenue}`
                })
              }} className='ddInsightsCardViewDetails'>View Details</div>
            </div>
          </div>
        </div>
        {/* <div className='ddInsightsCard'>
          <div style={{backgroundColor: '#FDB35D'}} className='ddInsightsCardLeftLine'></div>
          <div style={{color: '#FDB35D', backgroundColor: '#FFF3E5'}} className='ddInsightsCardLeftCol'>B</div>
          <div className='ddInsightsCardRightCol'>
            <div className='ddInsightsCardHeader'>Additions</div>
            <div className='ddInsightsCardInfo'>
              <div style={{flex: 1}}></div>
              <div className='ddInsightsCardSubHeader'>Rs. 0</div>
              <div style={{flex: 1}}></div>
              <div className='ddInsightsCardViewDetails'>View Details</div>
            </div>
          </div>
        </div> */}
        <div className='ddInsightsCard'>
          <div style={{backgroundColor: '#61B13E'}} className='ddInsightsCardLeftLine'></div>
          <div style={{color: '#61B13E', backgroundColor: '#F2FFED'}} className='ddInsightsCardLeftCol'>B</div>
          <div className='ddInsightsCardRightCol'>
            <div className='ddInsightsCardHeader'>Deductions</div>
            <div className='ddInsightsCardInfo'>
              <div style={{flex: 1}}></div>
              <div className='ddInsightsCardSubHeader'>Rs. {this.state.deductions}</div>
              <div style={{flex: 1}}></div>
              <div onClick={() => {
                this.props.toggleTaxBreakdown();
                this.props.setTaxBreakdown({
                  items: [
                    {
                      name: "Platform Service Fee",
                      value: `Rs. ${(this.state.breakdownInfo?.deductions?.service_fee_breakdown?.platform_service_fee??0).toFixed(2)}`
                    },
                    {
                      name: "GST on Platform Service Fee",
                      value: `Rs. ${(this.state.breakdownInfo?.deductions?.service_fee_breakdown?.gst_on_platform_service_fee??0).toFixed(2)}`
                    },
                    {
                      name: "Tax on Commission",
                      value: `Rs. ${(this.state.breakdownInfo?.deductions?.tcs_tds_breakdown?.tcs??0).toFixed(2)}`
                    },
                    {
                      name: "TDS Amount",
                      value: `Rs. ${(this.state.breakdownInfo?.deductions?.tcs_tds_breakdown?.tds??0).toFixed(2)}`
                    }
                  ],
                  title: 'Deductions',
                  netAmount: `Rs. ${this.state.deductions}`
                })
              }} className='ddInsightsCardViewDetails'>View Details</div>
            </div>
          </div>
        </div>
        <div className='ddInsightsCard'>
          <div style={{backgroundColor: '#0D7BCC'}} className='ddInsightsCardLeftLine'></div>
          <div style={{color: '#0D7BCC', backgroundColor: '#EDF5FF'}} className='ddInsightsCardLeftCol'>C</div>
          <div className='ddInsightsCardRightCol'>
            <div className='ddInsightsCardHeader'>Net Receivable (A-B)</div>
            <div className='ddInsightsCardInfo'>
              <div style={{flex: 1}}></div>
              <div className='ddInsightsCardSubHeader'>Rs. {this.state.netReceivable}</div>
              <div style={{flex: 1}}></div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='ddInsightsCardContainer'>
        <div className='ddInsightsCard'>
          <div style={{backgroundColor: '#662DC1'}} className='ddInsightsCardLeftLine'></div>
          <div style={{color: '#662DC1', backgroundColor: '#F7F2FF'}} className='ddInsightsCardLeftCol'>D</div>
          <div className='ddInsightsCardRightCol'>
            <div className='ddInsightsCardHeader'>Amount transferred by</div>
            <div className='ddInsightsCardHeader'>Daidish</div>
            <div className='ddInsightsCardInfo'>
              <div style={{flex: 1}}></div>
              <div style={{color: '#1F7E1B'}} className='ddInsightsCardSubHeader'>Rs. 56,700.42</div>
              <div style={{flex: 1}}></div>
              <div className='ddInsightsCardViewDetails'>View Details</div>
            </div>
          </div>
        </div>
        <div className='ddInsightsCard'>
          <div style={{backgroundColor: '#AE7AEB'}} className='ddInsightsCardLeftLine'></div>
          <div style={{color: '#AE7AEB', backgroundColor: '#F6EFFF'}} className='ddInsightsCardLeftCol'>E</div>
          <div className='ddInsightsCardRightCol'>
            <div className='ddInsightsCardHeader'>Your settlements</div>
            <div className='ddInsightsCardHeader'>(Banks UTR)</div>
            <div className='ddInsightsCardInfo'>
              <div style={{flex: 1}}></div>
              <div className='ddInsightsCardViewDetails'>View Details</div>
            </div>
          </div>
        </div>
        <div style={{flex: 1, margin: '15px'}}></div>
      </div> */}
    </div>;
  }

  toggleAccordion(){
    this.setState({
      detailsExpanded: !this.state.detailsExpanded
    })
  }

  detailedReport(){
    var listItems = [];
    for (var i=0; i<this.state.tempOrderList.length; i++){
      let order = this.state.tempOrderList[i];
      listItems.push(<Grid key={i} item xs={12} style={{margin:"0px 10px 1px 10px"}}>
      <div style={{display: 'flex', flexDirection: 'row', backgroundColor: 'white', width: '100%', alignItems: 'center'}} className="tableHeader">
      <div className='insightsTableCol'>{order.order_id}</div>
      <div className='insightsTableCol'>
        <div>{moment(order.order_date, 'YYYY-MM-DD').format('DD MMM YYYY')}</div>
        <div style={{color: '#939393', fontSize: '12px'}}>1:00 PM</div>
      </div>
      <div className='insightsTableCol'><span className='ddDeliveredIcon'></span>{order.status}</div>
      <div className='insightsTableCol'>Online</div>
      <div className='insightsTableCol'>Rs. {order.net_payout}</div>
      {/* <div style={{color: '#1F7E1B'}} className='insightsTableCol'><CheckCircleOutlineIcon className='ddSettledIcon'/>Settled</div> */}
      {/* <div className='insightsTableCol'>Rs. 0</div> */}
      <div onClick={() => {
        this.props.toggleTaxBreakdown();
        this.props.setTaxBreakdown({
          items: [
            {
              name: "Platform Service Fee",
              value: `Rs. ${(order?.service_fee_breakdown?.platform_service_fee??0).toFixed(2)}`
            },
            {
              name: "GST on Platform Service Fee",
              value: `Rs. ${(order?.service_fee_breakdown?.gst_on_platform_service_fee??0).toFixed(2)}`
            },
            {
              name: "Tax on Commission",
              value: `Rs. ${(order?.tcs_tds_breakdown?.tcs??0).toFixed(2)}`
            },
            {
              name: "TDS Amount",
              value: `Rs. ${(order?.tcs_tds_breakdown?.tds??0).toFixed(2)}`
            }
          ],
          title: 'Deductions',
          orderDetails: {
            grossRevenue: `Rs. ${(order?.net_customer_payable??0).toFixed(2)}`,
            netReceivable: `Rs. ${(order?.net_payout??0).toFixed(2)}`,
            deductions: `Rs. ${((order?.net_service_fee??0) + (order?.net_tcs_tds??0)).toFixed(2)}`,
            orderId: order?.order_id
          }
        })
      }} style={{color: '#425FA1', textDecoration: 'underline', cursor: 'pointer'}} className='insightsTableCol'>More</div>
    </div>
  </Grid>);
    }
    return <Grid item xs={12} >
      <Container fixed className="accordionContainer">
        <Accordion className="accordion" expanded={this.state.detailsExpanded} onChange={() => this.toggleAccordion()}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon style={{color: "#EB622F"}} fontSize="large" />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="stepHeadDimension"
          style={{height: '60px'}}
        >
          <div className="insightsStepHeader">View Detailed Report</div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{width:"100%"}}>
            <Grid item xs={12} style={{margin:"0px 10px"}}>
              <div style={{backgroundColor: 'white', paddingLeft: '15px', paddingRight: '15px'}} className="ddFoodSearchDiv">
                  <SearchIcon className="ddSearchIcon"/>
                  <input
                      type="text"
                      className="ddSearchTextBox"
                      placeholder="Search for order ID"
                      value={this.state.searchBarText}
                      onChange={this.orderSearch}
                  />
                  <span style={{float:"right", cursor:"pointer"}}>
                      <span style={{borderLeft:"1px solid #E6E6E6", height:"100%", position:"relative", top:"3px", marginRight:"5px"}}></span>
                      <FiFilter style={{color:"#EA5B2F", margin:"0px 5px", position:"relative", top:"6px"}}/> <span style={{fontFamily:"Poppins-Medium", color:"#3C3C3C", position:"relative", top: "4px", paddingRight:"5px"}}>Filter</span>
                  </span>
              </div>
            </Grid>
            {this.state.tempOrderList.length === 0 && <Grid item xs={12} style={{margin:"20px 10px 1px 10px"}}>
                <div style={{display: 'flex', flexDirection: 'row', backgroundColor: 'transparent', borderColor: 'transparent', width: '100%', alignItems: 'center'}} className="tableHeader">
                <div className='insightsTableCol'>No orders Present...</div>
              </div>
            </Grid>}
            {this.state.tempOrderList.length > 0 && <Grid item xs={12} style={{margin:"20px 10px 1px 10px"}}>
                <div style={{display: 'flex', flexDirection: 'row', backgroundColor: 'white', width: '100%'}} className="tableHeader">
                <div className='insightsTableHeader'>ORDER ID</div>
                <div className='insightsTableHeader'>ORDER DATE</div>
                <div className='insightsTableHeader'>STATUS</div>
                <div className='insightsTableHeader'>PAYMENT</div>
                <div className='insightsTableHeader'>NET RECIEVABLE</div>
                {/* <div className='insightsTableHeader'>PAYMENT STATUS</div> */}
                {/* <div className='insightsTableHeader'>UNSETTLED</div> */}
                <div className='insightsTableHeader'></div>
              </div>
            </Grid>}
            {listItems}
          </div>
          {/* <textarea type="text" className="restoDescTextArea" rows={1} placeholder='Enter Here, For example, "Brewery" or "North Indian"' value={this.state.restoDescText} onChange={this.handleTextChange} /> */}
        </AccordionDetails>
      </Accordion>
      </Container>
    </Grid>
  }

  checkIfEqual(firstDate, secondDate) {
    return (
      firstDate.getFullYear() === secondDate.getFullYear() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getDate() === secondDate.getDate()
    );
  }

    render() {
        return (
            <div>
                <div className="loadingGif" style={this.state.dataLoading ? {visibility:"visible", opacity: '0.7'} : {visibility:"hidden", opacity: '0'}}>
                  <img src={DaiDishLoading1} alt="DaiDish Loading..." style={this.state.isMobileScreen ? {width:"100%", marginTop:"25%"} : {width:"40%", marginTop:"0%"}}/>
                </div>
                <Grid container spacing={0} style={{width:"100%", minHeight:"40vw"}}>
                    <Grid item xs={12}>
                        <div style={{display: 'flex', flexDirection: 'row', padding: "20px 30px 0px 30px"}}>
                          <div style={{flex: '1'}}>
                            <div style={{fontFamily:"Bree-Regular", fontSize:"25px"}}>Insights</div>
                            <div style={{fontFamily:"Poppins-Regular", fontSize:"14px", color: '#6C6C6C'}}>Track your day to day performance</div>
                          </div>
                          <div className='ddFilterTabsContainer'>
                            <div onClick={() => this.changeFilterType('Today')} className={this.getFilterTabClassName('Today')} style={{marginRight: '1px'}}>Yesterday</div>
                            <div onClick={() => this.changeFilterType('Week')} className={this.getFilterTabClassName('Week')} style={{marginRight: '1px'}}>Week</div>
                            <div onClick={() => this.changeFilterType('Month')} className={this.getFilterTabClassName('Month')} style={{marginRight: '1px'}}>Month</div>
                            <div onClick={() => this.changeFilterType('Year')} className={this.getFilterTabClassName('Year')}>Year</div>
                          </div>
                          <div style={{margin: '0px 15px', backgroundColor: '#FFF', border: '1px solid #ECECEC'}} className="ddFilterTabsContainer">
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DateRangePicker
                                  startText="Check-in"
                                  endText="Check-out"
                                  value={this.state.selectedDate}
                                  onChange={date => {
                                    let dateItem = date;
                                    if (!dateItem[0]){
                                      dateItem[0] = this.state.selectedDate[0];
                                    }
                                    if (!dateItem[1]){
                                      dateItem[1] = this.state.selectedDate[1];
                                    }
                                    let keys = Object.keys(this.state.filterTypes);
                                    let keyItem = keys.filter((key) => this.checkIfEqual(new Date(this.state.filterTypes[key][0]), dateItem[0]) && this.checkIfEqual(new Date(this.state.filterTypes[key][1]), dateItem[1]));
                                    this.setState({
                                      selectedDate: dateItem,
                                      filterType: keyItem.length > 0 ? keyItem[0] : '',
                                      searchBarText: ""
                                    }, () => {
                                      this.setState({
                                        insightsFetching: true,
                                      });
                                      this.getGrossFinancials();
                                      this.getDetailedReport();
                                      this.getGrossFinancialsBreakdown();
                                    })
                                  }}
                                  renderInput={(startProps, endProps) => (
                                    <div className='ddFilterTabs'>
                                      <TodayIcon style={{fontSize: '13px', marginRight: '3px', transform: 'translateY(2px)', color: '#425FA1'}}/>
                                      <input readonly className='ddFilterDateRangeInput' {...startProps.inputProps}/>
                                      <span> - </span>
                                      <input readonly className='ddFilterDateRangeInput' {...endProps.inputProps}/>
                                    </div>
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                        </div>
                        {this.state.insightsFetching && <div style={{padding:"10px 30px", textAlign: 'center', fontFamily: 'Poppins-Regular'}}>
                            Loading data...
                        </div>}
                        {!this.state.insightsFetching && this.insightCards()}
                        {!this.state.insightsFetching && this.detailedReport()}
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default withRouter(InsightsScreen);