import React, {Component} from "react";
import { withRouter } from 'react-router';

class ConfirmationBox extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this);
        this.onActionConfirm = this.onActionConfirm.bind(this);
    }

    closeModalHandler() {
        this.props.toggleConfirmationBox();
    }

    onActionConfirm(){
        this.props.toggleConfirmationBox();
        this.props.onActionConfirm(this.props.actionType);
    }

    handleModalClick(e) {
        e.stopPropagation();
    }
    
    render() {
        if(!this.props.showConfirmationBox){
            return null;
        }
        return(
            <div className="RatingModal" onClick={this.closeModalHandler}>
                <div style={{height: "unset", margin: "auto"}} onClick={(e) => this.handleModalClick(e)} className="SuccessModalContainer">
                <div style={{width: "100%", textAlign: "center", fontFamily: "Poppins-Regular", padding: "30px 0px"}}>{this.props.infoText}</div>
                   <div style={{padding: "0px 0px 30px 0px", display: "flex", flexDirection: "row", width: "100%"}}>
                        <div style={{width: "50%", padding: "0px 1.5vw 0px 4.5vw"}}>
                            <div 
                                onClick={this.onActionConfirm}
                            // onClick={() => {
                            //         localStorage.removeItem("restaurant_id");
                            //         localStorage.removeItem("restaurant_details");
                            //         localStorage.removeItem("access_token");
                            //         localStorage.removeItem("refresh_token");
                            //         this.props.history.replace("/");
                            //     }} 
                                style={{width: "100%", border: "1px solid #707070", textAlign: "center", padding: "12px 0px", borderRadius: "20px", cursor: "pointer"}}>
                                Yes
                            </div>
                        </div>
                        <div style={{width: "50%", padding: "0px 4.5vw 0px 1.5vw"}}>
                            <div onClick={this.closeModalHandler} style={{width: "100%", backgroundColor: "#EA5B2F", border: "1px solid #EA5B2F", textAlign: "center", padding: "12px 0px", color: "white", borderRadius: "20px", cursor: "pointer"}}>
                                No
                            </div>
                        </div>
                   </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ConfirmationBox);