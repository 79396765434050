import React, {Component} from "react";
import '../../assets/styles/Commons.css';
import pin from '../../assets/images/pin.svg';

class RestaurantCardPreview extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this);
        this.toggleImageHover = this.toggleImageHover.bind(this);
        this.state = {
            phoneNumber: "",
            phoneNumberFetched: false
        }
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
      }

    updateDimensions() {
        let screen_width = window.innerWidth - 20;
        if(screen_width < 400) {
            this.setState({ noOfItems: 1 });
        } else if(screen_width < 800) {
          this.setState({ noOfItems: 2 });
        } else if (screen_width < 1200){
            this.setState({ noOfItems: 3 }); 
        } else if (screen_width < 2000){
            this.setState({ noOfItems: 4 }); 
        } else {
            this.setState({noOfItems: 5});
        }
        screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    closeModalHandler() {
        this.props.closeRestaurantCardPreviewModal();
    }

    toggleImageHover(index){
        this.props.toggleImageHover(index);
    }

    handleModalClick(e){
        e.stopPropagation();
    }

    mobileSpecificComponent() {
        if (this.state.isMobileScreen){
            return (
                <div className="RestaurantDetails">
                    <div>
                        <img style={{width: "10px", transform: "translateY(2px)", marginRight: "3px"}} src={pin} alt="pin"/> {this.props.restaurant.address}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="RestaurantDetails">
                    <img style={{width: "10px", transform: "translateY(2px)", marginRight: "3px"}} src={pin} alt="pin"/> {this.props.restaurant.address}
                </div>
            )
        }
    }

    restaurantDetails(){
        return (
            <div className="RestaurantDetailsLeftContainer">
                <div className="RestaurantDetailsLeftContainerFirst">
                    {this.props.restaurant.logo_url === '' ? null : <div style={{height: "70px", width: "70px", borderRadius: "50px", border: "2px solid white", marginBottom: "10px"}}>
                        <img style={{height: "100%", width: "100%", borderRadius: '50px'}} src={this.props.restaurant.logo_url} alt=""/>
                    </div>}
                    <div style={{fontSize: this.state.isMobileScreen ? "16px" : "24px"}} className="RestaurantName">{this.props.restaurant.name}</div>
                    <div style={{fontSize: this.state.isMobileScreen ? "14px" : "24px"}} className="RestaurantDescription">{this.props.restaurant.description}</div>
                    {this.mobileSpecificComponent()}  
                </div>
                <div className="RestaurantDetailsLeftContainerSecond">
                    <div style={{padding: "6px 20px", fontSize: "12px"}} className="RestaurantContact"> 
                        Call Restaurant
                    </div>
                </div>
            </div>
        )
    }
    
    render() {
        if(!this.props.restaurantPreviewOpen){
            return null;
        }
        return(
            <div className="RatingModal" onClick={this.closeModalHandler}>
                <div style={{background: "linear-gradient(to bottom, #F8D542 200px, white 200px)", margin: "auto", transform: "translateY(-60px)"}} onClick={(e) => this.handleModalClick(e)} className="RestaurantsPreviewModalContainer">
                    <div onClick={this.closeModalHandler} title="Close" className="close">X</div>
                    <div style={{margin: "auto"}}>
                        <div style={{margin: this.state.isMobileScreen ? "10px auto" : "30px auto", color: "#3356AA", fontSize: this.state.isMobileScreen ? "24px" : "48px", fontFamily: "Bree-SemiBold", textAlign: "center"}}>Looks Great!</div>
                        <div style={{height: "220px"}} className="RestaurantsCoverSuperContainer">
                            <div className="RestaurantsPreviewCoverContainer">
                                <img className="RestaurantsPreviewCoverImage" src={this.props.restaurant.profile_image_url} alt=""/>
                            </div>
                            {/* <div className="RestaurantsCoverBlur"></div> */}
                            {this.restaurantDetails()}
                            {/* <div className="RestaurantDetailsRightContainer">
                                <div onClick={() => this.showContactModalHandler()} className="RestaurantContact"> 
                                    <PhoneIcon style={{fontSize: "1vw", position: "absolute", top: "10px"}}/><span style={{marginLeft: "18px"}}> Call for delivery</span>
                                </div>   
                            </div> */}
                        </div>
                        <div style={{textAlign: "center", margin: "20px auto"}}>
                            <div onClick={this.closeModalHandler} style={{display: "inline-block", padding: "6px 30px", borderRadius: "5px", fontSize: "12px", fontFamily: "Poppins-Regular", backgroundColor: "#F8D542", cursor: "pointer"}}>
                                Okay
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RestaurantCardPreview;