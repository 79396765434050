import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import home_burger from '../../assets/images/partner_with_us_illustration.png';
import '../../assets/styles/Home.css';
import { withRouter } from 'react-router';
import logo from '../../assets/images/logo_white.png';
import logo_mobile from '../../assets/images/logo_mobile.png';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import '../../assets/styles/PartnerWithUs.css';
import "croppie/croppie.css";
import CONSTANTS from '../../config/constants';
import axios from 'axios';

// Top Header which contains logo, auth options, burger icon and header description
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
        this.toggleDishCardPreviewHandler = this.toggleDishCardPreviewHandler.bind(this);
        this.toggleRestaurantCardPreviewHandler = this.toggleRestaurantCardPreviewHandler.bind(this);
        this.showOrderDetailsHandler = this.showOrderDetailsHandler.bind(this);
    }

    showOrderDetailsHandler(order){
        this.props.showOrderDetails(order);
    }

    toggleDishCardPreviewHandler(dish){
        this.props.toggleDishCardPreview(dish);
    }

    toggleRestaurantCardPreviewHandler(dish){
        this.props.toggleRestaurantCardPreview(dish);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    getMobileComponents(isMobileScreen, headerStyle){
        if (isMobileScreen){
            return (
                <div>
                    <div style={headerStyle} className="Header">
                        {/* <div style={{position: "fixed", top: "0px", left: "0px", zIndex: "10000", width: "100vw"}}> */}
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                <Logo/>
                            </div>
                            <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                                <BurgerImage/>
                            </Grid>
                        {/* </div> */}
                        {/* <div style={{marginTop: "84px"}}> */}
                        {/* </div>   */}
                    </div> 
                    <ContactForm
                        authModalOpen={this.props.authModalOpen}
                        history={this.props.history}
                        toggleDishCardPreview={this.toggleDishCardPreviewHandler}
                        toggleRestaurantCardPreview={this.toggleRestaurantCardPreviewHandler}
                        orderId={this.props.orderId}
                        showOrderDetails={this.showOrderDetailsHandler}
                    />
                </div>
            )
        } else {
            return (
                <div style={headerStyle} className="Header">
                    {/* <div style={{position: "fixed", top: "0px", left: "0px", zIndex: "10000", width: "100vw"}}> */}
                        <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                            <Logo/>
                        </Grid>
                        <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                            <BurgerImage/>
                            <ContactForm
                                authModalOpen={this.props.authModalOpen}
                                history={this.props.history}
                                toggleDishCardPreview={this.toggleDishCardPreviewHandler}
                                toggleRestaurantCardPreview={this.toggleRestaurantCardPreviewHandler}
                                orderId={this.props.orderId}
                                showOrderDetails={this.showOrderDetailsHandler}
                            />
                        </Grid> 
                    {/* </div> */}
                    {/* <div style={{marginTop: "84px"}}> */}
                    {/* </div>   */}
                </div> 
            )
        }
    }

   render() {
    var headerStyle = {};
    if (this.state.isMobileScreen){
        headerStyle = {background: "linear-gradient(60deg, #FFFFFF 50%, #F9E841 50%)"};
    } else {
        headerStyle = {background: "linear-gradient(100deg, #F9E841 40%, #FFFFFF 40%)", height: "100vh"};
    }
    return (
        this.getMobileComponents(this.state.isMobileScreen, headerStyle)
    )
   }
}

// Website Logo
class Logo extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        
        if (this.state.isMobileScreen){
            return (
                <div className="Logo LogoHome">
                    {/* <Link  to={''} style={{ textDecoration: 'none' }}>
                        <img style={{cursor: "pointer", marginTop: "10px", marginLeft: "5px"}} src={logo_mobile} alt="" width={40} height={40}/>  
                    </Link> */}
                    <img style={{cursor: "pointer", marginTop: "10px", marginLeft: "5px"}} src={logo_mobile} alt="" width={40} height={40}/>  
                </div>
            )
        } else {
            return (
                <Grid container item xs={4}>
                    <React.Fragment>
                        <Grid item xs={12}>
                            <div className="Logo LogoHome">
                                {/* <Link  to={''} style={{ textDecoration: 'none' }}>
                                    <img style={{marginTop: "10px"}} className="LogoImage" src={logo} alt="logo"/>  
                                </Link> */}
                                <img style={{marginTop: "10px"}} className="LogoImage" src={logo} alt="logo"/>  
                            </div>
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }

    }
}

// Burger Image in the Header
class BurgerImage extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        var burgerContainerStyle = {};
        var burgerStyle = {};
        if (this.state.isMobileScreen){
            burgerContainerStyle = {paddingTop: "0px", paddingBottom: "20px", transform: "translateX(-5vw)"};
            burgerStyle = {width: '70vw', transform: 'translateX(-5vw)'};
        } else {
            burgerContainerStyle = {paddingTop: "20px", paddingBottom: "20px", paddingLeft: "0vw", transform: "translateY(-2vh)"};
            burgerStyle = {marginLeft: "0vw", width: "36vw"}
        }
        return (
            <Grid style={burgerContainerStyle} className="PartnerWithUsLogoContainer" container item direction="column" justifyContent="center" alignItems="center" xs={6}>
                <img style={burgerStyle} className="Burger" src={home_burger} alt="burger_img"/>
            </Grid>
        )
    }
}

// Description present in header
class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.fetchOrderDetails = this.fetchOrderDetails.bind(this);
        this.state = {
            activeScreen: "contact-details",
            restaurantOTP: "",
            restaurantOTPError: "",
            orderFulfilled: false,
            detailsSubmitting: false,
            orderDetailsFetched: false,
            order: {},
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.fetchOrderDetails();
    }

    fetchOrderDetails(){
        let url = CONSTANTS.hostURL + "api/resto-app/orders/get-by-ids/?order_ids=" + this.props.orderId;
        let headers = {};
        axios.get(url, {
            headers: headers
        }).then(res => {
            if (res.data.status === 1 && res.data.data.orders[0].order_status !== 'FULFILLED'){
                this.setState({
                    order: res.data.data.orders[0],
                    orderDetailsFetched: true
                })
            } else {
                this.props.history.replace("/errorPage");
            }
        }).catch((err) =>
            this.props.history.replace("/errorPage")
        );
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    togglePasswordVisibility(){
        if (this.state.passwordVisibility === "hidden"){
            this.setState({
                passwordVisibility: "visible"
            });
        } else {
            this.setState({
                passwordVisibility: "hidden"
            });
        }
    }

    passwordToggleIcon(type){
        if(this.state.passwordVisibility === "hidden"){
            return(
                <div style={{transform: "translateY(-5px)"}}>
                    <VisibilityIcon
                        fontSize="small"
                        style={{color: "black"}}
                    />
                </div>
            )
        } else {
            return(
                <div style={{transform: "translateY(-5px)"}}>
                    <VisibilityOffIcon
                        fontSize="small"
                        style={{color: "black"}}
                    />
                </div>
            )
        }
    }

    thankYouMessage(){
        return(
            <div className="PartnerWithUsOTPContainer">
                <div className="PartnerWithUsThankYouContainer">
                    <div className="PartnerWithUsThankYouHeader">Successfully Delivered!</div>
                    <div className="PartnerWithUsThankYouSubHeader">Thank You!</div>
                    <div onClick={() => window.location.href = 'https://www.daidish.com'} style={{width: "150px", margin: "auto", marginTop: "20px"}} className="PartnerWithUsSubmitButtonContainer">
                        <div className="AuthFormSubmitButton">Ok</div>
                    </div>
                </div>
            </div>
        )
    }

    handleContactDetailsSubmit() {
        this.setState({
            restaurantOTPError: "",
            detailsSubmitting: true
        })
        var isError = false;
        let otp = this.state.restaurantOTP;
        if (otp === ''){
            this.setState({
                restaurantOTPError: this.state.order.order_status === 'ON_THE_WAY' ? "Please enter the otp" : "Please enter the estimated delivery time"
            })
            isError = true;
        }
        if (!isError && this.state.orderDetailsFetched){
            let url = CONSTANTS.hostURL + "api/resto-app/orders/verify-delivery-otp";
            if (this.state.order.order_status !== 'ON_THE_WAY'){
                url = CONSTANTS.hostURL + "api/resto-app/orders/update-status/open/";
            }
            let headers = {}
            let req = {
                order_id: this.state.order.id
            }
            if (this.state.order.order_status === 'ON_THE_WAY'){
                req.delivery_otp = this.state.restaurantOTP
            } else {
                req.status = 'ON_THE_WAY';
                req.info = {
                    estimated_delivery_time: this.state.restaurantOTP
                }
            }
            axios.post(url, req,  {
                headers: headers
            }).then((response) => {
                // console.log(response);
                if (response.data.status === 1){
                    let order = this.state.order;
                    if (this.state.order.order_status === 'ON_THE_WAY'){
                        order.status = 'FULFILLED';
                    } else {
                        order.status = 'ON_THE_WAY';
                    }
                    this.setState({
                        orderFulfilled: this.state.order.order_status === 'ON_THE_WAY',
                        detailsSubmitting: false,
                        order: response.data.data,
                        restaurantOTP: ""
                    })
                } else {
                    this.setState({
                        restaurantOTPError: response.data.errors.message,
                        detailsSubmitting: false
                    })
                }
            }).catch((error) => this.setState({
                passwordError: error,
                detailsSubmitting: false
            }));
        } else {
            this.setState({
                detailsSubmitting: false
            })
        }
    }

    contactDetailsForm(){
        return (
            <div className="PartnerWithUsFieldSuperContainer">
                <div className="PartnerWithUsFieldError">{this.state.contactDetailsError}</div>
                <div onClick={() => this.props.showOrderDetails(this.state.order)} style={{cursor: 'pointer', textDecoration: 'underline'}} className="PartnerWithUsThankYouSubHeader">View order details</div>
                <div className="PartnerWithUsFieldContainer">
                    <div className="PartnerWithUsFieldName">
                        {this.state.order.order_status === 'ON_THE_WAY' ? 'OTP' : 'Estimated Delivery Time (in minutes)'}
                    </div>
                    <input type="number" style={{border: "#EBEBEB 1px solid"}} value={this.state.restaurantOTP} onChange={(event) => this.setState({restaurantOTP: event.target.value})} className="PartnerWithUsField" placeholder={this.state.order.order_status === 'ON_THE_WAY' ? "Enter OTP Here" : "Enter Estimated Delivery Time Here"}></input>
                    <div className="PartnerWithUsFieldError">{this.state.restaurantOTPError}</div>
                </div>
                <div onClick={() => this.handleContactDetailsSubmit()} className="PartnerWithUsSubmitButtonContainer">
                    <div className="AuthFormSubmitButton">{this.state.detailsSubmitting ? "Submitting.." : "Submit"}</div>
                </div>
            </div>
        )
    }

    render(){
        if (!this.state.isMobileScreen && this.state.orderDetailsFetched) {
            if (!this.state.orderFulfilled){
                return (
                    <Grid container item xs={6} direction="column" justifyContent="center" style={{height: "95vh", transform: "translateY(-5vh)"}}>
                        <div className="PartnerWithUsFormContainer">
                        <div className="PartnerWithUsHeader">
                            Welcome to  <span style={{color: "#EB622F"}}>DaiDish</span>
                        </div>
                        <div className="AuthFormContainer">
                            <div style={{fontFamily: "Bree-SemiBold", display: "block", marginBottom: "2vh"}} className="AuthFormButtons LoginWelcomeOTP">{this.state.order.order_status === 'ON_THE_WAY' ? "Enter OTP received from Customer" : "Enter estimated delivery time"}</div>
                        </div>
                        {this.contactDetailsForm()}
                        </div>
                    </Grid>
                )
            } else {
                return <Grid container item xs={6} direction="column" justify="center" style={{height: "95vh", transform: "translateY(-5vh)"}}>
                    <div className="PartnerWithUsFormContainer">
                        {this.thankYouMessage()}
                    </div>
                </Grid>;
            }
        } else {
            if (this.state.orderDetailsFetched){
                if (!this.state.orderFulfilled){
                    return (
                        <Grid container item xs={12} direction="column" justifyContent="center">
                            <div className="PartnerWithUsFormContainer">
                            <div className="PartnerWithUsHeader">
                                Welcome to  <span style={{color: "#EB622F"}}>DaiDish</span>
                            </div>
                            <div className="AuthFormContainer">
                                <div style={{fontFamily: "Bree-SemiBold", display: "block", marginBottom: "2vh"}} className="AuthFormButtons LoginWelcomeOTP">Enter OTP received from Customer</div>
                            </div>
                            {this.contactDetailsForm()}
                            </div>
                        </Grid>
                    )
                } else {
                    return <Grid container item xs={12} direction="column" justify="center">
                        <div className="PartnerWithUsFormContainer">
                            {this.thankYouMessage()}
                        </div>
                    </Grid>;
                }
            } else {
                return null;
            }
        }
    }
}

export default withRouter(Header);