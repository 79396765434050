import React, { Component } from 'react';
import '../../assets/styles/Home.css';
import '../../assets/styles/Restaurants.css';
import best_dish from '../../assets/images/best_dish.png';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { IoTriangleSharp } from 'react-icons/io5';
import { Star } from '@material-ui/icons';
import { Grid } from '@material-ui/core';

class BestDish extends Component {

    constructor(props){
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentWillMount() {
        this.setState({
            dish: {
                image: best_dish, 
                id: 1, 
                name: "Chicken Cheese Burger", 
                restaurant: "Sanky's Burger House", 
                cost: 300, 
                imageHover: false, 
                rateHover: false, 
                reviewHover: false, 
                liked: false
            }
        });
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    getDescription(description, dishName){
        let screen_width = window.innerWidth - 20;
        var maxLength = 190 + Math.floor(((screen_width - 360)/3));
        if (description !== "" && description !== null){
            if (description.length < maxLength){
                return description;
            } else {
                if (this.state.isMobileScreen){
                    return description.slice(0, maxLength) + "..";
                } else {
                    return description;
                }
            }
        } else {
            return dishName;
        }
    }

    getShowMore(description, dish){
        let screen_width = window.innerWidth - 20;
        var maxLength = 190 + Math.floor(((screen_width - 360)/3));
        if (this.state.isMobileScreen){
            if (description !== "" && description !== null){
                if (description.length > maxLength){
                    return <div className="DishDescriptionReadMore">{dish.imageHover ? "Read less" : "Read more"}</div> ;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    dotColour(dish){
        if (dish.choice === "V"){
            return {backgroundColor: "green", border: "2px solid white", outline: "2px solid green"};
        } else if (dish.choice === "N") {
            return {backgroundColor: "red", border: "2px solid white", outline: "2px solid red"};
        } else {
            return {backgroundColor: "orange", border: "2px solid white", outline: "2px solid green"};
        }
    }

    discountCard(dish){
        return (
            <span className="Ribbon BestDishRibbon">
                <div className="RibbonText">
                    <Star style={{color: "#FFFFFF", fontSize: "14px", transform: "translateY(2px)", marginRight: "5px"}}/>
                    Best Dish {this.props.index + 1}
                </div>
            </span>
        )
    }

    render() {
        // var yellowLineStyle = {};
        var descriptionOverlayStyle = {display: 'none'};
        let dish = this.props.dish;
        return (
            <div className="BestDishSuperContainer" style={{marginTop: "30px"}}>
                <div className="DishCardWrapper">
                    <div style={descriptionOverlayStyle} className="DishDescriptionOverlay BestDishDescriptionOverlay">
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div style={{flex: "1"}} className="DishDescriptionOverlayHeader">
                                {dish.name}
                            </div>
                            <CloseIcon style={{color: "black", opacity: this.state.gridSize === 12 ? "1" : "1", cursor: "pointer"}} />
                        </div>
                        <div className="DishDescriptionOverlayText">
                            {(dish.description === null || dish.description === "") ? dish.name : dish.description}
                        </div>
                    </div>

                    {this.props.index === 1 ?
                        <>
                            <div className="BestDishContainer" style={{height:"365px"}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} style={{paddingLeft:"60px"}}>
                                        <div className="BestDishDescription" style={{width:"100%"}}>
                                            <div className="DishDetailsRow" style={{padding: "10px"}}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{padding: "15px 0px 0px 20px"}}>
                                                        <span style={{position:"absolute", top:"24px", left: "55px"}}>{dish.choice === "V" ? <FiberManualRecordIcon className="vegTag" style={{fontSize: "18px"}}/> : <IoTriangleSharp className="nonVegTag" style={{fontSize: "18px"}}/>}</span>
                                                        <div style={{left:"20px", top: "-7px", position:"relative"}}>
                                                            <div className="BestDishName" style={{width:"515px"}}>
                                                                {dish.name}
                                                            </div>
                                                            <div className="BestDishRestaurant">{dish.restaurant}</div>
                                                            <div className="DishName">
                                                                Rs.{dish.cost}
                                                            </div>
                                                        </div>
                                                        <div style={{flex: "1 1 auto"}}>
                                                            <div style={{height: "102px", textOverflow: "ellipsis", overflow: "hidden"}} className="BestDishDetails DishDescription">
                                                                { this.getDescription(dish.description, dish.name) }
                                                                { this.getShowMore(dish.description, dish) }
                                                            </div>
                                                        </div>
                                                        <div className="DishCardActionsContainer BestDishCardActionsContainer" style={{borderBottomLeftRadius: this.state.isMobileScreen ? '15px': '0px'}}>
                                                            <div className="DishCardActionsText" style={{textAlign: "left"}}>
                                                                <span onClick={() => this.props.toggleDishInStock(this.props.dish.id, this.props.dish.in_stock, 'edit_dish')} className="dishAvailabilitySwitch">
                                                                    <span className="inStockButton" style={{paddingTop:"1px", paddingBottom:"1px"}}>
                                                                    <span className="inStockSlider" style={dish.in_stock === true ? {left:'11px', boxShadow: '2px 0px 2px 0.5px #aaaaaa', background: '#0C950C', paddingTop:"3px", paddingBottom:"3px"} : {left:'-11px', boxShadow: '-2px 0px 2px 0.5px #aaaaaa', background: '#DEE8FF', paddingTop:"3px", paddingBottom:"3px"}}></span>
                                                                    </span>
                                                                </span>
                                                                <span style={{paddingLeft: "20px", fontFamily: "Poppins-Regular", fontSize: "15px"}}>In stock</span>
                                                            </div>
                                                            <div className="DishCardActionsText" style={{textAlign: "right"}}>
                                                                <span onClick={() => this.props.handleEditBestDish(dish.name, dish.id, this.props.index)} className="ReviewText" style={{cursor: "pointer", backgroundColor: "#FCD844", padding: "12px 36px", borderRadius: "5px", marginRight: "20px"}}>Edit</span>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} style={{display:"flex", justifyContent:"center", flexWrap:"wrap", alignContent:"center"}}>
                                        <div className="BestDishImageContainer" style={{display:"flex", justifyContent:"center", flexWrap:"wrap", alignContent:"center", overflow:"visible", height:"300px"}}>
                                            <div style={{width:"300px", height:"300px", boxShadow: "-10px 10px 0px #E5E5E5", border: "1px solid #0000001A", borderRadius: "20px", position:"relative"}}>
                                                {this.discountCard(dish)}
                                                <img className="BestDishImage" style={{borderRadius: "20px"}} src={dish.profile_image_url === null ? best_dish : dish.profile_image_url} alt="bestDish"/>
                                                <div className="DishRatingsSuperContainer" style={{cursor:"pointer", borderTopRightRadius:"20px", borderBottomLeftRadius:"20px", bottom:"-2px", left:"-1px"}}>
                                                    <div className="DishRatingsContainer" 
                                                        //onClick={() => this.showReviewsModalHandler(dish)}
                                                    >
                                                        <Star style={{color: "#0F9917", fontSize: "18px", transform: "translateY(2px)", marginRight: "5px"}}/>
                                                        <span style={{fontFamily: "Poppins-SemiBold", fontSize: "18px"}}>{dish.average}</span><span style={{fontFamily: "Poppins-Medium", color: "#735E58", fontSize: "15px"}}> ({dish.no_rating})</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    :
                        <>
                            <div className="BestDishContainer" style={{height:"365px"}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} style={{display:"flex", justifyContent:"center", flexWrap:"wrap", alignContent:"center"}}>
                                        <div className="BestDishImageContainer" style={{display:"flex", justifyContent:"center", flexWrap:"wrap", alignContent:"center", overflow:"visible", height:"300px"}}>
                                            <div style={{width:"300px", height:"300px", boxShadow: "10px 10px 0px #E5E5E5", border: "1px solid #0000001A", borderRadius: "20px", position:"relative"}}>
                                                {this.discountCard(dish)}
                                                <img className="BestDishImage" style={{borderRadius: "20px"}} src={dish.profile_image_url === null ? best_dish : dish.profile_image_url} alt="bestDish"/>
                                                <div className="DishRatingsSuperContainer" style={{cursor:"pointer", borderTopRightRadius:"20px", borderBottomLeftRadius:"20px", bottom:"-2px", left:"-1px"}}>
                                                    <div className="DishRatingsContainer" 
                                                        //onClick={() => this.showReviewsModalHandler(dish)}
                                                    >
                                                        <Star style={{color: "#0F9917", fontSize: "18px", transform: "translateY(2px)", marginRight: "5px"}}/>
                                                        <span style={{fontFamily: "Poppins-SemiBold", fontSize: "18px"}}>{dish.average}</span><span style={{fontFamily: "Poppins-Medium", color: "#735E58", fontSize: "15px"}}> ({dish.no_rating})</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="BestDishDescription" style={{width:"100%"}}>
                                            <div className="DishDetailsRow" style={{padding: "10px"}}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{padding: "15px 0px 0px 20px"}}>
                                                        <span style={{position:"absolute", top:"24px", left: "55px"}}>{dish.choice === "V" ? <FiberManualRecordIcon className="vegTag" style={{fontSize: "18px"}}/> : <IoTriangleSharp className="nonVegTag" style={{fontSize: "18px"}}/>}</span>
                                                        <div style={{left:"20px", top: "-7px", position:"relative"}}>
                                                            <div className="BestDishName" style={{width:"515px"}}>
                                                                {dish.name}
                                                            </div>
                                                            <div className="BestDishRestaurant">{dish.restaurant}</div>
                                                            <div className="DishName">
                                                                Rs.{dish.cost}
                                                            </div>
                                                        </div>
                                                        <div style={{flex: "1 1 auto", width:"90%"}}>
                                                            <div style={{height: "102px", textOverflow: "ellipsis", overflow: "hidden"}} className="BestDishDetails DishDescription">
                                                                { this.getDescription(dish.description, dish.name) }
                                                                { this.getShowMore(dish.description, dish) }
                                                            </div>
                                                        </div>
                                                        <div className="DishCardActionsContainer BestDishCardActionsContainer" style={{borderBottomLeftRadius: this.state.isMobileScreen ? '15px': '0px', width:"90%"}}>
                                                            <div className="DishCardActionsText" style={{textAlign: "left"}}>
                                                                <span onClick={() => this.props.toggleDishInStock(this.props.dish.id, this.props.dish.in_stock, 'edit_dish')} className="dishAvailabilitySwitch">
                                                                    <span className="inStockButton" style={{paddingTop:"1px", paddingBottom:"1px"}}>
                                                                    <span className="inStockSlider" style={dish.in_stock === true ? {left:'11px', boxShadow: '2px 0px 2px 0.5px #aaaaaa', background: '#0C950C', paddingTop:"3px", paddingBottom:"3px"} : {left:'-11px', boxShadow: '-2px 0px 2px 0.5px #aaaaaa', background: '#DEE8FF', paddingTop:"3px", paddingBottom:"3px"}}></span>
                                                                    </span>
                                                                </span>
                                                                <span style={{paddingLeft: "20px", fontFamily: "Poppins-Regular", fontSize: "15px"}}>In stock</span>
                                                            </div>
                                                            <div className="DishCardActionsText" style={{textAlign: "right"}}>
                                                                <span onClick={() => this.props.handleEditBestDish(dish.name, dish.id, this.props.index)} className="ReviewText" style={{cursor: "pointer", backgroundColor: "#FCD844", padding: "12px 36px", borderRadius: "5px", marginRight: "20px"}}>Edit</span>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    }
                    


                    {/* <div className="BestDishContainer">
                        {this.discountCard(dish)}
                        <div className="BestDishImageContainer" style={{borderTopRightRadius:"0px", borderBottomRightRadius:"0px"}}>
                            <img className="BestDishImage" style={{transform:"translateY(-15%)"}} src={dish.profile_image_url === null ? best_dish : dish.profile_image_url} alt="bestDish"/>
                            <div className="RestaurantsCoverBlur"></div>
                            <div className="LikeButtonContainerSuper">
                                <div className="LikeButtonContainer">
                                    <div style={{backgroundColor: "#EA5B2F", padding: "3px 5px 3px 5px", borderRadius: "8px"}}>
                                        <span className={likeButtonStyle}></span>
                                    </div>
                                </div>
                            </div>
                            <div className="DishRatingsSuperContainer">
                                <div className="DishRatingsContainer">
                                    <Star style={{color: "#0F9917", fontSize: "18px", transform: "translateY(2px)", marginRight: "5px"}}/>
                                    <span style={{fontFamily: "Poppins-SemiBold", fontSize: "18px"}}>{dish.average}</span><span style={{fontFamily: "Poppins-Medium", color: "#735E58", fontSize: "18px"}}> ({dish.no_rating})</span>
                                </div>
                            </div>
                        </div>
                        <div className="BestDishDescription">
                            <div className="BestDishRatingsContainer">
                                <span className="BestDishRatingsTitle">
                                        Best Dish <span style={{fontSize: "14px"}}> | <img src={ratings} alt="Ratings"/> {dish.rating} ({dish.no_rating})</span>
                                </span>
                            </div>
                            <div className="DishDetailsRow" style={{padding: "10px"}}>
                                <div>
                                    {dish.choice === "V" ? <FiberManualRecordIcon style={{ fontSize: 13, transform: "translate(15px, -5px)" }} className="vegTag"/> : <FiberManualRecordIcon style={{ fontSize: 13, transform: "translate(15px, -5px)"  }} className="nonVegTag"/>}
                                </div>
                                <div className="BestDishDetailsColumn" style={{width: "100%"}}>
                                    <div className="BestDishNameRow">
                                        <div className="BestDishName">
                                            {dish.name}
                                        </div>
                                        <div className="BestDishNameTitle">
                                        <Star style={{color: "white", fontSize: this.state.isMobileScreen? "15px" : "20px", transform: "translateY(4px)", marginRight: "5px"}}/>
                                            Best Dish
                                        </div>
                                    </div>
                                    <div className="BestDishRestaurant">{this.props.restaurant.name}</div>
                                    <div className="DishName">
                                        Rs.{dish.cost}
                                    </div>
                                    <div style={{flex: "1 1 auto"}}>
                                        <div style={{height: "102px", textOverflow: "ellipsis", overflow: "hidden", paddingLeft: "0px"}} className="BestDishDetails DishDescription">
                                            { this.getDescription(dish.description, dish.name) }
                                            { this.getShowMore(dish.description, dish) }
                                        </div>
                                    </div>
                                    <div className="DishCardActionsContainer BestDishCardActionsContainer" style={{borderBottomLeftRadius: this.state.isMobileScreen ? '15px': '0px'}}>
                                        <div className="DishCardActionsText" style={{textAlign: "left"}}>
                                            <span onClick={() => this.props.toggleDishInStock(this.props.dish.id, this.props.dish.in_stock, 'edit_dish')} className="dishAvailabilitySwitch">
                                                <span className="inStockButton">
                                                <span className="inStockSlider" style={dish.in_stock === true ? {left:'11px', boxShadow: '2px 0px 2px 0.5px #aaaaaa', background: '#0C950C'} : {left:'-11px', boxShadow: '-2px 0px 2px 0.5px #aaaaaa', background: '#DEE8FF'}}></span>
                                                </span>
                                            </span>
                                            <span style={{paddingLeft: "20px", fontFamily: "Poppins-Regular", fontSize: "15px"}}>In stock</span>
                                        </div>
                                        <div className="DishCardActionsText" style={{textAlign: "right"}}>
                                            <span onClick={() => this.props.handleEditBestDish(dish.name, dish.id, this.props.index)} className="ReviewText" style={{cursor: "pointer", backgroundColor: "#FCD844", padding: "12px 36px", borderRadius: "5px", marginRight: "20px"}}>Edit</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default BestDish;