import React, {Component} from "react";
import { withRouter } from 'react-router';

class TaxBreakdown extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this);
    }

    closeModalHandler() {
        this.props.toggleTaxBreakdown();
    }

    handleModalClick(e) {
        e.stopPropagation();
    }
    
    render() {
        if(!this.props.showTaxBreakdown){
            return null;
        }
        return(
            <div className="RatingModal" onClick={this.closeModalHandler}>
                <div style={{height: "unset", margin: "auto", position: 'relative', maxWidth: this.props.taxBreakdown?.orderDetails ? '800px' : '600px'}} onClick={(e) => this.handleModalClick(e)} className="SuccessModalContainer">
                    <div onClick={this.closeModalHandler} style={{position: 'absolute', right: "10px", top: "10px", cursor: 'pointer'}}>X</div>
                    <div style={{paddingTop: '20px'}}>
                        {this.props.taxBreakdown?.orderDetails && 
                            <div>
                                <div style={{display: 'flex', padding: '0px 20px'}}>
                                    <div style={{fontFamily: 'Bree-Regular', fontSize: '20px'}}>Order Details </div>
                                    <div style={{flex: 1, fontFamily: 'Bree-Regular', fontSize: '20px', color: '#EB622F', paddingLeft: '5px'}}>#{this.props.taxBreakdown?.orderDetails?.orderId}</div>
                                </div>
                                <div className='ddInsightsCardContainer'>
                                    <div style={{height: '110px'}} className='ddInsightsCard'>
                                        <div style={{backgroundColor: '#FD5D5D', height: '80px'}} className='ddInsightsCardLeftLine'></div>
                                        <div style={{marginLeft: '10px'}} className='ddInsightsCardRightCol'>
                                            <div className='ddInsightsCardInfo'>
                                                <div style={{flex: 1}}></div>
                                                <div style={{color: '#2B2624'}} className='ddInsightsCardHeader'>Gross Revenue</div>
                                                <div style={{color: '#2B2624'}} className='ddInsightsCardSubHeader'>{this.props.taxBreakdown?.orderDetails?.grossRevenue}</div>
                                                <div style={{flex: 1}}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{height: '110px'}} className='ddInsightsCard'>
                                        <div style={{backgroundColor: '#FDB35D', height: '80px'}} className='ddInsightsCardLeftLine'></div>
                                        <div style={{marginLeft: '10px'}} className='ddInsightsCardRightCol'>
                                            <div className='ddInsightsCardInfo'>
                                                <div style={{flex: 1}}></div>
                                                <div style={{color: '#2B2624'}} className='ddInsightsCardHeader'>Deductions</div>
                                                <div style={{color: '#BF3232'}} className='ddInsightsCardSubHeader'>{this.props.taxBreakdown?.orderDetails?.deductions}</div>
                                                <div style={{flex: 1}}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{height: '110px'}} className='ddInsightsCard'>
                                        <div style={{backgroundColor: '#0D7BCC', height: '80px'}} className='ddInsightsCardLeftLine'></div>
                                        <div style={{marginLeft: '10px'}} className='ddInsightsCardRightCol'>
                                            <div className='ddInsightsCardInfo'>
                                                <div style={{flex: 1}}></div>
                                                <div style={{color: '#2B2624'}} className='ddInsightsCardHeader'>Net Receivable</div>
                                                <div style={{color: '#1F7E1B'}} className='ddInsightsCardSubHeader'>{this.props.taxBreakdown?.orderDetails?.netReceivable}</div>
                                                <div style={{flex: 1}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div style={{padding: '0px 20px'}}>
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1, fontFamily: 'Bree-Regular', fontSize: '20px'}}>{this.props.taxBreakdown.title}</div>
                            </div>
                            <div style={{padding: '5px 0px 30px 0px'}}>
                                {(this.props.taxBreakdown?.items).map((item, index) => {
                                    return (
                                        <div style={{
                                            display: 'flex', 
                                            borderBottom: '1px solid transparent',
                                            borderBottomColor: (index + 1) !== this.props.taxBreakdown?.items.length ? '#E8E8E8': 'transparent',
                                            padding: '14px 0px 10px 0px'
                                        }}>
                                            <div style={{flex: 1, fontFamily: 'Poppins-Medium', fontSize: '14px', color: "#5F514B"}}>{item.name}</div>
                                            <div style={{fontFamily: 'Poppins-Medium', fontSize: '14px', color: "#5F514B"}}>{item.value}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {this.props.taxBreakdown?.netAmount && <div style={{
                            display: 'flex', 
                            backgroundColor: '#F2F2F2',
                            padding: '20px',
                            borderBottomLeftRadius: '10px',
                            borderBottomRightRadius: '10px',
                            borderTop: '1px solid #E8E8E8',
                        }}>
                            <div style={{flex: 1, fontFamily: 'Poppins-SemiBold', fontSize: '14px', color: "#425FA1"}}>NET AMOUNT</div>
                            <div style={{fontFamily: 'Poppins-SemiBold', fontSize: '14px', color: "#425FA1"}}>{this.props.taxBreakdown?.netAmount}</div>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(TaxBreakdown);