import React, { Component } from 'react';
import Header from '../components/AddDishImage/Header';
import ContactFormMobile from '../components/AddDishImage/ContactFormMobile';
import DishCardPreview from '../components/AddDishImage/DishCardPreview';
import { withRouter } from 'react-router';
import axios from 'axios';
import CONSTANTS from '../config/constants';
import { setDeviceToken } from '../services/notification_handler';

// Initial Landing Page
class AddDishImage extends Component {
  
  constructor(){
    super();
    this.toggleAuthModal = this.toggleAuthModal.bind(this);
    this.setActiveForm = this.setActiveForm.bind(this);
    this.closeAuthModal = this.closeAuthModal.bind(this);
    this.toggleDishCardPreview = this.toggleDishCardPreview.bind(this);
    this.toggleImageHover = this.toggleImageHover.bind(this);
    this.closeDishCardPreviewModal = this.closeDishCardPreviewModal.bind(this);
    this.state = {
      authModalOpen: false,
      activeForm: "",
      isMobileScreen: false,
      dish: {},
      restaurant: {},
      dishPreviewOpen: false,
      descriptionOverlayStyle: {display: 'none'}
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (localStorage.getItem("restaurant_id") === null){
      this.props.history.replace("/");
    } else {
      this.updateDimensions();
      this.getDishDetails();
      window.addEventListener("resize", this.updateDimensions.bind(this));
    }
    setDeviceToken();
  }

  getDishDetails() {
    let urlForDishDetail = CONSTANTS.hostURL + "api/resto-app/dishes/" + this.props.match.params.dish_id + "/";
    let headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    }
    axios.get(urlForDishDetail, {
      headers: headers
    }).then(res => {
      if (res.data.status === 1){
        this.setState({
          dish: res.data.data,
          restaurant: JSON.parse(localStorage.getItem("restaurant_details"))
        })
      }else {
        let url = CONSTANTS.hostURL + "api/resto-app/login/refresh-session/";
        let refreshReq = {
          'refresh' : localStorage.getItem('refresh_token')
        }
        axios.post(url, refreshReq,  {
          headers: {}
        }).then((response) => {
          if (response.data.status === 1){
            localStorage.setItem('access_token', response.data.data.access);
            localStorage.setItem('refresh_token', response.data.data.refresh);
            this.getDishDetails();
          } else {
            localStorage.removeItem("restaurant_id");
            localStorage.removeItem("restaurant_details");
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            this.props.history.replace("/");
          }
        }).catch(error => {
          localStorage.removeItem("restaurant_id");
          localStorage.removeItem("restaurant_details");
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          this.props.history.replace("/");
        });
      }
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401){
          let url = CONSTANTS.hostURL + "api/resto-app/login/refresh-session/";
          let refreshReq = {
            'refresh' : localStorage.getItem('refresh_token')
          }
          axios.post(url, refreshReq,  {
            headers: {}
          }).then((response) => {
            if (response.data.status === 1){
              localStorage.setItem('access_token', response.data.data.access);
              localStorage.setItem('refresh_token', response.data.data.refresh);
              this.getDishDetails();
            } else {
              localStorage.removeItem("restaurant_id");
              localStorage.removeItem("restaurant_details");
              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");
              this.props.history.replace("/");
            }
          }).catch((error) => {
            localStorage.removeItem("restaurant_id");
            localStorage.removeItem("restaurant_details");
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            this.props.history.replace("/");
          });
        }
      }
    });
  }

  updateDimensions() {
    let screen_width = window.innerWidth;
    if(screen_width < 500) {
        this.setState({ isMobileScreen: true });
    } else {
        this.setState({ isMobileScreen: false });
    }
  }

  preventDefault(e) {
    e.preventDefault();
  }

  preventDefaultForScrollKeys(e) {
    var keys = {9: 1, 38: 1, 40: 1, 32: 1, 33: 1, 34: 1, 35: 1, 36: 1};
    if (keys[e.keyCode]) {
      e.preventDefault();
      return false;
    }
  }

  disableScrolling(wheelOpt, wheelEvent) {
    window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
    window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  enableScrolling(wheelOpt, wheelEvent) {
    window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
    window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
    window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
    window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  componentDidUpdate() {   
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    if(this.state.authModalOpen && !this.state.isMobileScreen){
      this.disableScrolling(wheelOpt, wheelEvent);
    } else {
      this.enableScrolling(wheelOpt, wheelEvent);
    }
  }

  componentWillUnmount(){
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    this.enableScrolling(wheelOpt, wheelEvent);
  }

  toggleAuthModal() {
    this.setState({
      authModalOpen: true
    })
  }

  setActiveForm(form){
    if (this.state.activeForm !== form){
      this.setState({
        activeForm: form
      })
    }
  }

  closeAuthModal(){
    if (this.state.authModalOpen){
      this.setState({
        authModalOpen: false
      })
    }
  }

  toggleDishCardPreview(dish){
    this.setState({
      dishPreviewOpen: true,
      dish: dish
    })
  }

  toggleImageHover(index){
    let dish = this.state.dish;
    if (dish.imageHover){
      this.setState({
        descriptionOverlayStyle: {display: 'none'}
      })
    } else {
      this.setState({
        descriptionOverlayStyle: {display: 'block'}
      })
    }
    dish.imageHover = !this.state.dish.imageHover;
    this.setState({
      dish: dish
    });
  }

  closeDishCardPreviewModal(){
    this.setState({
      dishPreviewOpen: false
    })
  }

  render() {
    return (
      <div>
        <Header
          toggleAuthModal={this.toggleAuthModal}
          setActiveForm={this.setActiveForm}
          authModalOpen={this.state.authModalOpen}
          toggleDishCardPreview={this.toggleDishCardPreview}
          dish={this.state.dish}
          restaurant={this.state.restaurant}
        />
        <ContactFormMobile
          authModalOpen={this.state.authModalOpen}
          toggleDishCardPreview={this.toggleDishCardPreview}
        />
        <DishCardPreview
          dishPreviewOpen={this.state.dishPreviewOpen}
          toggleImageHover={this.toggleImageHover}
          dish={this.state.dish}
          descriptionOverlayStyle={this.state.descriptionOverlayStyle}
          closeDishCardPreviewModal={this.closeDishCardPreviewModal}
        />
      </div>
    );
  }
}

export default withRouter(AddDishImage);
