import React, { Component } from 'react';
import axios from 'axios';
import CONSTANTS from '../../config/constants';
import Grid from '@material-ui/core/Grid';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import Divider from '@material-ui/core/Divider';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EditIcon from '@material-ui/icons/Edit';
import PreparingOrdersCard from './PreparingOrdersCard.js';
import NewOrdersCard from './NewOrdersCard.js';
import no_orders from '../../assets/images/no_orders.svg';
import Autosuggest from 'react-autosuggest';
import '../../assets/styles/OrderScreen.scss';
// import Checkbox from '@material-ui/core/Checkbox';

class OrderScreen extends Component {
    constructor(props){
        super();
        this.getOrderFilterCount = this.getOrderFilterCount.bind(this);
        this.changeFoodPreparationTime = this.changeFoodPreparationTime.bind(this);
        this.getOrderItems = this.getOrderItems.bind(this);
        this.getTotalCharge=this.getTotalCharge.bind(this);
        this.showChangeTimeCard=this.showChangeTimeCard.bind(this);
        this.changeOrderStatus=this.changeOrderStatus.bind(this);
        this.getOrderTime = this.getOrderTime.bind(this);
        this.showRejectCard = this.showRejectCard.bind(this);
        this.selectedDeclineReason = this.selectedDeclineReason.bind(this);
        this.backToOrderCard = this.backToOrderCard.bind(this);
        this.handleDeclineOrder = this.handleDeclineOrder.bind(this);
        this.setOthersDeclineReason = this.setOthersDeclineReason.bind(this);     
        this.changeFoodPreparationTime=this.changeFoodPreparationTime.bind(this);
        this.backToPreparingCard=this.backToPreparingCard.bind(this);
        this.getCustomerInfo=this.getCustomerInfo.bind(this);
        this.state = {
            isOnline: true,
            authModalOpen: false,
            activeForm: "",
            isMobileScreen: false,
            descriptionOverlayStyle: {display: 'none'},
            expandedMenu: true,
            leftMenuWidth: 2,
            rightMenuWidth: 10,
            menuArrow: "buttonFlip",
            selectedMenu: 0,
            orders: [],
            prepOrders: [],
            prepOrdersChanged: false,
            delivery_executives: []
        }
    }

    componentDidMount() {
      window.scrollTo(0, 0);
      this.updateDimensions();
      let orders = [];
      for (var i=0; i<this.props.orders.length; i++){
        let newOrder = this.props.orders[i];
        // console.log(newOrder.status_update_logs)
        // if('status_update_logs' in newOrder && "UNDER_PREPARATION" in newOrder.status_update_logs){
        //     this.setState({
        //         ["orderAcceptedAt_"+newOrder.id] : this.timeRemaining(newOrder.status_update_logs.UNDER_PREPARATION.updated_at)
        //     });
        // }
        // else{
        //     this.setState({
        //         ["orderAcceptedAt_"+newOrder.id] : this.timeRemaining("00-0000")
        //     });
        // }
        

        newOrder['data'] = {
            'food_preparation_time': this.getStatusLogInfo(newOrder, 20, 'UNDER_PREPARATION', 'preparation_time'),
            'under_preparation_updated_at': this.getStatusLogInfo(newOrder, 20, 'UNDER_PREPARATION', 'updated_at'),
            'decline_reason': this.getStatusLogInfo(newOrder, '', 'DECLINED', 'rejection_reason'),
            'delivery_partner_name': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_name'),
            'delivery_partner_name_final': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_name'),
            'delivery_partner_mobile': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_mobile'),
            'delivery_partner_mobile_final': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_mobile'),
            'food_preparation_time_final': this.getStatusLogInfo(newOrder, 20, 'UNDER_PREPARATION', 'preparation_time'),
            'delivery_tracking_url': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'tracking_url'),
            'delivery_time': this.getStatusLogInfo(newOrder, 20, 'ON_THE_WAY', 'estimated_delivery_time'),
            'delivery_tracking_url_final': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'tracking_url'),
            'delivery_time_final': this.getStatusLogInfo(newOrder, 20, 'ON_THE_WAY', 'estimated_delivery_time'),
            'delivery_executive_mobile': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_mobile'),
            'delivery_otp': '',
            'otp_error': ''
        }
        orders.push(newOrder);
        if (this.props.restaurant.delivery_executives !== null && this.props.restaurant.delivery_executives !== undefined && Object.keys(this.props.restaurant.delivery_executives).length !== 0) {
            this.setState({
                delivery_executives: this.convertMapToList(this.props.restaurant.delivery_executives)
            })
        }
      }
    //   this.countDownTimer();
        this.setState({
                orders: orders,
                isOnline: this.props.restaurant.is_accepting_orders
        }, () => this.getPreparingOrders())
        this.setState({
            prepOrdersChanged: false
        })
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    getStatusLogInfo(order, defaultValue, statusName, keyName){
        let logInfo = defaultValue;
        // console.log('getting logInfo');
        // console.log(order.status_update_logs);
        if ('status_update_logs' in order && statusName in order.status_update_logs && keyName in order.status_update_logs[statusName]){
            logInfo = order.status_update_logs[statusName][keyName];
        }
        return logInfo;
    }

    componentDidUpdate(){
        if (this.props.newOrderPushed === true){
            // console.log('orders updated');
            // console.log(this.props.orders.length);
            let orders = this.state.orders;
            // console.log(orders.length);
            for (var i=0; i<this.props.orders.length; i++){
                let orderFound = false;
                let orderIndex = -1;
                for (var j=0; j<orders.length; j++){
                    if (orders[j].id === this.props.orders[i].id){
                        orderFound = true;
                        orderIndex = j;
                        break;
                    }
                }
                if (!orderFound){
                    let newOrder = this.props.orders[i];
                    // console.log(newOrder);
                    newOrder['data'] = {
                        'food_preparation_time': this.getStatusLogInfo(newOrder, 20, 'UNDER_PREPARATION', 'preparation_time'),
                        'under_preparation_updated_at': this.getStatusLogInfo(newOrder, 20, 'UNDER_PREPARATION', 'updated_at'),
                        'decline_reason': this.getStatusLogInfo(newOrder, '', 'DECLINED', 'rejection_reason'),
                        'delivery_partner_name': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_name'),
                        'delivery_partner_name_final': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_name'),
                        'delivery_partner_mobile': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_mobile'),
                        'delivery_partner_mobile_final': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_mobile'),
                        'food_preparation_time_final': this.getStatusLogInfo(newOrder, 20, 'UNDER_PREPARATION', 'preparation_time'),
                        'delivery_tracking_url': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'tracking_url'),
                        'delivery_time': this.getStatusLogInfo(newOrder, 20, 'ON_THE_WAY', 'estimated_delivery_time'),
                        'delivery_tracking_url_final': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'tracking_url'),
                        'delivery_time_final': this.getStatusLogInfo(newOrder, 20, 'ON_THE_WAY', 'estimated_delivery_time'),
                        'delivery_executive_mobile': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_mobile'),
                        'delivery_otp': '',
                        'otp_error': ''
                    }
                    if (i > orders.length){
                        orders.push(newOrder);
                    } else {
                        orders.splice(i,0,newOrder);
                    }
                }
                else {
                    if (orders[orderIndex].order_status !== this.props.orders[i].order_status){
                        orders[orderIndex].order_status = this.props.orders[i].order_status;
                    }
                }
            }
            this.setState({
                orders: orders
            }, () => this.getPreparingOrders())
            this.setState({
                prepOrdersChanged: false
            })
            this.props.toggleNewOrderPushed();
        }
        if (this.props.onlineStateChanged === true){
            this.setState({
                isOnline: !this.state.isOnline
            })
            this.props.toggleOnlineStateChanged();
        }
    }
  
    updateDimensions() {
      let screen_width = window.innerWidth;
      if(screen_width < 500) {
          this.setState({ isMobileScreen: true });
      } else {
          this.setState({ isMobileScreen: false });
      }
    }

    showRejectCard(index){
        this.setState({["rejectCard_"+index] : true});
        let orders = this.state.orders;
        let newOrder = orders[index];
        newOrder['data'].decline_reason = '';
        orders[index] = newOrder;
        this.setState({
            orders: orders
        })
    }
    
    showChangeTimeCard(index){
        this.setState({["changeTime_"+index] : true});
        let orders = this.state.orders;
        let newOrder = orders[index];
        newOrder.data.food_preparation_time = newOrder.data.food_preparation_time_final;
        orders[index] = newOrder;
        this.setState({
            orders: orders
        })
    }

    showChangeTimeCardAtOutForDelivery(index){
        this.setState({["changeTimeAtOutForDelivery_"+index] : true});
        let orders = this.state.orders;
        let newOrder = orders[index];
        newOrder.data.delivery_time = newOrder.data.delivery_time_final;
        orders[index] = newOrder;
        this.setState({
            orders: orders
        })
    }

    showChangeDeliveryDetailsCardAtOutForDelivery(index){
        this.setState({["changeDeliveryDetailsAtOutForDelivery_"+index] : true});
        let orders = this.state.orders;
        let newOrder = orders[index];
        newOrder.data.delivery_partner_name = newOrder.data.delivery_partner_name_final;
        newOrder.data.delivery_partner_mobile = newOrder.data.delivery_partner_mobile_final;
        newOrder.data.delivery_tracking_url = newOrder.data.delivery_tracking_url_final;
        orders[index] = newOrder;
        this.setState({
            orders: orders
        })
    }

    backToOrderCard(index){
        this.setState({["rejectCard_"+index] : false});
        this.setState({["rejectReason_"+index+"_1"] : false});
        this.setState({["rejectReason_"+index+"_2"] : false});
        this.setState({["rejectReason_"+index+"_3"] : false});
    }

    backToPreparingCard(index){
        this.setState({["changeTime_"+index] : false});
    }

    backToOutForDeliveryCard(index){
        this.setState({["changeTimeAtOutForDelivery_"+index] : false});
        this.setState({["changeDeliveryDetailsAtOutForDelivery_"+index] : false});
    }

    selectedDeclineReason(index, label, labelIndex){
        for (var i=0; i<3; i++){
            this.setState({["rejectReason_"+index+"_" + (i + 1).toString()] : i === labelIndex ? true : false});
        }
        let orders = this.state.orders;
        let newOrder = orders[index];
        newOrder['data'].decline_reason = label === 'Other' ? '' : label;
        orders[index] = newOrder;
        this.setState({
            orders: orders
        })
    }

    getOrderFilterCount(orderStatus){
        let filteredOrders = [];
        if (Array.isArray(this.state.orders)){
            filteredOrders = this.state.orders.filter(
                order => order.order_status === orderStatus
            );
        }
        return filteredOrders.length;
    }

    getOrderItems(order){
        return <div style={{paddingTop:'5px'}}>
            {
                Object.keys(order.items).map((key, itemIndex) => 
                    <div key={itemIndex} className="orderTime">
                        <Grid container spacing={0}>
                            <Grid item xs={9}>
                                {order.items[key].quantity} x {order.items[key].name}
                            </Grid>
                            <Grid item xs={3}>
                                <span style={{float:'right'}}>Rs. {order.items[key].item_total}</span>
                            </Grid>
                        </Grid>
                    </div>
                ) 
            }
        </div>;
    }

    getTotalCharge(order){
        return <div className="orderLabel1" style={{paddingTop:'5px'}}>
            <span>Total</span>
            <span style={{float:'right', color:'#009828'}}>{order.net_total}</span>
        </div>;
    }

    changeFoodPreparationTime(type, index, cardType){
        let orders = this.state.orders;
        let newOrder = orders[index];
        if(cardType === "PREPARING_ORDERS"){
            if (type === 'decrease'){
                if (newOrder.status_update_logs.UNDER_PREPARATION.preparation_time !== 0){
                    newOrder.status_update_logs.UNDER_PREPARATION.preparation_time = newOrder.status_update_logs.UNDER_PREPARATION.preparation_time - 5;
                }
            } else {
                newOrder.status_update_logs.UNDER_PREPARATION.preparation_time = newOrder.status_update_logs.UNDER_PREPARATION.preparation_time + 5;
            }
        }
        else{
            if (type === 'decrease'){
                if (newOrder['data'].food_preparation_time !== 0){
                    newOrder['data'].food_preparation_time = newOrder['data'].food_preparation_time - 5;
                }
            } else {
                newOrder['data'].food_preparation_time = newOrder['data'].food_preparation_time + 5;
            }
        }
        
        orders[index] = newOrder;
        this.setState({
            orders: orders
        })
    }

    changeDeliveryTime(type, index){
        let orders = this.state.orders;
        let newOrder = orders[index];
        if (type === 'decrease'){
            if (newOrder['data'].delivery_time !== 0){
                newOrder['data'].delivery_time = newOrder['data'].delivery_time - 5;
            }
        } else {
            newOrder['data'].delivery_time = newOrder['data'].delivery_time + 5;
        }
        orders[index] = newOrder;
        this.setState({
            orders: orders
        })
    }

    changeOrderStatus(index, status, info){
        this.setState({
            prepOrdersChanged: true
        })
        let orders = this.state.orders;
        let newOrder = orders[index];
        let oldStatus = newOrder.order_status;
        let oldPreparationTime = this.getStatusLogInfo(newOrder, 20, 'UNDER_PREPARATION', 'preparation_time');
        let old_under_preparation_updated_at = this.getStatusLogInfo(newOrder, 20, 'UNDER_PREPARATION', 'updated_at');
        let oldDeliveryTime = this.getStatusLogInfo(newOrder, 20, 'ON_THE_WAY', 'estimated_delivery_time');
        let oldDeliveryPartnerName = this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_name');
        let oldDeliveryPartnerMobile = this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_mobile');
        let oldDeliveryTrackingURL = this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'tracking_url');
        newOrder.order_status = status;
        newOrder.data.food_preparation_time_final = newOrder.data.food_preparation_time;
        newOrder.data.under_preparation_updated_at_final = newOrder.data.under_preparation_updated_at;
        newOrder.data.delivery_time_final = newOrder.data.delivery_time;
        newOrder.data.delivery_partner_name_final = newOrder.data.delivery_partner_name;
        newOrder.data.delivery_partner_mobile_final = newOrder.data.delivery_partner_mobile;
        newOrder.data.delivery_tracking_url_final = newOrder.data.delivery_tracking_url;
        orders[index] = newOrder;
        this.setState({
            orders: orders,
        }, () => console.log(this.state.orders))
        this.setState({
            prepOrdersChanged: false
        })
        this.props.updateOrderStatus(index, status);
        let url = CONSTANTS.hostURL + 'api/resto-app/orders/update-status/';
        let req = {
            'order_id': newOrder.id,
            'status': status,
            'info': info
        }
        axios.post(url, req,  {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        }).then((response) => {
            if (response.data.status === 1){
                this.props.updateOrderStatus(index, status);
                this.updateOrders(index)
                let delivery_executives = this.state.delivery_executives;
                if (status === "ON_THE_WAY" && this.state.orders[index].data.delivery_partner_name !== '' && this.state.orders[index].data.delivery_executive_mobile === ''){
                    let restaurant = this.props.restaurant;
                    restaurant.delivery_executives[this.state.orders[index].data.delivery_partner_mobile] =  this.state.orders[index].data.delivery_partner_name;
                    localStorage.setItem('restaurant_details', JSON.stringify(restaurant));
                    this.props.updateRestoDetails(restaurant);
                    delivery_executives.push({
                        name: this.state.orders[index].data.delivery_partner_name,
                        mobile_number: this.state.orders[index].data.delivery_partner_mobile
                    });
                    this.setState({
                        delivery_executives: delivery_executives
                    })
                }
            } else {
                newOrder.order_status = oldStatus;
                newOrder.data.food_preparation_time_final = oldPreparationTime;
                newOrder.data.under_preparation_updated_at_final = old_under_preparation_updated_at;
                newOrder.data.delivery_time_final = oldDeliveryTime;
                newOrder.data.delivery_partner_name_final = oldDeliveryPartnerName;
                newOrder.data.delivery_partner_mobile_final = oldDeliveryPartnerMobile;
                newOrder.data.delivery_tracking_url_final = oldDeliveryTrackingURL;
                if (status === 'FULFILLED' && 'delivery_otp' in info){
                    newOrder.data.otp_error = 'Incorrect OTP entered';
                }
                orders[index] = newOrder;
                this.setState({
                    orders: orders,
                }, () => this.getPreparingOrders())
                this.setState({
                    prepOrdersChanged: false
                })
            }
        }).catch((error) => {
            // console.log(error);
            newOrder.order_status = oldStatus;
            newOrder.data.food_preparation_time_final = oldPreparationTime;
            newOrder.data.under_preparation_updated_at_final = old_under_preparation_updated_at;
            newOrder.data.delivery_time_final = oldDeliveryTime;
            if (status === 'FULFILLED' && 'delivery_otp' in info){
                newOrder.data.otp_error = 'Incorrect OTP entered';
            }
            orders[index] = newOrder;
            this.setState({
                orders: orders,
            }, () => this.getPreparingOrders())
            this.setState({
                prepOrdersChanged: false
            })
        });
        this.setState({
            ["changeTime_"+index] : false
        })
    }

    updateOrders(index){
        let url = CONSTANTS.hostURL + "api/resto-app/orders/get-by-ids/?order_ids=" + this.state.orders[index].order_id;
        let headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
        axios.get(url, {
            headers: headers
        }).then(res => {
            if (res.data.status === 1){
                let newOrder = res.data.data.orders[0];
                newOrder['data'] = {
                    'food_preparation_time': this.getStatusLogInfo(newOrder, 20, 'UNDER_PREPARATION', 'preparation_time'),
                    'under_preparation_updated_at': this.getStatusLogInfo(newOrder, 20, 'UNDER_PREPARATION', 'updated_at'),
                    'decline_reason': this.getStatusLogInfo(newOrder, '', 'DECLINED', 'rejection_reason'),
                    'delivery_partner_name': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_name'),
                    'delivery_partner_name_final': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_name'),
                    'delivery_partner_mobile': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_mobile'),
                    'delivery_partner_mobile_final': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'delivery_partner_mobile'),
                    'food_preparation_time_final': this.getStatusLogInfo(newOrder, 20, 'UNDER_PREPARATION', 'preparation_time'),
                    'delivery_tracking_url': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'tracking_url'),
                    'delivery_time': this.getStatusLogInfo(newOrder, 20, 'ON_THE_WAY', 'estimated_delivery_time'),
                    'delivery_tracking_url_final': this.getStatusLogInfo(newOrder, '', 'ON_THE_WAY', 'tracking_url'),
                    'delivery_time_final': this.getStatusLogInfo(newOrder, 20, 'ON_THE_WAY', 'estimated_delivery_time'),
                    'delivery_otp': '',
                    'otp_error': ''
                }
                let orders = this.state.orders;
                orders[index] = newOrder;
                this.setState({
                    orders: orders
                },() => this.getPreparingOrders())
            }
        });
    }

    getPreparingOrders(){
        let prepOrders = [];
        for(let i=0; i<this.state.orders.length; i++){
            if(this.state.orders[i].order_status === 'UNDER_PREPARATION'){
                prepOrders.push(this.state.orders[i])
            }
        }
        this.setState({
            prepOrders: prepOrders
        })
    }

    handleDeclineOrder(index){
        if (this.state.orders[index].data.decline_reason !== ''){
            this.changeOrderStatus(index, 'DECLINED', {
                'rejection_reason': this.state.orders[index].data.decline_reason
            });
        }
    }

    getOrderTime(order){
        let time = order.ordered_at.split("T")[1].substring(0, order.ordered_at.split("T")[1].length - 1);
        let hour = time.split(":")[0];
        let minute = time.split(":")[1]
        let am_pm = hour < 12 ? ' AM' : ' PM';
        if (hour === 0){
            hour = 12;
        } else {
            if (hour > 12){
                hour = hour - 12;
            }
        }
        return hour + ':' + minute + am_pm;
    }

    getCustomerInfo(order){
        return <>
            <div style={{marginTop:'18px'}}>
                <span className="orderLabel1">{order.order_id}</span>
            </div>
            <div style={{display: "flex"}}>
                <div style={{flex: "1"}}>
                    <span className="orderTime" style={{flex: '1'}}>{order.user !== null ? order.user.name : '--'}</span>
                </div>
                <div>
                    <span className="orderTime" style={{float:'right'}}>{this.getOrderTime(order)}</span>
                </div>
            </div>
            <Divider style={{marginTop: "3px"}}/>
        </>
    }

    setOthersDeclineReason(ordersData){
        this.setState({
            orders: ordersData
        })
    }

    newOrders(){
        const orderCards = this.state.orders.map((order, index) => (
            order.order_status === 'ORDERED' ? 
                <NewOrdersCard
                    key={index}
                    index={index}
                    order={order}
                    orders={this.state.orders}
                    setOrder = {this.props.setOrder}
                    getOrderTime = {this.getOrderTime}
                    getOrderItems = {this.getOrderItems}
                    getTotalCharge = {this.getTotalCharge}
                    changeFoodPreparationTime = {this.changeFoodPreparationTime}
                    showRejectCard = {this.showRejectCard}
                    changeOrderStatus = {this.changeOrderStatus}
                    rejectCardIndex = {this.state["rejectCard_"+index]}
                    rejectReasonIndex1 = {this.state["rejectReason_"+index+"_1"]}
                    rejectReasonIndex2 = {this.state["rejectReason_"+index+"_2"]}
                    rejectReasonIndex3 = {this.state["rejectReason_"+index+"_3"]}
                    selectedDeclineReason = {this.selectedDeclineReason}
                    backToOrderCard = {this.backToOrderCard}
                    handleDeclineOrder = {this.handleDeclineOrder}
                    setOthersDeclineReason = {this.setOthersDeclineReason}
                />
            :
                <></>
        ));
        return <>{orderCards}</>;
    }
    
    getIndexOfOrder(order_id){
        let indVal = 0;
        for(let i=0; i<this.state.orders.length; i++){
            if(this.state.orders[i].order_id === order_id){
                indVal = i;
            }
        }
        return(indVal)
    }

    preparingOrders(){
        const orderCards = this.state.prepOrders.map((order, index) => (
            <PreparingOrdersCard
                key={index}
                index={this.getIndexOfOrder(order.order_id)}
                order={order}
                setOrder = {this.props.setOrder}
                getCustomerInfo={this.getCustomerInfo}
                getOrderItems = {this.getOrderItems}
                getTotalCharge={this.getTotalCharge}
                showChangeTimeCard={this.showChangeTimeCard}
                changeOrderStatus={this.changeOrderStatus}
                changeTime_index = {this.state["changeTime_"+this.getIndexOfOrder(order.order_id)]}
                changeFoodPreparationTime={this.changeFoodPreparationTime}
                backToPreparingCard={this.backToPreparingCard}
                orderAcceptedAt_orderId={this.state["orderAcceptedAt_"+order.id]}
                ordersChanged={this.state.ordersChanged}
                prepOrdersChanged={this.state.prepOrdersChanged}
            />
        ));
        return <>{orderCards}</>;
    }

    convertMapToList = (map) => {
        let returnList = Object.keys(map).map((key) => {
            return {
                name: map[key],
                mobile_number: key
            }
        });
        return returnList;
    }

    onSuggestionsFetchRequested = ({ cuisineTextValue }) => {};

    onSuggestionsClearRequested = () => {};

    getSuggestionValue = suggestion => `${suggestion.name} (${suggestion.mobile_number})`;

    renderSuggestion = (suggestion, index) => (
        <div onClick={() => {
            let orders = this.state.orders;
            let newOrder = orders[index];
            newOrder.data.delivery_executive_mobile = suggestion.mobile_number;
            orders[index] = newOrder;
            this.setState({
                orders: orders
            })
        }}>
          {`${suggestion.name} (${suggestion.mobile_number})`}
        </div>
    );

    onChange = (event, index, { newValue }) => {
        if (newValue.includes(" (") && newValue.includes(")")){} else {
            let orders = this.state.orders;
            let newOrder = orders[index];
            newOrder.data.delivery_executive_mobile = newValue;
            orders[index] = newOrder;
            this.setState({
                orders: orders
            })
        }
        let suggestions = this.state.delivery_executives;
        if (newValue !== ""){
            let delivery_executives = {};
            for (var i=0; i<suggestions.length; i++){
                if (suggestions[i].name.toLowerCase().includes(newValue.toLowerCase()) || suggestions[i].mobile_number.toLowerCase().includes(newValue.toLowerCase())){
                    delivery_executives[suggestions[i].mobile_number] = suggestions[i].name;
                }
            }
            this.setState({
                delivery_executives: this.convertMapToList(delivery_executives)
            })
        } else {
            this.setState({
                delivery_executives: this.convertMapToList(this.props.restaurant.delivery_executives)
            })
        }
    };

    autoSuggestItem(index) {
        let value = '';
        if (this.state.orders[index].data.delivery_executive_mobile in this.props.restaurant.delivery_executives){
            value = `${this.props.restaurant.delivery_executives[this.state.orders[index].data.delivery_executive_mobile]} (${this.state.orders[index].data.delivery_executive_mobile})`;
        } else {
            value = this.state.orders[index].data.delivery_executive_mobile;
        }
        if (value === undefined || value === null){
            value = '';
        }
        const inputProps = {
            placeholder: 'Enter name of an existing Delivery partner',
            value,
            onChange: ((event, value) => this.onChange(event, index, value))
        };

        return <Autosuggest
            suggestions={this.state.delivery_executives}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={(suggestion) => this.renderSuggestion(suggestion, index)}
            inputProps={inputProps}
        />;
    }

    packedOrders(){

        const orderCards = this.state.orders.map((order, index) => {
            if (order.order_status === 'READY_FOR_PICKUP'){
                return <div key={index} className="orderCards">
                    <div style={{float:'right'}}><ZoomOutMapIcon className="orderInfoBtn" onClick={() => this.props.setOrder(order)}/></div>
                    <div style={{padding:'10px'}}>
                        {this.getCustomerInfo(order)}
                        {this.getOrderItems(order)}
                        {this.getTotalCharge(order)}
                        {order.delivery_mode === 'SELF_PICKUP' || this.props.restaurant.delivery_option !== 'DAIDISH_ASSISTED_DELIVERY' ? <Divider/> : null}
                        {order.delivery_mode === 'DELIVERY' ? this.props.restaurant.delivery_option !== 'DAIDISH_ASSISTED_DELIVERY' ? <div style={{color:"#EA5B2F", padding: '10px 0px'}}> 
                            <span style={{fontSize: '12px'}} className="infoText">{(this.props.restaurant.delivery_executives !== null && this.props.restaurant.delivery_executives !== undefined && Object.keys(this.props.restaurant.delivery_executives).length !== 0) ? "" : "Add Delivery Partner Name and Contact" }</span>
                        </div> : null : <div style={{color:"#EA5B2F", padding: '10px 0px'}}> 
                            <span style={{fontSize: '12px'}} className="infoText">Enter OTP received from customer</span>
                        </div>}
                        {order.delivery_mode === 'DELIVERY' ? this.props.restaurant.delivery_option !== 'DAIDISH_ASSISTED_DELIVERY' ? <Grid container spacing={1} >
                            {(this.props.restaurant.delivery_executives !== null && this.props.restaurant.delivery_executives !== undefined && Object.keys(this.props.restaurant.delivery_executives).length !== 0) ? <>
                                <Grid className="OrderScreenAutoSuggest" item xs={12}>
                                    {this.autoSuggestItem(index)}
                                </Grid>
                                <div style={{padding: '0px', fontSize: '14px', margin: "auto", color: "black"}} className="infoText">OR</div>
                                <Grid item xs={12}>
                                    <div style={{color:"#EA5B2F", padding: '2px 0px'}}>
                                        <span style={{fontSize: '12px'}} className="infoText">Add Delivery Partner Name and Contact</span>
                                    </div>
                                </Grid>
                            </> : null}
                            <Grid item xs={12}>
                                <input value={this.state.orders[index].data.delivery_partner_name} onChange={(event) => {
                                    let orders = this.state.orders;
                                    let newOrder = orders[index];
                                    newOrder.data.delivery_partner_name = event.target.value;
                                    orders[index] = newOrder;
                                    this.setState({
                                        orders: orders
                                    })
                                }} type="text" placeholder="Enter Name" className="deliveryPartnerTextBox"/>
                            </Grid>
                            <Grid item xs={12}>
                                <input value={this.state.orders[index].data.delivery_partner_mobile} onChange={(event) => {
                                    let orders = this.state.orders;
                                    let newOrder = orders[index];
                                    newOrder.data.delivery_partner_mobile = event.target.value;
                                    orders[index] = newOrder;
                                    this.setState({
                                        orders: orders
                                    })
                                }} type="text" placeholder="Enter Number" className="deliveryPartnerTextBox"/>
                            </Grid>
                            <div style={{padding: '0px', fontSize: '14px', margin: "auto", color: "black"}} className="infoText">OR</div>
                            <Grid item xs={12}>
                                <div style={{color:"#EA5B2F", padding: '2px 0px'}}> 
                                    <span style={{fontSize: '12px'}} className="infoText">Add Delivery Tracking URL</span>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <input value={this.state.orders[index].data.delivery_tracking_url} onChange={(event) => {
                                    let orders = this.state.orders;
                                    let newOrder = orders[index];
                                    newOrder.data.delivery_tracking_url = event.target.value;
                                    orders[index] = newOrder;
                                    this.setState({
                                        orders: orders
                                    })
                                }} type="text" placeholder="Enter tracking URL" className="deliveryPartnerTextBox"/>
                            </Grid>
                        </Grid> : null : <Grid container spacing={1} >
                            <Grid item xs={12}>
                                <input value={this.state.orders[index].data.delivery_otp} onChange={(event) => {
                                    let orders = this.state.orders;
                                    let newOrder = orders[index];
                                    newOrder.data.delivery_otp = event.target.value;
                                    orders[index] = newOrder;
                                    this.setState({
                                        orders: orders
                                    })
                                }} type="text" placeholder="Enter OTP" className="deliveryPartnerTextBox"/>
                            </Grid>
                        </Grid>}
                        <div style={{color:"#EA5B2F", padding: '10px 0px'}}> 
                            <span style={{fontSize: '12px'}} className="infoText">{this.state.orders[index].data.otp_error}</span>
                        </div>
                        {/* <Divider style={{marginTop:"5px"}}/> */}
                        {this.props.restaurant.delivery_option !== 'DAIDISH_ASSISTED_DELIVERY' ? order.delivery_mode === 'DELIVERY' ? <div style={{marginTop: '10px'}} className="infoText">Estimated Delivery Time</div> : null : null}
                        {this.props.restaurant.delivery_option !== 'DAIDISH_ASSISTED_DELIVERY' ? order.delivery_mode === 'DELIVERY' ? <Grid style={{paddingBottom: '5px'}} container spacing={0}>
                            <Grid item xs={4}>
                                <div onClick={() => this.changeDeliveryTime('decrease', index)} className="leftBtn">-</div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className="centerBlock">{this.state.orders[index].data.delivery_time} mins</div>
                            </Grid>
                            <Grid item xs={4}>
                                <div onClick={() => this.changeDeliveryTime('increase', index)} className="rightBtn">+</div>
                            </Grid>
                        </Grid> : null : null}
                        <Grid container spacing={1} style={{paddingTop:'5px'}}>
                            <Grid item xs={12}>
                                <div onClick={() => {
                                    if (this.props.restaurant.delivery_option === 'DAIDISH_ASSISTED_DELIVERY'){
                                        if (order.delivery_mode === 'DELIVERY'){
                                            this.changeOrderStatus(index, 'ON_THE_WAY', {});
                                        } else {
                                            if (this.state.orders[index].data.delivery_otp !== ''){
                                                this.changeOrderStatus(index, 'FULFILLED', {
                                                    'delivery_otp': this.state.orders[index].data.delivery_otp
                                                });
                                            }
                                        }
                                    } else {
                                        if (order.delivery_mode === 'DELIVERY'){
                                            if (this.state.orders[index].data.delivery_executive_mobile !== '' && this.state.orders[index].data.delivery_executive_mobile in this.props.restaurant.delivery_executives){
                                                if (this.state.orders[index].data.delivery_partner_name !== ''){
                                                    this.props.informationBox("Please select either an existing delivery partner or add a new partner, not both.");
                                                } else if (this.state.orders[index].data.delivery_tracking_url !== ''){
                                                    this.props.informationBox("Please select either an existing delivery partner or add a tracking URL, not both.");
                                                } else {
                                                    this.changeOrderStatus(index, 'ON_THE_WAY', {
                                                        'delivery_partner_name': this.props.restaurant.delivery_executives[this.state.orders[index].data.delivery_executive_mobile],
                                                        'delivery_partner_mobile': this.state.orders[index].data.delivery_executive_mobile,
                                                        'estimated_delivery_time': this.state.orders[index].data.delivery_time
                                                    });
                                                }
                                            } else if (this.state.orders[index].data.delivery_partner_name !== '' && this.state.orders[index].data.delivery_partner_mobile !== ''){
                                                if (this.state.orders[index].data.delivery_executive_mobile !== '' && this.state.orders[index].data.delivery_executive_mobile in this.props.restaurant.delivery_executives){
                                                    this.props.informationBox("Please select either an existing delivery partner or add a new partner, not both.");
                                                } else if (this.state.orders[index].data.delivery_tracking_url !== ''){
                                                    this.props.informationBox("Please add a new delivery partner or add a tracking URL, not both.");
                                                } else {
                                                    this.changeOrderStatus(index, 'ON_THE_WAY', {
                                                        'delivery_partner_name': this.state.orders[index].data.delivery_partner_name,
                                                        'delivery_partner_mobile': this.state.orders[index].data.delivery_partner_mobile,
                                                        'estimated_delivery_time': this.state.orders[index].data.delivery_time
                                                    });
                                                }
                                            } else {
                                                if (this.state.orders[index].data.delivery_tracking_url !== ''){
                                                    if (this.state.orders[index].data.delivery_executive_mobile !== '' && this.state.orders[index].data.delivery_executive_mobile in this.props.restaurant.delivery_executives){
                                                        this.props.informationBox("Please select either an existing delivery partner or add a tracking URL, not both.");
                                                    } else if (this.state.orders[index].data.delivery_partner_name !== ''){
                                                        this.props.informationBox("Please add a new delivery partner or add a tracking URL, not both.");
                                                    } else {
                                                        this.changeOrderStatus(index, 'ON_THE_WAY', {
                                                            'estimated_delivery_time': this.state.orders[index].data.delivery_time,
                                                            'tracking_url': this.state.orders[index].data.delivery_tracking_url
                                                        });
                                                    }
                                                }
                                            }
                                        } else {
                                            if (this.state.orders[index].data.delivery_otp !== ''){
                                                this.changeOrderStatus(index, 'FULFILLED', {
                                                    'delivery_otp': this.state.orders[index].data.delivery_otp
                                                });
                                            }
                                        }
                                    }
                                }} className="outForDeliveryBtn">{order.delivery_mode === 'DELIVERY' ? this.props.restaurant.delivery_option !== 'DAIDISH_ASSISTED_DELIVERY' ? "OUT FOR DELIVERY" : 'ORDER HANDED OVER' : "ORDER DELIVERED"}</div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            } else {
                return null;
            }
        });
        return <>{orderCards}</>;
    }

    pickedUpForDeliveryOrders(){
        const orderCards = this.state.orders.map((order, index) =>{
            if (order.order_status === 'ON_THE_WAY'){
                return <div key={index} className="orderCards">
                    <div style={{float:'right'}}><ZoomOutMapIcon className="orderInfoBtn" onClick={() => this.props.setOrder(order)}/></div>
                    <div style={{padding:'10px'}}>
                        {this.getCustomerInfo(order)}
                        {this.getOrderItems(order)}
                        {this.getTotalCharge(order)}
                        {this.props.restaurant.delivery_option !== 'DAIDISH_ASSISTED_DELIVERY' ? <Divider /> : null}
                        {this.props.restaurant.delivery_option !== 'DAIDISH_ASSISTED_DELIVERY' ? <Grid style={{padding: '10px 0px'}} container spacing={1}>
                            <Grid item xs={3}>
                                <div className="redTimer"><ScheduleIcon className="timerIcon" style={{fontSize:'18px'}}/><span style={{transform: 'translateY(-2px)', display: 'inline-block'}}>05:04</span></div>
                            </Grid>
                            <Grid item xs={9}>
                                <div className="changeTime" onClick={() => this.showChangeTimeCardAtOutForDelivery(index)}>Change Timing</div>
                            </Grid>
                        </Grid> : null}
                        {this.props.restaurant.delivery_option !== 'DAIDISH_ASSISTED_DELIVERY' ? <div style={{color:"#EA5B2F"}}> 
                            <span style={{fontSize: '12px', padding: '1px 0px'}} className="infoText">{order.data.delivery_tracking_url === '' ? "Delivery Person Details" : "Delivery Tracking URL"}</span>
                        </div> : null}
                        {this.props.restaurant.delivery_option !== 'DAIDISH_ASSISTED_DELIVERY' ? <Grid style={{padding: '2px 0px 10px 0px'}} container spacing={0} padding={0}>
                            <Grid item xs={9}>
                                {order.data.delivery_tracking_url === '' ?  
                                    <span style={{fontSize: '12px', padding: '1px 0px'}} className="infoText">
                                        {order.data.delivery_partner_name_final} ({order.data.delivery_partner_mobile_final})
                                    </span> : <a href={order.data.delivery_tracking_url_final} target="_blank" rel="noopener noreferrer">
                                        <span style={{fontSize: '12px', padding: '1px 0px'}} className="infoText">
                                            {order.data.delivery_tracking_url_final}
                                        </span>
                                    </a>
                                }
                            </Grid>
                            <Grid item xs={3}>
                                <div onClick={() => this.showChangeDeliveryDetailsCardAtOutForDelivery(index)} style={{fontSize: '12px', textAlign: "right"}} className="editBtn"><EditIcon className="timerIcon" style={{fontSize:"18px"}}/><span style={{display: 'inline-block', transform: 'translateY(-2px)'}}> Edit</span></div>
                            </Grid>
                        </Grid> : null}
                        
                        {/* <Grid container spacing={1} style={{paddingTop:'5px'}}>
                            <Grid item xs={12}>
                                <div onClick={() => this.changeOrderStatus(index, 'FULFILLED', {
                                    'delivery_otp': '1234'
                                })} className="orderDeliveredBtn" >ORDER DELIVERED</div>
                            </Grid>
                        </Grid> */}
                    </div>
                    {this.state["changeDeliveryDetailsAtOutForDelivery_"+index] && <div className="rejectCard" style={{paddingTop:'5px', paddingLeft:'10px', paddingRight:'10px'}}>
                        {order.data.delivery_tracking_url_final === '' ? <div style={{color:"#EA5B2F", padding: '10px 0px'}}> 
                            <span style={{fontSize: '12px'}} className="infoText">Edit Delivery Partner Name and Contact</span>
                        </div> : <div style={{color:"#EA5B2F", padding: '10px 0px'}}> 
                            <span style={{fontSize: '12px'}} className="infoText">Edit Delivery Tracking URL</span>
                        </div>}
                        {order.data.delivery_tracking_url_final === '' ? <Grid container spacing={1} >
                            <Grid item xs={12}>
                                <input value={this.state.orders[index].data.delivery_partner_name} onChange={(event) => {
                                    let orders = this.state.orders;
                                    let newOrder = orders[index];
                                    newOrder.data.delivery_partner_name = event.target.value;
                                    orders[index] = newOrder;
                                    this.setState({
                                        orders: orders
                                    })
                                }} type="text" placeholder="Enter Name" className="deliveryPartnerTextBox"/>
                            </Grid>
                            <Grid item xs={12}>
                                <input value={this.state.orders[index].data.delivery_partner_mobile} onChange={(event) => {
                                    let orders = this.state.orders;
                                    let newOrder = orders[index];
                                    newOrder.data.delivery_partner_mobile = event.target.value;
                                    orders[index] = newOrder;
                                    this.setState({
                                        orders: orders
                                    })
                                }} type="text" placeholder="Enter Number" className="deliveryPartnerTextBox"/>
                            </Grid>
                        </Grid> : <Grid container spacing={1} >
                            <Grid item xs={12}>
                                <input value={this.state.orders[index].data.delivery_tracking_url} onChange={(event) => {
                                    let orders = this.state.orders;
                                    let newOrder = orders[index];
                                    newOrder.data.delivery_tracking_url = event.target.value;
                                    orders[index] = newOrder;
                                    this.setState({
                                        orders: orders
                                    })
                                }} type="text" placeholder="Enter tracking URL" className="deliveryPartnerTextBox"/>
                            </Grid>
                        </Grid>}
                        <Grid container spacing={1} style={{position:"absolute", bottom:'5px', paddingRight:"15px"}}>
                            <Grid item xs={6}>
                                <div className="rejectBtn" onClick={() => this.backToOutForDeliveryCard(index)}>Cancel</div>
                            </Grid>
                            <Grid item xs={6}>
                                <div onClick={() => {
                                    if ((this.state.orders[index].data.delivery_partner_name !== '' || this.state.orders[index].data.delivery_partner_mobile !== '') && this.state.orders[index].data.delivery_tracking_url !== ''){
                                        return;
                                    } else {
                                        this.setState({["changeDeliveryDetailsAtOutForDelivery_"+index] : false});
                                        if (this.state.orders[index].data.delivery_partner_name !== '' && this.state.orders[index].data.delivery_partner_mobile !== ''){
                                            this.changeOrderStatus(index, 'ON_THE_WAY', {
                                                'delivery_partner_name': this.state.orders[index].data.delivery_partner_name,
                                                'delivery_partner_mobile': this.state.orders[index].data.delivery_partner_mobile,
                                                'estimated_delivery_time': this.state.orders[index].data.delivery_time
                                            });
                                        } else {
                                            if (this.state.orders[index].data.delivery_tracking_url !== ''){
                                                this.changeOrderStatus(index, 'ON_THE_WAY', {
                                                    'estimated_delivery_time': this.state.orders[index].data.delivery_time,
                                                    'tracking_url': this.state.orders[index].data.delivery_tracking_url
                                                });
                                            }
                                        }
                                    }
                                }} className="submitBtn">Update</div>
                            </Grid>
                        </Grid>    
                    </div>}
                    {this.state["changeTimeAtOutForDelivery_"+index] && <div className="rejectCard">
                        <Grid container spacing={1} style={{paddingTop:'5px', paddingLeft:'10px', paddingRight:'10px'}}>
                            <Grid item xs={12}>
                                <span className="orderLabel1" 
                                style={{top:'-3px', color:"#EA5B2F"}}
                                >Update Time</span>
                            </Grid>
                            <Grid style={{paddingBottom:'5px'}} container spacing={0}>
                                <Grid item xs={4}>
                                    <div onClick={() => this.changeDeliveryTime('decrease', index)} className="leftBtn">-</div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="centerBlock">{order.data.delivery_time} mins</div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div  onClick={() => this.changeDeliveryTime('increase', index)} className="rightBtn">+</div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {this.state["rejectReason_"+index+"_3"] && <textarea type="text" style={{width:'98%'}}className="restoDescTextArea" rows={2} placeholder='Reason for declining order'/>}
                            </Grid>
                            <Grid container spacing={1} style={{position:"absolute", bottom:'5px', paddingRight:"15px"}}>
                                <Grid item xs={6}>
                                    <div className="rejectBtn" onClick={() => this.backToOutForDeliveryCard(index)}>Cancel</div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div onClick={() => {
                                        this.setState({["changeTimeAtOutForDelivery_"+index] : false});
                                        if (this.state.orders[index].data.delivery_partner_name_final !== '' && this.state.orders[index].data.delivery_partner_name_final !== ''){
                                            this.changeOrderStatus(index, 'ON_THE_WAY', {
                                                'delivery_partner_name': this.state.orders[index].data.delivery_partner_name_final,
                                                'delivery_partner_mobile': this.state.orders[index].data.delivery_partner_mobile_final,
                                                'estimated_delivery_time': this.state.orders[index].data.delivery_time
                                            });
                                        } else {
                                            if (this.state.orders[index].data.delivery_tracking_url !== ''){
                                                this.changeOrderStatus(index, 'ON_THE_WAY', {
                                                    'estimated_delivery_time': this.state.orders[index].data.delivery_time,
                                                    'tracking_url': this.state.orders[index].data.delivery_tracking_url_final
                                                });
                                            }
                                        }
                                    }} className="submitBtn">Update</div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>}
                </div>;
            } else {
                return null;
            }
        });
        return <>{orderCards}</>;
    }

    fulfilledOrders(){
        const orderCards = this.state.orders.map((order, index) => {
            if (order.order_status === 'FULFILLED'){
                return <div key={index} className="orderCards">
                    <div style={{float:'right'}}><ZoomOutMapIcon className="orderInfoBtn" onClick={() => this.props.setOrder(order)}/></div>
                    <div style={{padding:'10px'}}>
                        {this.getCustomerInfo(order)}
                        {this.getOrderItems(order)}
                        {this.getTotalCharge(order)}
                        <Divider />
                        <div className="fulfilledText">Successfully Fulfilled!</div>
                    </div>
                </div>;
            } else {
                return null;
            }
        });
        return <>{orderCards}</>;
    }

    OrderCards(){
        return(
            <>
                <Grid container spacing={0} style={{width:"1650px"}}>
                    <div className="menuGridPosition2" style={{width:'320px'}}>
                        <div className="newOrders" style={{background: '#3587F5'}}>
                            <span>New Orders({this.getOrderFilterCount('ORDERED')})</span>
                        </div>
                        {this.newOrders()}
                    </div>
                    <div className="menuGridPosition2" style={{width:'320px'}}>
                        <div className="newOrders" style={{background: '#E69700'}}>
                            <span>Preparing({this.getOrderFilterCount('UNDER_PREPARATION')})</span>
                        </div>
                        {this.preparingOrders()}
                    </div>
                    <div className="menuGridPosition2" style={{width:'320px'}}>
                        <div className="newOrders" style={{background: '#A6D01D'}}>
                            <span>Order Ready({this.getOrderFilterCount('READY_FOR_PICKUP')})</span>
                        </div>
                        {this.packedOrders()}
                    </div>
                    <div className="menuGridPosition2" style={{width:'320px'}}>
                        <div className="newOrders" style={{background: '#72C413'}}>
                            <span>Out for Delivery({this.getOrderFilterCount('ON_THE_WAY')})</span>
                        </div>
                        {this.pickedUpForDeliveryOrders()}
                    </div>
                    <div className="menuGridPosition2" style={{width:'320px'}}>
                        <div className="newOrders" style={{background: '#009828'}}>
                            <span>Order Fulfilled({this.getOrderFilterCount('FULFILLED')})</span>
                        </div>
                        {this.fulfilledOrders()}
                    </div>
                </Grid>
            </>
        );
    }

    handleOnlineSwitch(){
        this.props.handleOnlineSwitch();
    }

    render() {
        // console.log('props display');
        // console.log(this.state.orders);

        return(
        <div>
            <div style={{fontFamily:"Bree-Regular", fontSize:"25px", marginTop: '20px', marginLeft: '20px'}}>Today's Orders</div>
            {(this.props.orders.length === 0 || this.props.orders.length === undefined || this.props.orders.length === null) && <div style={{backgroundColor: '#FAFAFA', padding: '100px 0px', margin: '20px', border: '1px solid #E8E8E8', borderRadius: '5px'}}>
                <img style={{display: 'block', margin: 'auto', width: '200px', borderRadius: '200px'}} src={no_orders} alt="Rating Information 1"/>
                <div style={{fontSize: '20px'}} className="RatingInformationSubHeader">
                    You don't have any orders yet.
                </div>
            </div>}
            
            {this.state.orders.length > 0 && this.OrderCards()}
        </div>);
    }
}
export default OrderScreen;