import React, {Component} from "react";
import { withRouter } from 'react-router';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import PopSound from '../../assets/media/ding.mp3';

class PreparingOrdersCard extends Component {
    constructor(props){
        super(props);
        this.timeRemaining = this.timeRemaining.bind(this);
        this.state = {
            //orderAcceptedAt: "1990-01-01T00:00:00.000000+05:30",
            //preparation_time: 0,
            remainingTime: "--:--",
            audio: new Audio(PopSound),
        }
    }

    componentDidMount() {
        // console.log(this.props.order)
        this.setState({
            orderAcceptedAt : this.props.order.status_update_logs.UNDER_PREPARATION.updated_at,
            preparation_time : this.props.order.status_update_logs.UNDER_PREPARATION.preparation_time
        });
        this.countDownTimer();
    }

    componentDidUpdate() {
        if(this.state.prepOrdersChanged){
            this.setState({
                orderAcceptedAt : this.props.order.status_update_logs.UNDER_PREPARATION.updated_at,
                preparation_time : this.props.order.status_update_logs.UNDER_PREPARATION.preparation_time
            });
            this.countDownTimer();
        }
    }

    countDownTimer(){
        var interval = setInterval(() => {
            if(this.state.remainingTime === "00:00"){
                clearInterval(interval);
            }
            else{
                if(this.state.remainingTime === "5:00"){
                    this.playPause();
                }
                this.setState({
                    remainingTime : this.timeRemaining()
                })
            }
        }, 1000);
    }

    playPause = () => {
        if (this.state.isPlaying) {
          this.state.audio.pause();
        } else {
          this.state.audio.play();
        }
        this.setState({ isPlaying: !this.state.isPlaying });
    }
    
    timeRemaining(){
        var momentTz = require('moment-timezone');
        var date = momentTz.tz(this.props.order.status_update_logs.UNDER_PREPARATION.updated_at, 'Asia/Kolkata').format();

        var a = momentTz.tz(momentTz(), 'Asia/Kolkata').format();
        var b = date;
        var timeDifference = (new Date(a) - new Date(b))/1000;

        var tempCurrDate = a.split("T");
        var tempAcceptedDate = b.split("T");

        // console.log("CurrentDateTime :" + a)
        // console.log("orderAcceptedAt :" + date)
        // console.log((new Date(a) - new Date(b))/1000)

        // console.log("Current Date : " + tempCurrDate[0])
        // console.log("Accepted Date : " + tempAcceptedDate[0])

        if((tempCurrDate[0] === tempAcceptedDate[0]) && (timeDifference < (this.props.order.status_update_logs.UNDER_PREPARATION.preparation_time * 60))){
            var timeLeft = (this.props.order.status_update_logs.UNDER_PREPARATION.preparation_time * 60) - timeDifference;
            var d = Number(timeLeft);
            var m = Math.floor(d / 60);
            var s = ('0' + Math.floor(d % 60)).slice(-2);
            return m + ":" + s; 
        }
        else{
            return "00:00"
        }
    }

    isLessThanFiveMins(){
        var timing = this.state.remainingTime.split(":")
        if(Number(timing[0]) === 0)
            return 0;
        else if(Number(timing[0]) <= 4)
            return 5;
    }

    render() {
        return(
            <div key={this.props.index} className={this.isLessThanFiveMins() === 0 ? "orderCards lapsedCards" : this.isLessThanFiveMins() === 5 ? "orderCards warningCards" : "orderCards"}>
                <div style={{float:'right'}}><ZoomOutMapIcon className="orderInfoBtn" onClick={() => this.props.setOrder(this.props.order)}/></div>
                <div style={{padding:'10px'}}>
                    {this.props.getCustomerInfo(this.props.order)}
                    {this.props.getOrderItems(this.props.order)}
                    {this.props.getTotalCharge(this.props.order)}
                    <Divider />
                    <Grid style={{padding: '10px 0px'}} container spacing={1}>
                        <Grid item xs={3}>
                            <div className="greenTimer"><ScheduleIcon className="timerIcon" style={{fontSize:'18px'}}/><span style={{transform: 'translateY(-2px)', display: 'inline-block'}}>{this.state.remainingTime}</span></div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className="changeTime" onClick={() => this.props.showChangeTimeCard(this.props.index)}>Change Timing</div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{paddingTop:'5px'}}>
                        <Grid item xs={12}>
                            <div onClick={() => this.props.changeOrderStatus(this.props.index, 'READY_FOR_PICKUP', {})} className="orderReadyBtn">ORDER READY</div>
                        </Grid>
                    </Grid>
                </div>
                {this.props.changeTime_index && <div className="rejectCard">
                    <Grid container spacing={1} style={{paddingTop:'5px', paddingLeft:'10px', paddingRight:'10px'}}>
                        <Grid item xs={12}>
                            <span className="orderLabel1" 
                            style={{top:'-3px', color:"#EA5B2F"}}
                            >Update Preparation Time</span>
                        </Grid>
                        <Grid style={{paddingBottom:'5px'}} container spacing={0}>
                            <Grid item xs={4}>
                                <div onClick={() => this.props.changeFoodPreparationTime('decrease', this.props.index, "PREPARING_ORDERS")} className="leftBtn">-</div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className="centerBlock">{this.props.order.status_update_logs.UNDER_PREPARATION.preparation_time} mins</div>
                            </Grid>
                            <Grid item xs={4}>
                                <div onClick={() => this.props.changeFoodPreparationTime('increase', this.props.index, "PREPARING_ORDERS")} className="rightBtn">+</div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{position:"absolute", bottom:'5px', paddingRight:"15px"}}>
                            <Grid item xs={6}>
                                <div className="rejectBtn" onClick={() => this.props.backToPreparingCard(this.props.index)}>Cancel</div>
                            </Grid>
                            <Grid item xs={6}>
                                <div onClick={() => {
                                    this.props.changeOrderStatus(
                                        this.props.index, 'UNDER_PREPARATION', {'preparation_time': this.props.order.status_update_logs.UNDER_PREPARATION.preparation_time});
                                        this.setState({preparation_time : this.props.order.status_update_logs.UNDER_PREPARATION.preparation_time});
                                }}
                                    className="submitBtn">Update</div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>}
            </div>
        )
    }
}

export default withRouter(PreparingOrdersCard);