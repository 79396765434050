import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './pages/Login';
import RestoApp from './pages/RestoApp';
import AddRestaurantCover from './pages/AddRestaurantCover';
import VerifyOtp from './pages/VerifyOtp';
import AddDishImage from './pages/AddDishImage';
import HttpsRedirect from 'react-https-redirect';
import ErrorPage from './pages/ErrorPage';

function App() {
  return (
    <HttpsRedirect>
      <div>
        <BrowserRouter>
          <div>
            <Switch>
              <Route exact path='/' component={Login}/>
              <Route exact path='/restoApplication/' component={RestoApp}/>
              <Route exact path='/addRestaurantCover/' component={AddRestaurantCover}/>
              <Route exact path='/addDishImage/:dish_id' component={AddDishImage}/>
              <Route exact path='/verifyOtp/:daidish_slug-:order_id1-:order_id2' component={VerifyOtp}/>
              <Route exact path='/errorPage' component={ErrorPage}/>
              <Route component={ErrorPage}/>
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    </HttpsRedirect>
  );
}

export default App;
