import React, { Component, createRef } from 'react';
import { Grid } from '@material-ui/core';
import home_burger from '../../assets/images/partner_with_us_illustration.png';
import '../../assets/styles/Home.css';
import { withRouter } from 'react-router';
import logo from '../../assets/images/logo_white.png';
import logo_mobile from '../../assets/images/logo_mobile.png';
import '../../assets/styles/PartnerWithUs.css';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone'
import "croppie/croppie.css";
import Croppie from "croppie";
import ArrowBack from '@material-ui/icons/ArrowBack';
import axios from 'axios';
import CONSTANTS from '../../config/constants';

// Top Header which contains logo, auth options, burger icon and header description
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
        this.toggleRestaurantCardPreviewHandler = this.toggleRestaurantCardPreviewHandler.bind(this);
    }

    toggleRestaurantCardPreviewHandler(restaurant){
        this.props.toggleRestaurantCardPreview(restaurant);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    getMobileComponents(isMobileScreen, headerStyle){
        if (isMobileScreen){
            return (
                <div style={headerStyle} className="Header">
                    {/* <div style={{position: "fixed", top: "0px", left: "0px", zIndex: "10000", width: "100vw"}}> */}
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <Logo/>
                        </div>
                        <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                            <BurgerImage/>
                        </Grid>
                    {/* </div> */}
                    {/* <div style={{marginTop: "84px"}}> */}
                    {/* </div>   */}
                </div> 
            )
        } else {
            return (
                <div style={headerStyle} className="Header">
                    {/* <div style={{position: "fixed", top: "0px", left: "0px", zIndex: "10000", width: "100vw"}}> */}
                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="stretch">
                            <Logo/>
                        </Grid>
                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="stretch">
                            <BurgerImage/>
                            <ContactForm
                                authModalOpen={this.props.authModalOpen}
                                history={this.props.history}
                                toggleRestaurantCardPreview={this.toggleRestaurantCardPreviewHandler}
                                restaurant={this.props.restaurant}
                            />
                        </Grid> 
                    {/* </div> */}
                    {/* <div style={{marginTop: "84px"}}> */}
                    {/* </div>   */}
                </div> 
            )
        }
    }

   render() {
    var headerStyle = {};
    if (this.state.isMobileScreen){
        headerStyle = {background: "linear-gradient(60deg, #FFFFFF 50%, #F9E841 50%)"};
    } else {
        headerStyle = {background: "linear-gradient(100deg, #F9E841 40%, #FFFFFF 40%)", height: "100vh"};
    }
    return (
        this.getMobileComponents(this.state.isMobileScreen, headerStyle)
    )
   }
}

// Website Logo
class Logo extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        
        if (this.state.isMobileScreen){
            return (
                <div className="Logo LogoHome">
                    <Link  to={'/'} style={{ textDecoration: 'none' }}>
                        <img style={{cursor: "pointer", marginTop: "10px", marginLeft: "5px"}} src={logo_mobile} alt="" width={40} height={40}/>  
                    </Link>
                </div>
            )
        } else {
            return (
                <Grid container item xs={4}>
                    <React.Fragment>
                        <Grid item xs={12}>
                            <div className="Logo LogoHome">
                                <Link  to={'/'} style={{ textDecoration: 'none' }}>
                                    <img style={{marginTop: "10px"}} className="LogoImage" src={logo} alt="logo"/>  
                                </Link>
                            </div>
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }

    }
}

// Burger Image in the Header
class BurgerImage extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        var burgerContainerStyle = {};
        var burgerStyle = {};
        if (this.state.isMobileScreen){
            burgerContainerStyle = {paddingTop: "0px", paddingBottom: "20px", transform: "translateX(-5vw)"};
            burgerStyle = {width: '70vw', transform: 'translateX(-5vw)'};
        } else {
            burgerContainerStyle = {paddingTop: "20px", paddingBottom: "20px", paddingLeft: "0vw", transform: "translateY(-2vh)"};
            burgerStyle = {marginLeft: "0vw", width: "36vw"}
        }
        return (
            <Grid style={burgerContainerStyle} className="PartnerWithUsLogoContainer" container item direction="column" justify="center" alignItems="center" xs={6}>
                <img style={burgerStyle} className="Burger" src={home_burger} alt="burger_img"/>
            </Grid>
        )
    }
}

// Description present in header
class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.restaurantImageRef = createRef(null);
        this.state = {
            restaurantImage: null,
            restaurantImageError: "",
            currentRestaurantUploadStep: 1,
            restaurantImageName: "",
            imageUploading: false
        };
        this.onFileInputChange = this.onFileInputChange.bind(this);
        this.handleBrowseFileClick = this.handleBrowseFileClick.bind(this);
        this.cropImage = this.cropImage.bind(this);
        this.handleCroppedImage = this.handleCroppedImage.bind(this);
        this.handleImageUpload = this.handleImageUpload.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    handleChange = address => {
        // console.log(address);
        var lenDiff = address.length - this.state.address.length;
        // console.log(lenDiff < -1);
        if (lenDiff < -1){
            this.setState({
                clearUserSuggestions: true
            })
        } else {
            this.setState({
                clearUserSuggestions: false
            })
        }
        this.setState({
            address: address,
            shortAddress: "",
            latitude: 0.0,
            longitude: 0.0
        })
    };

    setMobileScreen(type){
        this.setState({
            isMobileScreen: type
        })
    }

    contactFormHeader(){
        return(
            <div style={{display: "flex", flexDirection: "row"}} className="PartnerWithUsHeader">
                <div onClick={() => window.history.back()} className="PartnerWithUsBackContainer"><ArrowBack className="PartnerWithUsBackIcon"/> Back</div>
                <div style={{flex: "1", transform: "translateX(-45px)"}}>Upload <span style={{color: "#EB622F"}}>Images</span></div>
            </div>
        )
    }
           

    onFileInputChange(event, type){
        if (!this.state.contactDetailsSubmitting){
            var file;
            if (type === "select"){
                if (event.target.files === null){
                    return;
                } else {
                    file = event.target.files[0];
                }
            } else {
                if (event === null){
                    return;
                } else {
                    file = event[0];
                }
            }
            this.setState({
                currentRestaurantUploadStep: 2,
                restaurantImageName: file.name
            }, () => {
                window.restaurant_image = new Croppie(document.getElementById("output_restaurant_image"), {
                    enableExif: true,
                    showZoomer : false,
                    url : 'DSCN3409.JPG',
                    viewport: {
                        width: 600,
                        height: 170,
                        type: 'square'
                    },
                    boundary: {}
                });
            })
            const reader = new FileReader();
            reader.onload = function(){
                const output = document.getElementById('output_restaurant_image');
                output.src = reader.result;
                window.restaurant_image.bind({ url: reader.result });
                // console.log('output');
                // console.log(output);
            }
            reader.readAsDataURL(file);
        }
    }

    handleCroppedImage(resp, type){
        this.setState({
            restaurantImage: resp
        })
        if (type === "upload"){
            this.setState({
                currentRestaurantUploadStep: 3
            })
        } else {
            this.props.toggleRestaurantCardPreview({
                id: 1,
                name: this.props.restaurant.name,
                address: this.props.restaurant.address,
                profile_image_url: resp,
                logo_url: this.props.restaurant.logo_url,
                description: this.props.restaurant.description
            });
        }
    }

    cropImage(type) {
        const resImgWidth = 1200; /*Output Image Width*/
            const resImgHeight = 340;
            window.restaurant_image.result({
            type: 'base64',
            size: {
                width: resImgWidth,
                height: resImgHeight
            },
            format : 'png',
            quality : 0
            }).then((resp) => this.handleCroppedImage(resp, type));
    }

    getUploadImageHeader(){
        if (this.state.currentRestaurantUploadStep !== 3) {
            return (
                <div className="UploadImageHeader">Upload Your <span style={{color: "#EB622F"}}>Restaurant Banner</span> Image here</div>
            )
        } else {
            return (
                <div className="UploadImageHeader UploadImageSuccessHeader">Image Successfully Uploaded!</div>
            )
        }
    }

    uploadImagesForm(){
        return (
            <div className="PartnerWithUsFieldSuperContainer">
                {this.getUploadImageHeader()}
                {this.decideRestaurantUploadForm()}
            </div>
        )
    }

    handleBrowseFileClick(event){
        event.stopPropagation();
        if (!this.state.contactDetailsSubmitting){
            this.restaurantImageRef.current.click();
        }
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    handleImageUpload() {
        if (!this.state.imageUploading){
            this.setState({
                imageUploading: true
            });
            var form_data = new FormData();
            form_data.append('profile', this.dataURLtoFile(this.state.restaurantImage, this.state.restaurantImageName), this.state.restaurantImageName);
            let url = CONSTANTS.hostURL + "api/resto-app/restaurant/update-image/";
            let headers = {
                'Content-Type' : 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            };
            axios.post(url, form_data, {
                headers: headers
            }).then(res => {
                var data = res.data;
                if (data.status === 1){
                    window.history.back();
                } else {
                    if (res.status === 401){
                        let url = CONSTANTS.hostURL + "api/resto-app/login/refresh-session/";
                        let refreshReq = {
                          'refresh' : localStorage.getItem('refresh_token')
                        }
                        axios.post(url, refreshReq,  {
                          headers: {}
                        }).then((response) => {
                          if (response.data.status === 1){
                            localStorage.setItem('access_token', response.data.data.access);
                            localStorage.setItem('refresh_token', response.data.data.refresh);
                            this.handleImageUpload();
                          } else {
                            localStorage.removeItem("restaurant_id");
                            localStorage.removeItem("restaurant_details");
                            localStorage.removeItem("access_token");
                            localStorage.removeItem("refresh_token");
                            this.props.history.replace("/");
                          }
                        }).catch(error => {
                            this.setState({
                                imageUploading: false
                            });
                            localStorage.removeItem("restaurant_id");
                            localStorage.removeItem("restaurant_details");
                            localStorage.removeItem("access_token");
                            localStorage.removeItem("refresh_token");
                            this.props.history.replace("/");
                        });
                    } else {
                        this.setState({
                            imageUploading: false
                        })
                    }
                }
            }).catch((err) => {
                if (err.response){
                    if (err.response.status === 401){
                        let url = CONSTANTS.hostURL + "api/resto-app/login/refresh-session/";
                        let refreshReq = {
                          'refresh' : localStorage.getItem('refresh_token')
                        }
                        axios.post(url, refreshReq,  {
                          headers: {}
                        }).then((response) => {
                          if (response.data.status === 1){
                            localStorage.setItem('access_token', response.data.data.access);
                            localStorage.setItem('refresh_token', response.data.data.refresh);
                            this.handleImageUpload();
                          } else {
                            localStorage.removeItem("restaurant_id");
                            localStorage.removeItem("restaurant_details");
                            localStorage.removeItem("access_token");
                            localStorage.removeItem("refresh_token");
                            this.props.history.replace("/");
                          }
                        }).catch(error => {
                            this.setState({
                                imageUploading: false
                            });
                            localStorage.removeItem("restaurant_id");
                            localStorage.removeItem("restaurant_details");
                            localStorage.removeItem("access_token");
                            localStorage.removeItem("refresh_token");
                            this.props.history.replace("/");
                        });
                    } else {
                        this.setState({
                            imageUploading: false
                        })
                    }
                }
            });
        }
    }

    decideRestaurantUploadForm(){
        if (this.state.currentRestaurantUploadStep === 1){
            return(
                <div>
                    <div onClick={(event) => this.handleBrowseFileClick(event)} style={{cursor: "pointer"}} className="UploadRestaurantImageFormContainer">
                        <Dropzone onDrop={files => this.onFileInputChange(files, "drop")}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div style={{fontSize: "13px", fontFamily: "Poppins-Regular", textAlign: "center"}}>Drag & Drop your file here</div>
                                    <div onClick={(event) => this.handleBrowseFileClick(event)} style={{fontSize: "13px", fontFamily: "Poppins-Regular", margin: "5px 0px", textAlign: "center"}}>Or</div>
                                    <div onClick={(event) => this.handleBrowseFileClick(event)} style={{textAlign: "center", marginTop: "20px"}}>
                                        <span onClick={(event) => this.handleBrowseFileClick(event)} style={{padding: "8px 20px", fontSize: "13px", fontFamily: "Poppins-Regular", borderRadius: "5px", color: "white", backgroundColor: "#2B2624", cursor: "pointer"}}>Browse Files</span>
                                    </div>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        <input onChange={(event) => this.onFileInputChange(event, "select")} ref={this.restaurantImageRef} style={{display: "none"}} type="file" className="PartnerWithUsField" accept="image/png, image/gif, image/jpeg"/>
                    </div>
                    {this.state.contactDetailsSubmitting ? <div onClick={() => this.handleContactDetailsSubmit()} className="PartnerWithUsSubmitButtonContainer">
                    <div className="AuthFormSubmitButton">{this.state.contactDetailsSubmitting ? "Proceeding, please wait.." : "Proceed"}</div>
                </div> : <div style={{margin: "15px", marginTop: "30px", textDecoration: "underline", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center", color: "#3356AA", cursor: "pointer"}} onClick={() => this.handleContactDetailsSubmit()}></div>}
                </div>
            )
        } else if (this.state.currentRestaurantUploadStep === 2) {
            return (
                <div>
                    <div className="UploadImageFormContainer UploadRestaurantImageFormCropContainer">
                        <div id="output_restaurant_image"></div>
                    </div>
                    <div style={{margin: "6px auto", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center"}}>Drag the image to the portion you need</div>
                    <div style={{textAlign: "center"}}>
                        <span onClick={() => this.cropImage("preview")} style={{fontSize: "12px", fontFamily: "Poppins-Regular", color: "#EA5B30", borderBottom: "1px solid #EA5B30", cursor: "pointer"}}>Preview</span>
                    </div>
                    <div style={{textAlign: "center", marginTop: "20px"}}>
                        <div onClick={() => this.cropImage("upload")} style={{display: "inline-block", padding: "6px 30px", borderRadius: "5px", fontSize: "12px", fontFamily: "Poppins-Regular", backgroundColor: "#F8D542", cursor: "pointer"}}>
                            Upload
                        </div>
                    </div>
                    <div style={{margin: "15px", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center", color: "#3356AA", cursor: "pointer"}} onClick={() => this.setState({currentRestaurantUploadStep: 1, restaurantImage: null, restaurantImageName: ""})}>Re-Upload</div>
                </div>
            )
        }  else {
            return (
                <div>
                    <div className="RestaurantPreviewImageContainer">
                        <img style={{width: "600px", height: "170px"}} src={this.state.restaurantImage} alt="Restaurant Cover"/>
                    </div>
                    <div style={{margin: "15px", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center", color: "#3356AA", cursor: "pointer"}} onClick={() => this.setState({currentRestaurantUploadStep: 1, restaurantImage: null, restaurantImageName: ""})}>Re-Upload</div>
                    <div onClick={() => this.handleImageUpload()} className="PartnerWithUsSubmitButtonContainer">
                        <div className="AuthFormSubmitButton">{this.state.imageUploading ? "Saving & Proceeding.." : "Save & Proceed"}</div>
                    </div>
                </div>
            )
        }
    }

    render(){
        // console.log(this.state.isMobileScreen);
        if (!this.state.isMobileScreen) {
            return (
                <Grid container item xs={6} direction="column" justify="center" style={{height: "95vh", transform: "translateY(-5vh)"}}>
                    <div className="PartnerWithUsFormContainer">
                        {this.contactFormHeader()}
                        {this.uploadImagesForm()}
                    </div>
                </Grid>
            )
        } else {
            return null;
        }
    }
}

export default withRouter(Header);