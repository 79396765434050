import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import axios from 'axios';
import CONSTANTS from '../../config/constants';
import SampleImage from '../../assets/images/no-photo-available.png';
import NoDishes from '../../assets/images/no_dishes.svg';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// import VisibilityOnIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBack from '@material-ui/icons/ArrowBack';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
import { withRouter } from 'react-router';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { IoTriangleSharp } from 'react-icons/io5';
import Autosuggest from 'react-autosuggest';
import '../../assets/styles/ManageMenu.css';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
// import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Divider from '@material-ui/core/Divider';
import { RiEdit2Line } from "react-icons/ri";
import { RiSaveFill } from "react-icons/ri";
import { MdOutlineCancel } from "react-icons/md";
import BestDish from './BestDish';
import { Star } from '@material-ui/icons';
import NoBestDish1 from '../../assets/images/noBestDish1.png';
import NoBestDish2 from '../../assets/images/noBestDish2.png';
import PopularTag from '../../assets/images/PopularDishStar.png';
import BestDishTag from '../../assets/images/bestDishStar.png';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class ManageMenu extends Component {
    constructor(props){
      super();
      this.toggleToEditBestDish = this.toggleToEditBestDish.bind(this);
      this.toggleToEditMenu = this.toggleToEditMenu.bind(this);
      this.toggleEditSubMenusDishesSection = this.toggleEditSubMenusDishesSection.bind(this);
      this.toggleAddDishSection = this.toggleAddDishSection.bind(this);
      this.toggleInStock = this.toggleInStock.bind(this);
      this.toggleDishInStock = this.toggleDishInStock.bind(this);
      this.addNewSubMenu = this.addNewSubMenu.bind(this);
      this.closeNewSubMenu = this.closeNewSubMenu.bind(this);
      this.handleDishTypeChange = this.handleDishTypeChange.bind(this);
      this.handleBestDishTypeChange = this.handleBestDishTypeChange.bind(this);
      this.handlebestDish1AddOnSwitchChange = this.handlebestDish1AddOnSwitchChange.bind(this);
      this.handleEditDishAddOnSwitchChange = this.handleEditDishAddOnSwitchChange.bind(this);
      this.handleAddDishAddOnSwitchChange = this.handleAddDishAddOnSwitchChange.bind(this);
      this.handleEditBestDish = this.handleEditBestDish.bind(this);
      this.handleAddCustomisation = this.handleAddCustomisation.bind(this);
      this.state = {
        authModalOpen: false,
        activeForm: "",
        isMobileScreen: false,
        descriptionOverlayStyle: {display: 'none'},
        expandedMenu: true,
        leftMenuWidth: 2,
        rightMenuWidth: 10,
        menuArrow: "buttonFlip",
        dishOrMenu: "bestDish",
        currentSubMenuDish: null,
        editDishName: null,
        inStock1: "NAVL",
        inStock2: "NAVL",
        newSubMenu: false,
        restaurant_id: null,
        subMenuAvlability: [],
        selectedDishType: "",
        currentDishName: "",
        currentDishChoice: "",
        currentDishCost: "",
        currentDishIsBestDish: false,
        currentDishPackaging: "",
        currentDishDescription: "",
        currentDishProfilePic: null,
        currentDishIsPopular: false,
        currentDishIsPopular_flag: false,
        currentDishInStock: false,
        selectedSubMenuId: -1,
        subMenuTextValue: '',
        subMenuSuggestions: [],
        subMenuSelected: false,
        expanded : false,
        editDishSaveText: "Save & Proceed",
        showPopup: false,
        popupType: "",
        confirmQuestion: "",
        sub_menu_dishes: [],
        subMenuDishesLoaded: false,
        currentCustomisationIndex: -1,

        bestDish1CustomisationVisibility: false,
        bestDish1CustomList: [],
        bestDish1CustomLabel: "",
        addCustomLabelTextBoxBD1: false,

        editDishCustomisationVisibility: false,
        editDishCustomList: [],

        addDishCustomisationVisibility: false,
        addDishCustomList: [],
        addCustomLabelTextBoxAD: false,
        addCustomLabelTextBoxED: false,
        currentSubMenuId: -1,

        editBestDish1AddOnsSwitchStyle : {
          background : "#DEE8FF",
          color: "#425FA1",
          left: "0px",
          boxShadow: "-2px 0px 2px 0.5px #aaaaaa",
          paddingRight: "12px",
          paddingLeft: "12px",
        },
        editBestDish1AddOnsSwitch: false,

        editDishAddOnsSwitchStyle : {
          background : "#DEE8FF",
          color: "#425FA1",
          left: "0px",
          boxShadow: "-2px 0px 2px 0.5px #aaaaaa",
          paddingRight: "12px",
          paddingLeft: "12px",
        },
        editDishAddOnsSwitch: false,
        currentDishId: -1,
        currentDishIndex: -1,

        addDishAddOnsSwitchStyle : {
          background : "#DEE8FF",
          color: "#425FA1",
          left: "0px",
          boxShadow: "-2px 0px 2px 0.5px #aaaaaa",
          paddingRight: "12px",
          paddingLeft: "12px",
        },
        addDishAddOnsSwitch: false,
        bestDishIndex: -1,
      }
    }
  
    componentDidMount() {
      window.scrollTo(0, 0);
      this.updateDimensions();
      window.addEventListener("resize", this.updateDimensions.bind(this));
      this.setState({
        restaurant_id: this.props.restaurant_id
      });   
      if (localStorage.getItem('dish_image_id') !== null){
        this.setState({
          currentSubMenuId: localStorage.getItem('current_sub_menu_id'),
          editDishName: localStorage.getItem('dish_name')
        }, () => {
          this.toggleEditDishesSection(localStorage.getItem('dish_name'), localStorage.getItem('dish_image_id'));
          if (localStorage.getItem('current_menu')!== 'editBestDish'){
            this.setState({
              currentSubMenuDish: JSON.parse(localStorage.getItem('current_sub_menu'))
            }, () => {
              this.getRestaurantDishes(this.state.currentSubMenuDish.id, 1);
            })
          }
          this.setState({
            dishOrMenu: localStorage.getItem('current_menu')
          })
          localStorage.removeItem('dish_image_id');
          localStorage.removeItem('current_menu');
          localStorage.removeItem('current_sub_menu_id');
          localStorage.removeItem('dish_name');
          localStorage.removeItem('current_sub_menu')
        })
      }   
    }
  
    updateDimensions() {
      let screen_width = window.innerWidth;
      if(screen_width < 500) {
          this.setState({ isMobileScreen: true });
      } else {
          this.setState({ isMobileScreen: false });
      }
    }

    toggleToEditBestDish(){
      this.setState({ dishOrMenu : "bestDish", currentDishId: -1, currentDishIndex: -1});
    }
    toggleToEditMenu(){
      this.setState({ dishOrMenu : "subMenus"});
    }

    handleCheckBoxChange = (event) => {
      this.setState({ [event.target.name] : event.target.checked});
    }

    handleCheckBoxChangePopDish = (event) => {
      if (this.state.currentDishProfilePic === null && event.target.checked){
        this.props.informationBox("Popular dishes will be shown in the restaurant page of Daidish Website. Please add an image first.");
        return;
      }
      this.setState({currentDishIsPopular : event.target.checked})
    }

    toggleEditSubMenusDishesSection(currentSubMenuDish){
      if(this.state.dishOrMenu === "subMenus")
        this.setState({
          dishOrMenu : "dishList",
          subMenuDishesLoaded: false,
          currentSubMenuDish : currentSubMenuDish,
        });
      else
        this.setState({ 
          dishOrMenu : "subMenus",
          currentSubMenuDish : null,
        });
    }

    toggleAddDishSection(){
      this.setState({
        dishOrMenu : "addDish",
        currentDishId: -1,
        currentDishIndex: -1,
        currentDishName: "",
        currentDishChoice: "",
        currentDishCost: "",
        currentDishIsBestDish: false,
        currentDishPackaging: "",
        currentDishDescription: "",
        currentDishProfilePic: null,
        currentDishIsPopular: false,
        currentDishInStock: false
      });
    }

    sortObjectOfObjects(data, attr) {
      var arr = [];
      for (var prop in data) {
          if (data.hasOwnProperty(prop)) {
              var obj = {};
              obj[prop] = data[prop];
              obj[prop].key = prop;
              if (data[prop].hasOwnProperty(attr)){
                  obj.tempSortName = data[prop][attr];
              } else {
                  obj.tempSortName = 0;
              }
              arr.push(obj);
          }
      }
  
      arr.sort(function(a, b) {
          var at = a.tempSortName,
              bt = b.tempSortName;
          return at < bt ? 1 : ( at > bt ? -1 : 0 );
      });
  
      var result = [];
      for (var i=0, l=arr.length; i<l; i++) {
          obj = arr[i];
          delete obj.tempSortName;
          for (var props in obj) {
              if (obj.hasOwnProperty(props)) {
                  var id = props;
              }
          }
          var item = obj[id];
          result.push(item);
      }
      return result;
    }

    updateSubMenuAvailability(){
      let req = {};
      let url = CONSTANTS.hostURL + "api/resto-app/sub-menu/" + this.state.currentSubMenuDish.id + "/availability";
      let headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
      let sub_menu_dishes = this.state.sub_menu_dishes;
      axios.post(url, req,  {
        headers: headers
      }).then((response) => {
        if (response.data.status === 1){
          for (var i=0; i<sub_menu_dishes.length; i++){
            sub_menu_dishes[i].in_stock = true;
          }
          this.setState({
            sub_menu_dishes: sub_menu_dishes
          });
        }
      }).catch((err) => {
        console.log(err);
      });
    }

    toggleEditDishesSection(dishName, dishId){
      if(this.state.dishOrMenu === "dishList" || this.state.dishOrMenu === "bestDish") {
        this.setState({
          dishOrMenu : this.state.dishOrMenu === "dishList" ? "editDish" : "editBestDish",
          currentDishId: dishId,
          editDishName : dishName,
          currentDishName: dishName
        });
        let urlForDishDetail = CONSTANTS.hostURL + "api/resto-app/dishes/" + dishId + "/";
        let headers = {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
        axios.get(urlForDishDetail, {
          headers: headers
        }).then(res => {
          if (res.data.status === 1){
            this.setState({
              currentDishChoice: res.data.data.choice,
              currentDishCost: res.data.data.cost,
              currentDishIsBestDish: res.data.data.is_best_dish,
              currentDishPackaging: res.data.data.packaging_charge,
              currentDishDescription: res.data.data.description?.length??0 > (res.data.data.is_best_dish ? 225 : 225) ? res.data.data.description.substring(0,(res.data.data.is_best_dish ? 225 : 225)) : res.data.data.description,
              currentDishInStock: res.data.data.in_stock,
              currentDishProfilePic: (res.data.data.profile_image_url === null || res.data.data.profile_image_url === "") ? null : res.data.data.profile_image_url,
              currentDishIsPopular: res.data.data.is_popular,
              currentDishIsPopular_flag: res.data.data.is_popular
            });
            if (res.data.data.customizations === null && res.data.data.add_ons === null) {
              this.setState({
                editDishAddOnsSwitch: false,
                editDishCustomList: [],
                editDishCustomisationVisibility : false,
                editDishAddOnsSwitchStyle : {
                  background : "#DEE8FF",
                  color: "#425FA1",
                  left: "0px",
                  boxShadow: "-2px 0px 2px 0.5px #aaaaaa",
                  paddingRight: "12px",
                  paddingLeft: "12px",
                }
              });
            } else {
              let customizations = [];
              if (res.data.data.customizations !== null){
                let customisationDataList = this.sortObjectOfObjects(res.data.data.customizations, 'sort_order');
                for (var i=0; i< customisationDataList.length; i++){
                  let customization_values = [];
                  let default_values = [];
                  let default_codes = [];
                  let customisation = customisationDataList[i];
                  if (customisation['default'] !== '' && customisation['default'] !== null && customisation['default'] !== undefined){
                    default_codes = customisation['default'].split(',');
                  }
                  let modifications = this.sortObjectOfObjects(customisation.modifications, 'sort_order');
                  for (var j=0; j< modifications.length; j++){
                    let modification = modifications[j];
                    var isDefault = false;
                    if (default_codes.includes(modification['key'])){
                      isDefault = true;
                      default_values.push(customisation.modifications[modification['key']]['name']);
                    }
                    customization_values.push({
                      'name': modification['name'],
                      'price': modification['unit_price'],
                      'type':  modification['type'],
                      'unique_code': modification['key'],
                      'is_default': isDefault
                    });
                  }
                  customizations.push({
                    customisationName : customisation['name'],
                    customisationMinSelections: customisation['min_selections'],
                    customisationMaxSelections: customisation['max_selections'],
                    customisationDefault: default_values.join(','),
                    customisationCode: customisation['key'],
                    customisationValues : customization_values
                  });
                }
              }
              this.setState({
                editDishCustomList: customizations,
                editDishAddOnsSwitch: true,
                editDishCustomisationVisibility : true,
                editDishAddOnsSwitchStyle : {
                  background : "#425FA1",
                  color: "#DEE8FF",
                  left: "27px",
                  boxShadow: "2px 0px 2px 0.5px #aaaaaa",
                  paddingRight: "12px",
                  paddingLeft: "12px",
                }
              });
            }
          }
        });
      } else {
        if (this.state.currentDishIsPopular && this.state.currentDishProfilePic === null){
          this.props.informationBox("Popular dishes will be shown in the restaurant page of Daidish Website. Please add an image.");
          return;
        }
        this.setState({ 
          dishOrMenu : this.state.dishOrMenu === "editBestDish" ? "bestDish" : "dishList",
          editDishName : null,
          currentDishId: -1,
          currentDishIndex: -1,
          currentDishName: "",
          currentDishChoice: "",
          currentDishCost: "",
          currentDishIsBestDish: false,
          currentDishPackaging: "",
          currentDishDescription: "",
          currentDishProfilePic: null,
          currentDishIsPopular: false,
          currentDishIsPopular_flag: false,
          currentDishInStock: false,
          bestDishIndex : -1
        });
      }
    }

    copyExistingDish(dishId){
      this.setState({
        dishOrMenu : "addDish",
        currentDishId: -1,
        currentDishIndex: -1,
      })
      let urlForDishDetail = CONSTANTS.hostURL + "api/resto-app/dishes/" + dishId + "/";
      let headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
      axios.get(urlForDishDetail, {
        headers: headers
      }).then(res => {
        if (res.data.status === 1){
          this.setState({
            currentDishName: "",
            currentDishChoice: res.data.data.choice,
            currentDishCost: res.data.data.cost,
            currentDishPackaging: res.data.data.packaging_charge,
            currentDishDescription: res.data.data.description?.length??0 > 225 ? res.data.data.description.substring(0,225) : res.data.data.description,
            currentDishInStock: false
          });
        }
      });
    }

    toggleInStock(switchName){
      if(this.state[switchName] === "AVL")
        this.setState({[switchName] : "NAVL"});
      else
        this.setState({[switchName] : "AVL"});
    }

    toggleDishInStock(dish_id, in_stock, type){
      let req = {};
      if (in_stock === true){
        req = {"in_stock": false};
      } else {
        req = {"in_stock": true};
      }
      let url = CONSTANTS.hostURL + "api/resto-app/dishes/" + dish_id + "/availability";
      let headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
      let sub_menu_dishes = this.state.sub_menu_dishes;
      let dish_index = -1;
      for (var i=0; i<sub_menu_dishes.length; i++){
        if (sub_menu_dishes[i].id === dish_id){
          dish_index = i;
        }
      }
      if (dish_index !== -1){
        sub_menu_dishes[dish_index].in_stock = req.in_stock;
      }
      if (type === "edit_dish"){
        this.setState({
          currentDishInStock: !in_stock
        });
      }
      this.setState({
        sub_menu_dishes: sub_menu_dishes
      });
      let bestDishIndex = -1;
      let bestDishes = this.props.bestDishes;
      for (var j=0; j<this.props.bestDishes.length; j++){
        if (this.props.bestDishes[j].id === dish_id){
          bestDishIndex = j;
        }
      }
      if (bestDishIndex !== -1){
        bestDishes[bestDishIndex].in_stock = req.in_stock;
        this.props.updateBestDishDetails(bestDishes);
      }
      axios.post(url, req,  {
        headers: headers
      }).then((response) => {
        if (response.data.status !== 1){
          if (dish_index !== -1){
            sub_menu_dishes[dish_index].in_stock = !req.in_stock;
          }
          if (bestDishIndex !== -1){
            bestDishes[bestDishIndex].in_stock = !req.in_stock;
            this.props.updateBestDishDetails(bestDishes);
          }
          if (type === "edit_dish"){
            this.setState({
              currentDishInStock: in_stock
            });
          }
          this.setState({
            sub_menu_dishes: sub_menu_dishes
          });
        }
      }).catch((err) => {
        if (dish_index !== -1){
          sub_menu_dishes[dish_index].in_stock = !req.in_stock;
        }
        if (type === "edit_dish"){
          this.setState({
            currentDishInStock: in_stock
          });
        }
        this.setState({
          sub_menu_dishes: sub_menu_dishes
        });
      });
    }

    handleBestDishTypeChange = (event) => {
      this.setState({selectedDishType : event.target.value});
    };

    handleDishTypeChange = (event) => {
      this.setState({currentDishChoice : event.target.value});
    };

    handlebestDish1AddOnSwitchChange(){
      if(this.state.editBestDish1AddOnsSwitch === false){
        this.setState({editBestDish1AddOnsSwitch: true});
        this.setState({editBestDish1AddOnsSwitchStyle : {
          background : "#425FA1",
          color: "#DEE8FF",
          left: "27px",
          boxShadow: "2px 0px 2px 0.5px #aaaaaa",
          paddingRight: "12px",
          paddingLeft: "12px",
        }});
      }
      else{
        this.setState({editBestDish1AddOnsSwitch: false});
        this.setState({editBestDish1AddOnsSwitchStyle : {
          background : "#DEE8FF",
          color: "#425FA1",
          left: "0px",
          boxShadow: "-2px 0px 2px 0.5px #aaaaaa",
          paddingRight: "12px",
          paddingLeft: "12px",
        }});
      }
    }

    handleEditDishAddOnSwitchChange(){
      if(this.state.editDishAddOnsSwitch === false){
        this.setState({editDishAddOnsSwitch: true});
        this.setState({editDishAddOnsSwitchStyle : {
          background : "#425FA1",
          color: "#DEE8FF",
          left: "27px",
          boxShadow: "2px 0px 2px 0.5px #aaaaaa",
          paddingRight: "12px",
          paddingLeft: "12px",
        }});
      }
      else{
        this.setState({editDishAddOnsSwitch: false});
        this.setState({editDishAddOnsSwitchStyle : {
          background : "#DEE8FF",
          color: "#425FA1",
          left: "0px",
          boxShadow: "-2px 0px 2px 0.5px #aaaaaa",
          paddingRight: "12px",
          paddingLeft: "12px",
        }});
      }
    }

    handleAddDishAddOnSwitchChange(){
      if (this.state.currentDishId !== -1){
        if(this.state.addDishAddOnsSwitch === false){
          this.setState({addDishAddOnsSwitch: true});
          this.setState({addDishAddOnsSwitchStyle : {
            background : "#425FA1",
            color: "#DEE8FF",
            left: "27px",
            boxShadow: "2px 0px 2px 0.5px #aaaaaa",
            paddingRight: "12px",
            paddingLeft: "12px",
          }});
        }
        else{
          this.setState({addDishAddOnsSwitch: false});
          this.setState({addDishAddOnsSwitchStyle : {
            background : "#DEE8FF",
            color: "#425FA1",
            left: "0px",
            boxShadow: "-2px 0px 2px 0.5px #aaaaaa",
            paddingRight: "12px",
            paddingLeft: "12px",
          }});
        }
      } else {
        this.props.informationBox("Please save the dish details first & try again");
        return;
      }
    }

    handleChange = (panel) => (event, isExpanded) => {
      if(isExpanded)
        this.setState({ expanded : panel});
      else
        this.setState({ expanded : false});
    };

    saveCustomisation(screenType, index){
      let changeIndex = index;
      let tempData = []
      if(screenType === "bestDish1"){
        let newItem = {
          customisationName : this.state.bestDish1CustomLabel,
          customisationType : "optional",
          customisationDefault: "",
          customisationValues : []
        }
        if (changeIndex === -1){
          changeIndex = this.state.bestDish1CustomList.length - 1;
        }
        tempData = this.state.bestDish1CustomList;
        tempData[changeIndex] = newItem;
        this.setState({
          bestDish1CustomList : tempData,
          bestDish1CustomLabel: "",
          addCustomLabelTextBoxBD1 : false
        })
      }

      if(screenType === "editDish" || screenType === "editBestDish"){
        
        let newItem = {
          customisationName : this.state.editDishCustomLabel,
          customisationType : this.state.currentCustomisationType,
          customisationDefault: tempData[changeIndex].customisationDefault,
          customisationValues : tempData[changeIndex].customisationValues,
          customisationCode: tempData[changeIndex].customisationCode
        }
        tempData[changeIndex] = newItem;
        this.setState({
          editDishCustomList : tempData
        }, this.handleCustomisationSave("customisation_save", screenType));
      }

      if(screenType === "addDish"){
        if (this.state.currentDishId === -1){
          this.props.informationBox("Please save the dish details first & try again");
          return;
        }
        if (this.state.editDishCustomLabel === ""){
          this.props.informationBox("Please enter a customisation name");
          return;
        }
        if (this.state.currentCustomisationType === ""){
          this.props.informationBox("Please select a customisation type");
          return;
        }
        tempData = this.state.addDishCustomList;
        if (changeIndex === -1){
          changeIndex = this.state.addDishCustomList.length - 1;
        }
        if (tempData[changeIndex].customisationValues.length === 0){
          this.props.informationBox("Please add atleast one customisation type");
          return;
        }
        let newItem = {
          customisationName : this.state.editDishCustomLabel,
          customisationType : this.state.currentCustomisationType,
          customisationDefault: tempData[changeIndex].customisationDefault,
          customisationValues : tempData[changeIndex].customisationValues,
          customisationCode: tempData[changeIndex].customisationCode
        }
        tempData[changeIndex] = newItem;
        this.setState({
          addDishCustomList : tempData
        }, this.handleCustomisationSave("customisation_save", screenType))
      }
    }
    
    addCustomType(index, screenType){
      let tempData = []
      if(screenType === "bestDish1"){
        tempData = this.state.bestDish1CustomList
        tempData[index].customisationValues.push({
          "name": "",
          "price": 0,
          "type": 'V',
          "unique_code": '',
          "is_default": false
        });
        this.setState({
          bestDish1CustomList : tempData,
        })
      }

      if(screenType === "editDish" || screenType === "editBestDish"){
        tempData = this.state.editDishCustomList;
        tempData[index].customisationMaxSelections = tempData[index].customisationValues.length + 1;
        tempData[index].customisationValues.push({
          "name": "",
          "price": 0,
          "type": 'V',
          "unique_code": '',
          "is_default": false
        })
        this.setState({
          editDishCustomList : tempData,
        })
      }

      if(screenType === "addDish"){
        tempData = this.state.addDishCustomList;
        tempData[index].customisationMaxSelections = tempData[index].customisationValues.length + 1;
        tempData[index].customisationValues.push({
          "name": "",
          "price": 0,
          "type": 'V',
          "unique_code": '',
          "is_default": false
        });
        this.setState({
          addDishCustomList : tempData,
        })
      }
    }

    handleCustomisationSave(type, screen_type) {
      if (this.state.currentDishId === -1){
        this.props.informationBox("Please save the dish details first & try again");
        return;
      }
      let req = {};
      let edit_list;
      if (screen_type === "editDish" || screen_type === "editBestDish"){
        edit_list = this.state.editDishCustomList;
      } else {
        edit_list = this.state.addDishCustomList;
      }
      let newGroups = [];
      let newItems = {};
      let existingCustomizations = {};
      for (var i=0; i<edit_list.length; i++){
        let customisationGroup = {};
        let groupAlreadyPresent = false;
        let customisationValues = edit_list[i].customisationValues;
        if (edit_list[i].customisationName === ""){
          this.props.informationBox("Please enter a customisation name");
          return;
        }
        if (edit_list[i].customisationMinSelections === ""){
          this.props.informationBox("Please select whether customisation is optional or mandatory");
          return;
        }
        if (edit_list[i].customisationValues.length === 0){
          this.props.informationBox("Please add atleast one customisation type");
          return;
        }
        if (edit_list[i].customisationMinSelections > edit_list[i].customisationMaxSelections){
          this.props.informationBox("Minimum selections can't be greater than maximum selections");
          return;
        }
        customisationGroup['name'] = edit_list[i].customisationName;
        customisationGroup['sort_order'] = edit_list.length - i;
        customisationGroup['min_selections'] = edit_list[i].customisationMinSelections;
        customisationGroup['max_selections'] = edit_list[i].customisationMaxSelections;
        customisationGroup["max_selections_per_item"] = 1;
        let default_values = edit_list[i].customisationDefault.split(",");
        for (var a=0; a< edit_list[i].customisationDefault.split(",").length; a++){
          if (!edit_list[i].customisationDefault.split(",")[a]){
            default_values.splice(a, 1);
          }
        }
        if (edit_list[i].customisationCode === ''){
          customisationGroup['modifications'] = [];
          if (edit_list[i].customisationDefault !== ''){
            let default_names = [];
            let value_names = [];
            for (var l=0; l<customisationValues.length; l++){
              if (value_names.includes(customisationValues[l]['name'])){
                this.props.informationBox("Please enter different names for customisation types");
                return;
              } else {
                value_names.push(customisationValues[l]['name']);
              }
              if (default_values.includes(customisationValues[l]['name'])){
                default_names.push(customisationValues[l]['name']);
              }
            }
            customisationGroup['default'] = default_names.join(",");
            if (customisationGroup['min_selections'] !== default_values.length && customisationGroup['min_selections'] > 0){
              this.props.informationBox("No of default options should be equal to the minimum number of selections");
              return;
            }
          } else {
            if (customisationGroup['min_selections'] >= 1){
              this.props.informationBox("Select default option(s) for mandatory customisation.");
              return;
            }
          }
          newGroups.push(customisationGroup);
        } else {
          groupAlreadyPresent = true;
          let default_codes = [];
          let value_names = [];
          for (var k=0; k<customisationValues.length; k++){
            if (value_names.includes(customisationValues[k]['name'])){
              this.props.informationBox("Please enter different names for customisation types");
              return;
            } else {
              value_names.push(customisationValues[k]['name']);
            }
            if (default_values.includes(customisationValues[k]['name'])){
              if (customisationValues[k]['unique_code']){
                default_codes.push(customisationValues[k]['unique_code']);
              }
            }
          }
          if (default_values.length === 0 && customisationGroup['min_selections'] >= 1){
            this.props.informationBox("Select default option(s) for mandatory customisation.");
            return;
          }
          if (default_values.length !== customisationGroup['min_selections'] && customisationGroup['min_selections'] > 0){
            this.props.informationBox("No of default options should be equal to the minimum number of selections");
            return;
          }
          customisationGroup['default'] = default_codes.join(',')
          customisationGroup['modifications'] = {};
          existingCustomizations[edit_list[i].customisationCode] = customisationGroup;
        }
        for (var j=0; j<customisationValues.length; j++){
          if (!customisationValues[j]['name']){
            this.props.informationBox("Please enter a name for customisation type.");
            return;
          }
          if (isNaN(parseInt(customisationValues[j]['price']))){
            this.props.informationBox("Please enter a valid price for customisation type.");
            return;
          }
          let customisationItem = {};
          customisationItem['name'] = customisationValues[j]['name'];
          customisationItem['unit_price'] = parseInt(customisationValues[j]['price']);
          customisationItem['type'] = (customisationValues[j]['type'] === undefined || customisationValues[j]['type'] === null) ? 'V' : customisationValues[j]['type'];
          customisationItem['sort_order'] = customisationValues.length - j;
          if (customisationValues[j]['unique_code'] === '' || customisationValues[j]['unique_code'] === undefined || customisationValues[j]['unique_code'] === null){
            if (!groupAlreadyPresent){
              newGroups[newGroups.length - 1]['modifications'].push(customisationItem);
            } else {
              if (default_values.includes(customisationItem['name'])){
                customisationItem['is_default'] = true;
              }
              if (newItems.hasOwnProperty(edit_list[i].customisationCode)){
                newItems[edit_list[i].customisationCode].push(customisationItem);
              } else {
                newItems[edit_list[i].customisationCode] = [customisationItem];
              }
            }
          } else {
            existingCustomizations[edit_list[i].customisationCode]['modifications'][customisationValues[j]['unique_code']] = customisationItem;
          }
        }
      }
      if (newGroups.length > 0){
        req['newGroups'] = newGroups;
      }
      if (Object.keys(newItems).length > 0){
        req['newItems'] = newItems;
      }
      if (Object.keys(existingCustomizations).length > 0){
        req['existingCustomizations'] = existingCustomizations;
      }
      if (Object.keys(req).length === 0){
        req['existingCustomizations'] = {};
        req['newGroups'] = [];
      }
      let url = CONSTANTS.hostURL + "api/resto-app/dishes/" + this.state.currentDishId + "/update-add-ons-and-customizations/";
        let headers = {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
        axios.post(url, req,  {
          headers: headers
        }).then((response) => {
          if (response.data.status === 1){
            if (type === "customisation_save"){
              this.props.informationBox("Customizations Updated Successfully");
              let customizations = [];
              if (response.data.data.customizations !== null){
                let customisationDataList = this.sortObjectOfObjects(response.data.data.customizations, 'sort_order');
                for (var i=0; i< customisationDataList.length; i++){
                  let customization_values = [];
                  let default_values = [];
                  let default_codes = [];
                  let customisation = customisationDataList[i];
                  if (customisation['default'] !== '' && customisation['default'] !== null && customisation['default'] !== undefined){
                    default_codes = customisation['default'].split(',');
                  }
                  let modifications = this.sortObjectOfObjects(customisation.modifications, 'sort_order');
                  for (var j=0; j< modifications.length; j++){
                    let modification = modifications[j];
                    var isDefault = false;
                    if (default_codes.includes(modification['key'])){
                      isDefault = true;
                      default_values.push(customisation.modifications[modification['key']]['name']);
                    }
                    customization_values.push({
                      'name': modification['name'],
                      'price': modification['unit_price'],
                      'type':  modification['type'],
                      'unique_code': modification['key'],
                      'is_default': isDefault
                    });
                  }
                  customizations.push({
                    customisationName : customisation['name'],
                    customisationMinSelections: customisation['min_selections'],
                    customisationMaxSelections: customisation['max_selections'],
                    customisationDefault: default_values.join(','),
                    customisationCode: customisation['key'],
                    customisationValues : customization_values
                  });
                }
              }
              this.setState({
                editDishCustomList: customizations,
                editDishAddOnsSwitch: true,
                editDishCustomisationVisibility : true,
                editDishAddOnsSwitchStyle : {
                  background : "#425FA1",
                  color: "#DEE8FF",
                  left: "27px",
                  boxShadow: "2px 0px 2px 0.5px #aaaaaa",
                  paddingRight: "12px",
                  paddingLeft: "12px",
                }
              });
              this.setState({
                addCustomLabelTextBoxED : false
              });
            }
          } else {
            this.props.informationBox(response.data.errors.message);
          }
        }).catch((err) => {
          this.props.informationBox(err.response.data.message);
        });
    }
    
    customTypesList(keyItem, customization_index, screenType, editRequired){
      let custom_list = [];
      if (screenType === "editDish" || screenType === "editBestDish"){
        custom_list = this.state.editDishCustomList;
      } else if (screenType === "addDish"){
        custom_list = this.state.addDishCustomList;
      } else {
        custom_list = this.state.bestDish1CustomList;
      }
      let cList = keyItem.customisationValues;
      const listItems = cList.map((keyItem, index) =>
          <div style={{display: "flex", flexDirection: "row", marginLeft: "10px", marginRight: "10px", marginBottom: "10px"}}>
            {editRequired && <div style={{width: "100px", marginRight: "20px", paddingLeft: '30px'}}>
              <Checkbox
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                checked={custom_list[customization_index].customisationValues[index].is_default === true}
                onChange={(event) => {
                  if (screenType === "editDish" || screenType === "editBestDish"){
                    var edit_dish_list = this.state.editDishCustomList;
                    let default_values = edit_dish_list[customization_index].customisationDefault.split(',');
                    let customisation_values = edit_dish_list[customization_index].customisationValues;
                    if (custom_list[customization_index].customisationValues[index].is_default === false){
                      customisation_values[index].is_default = true;
                      default_values.push(this.state.editDishCustomList[customization_index].customisationValues[index].name);
                    } else {
                      customisation_values[index].is_default = false;
                      default_values.splice(default_values.indexOf(this.state.editDishCustomList[customization_index].customisationValues[index].name), 1);
                    }
                    edit_dish_list[customization_index].customisationDefault = default_values.join(',');
                    edit_dish_list[customization_index].customisationValues = customisation_values;
                    this.setState({
                      editDishCustomList: edit_dish_list
                    });
                  } else if (screenType === "addDish"){
                    var add_dish_list = this.state.addDishCustomList;
                    let default_values = add_dish_list[customization_index].customisationDefault.split(',');
                    let customisation_values = add_dish_list[customization_index].customisationValues;
                    if (custom_list[customization_index].customisationValues[index].is_default === false){
                      customisation_values[index].is_default = true;
                      default_values.push(this.state.addDishCustomList[customization_index].customisationValues[index].name);
                    } else {
                      customisation_values[index].is_default = false;
                      default_values.splice(default_values.indexOf(this.state.addDishCustomList[customization_index].customisationValues[index].name), 1);
                    }
                    add_dish_list[customization_index].customisationDefault = default_values.join(',');
                    add_dish_list[customization_index].customisationValues = customisation_values;
                    this.setState({
                      addDishCustomList: add_dish_list
                    });
                  } else {
                    var best_dish_list = this.state.bestDish1CustomList;
                    let default_values = best_dish_list[customization_index].customisationDefault.split(',');
                    let customisation_values = best_dish_list[customization_index].customisationValues;
                    if (custom_list[customization_index].customisationValues[index].is_default === false){
                      customisation_values[index].is_default = true;
                      default_values.push(this.state.bestDish1CustomList[customization_index].customisationValues[index].name);
                    } else {
                      customisation_values[index].is_default = false;
                      default_values.splice(default_values.indexOf(this.state.bestDish1CustomList[customization_index].customisationValues[index].name), 1);
                    }
                    best_dish_list[customization_index].customisationDefault = default_values.join(',');
                    best_dish_list[customization_index].customisationValues = customisation_values;
                    this.setState({
                      bestDish1CustomList: best_dish_list
                    });
                  }
                }}
              />            
            </div>}
            <div style={{flex: 3, marginRight: "20px"}}>
              <input readOnly={!editRequired} value={custom_list[customization_index].customisationValues[index].name} onChange={(event) => {
                var new_name = event.target.value;
                if (screenType === "editDish" || screenType === "editBestDish"){
                  var edit_dish_list = this.state.editDishCustomList;
                  edit_dish_list[customization_index].customisationValues[index].name = new_name;
                  this.setState({
                    editDishCustomList: edit_dish_list
                  });
                } else if (screenType === "addDish"){
                  var add_dish_list = this.state.addDishCustomList;
                  add_dish_list[customization_index].customisationValues[index].name = new_name;
                  this.setState({
                    addDishCustomList: add_dish_list
                  });
                } else {
                  var best_dish_list = this.state.bestDish1CustomList;
                  best_dish_list[customization_index].customisationValues[index].name = new_name;
                  this.setState({
                    bestDish1CustomList: best_dish_list
                  });
                }
              }} type="text" placeholder="Enter Customisation type" class="textBoxStyle" style={{width: "100%", border: "1px solid rgb(217, 217, 217)", backgroundColor: "white"}}/>
            </div>
            <div style={{flex: 1}}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <input readOnly={!editRequired} value={custom_list[customization_index].customisationValues[index].price} onChange={(event) => {
                    var new_price = event.target.value;
                    if (screenType === "editDish" || screenType === "editBestDish"){
                      var edit_dish_list = this.state.editDishCustomList;
                      edit_dish_list[customization_index].customisationValues[index].price = new_price;
                      this.setState({
                        editDishCustomList: edit_dish_list
                      });
                    } else if (screenType === "addDish"){
                      var add_dish_list = this.state.addDishCustomList;
                      add_dish_list[customization_index].customisationValues[index].price = new_price;
                      this.setState({
                        addDishCustomList: add_dish_list
                      });
                    } else {
                      var best_dish_list = this.state.bestDish1CustomList;
                      best_dish_list[customization_index].customisationValues[index].price = new_price;
                      this.setState({
                        bestDish1CustomList: best_dish_list
                      });
                    }
                  }} style={{width:"100%", border: "1px solid rgb(217, 217, 217)", backgroundColor: "white"}} type="text" placeholder="Price in Rs." className="textBoxStyle" />
                </Grid>
                {/* <Grid item xs={1}>
                  <span className="packagingSticker" style={{left:"-5px", backgroundColor: "#DEE8FF", color: "#425FA1", top: "10px", padding: "11px"}}>Rs.</span>
                </Grid> */}
              </Grid>
            </div>
            {editRequired && <div style={{marginLeft: "20px"}}>
              <IconButton style={{top:"-4px", left:"10px"}}>
                <DeleteIcon onClick={() => {
                  if (screenType === "editDish" || screenType === "editBestDish"){
                    var edit_dish_list = this.state.editDishCustomList;
                    var customisationValues = custom_list[customization_index].customisationValues;
                    if (customisationValues.length > 1){
                      customisationValues.splice(index, 1);
                      edit_dish_list[customization_index].customisationValues = customisationValues;
                      edit_dish_list[customization_index].customisationMaxSelections = customisationValues.length;
                      this.setState({
                        editDishCustomList: edit_dish_list
                      });
                    } else {
                      this.props.informationBox("Customisation needs to have atleast one customisation type present");
                    }
                  } else if (screenType === "addDish"){
                    var add_dish_list = this.state.addDishCustomList;
                    customisationValues = custom_list[customization_index].customisationValues;
                    if (customisationValues.length > 1){
                      customisationValues.splice(index, 1);
                      add_dish_list[customization_index].customisationValues = customisationValues;
                      add_dish_list[customization_index].customisationMaxSelections = customisationValues.length;
                      this.setState({
                        addDishCustomList: add_dish_list
                      });
                    } else {
                      this.props.informationBox("Customisation needs to have atleast one customisation type present");
                    }
                  } else {
                    var best_dish_list = this.state.bestDish1CustomList;
                    customisationValues = custom_list[customization_index].customisationValues;
                    if (customisationValues.length > 1){
                      customisationValues.splice(index, 1);
                      best_dish_list[customization_index].customisationValues = customisationValues;
                      best_dish_list[customization_index].customisationMaxSelections = customisationValues.length;
                      this.setState({
                        bestDish1CustomList: best_dish_list
                      });
                    } else {
                      this.props.informationBox("Customisation needs to have atleast one customisation type present");
                    }
                  }
                }}/>
              </IconButton>
            </div>}
          </div>
      );
      return(
      <>
        {cList.length > 0 && <Grid container><Grid item xs={12}><div style={{display: "flex", flexDirection: "row", marginBottom: "10px", marginLeft: "10px", marginRight: "10px"}}>{editRequired && <div style={{width: "100px", marginRight: "20px"}} className="textBoxLabel">Select Default</div>}
        <div style={{flex: 3, marginRight: "20px"}} className="textBoxLabel">Customisation Items</div>
        <div style={{flex: 1}} item xs={3} className="textBoxLabel">Price (Rs.)</div>
        {editRequired && <div style={{marginLeft: "20px"}} className="textBoxLabel">Delete</div>}
        {/* <Grid item xs={12}><Divider/></Grid> */}
        </div></Grid></Grid>}
        <Grid container><Grid item xs={12}>{listItems}</Grid></Grid>
      </>);
    }

    handleDragDrop(param, accordList, screenType) {
      const startIndex = param.source.index;
      const endIndex = param.destination?.index;
      const changedList = Array.from(accordList);
      const [removed] = changedList.splice(startIndex, 1);
      changedList.splice(endIndex, 0, removed);
      if(screenType === "bestDish1"){
        this.setState({
          bestDish1CustomList: [...changedList]
        }, () => this.handleCustomisationSave("customisation_save", screenType));
      }
      if(screenType === "editDish" || screenType === "editBestDish"){
        this.setState({
          editDishCustomList: [...changedList]
        }, () => this.handleCustomisationSave("customisation_save", screenType));
      }
      if(screenType === "addDish"){
        this.setState({
          addDishCustomList: [...changedList]
        }, () => this.handleCustomisationSave("customisation_save", screenType));
      }
    }

    dishCustomisationAccordions(screenType){
      let accordList = [];
      if(screenType === "bestDish1"){
        accordList = this.state.bestDish1CustomList;
      }
      if(screenType === "editDish" || screenType === "editBestDish"){
        accordList = this.state.editDishCustomList;
      }
      if(screenType === "addDish"){
        accordList = this.state.addDishCustomList;
      }
      
      const listItems = accordList.map((keyItem, index) =>
        <Draggable
          key={keyItem.customisationName}
          draggableId={keyItem.customisationName}
          index={index}
        >
          {(provided, snapshot) => (
            <Grid item xs={12} style={index === 0 ? {margin: "20px 10px 10px 10px"} : {margin: "10px", position:"relative", top:"-"+(index*10)+"px"}}>
              <Accordion ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={this.state.expanded === 'panel'+keyItem.customisationName ? "accordionSelected" : "customisationAccordion"} expanded={this.state.expanded === 'panel'+keyItem.customisationName } onChange={this.handleChange('panel'+keyItem.customisationName)}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon style={{color: "#EB622F"}} fontSize="large" />}
                  aria-controls={'panel'+keyItem.customisationName+"-content"}
                  id={'panel'+keyItem.customisationName+"-header"}
                  className="dishCustomisationAcordionDimension"
                >
                  <Grid container spacing={2}>
                    <div style={{width:"100%"}}>
                      {this.state[keyItem.customisationName+"Text"] ?
                        <><input type="text" placeholder="Customisation Name" 
                          value={this.state[keyItem.customisationName+"TextBox"]} 
                          onClick={(event) => {
                            event.stopPropagation()
                          }}
                          onChange={(event) => {
                            this.setState({[keyItem.customisationName+"TextBox"]: event.target.value})
                          }}
                          className="editTextBoxStyle" 
                          style={{border:"#D9D9D9 solid 1px"}}/>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation()
                            let tempData = []
                            if(screenType === "bestDish1"){
                              tempData = this.state.bestDish1CustomList
                              tempData[index].customisationName = this.state[keyItem.customisationName+"TextBox"]
                              this.setState({
                                bestDish1CustomList : tempData,
                                [keyItem.customisationName+"TextBox"] : "",
                                [keyItem.customisationName+"Text"] : false,
                              })
                            }
                            if(screenType === "editDish" || screenType === "editBestDish"){
                              tempData = this.state.editDishCustomList
                              tempData[index].customisationName = this.state[keyItem.customisationName+"TextBox"]
                              this.setState({
                                editDishCustomList : tempData,
                                [keyItem.customisationName+"TextBox"] : "",
                                [keyItem.customisationName+"Text"] : false,
                              }, () => this.handleCustomisationSave("customisation_save", screenType));
                            }
                            if(screenType === "addDish"){
                              tempData = this.state.addDishCustomList
                              tempData[index].customisationName = this.state[keyItem.customisationName+"TextBox"]
                              this.setState({
                                addDishCustomList : tempData,
                                [keyItem.customisationName+"TextBox"] : "",
                                [keyItem.customisationName+"Text"] : false,
                              }, () => this.handleCustomisationSave("customisation_save", screenType))
                            }
                          }}
                          onFocus={(event) => event.stopPropagation()}>
                            <RiSaveFill/>
                        </IconButton>
                        <IconButton
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => {
                          event.stopPropagation()
                          this.setState({[keyItem.customisationName+"Text"] : false})
                        }}>
                          <MdOutlineCancel/>
                        </IconButton>
                        </>
                        : <div class="textBoxLabel" style={{position:"relative", top:"19px", paddingLeft:"30px"}}>{keyItem.customisationName} <span style={{paddingLeft: '5px', fontSize: '10px', color: keyItem.customisationMinSelections === 0 ? 'green' : '#EA5B2F', transform: 'translateY(-2px)', display: 'inline-block'}}>{keyItem.customisationMinSelections === 0 ? "OPTIONAL" : "MANDATORY"}</span></div>
                      }
                                        
                      
                      <span style={{float:"right", top: this.state[keyItem.customisationName+"Text"] === undefined || this.state[keyItem.customisationName+"Text"] === false ? "-16px" : "0px", position:"relative", transition:"none"}}
                        onClick={(event) => {
                          if (screenType === "editDish" || screenType === "editBestDish"){
                            if (this.state.currentDishId === -1){
                              this.props.informationBox("Please save the dish details first & try again");
                              return;
                            }
                            var edit_dish_list = this.state.editDishCustomList;
                            edit_dish_list.splice(index, 1);
                            this.setState({
                              editDishCustomList: edit_dish_list
                            }, () => this.handleCustomisationSave("customisation_save", screenType));
                          } else if (screenType === "addDish"){
                            if (this.state.currentDishId === -1){
                              this.props.informationBox("Please save the dish details first & try again");
                              return;
                            }
                            var add_dish_list = this.state.addDishCustomList;
                            add_dish_list.splice(index, 1);
                            this.setState({
                              addDishCustomList: add_dish_list
                            }, () => this.handleCustomisationSave("customisation_save", screenType));
                          } else {
                            var best_dish_list = this.state.bestDish1CustomList;
                            best_dish_list.splice(index, 1);
                            this.setState({
                              bestDish1CustomList: best_dish_list
                            });
                          }
                        }}
                        onFocus={(event) => event.stopPropagation()}>
                        <IconButton aria-label="deleteCustom">
                          <DeleteIcon />
                        </IconButton>
                      </span>
                      <span style={{float:"right", top: this.state[keyItem.customisationName+"Text"] === undefined || this.state[keyItem.customisationName+"Text"] === false ? "-16px" : "0px", position:"relative", transition:"none"}}
                        onClick={(event) => {
                          event.stopPropagation();
                          this.setState({
                            addCustomLabelTextBoxED : true,
                            editDishCustomisationVisibility : true,
                            currentCustomisationIndex: index
                          });
                          // this.setState({
                          //   [keyItem.customisationName+"Text"] : true,
                          //   [keyItem.customisationName+"TextBox"] : keyItem.customisationName,
                          // })
                        }}
                        onFocus={(event) => event.stopPropagation()}>
                        <IconButton aria-label="deleteCustom">
                          <RiEdit2Line/>
                        </IconButton>
                      </span>
                      
                    </div>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} style={{paddingLeft:"30px", paddingRight:"30px"}}>
                    {this.customTypesList(keyItem, index, screenType, false)}
                    {/* <Grid item xs={12}><Divider/></Grid> */}
                    {/* <Grid item xs={12}>
                      <span onClick={() => this.addCustomType(index,screenType)} className="textBoxLabel" style={{position:"relative",color:"#EB622F", cursor:"pointer"}}>
                        + Add Customisation type
                      </span>
                    </Grid> */}
                  </Grid>
                </AccordionDetails>
                {/* <AccordionActions>
                  <span onClick={() => this.handleCustomisationSave("customisation_save", screenType)} style={{borderColor: "#FCD844", marginRight: "30px"}} className="ddSmallButtonSave">Save</span> 
                </AccordionActions> */}
              </Accordion> 
            </Grid>
          )}
        </Draggable>
      );
      return(
        <DragDropContext
          onDragEnd={(param) => this.handleDragDrop(param, accordList, screenType)}
        >
          <Droppable droppableId="droppable">
            {(provided, _) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {listItems}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    }

    addNewSubMenu(){
      this.setState({ newSubMenu : true});
    }
    closeNewSubMenu(){
      this.setState({ newSubMenu : false});
    }

    subMenuList(){
      const listItems = this.props.sub_menus_name.map((keyItem, index) =>
      <div style={keyItem.avl ? {} : {backgroundColor: '#ECECEC'}} className="tableContents">
        <Grid container spacing={0} >
          <Grid item xs={7} style={{position:"relative"}}>
            <FormControlLabel
              className="checkBoxPos"
              control={
                <Checkbox
                  checked={this.state.checkedB}
                  onChange={() => this.handleCheckBoxChange()}
                  name={"checkedB_"+ keyItem.id}
                  color="primary"
                />
              }
            />
            <span className="dishName" onClick={() => {this.toggleEditSubMenusDishesSection(keyItem); this.getRestaurantDishes(keyItem.id, 1)}}>{keyItem.name}</span>
            <span style={{right:"50px", marginTop:"-2px"}} className="ddDishCountBadge">
              {keyItem.count === 0 ? "No Dishes" : keyItem.count === 1 ? keyItem.count + " Dish" : keyItem.count + " Dishes"}
            </span>
          </Grid>
          <Grid item xs={2}>
            <span style={{marginLeft: '10px'}} className="viewButton" onClick={() => {this.toggleEditSubMenusDishesSection(keyItem); this.getRestaurantDishes(keyItem.id, 1)}}>View</span>
          </Grid>
          <Grid item xs={3}>
            {/* <IconButton aria-label="deleteSubMenu" style={{float:'right'}}>
              <DeleteIcon />
            </IconButton> */}
            <span style={{float:'right', marginRight: '40px', marginTop: '10px'}} className="inStockButton" onClick={() => this.setState({showPopup: true, popupType: "sub_menu_hide", currentSubMenuId: keyItem.id, confirmQuestion: "Are you sure you want to " + (keyItem.avl ? "hide" : "show") + " the sub menu?"})}>
              <span className="inStockSlider" style={keyItem.avl ? {left:'11px', boxShadow: '2px 0px 2px 0.5px #aaaaaa', background: '#0C950C'} : {left:'-11px', boxShadow: '-2px 0px 2px 0.5px #aaaaaa', background: '#DEE8FF'}}></span>
            </span>
            {/* <IconButton onClick={() => this.setState({showPopup: true, popupType: "sub_menu_hide", currentSubMenuId: keyItem.id, confirmQuestion: "Are you sure you want to " + (keyItem.avl ? "hide" : "show") + " the sub menu?"})} aria-label="subMenuVisibility" style={{float:'right', marginRight: '40px'}}>
              {keyItem.avl ? <VisibilityOffIcon/> : <VisibilityOnIcon/>}
            </IconButton> */}
            {/* <IconButton aria-label="subMenuCopy" style={{float:'right'}}>
              <FileCopyIcon />
            </IconButton> */}
          </Grid>
        </Grid>
      </div>
      );
      return (listItems);
    }

    onChange = (event, { newValue }) => {
      let suggestions = this.state.subMenuSuggestions;
      let suggestionFound = false;
      let subMenuId = -1;
      let currentValue = newValue;
      for (var i=0; i<suggestions.length; i++){
        if (suggestions[i].name === newValue){
          suggestionFound = true;
          subMenuId = suggestions[i].id;
          break;
        }
      }
      this.setState({
        subMenuTextValue: currentValue,
        selectedSubMenuId: subMenuId,
        subMenuSelected: suggestionFound,
        subMenuSuggestions: []
      }, () => this.getSuggestions(newValue, suggestionFound))
    };
  
    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ subMenuTextValue }) => {};
  
    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
      this.setState({
        subMenuSuggestions: []
      });
    };

    // Teach Autosuggest how to calculate suggestions for any given input value.
    getSuggestions(value, suggestionPresent){
      if (!suggestionPresent){
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        if (inputLength >= 3) {
          let headers = {
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token') 
          }
          let urlForSuggestions = CONSTANTS.hostURL + "api/resto-app/search/" + inputValue + "?entity=sub_menu&channel=resto-app";
          axios.get(urlForSuggestions,  {
            headers: headers
          }).then((response) => {
              if (response.data.status === 1){
                let results = [];
                for (var i=0; i<response.data.data.results.length; i++){
                  let cuisineFound = false;
                  for (var j=0; j<this.props.sub_menus_name.length; j++){
                    if (this.props.sub_menus_name[j].id === response.data.data.results[i].id){
                      cuisineFound = true;
                      break;
                    }
                  }
                  if (!cuisineFound){
                    results.push(response.data.data.results[i]);
                  }
                }
                if (!this.state.cuisineSelected){
                  this.setState({
                    subMenuSuggestions: results
                  })
                }
              }
          });
        } else {
          this.setState({
            subMenuSuggestions: []
          });
        }
      }
    };

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    getSuggestionValue = suggestion => suggestion.name;

    // Use your imagination to render suggestions.
    renderSuggestion = suggestion => (
      <div>
        {suggestion.name}
      </div>
    );

    handleSubMenuSave() {
      if (this.state.subMenuTextValue !== ""){
        let req = {};
        if (this.state.selectedSubMenuId !== -1){
          req = {"sub_menu_id": this.state.selectedSubMenuId};
        } else {
          req = {"sub_menu_name": this.state.subMenuTextValue};
        }
        let url = CONSTANTS.hostURL + "api/resto-app/restaurant/sub-menu/";
        let headers = {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
        axios.post(url, req,  {
          headers: headers
        }).then((response) => {
          if (response.data.status === 1){
            let result = response.data.data;
            let new_sub_menu = {
              "id": result.id, 
              "name": result.name, 
              "count": 0, 
              "avl": true
            };
            let sub_menus_name = this.props.sub_menus_name;
            sub_menus_name.splice(sub_menus_name.length, 0, new_sub_menu);
            this.setState({
              newSubMenu: false,
              selectedSubMenuId: -1,
              subMenuTextValue: "",
              subMenuDishesLoaded: true
            });
            this.props.updateSubMenuList(sub_menus_name);
          }
        });
      }
    }

    handleSubMenuHide(subMenuId) {
      let sub_menus_name = this.props.sub_menus_name;
      let deleteIndex = -1;
      for (var i=0; i<this.props.sub_menus_name.length; i++){
        if (this.props.sub_menus_name[i].id === subMenuId){
          deleteIndex = i;
        }
      }
      let req = {"sub_menu_id": subMenuId};
      if (deleteIndex !== -1){
        let sub_menu = sub_menus_name[deleteIndex];
        sub_menu.avl = !sub_menu.avl;
        req['is_visible'] = sub_menu.avl;
        if (this.state.currentSubMenuDish !== null && this.state.currentSubMenuDish.id !== null && sub_menu.id === this.state.currentSubMenuDish.id){
          this.setState({
            currentSubMenuDish : sub_menu
          })
        }
      }
      let url = CONSTANTS.hostURL + "api/resto-app/restaurant/sub-menu/";
      let headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
      axios.delete(url, {
        headers: headers,
        data: req
      }).then((response) => {
        if (response.data.status === 1){
          this.setState({
            sub_menus_name : sub_menus_name,
            currentSubMenuId: -1,
            popupType: "",
            showPopup: false,
            confirmQuestion: ""
          });
          this.props.updateSubMenuList(sub_menus_name);
        } else {
          if (deleteIndex !== -1){
            let sub_menu = sub_menus_name[deleteIndex];
            sub_menu.avl = !sub_menu.avl;
            if ( this.state.currentSubMenuDish.id !== null && sub_menu.id === this.state.currentSubMenuDish.id){
              this.setState({
                currentSubMenuDish : sub_menu
              })
            }
          }
        }
      }).catch((err) => {
        if (deleteIndex !== -1){
          let sub_menu = sub_menus_name[deleteIndex];
          sub_menu.avl = !sub_menu.avl;
          if ( this.state.currentSubMenuDish.id !== null && sub_menu.id === this.state.currentSubMenuDish.id){
            this.setState({
              currentSubMenuDish : sub_menu
            })
          }
        }
      });
    }


    editSubMenusSection(){

      const value = this.state.subMenuTextValue;
      const subMenuSuggestions = this.state.subMenuSuggestions;

      const inputProps = {
        placeholder: 'Enter name of the Sub-Menu here. For Eg. Desserts',
        value,
        onChange: this.onChange
      };

      return(
        <Grid item xs={12} className="menuGridPosition2">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="bannerText">Sub-Menus and Dishes</div>
              <div className="infoTags2">Edit or Update Restaurant's Menu</div>
              <span className="backButton" onClick={this.addNewSubMenu} style={{float:'right',marginRight: '22px'}}>+ Add Sub-Menu</span>
            </Grid>
            <Grid item xs={12}>
              <div className="tableHeader">
                <Grid container spacing={1} >
                  <Grid item xs={9}>Name</Grid>
                  <Grid item xs={3} style={{textAlign:"right"}}><div style={{paddingRight: "25px"}}>Visibility</div></Grid>
                </Grid>
              </div>

              {this.subMenuList()}

              {this.state.newSubMenu && <div className="newSubMenuOrDish">
                <Grid container spacing={0} >
                  <Grid item xs={10}>
                    {/* <input type="text" placeholder="Enter name of the Sub-Menu here. For Eg. Desserts" className="textBoxStyle" style={{width:"100%", marginTop:'5px'}}/> */}
                    <Autosuggest
                      suggestions={subMenuSuggestions}
                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                      getSuggestionValue={this.getSuggestionValue}
                      renderSuggestion={this.renderSuggestion}
                      inputProps={inputProps}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <span onClick={() => this.handleSubMenuSave()} className="saveSubMenuButton" style={{top:'10px', left:'20px', color: "white !important"}}>Save</span>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton aria-label="moreOptions" style={{float:'right'}} onClick={this.closeNewSubMenu}>
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>}

            </Grid>
          </Grid>
        </Grid>
      );
    }

    getRestaurantDishes(sub_menu_id, page_no){
      let urlForSubMenuCount = CONSTANTS.hostURL + "api/resto-app/dishes/?page=" + page_no +"&sub_menu=" + sub_menu_id;
      if (page_no === 1){
        this.setState({sub_menu_dishes : []});
      }
      let headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
      axios.get(urlForSubMenuCount, {
        headers: headers
      }).then(res => {
        let sub_menu_dishes = [...this.state.sub_menu_dishes, ...res.data.data.results];
        this.setState({
          sub_menu_dishes: sub_menu_dishes,
          subMenuDishesLoaded: true
        });
        let nextPage = res.data.data.next;
        if (nextPage !== null){
          this.getRestaurantDishes(sub_menu_id, page_no + 1);
        }
      });
    }

    handleModalClick(e) {
        e.stopPropagation();
    }

    confirmActionPopup() {
      if(!this.state.showPopup){
        return null;
      }
      return(
          <div className="RatingModal" onClick={this.closeModalHandler}>
              <div style={{height: "unset", margin: "auto"}} onClick={(e) => this.handleModalClick(e)} className="SuccessModalContainer">
                <div style={{width: "100%", textAlign: "center", fontFamily: "Poppins-Regular", padding: "30px 0px"}}>{this.state.confirmQuestion}</div>
                <div style={{padding: "0px 0px 30px 0px", display: "flex", flexDirection: "row", width: "100%"}}>
                      <div style={{width: "50%", padding: "0px 1.5vw 0px 4.5vw"}}>
                          <div onClick={() => {
                            if (this.state.popupType === "dish_delete"){
                              this.handleSubMenuDishDelete(this.state.currentDishId, this.state.currentDishIndex);
                            } else if (this.state.popupType === "sub_menu_hide"){
                              this.handleSubMenuHide(this.state.currentSubMenuId);
                            }
                          }} 
                              style={{width: "100%", border: "1px solid #707070", textAlign: "center", padding: "12px 0px", borderRadius: "20px", cursor: "pointer"}}>
                              Yes
                          </div>
                      </div>
                      <div style={{width: "50%", padding: "0px 4.5vw 0px 1.5vw"}}>
                          <div onClick={() => {
                            if (this.state.popupType === "dish_delete"){
                              this.setState({
                                currentDishID: -1,
                                currentDishIndex: -1,
                              })
                            } else if (this.state.popupType === "sub_menu_hide"){
                              this.setState({
                                currentSubMenuId: -1
                              })
                            }
                            this.setState({
                              popupType: "",
                              showPopup: false,
                              confirmQuestion: ""
                            })
                          }} style={{width: "100%", backgroundColor: "#EA5B2F", border: "1px solid #EA5B2F", textAlign: "center", padding: "12px 0px", color: "white", borderRadius: "20px", cursor: "pointer"}}>
                              No
                          </div>
                      </div>
                </div>
              </div>
          </div>
      )
    }

    handleSubMenuDishDelete(id, index){
      let req = {};
      let url = CONSTANTS.hostURL + "api/resto-app/dishes/" + id + "/";
      let headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
      axios.delete(url, {
        headers: headers,
        data: req
      }).then((response) => {
        if (response.data.status === 1){
          let sub_menu_dishes = this.state.sub_menu_dishes;
          sub_menu_dishes.splice(index, 1);
          let sub_menus_name = this.props.sub_menus_name;
          let changeIndex = -1;
          for (var i=0; i<this.props.sub_menus_name.length; i++){
            if (this.props.sub_menus_name[i].id === this.state.currentSubMenuDish.id){
              changeIndex = i;
            }
          }
          if (changeIndex !== -1){
            sub_menus_name[changeIndex].count = sub_menus_name[changeIndex].count - 1;
          }
          this.setState({
            sub_menus_name : sub_menus_name,
            sub_menu_dishes: sub_menu_dishes,
            currentDishID: -1,
            currentDishIndex: -1,
            popupType: "",
            showPopup: false,
            confirmQuestion: ""
          });
          this.props.informationBox("Dish deleted successfully.");
          this.props.updateSubMenuList(sub_menus_name);
        }
      });
    }

    ispopular(itemId){
      let popularID = JSON.parse(localStorage.getItem("restaurant_details")).popular_dishes
      for(var i=0; i<popularID.length; i++){
        if(popularID[i] === itemId)
          return true
      }
      return false;
    }

    isbest(itemId){
      for (var j=0; j<this.props.bestDishes.length; j++){
        if (this.props.bestDishes[j].id === itemId){
          return true
        }
      }
      // let popularID = JSON.parse(localStorage.getItem("restaurant_details")).popular_dishes
      // for(var i=0; i<popularID.length; i++){
      //   if(popularID[i] === itemId)
      //     return true
      // }
      return false;
    }

    editSubMenusDishesSection(){
      return(
        <Grid item xs={12} className="menuGridPosition2">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div>
                <ArrowBack onClick={this.toggleEditSubMenusDishesSection} style={{transform: 'translateY(5px)', marginRight: '5px', cursor: 'pointer'}}/>
                <span className="bannerText">{this.state.currentSubMenuDish.name} ({this.state.currentSubMenuDish.count})</span>
                  <span style={{marginLeft: '10px', cursor: 'pointer', padding: '5px 10px', borderRadius: '20px', fontSize: '14px', color: 'white', backgroundColor: '#EA5B2F', fontFamily: 'Poppins-Regular'}} onClick={() => this.updateSubMenuAvailability()}>Mark all dishes in stock</span>
                  {/* <span className="dishAvailabilitySwitch">
                    <span className="inStockButton" onClick={() => this.setState({showPopup: true, popupType: "sub_menu_hide", currentSubMenuId: this.state.currentSubMenuDish.id, confirmQuestion: "Are you sure you want to " + (this.state.currentSubMenuDish.avl ? "hide" : "show") + " the sub menu?"})}>
                      <span className="inStockSlider" style={this.state.currentSubMenuDish.avl ? {left:'11px', boxShadow: '2px 0px 2px 0.5px #aaaaaa', background: '#0C950C'} : {left:'-11px', boxShadow: '-2px 0px 2px 0.5px #aaaaaa', background: '#DEE8FF'}}></span>
                    </span>
                  </span> */}
                {/* <span className="inStockText">Is Visible</span> */}
              </div>
              <div className="infoTags2">Edit or Update {this.state.currentSubMenuDish.name} items</div>
              <div style={{float: 'right'}}>
              <span className="backButton" style={{marginRight: '22px'}} onClick={this.toggleAddDishSection}>+ Add New Dish</span>
              {/* <span className="backButton" style={{marginRight: '22px'}} onClick={this.toggleEditSubMenusDishesSection}>Back</span> */}
              </div>
            </Grid>
            <Grid item xs={12}>
              {
                this.state.sub_menu_dishes.length > 0 && <div className="tableHeader">
                  <Grid container spacing={0} >
                    <Grid item xs={7}>Name</Grid>
                    <Grid item xs={2}>In Stock</Grid>
                    <Grid item xs={1}>Cost</Grid>
                    <Grid item xs={2} style={{textAlign:"right"}}><div style={{paddingRight: "25px"}}>Options</div></Grid>
                  </Grid>
                </div>
              }
              {
                (this.state.sub_menu_dishes.length === 0 && this.state.subMenuDishesLoaded) && <div>
                  <img src={NoDishes} style={{display: 'block', margin: 'auto', width: '280px'}} alt="image1"/>
                  <div style={{margin: 'auto', textAlign: 'center', fontSize: '24px', fontFamily: 'Bree-Regular'}}>No dishes added yet</div>
                  <div style={{margin: 'auto', textAlign: 'center', fontSize: '12px', fontFamily: 'Poppins-Regular', color: '#6C6C6C', marginTop: '8px'}}>Looks like this menu is empty</div>
                  <span className="backButton" style={{display: 'block', width: '115px', margin: 'auto', marginTop: '30px'}} onClick={this.toggleAddDishSection}>+ Add New Dish</span>
                </div>
              }
              {this.state.sub_menu_dishes.map((keyItem, index) =>
                <div className="tableContents">
                  <Grid container spacing={0} >
                    <Grid item xs={7} style={{position:"relative", display:"flex", flexDirection:"row"}}>
                      <FormControlLabel
                        className="checkBoxPos"
                        style={{top:"0px"}}
                        control={
                          <Checkbox
                            checked={this.state.checkedB}
                            onChange={this.handleCheckBoxChange}
                            name="checkedB"
                            color="primary"
                          />
                        }
                      />
                      <div className="dishName" style={{top:"10px", width:"360px", paddingBottom:"10px"}}>
                        {keyItem.name} 
                        {this.ispopular(keyItem.id) && 
                          <img style={{width:"20px", position:"relative", left:"10px", top:"5px"}} src={PopularTag} alt="Populartag"/>
                        }
                        {this.isbest(keyItem.id) && 
                          <img style={{width:"20px", position:"relative", left:"10px", top:"5px"}} src={BestDishTag} alt="BestDishTag"/>
                        }
                      </div>
                      <div style={{right:"50px", marginTop:"-2px"}} className="ddDishCountBadge">
                        {(Math.round(keyItem.average * 100) / 100).toFixed(2) % 1 === 0 ? Math.floor((Math.round(keyItem.average * 100) / 100)) : (Math.round(keyItem.average * 100) / 100).toFixed(2)}
                        <Star style={{color: "white", fontSize: "13px", transform: "translateY(2px)"}}/>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <span className="noOfDishes">
                        <span className="inStockButton" onClick={() => this.toggleDishInStock(keyItem.id, keyItem.in_stock, "sub_menu")}>
                          <span className="inStockSlider" style={keyItem.in_stock === true ? {left:'11px', boxShadow: '2px 0px 2px 0.5px #aaaaaa', background: '#0C950C'} : {left:'-11px', boxShadow: '-2px 0px 2px 0.5px #aaaaaa', background: '#DEE8FF'}}></span>
                        </span>
                      </span>
                    </Grid>
                    <Grid item xs={1}>
                      <span className="dishName" style={{top:"10px"}}>Rs. {keyItem.cost}</span>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <IconButton onClick={() => this.setState({showPopup: true, popupType: "dish_delete", currentDishId: keyItem.id, currentDishIndex: index, confirmQuestion: "Are you sure you want to delete the dish?"})} aria-label="deleteSubMenu" style={{float:'right'}}>
                          <DeleteIcon />
                        </IconButton>
                        {/* <IconButton aria-label="subMenuVisibility" style={{float:'right'}}>
                          <VisibilityOffIcon />
                        </IconButton> */}
                        {/* <IconButton onClick={() => this.copyExistingDish(keyItem.id)} aria-label="subMenuCopy" style={{float:'right'}}>
                          <FileCopyIcon />
                        </IconButton> */}
                        <span style={{float:'right'}} className="viewButton" onClick={() => {this.toggleEditDishesSection(keyItem.name, keyItem.id)}}>Edit</span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }

    handleDishSave(dish_id){
      if (this.state.currentDishId === -1){
        this.handleAddDishSave();
      } else {
        this.handleEditDishSave();
      }
    }

    handleAddDishSave(){
      let errorText = [];
      let cost_error = "";
      var regex = /^([+-]?[1-9]\d*|0)$/;
      var mandatoryList = [];
      var validList = [];
      var minString = '';
      if (this.state.currentDishName === ""){
        mandatoryList.push('dish name');
        errorText.push("Please enter the dish name.")
      }
      if (this.state.currentDishCost === ""){
        mandatoryList.push('dish cost');
        cost_error = "Please enter the dish cost.";
      } else {
        if (!this.state.currentDishCost.toString().match(regex)){
          validList.push('dish cost');
          cost_error = "Please enter a valid dish cost.";
        }
      }
      if (cost_error !== ""){
        errorText.push(cost_error);
      }
      cost_error = "";
      if (this.state.currentDishPackaging === ""){
        mandatoryList.push('dish packaging charge');
        cost_error = "Please enter the dish packaging charge.";
      } else {
        if (!this.state.currentDishPackaging.toString().match(regex)){
          validList.push('dish packaging charge');
          cost_error = "Please enter a valid dish packaging charge.";
        }
      }
      if (cost_error !== ""){
        errorText.push(cost_error);
      }
      if (this.state.currentDishChoice === ""){
        mandatoryList.push('dish type');
        errorText.push("Please enter the dish type i.e. Veg, Non-Veg or Egg.");
      }
      if (this.state.currentDishDescription === ""){
        mandatoryList.push('dish description');
        errorText.push("Please enter the dish description.")
      } else {
        if ((this.state.currentDishDescription?.length??0) > 225){
          minString = "Dish description can't be more than 225 characters.";
          errorText.push("Dish description can't be more than 225 characters.")
        }
      }
      if (mandatoryList.length > 0 || validList.length > 0 || minString !== '') {
        let errorStringList = [];
        if (mandatoryList.length > 0){
          let mandatoryErrorString = 'Please enter ' + mandatoryList.join(', ') + '.';
          errorStringList.push(mandatoryErrorString);
        } 
        if (validList.length > 0){
          let validErrorString = 'Please enter a valid ' + validList.join(', ') + '.';
          errorStringList.push(validErrorString);
        } 
        if (minString !== ''){
          errorStringList.push(minString);
        }
        this.props.informationBox(errorStringList.join(' '));
        return;
      }
      var form_data = new FormData();
      form_data.append('name', this.state.currentDishName);
      form_data.append('choice', this.state.currentDishChoice);
      form_data.append('cost', this.state.currentDishCost);
      form_data.append('packaging_charge', this.state.currentDishPackaging);
      form_data.append('description', this.state.currentDishDescription);
      form_data.append('sub_menu_ids', this.state.currentSubMenuDish.id);
      let url = CONSTANTS.hostURL + "api/resto-app/dishes/";
      let headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
      this.setState({
        editDishSaveText: "Saving.."
      })
      axios.post(url, form_data,  {
        headers: headers
      }).then((response) => {
        if (response.data.status === 1){
          let sub_menu_dishes = this.state.sub_menu_dishes;
          sub_menu_dishes.push({
            'id': response.data.data.id,
            'name': response.data.data.name,
            'in_stock': false,
            'profile_image_url': response.data.data.profile_image_url,
            'average': 0
          });
          let sub_menus_name = this.props.sub_menus_name;
          let changeIndex = -1;
          for (var i=0; i<this.props.sub_menus_name.length; i++){
            if (this.props.sub_menus_name[i].id === this.state.currentSubMenuDish.id){
              changeIndex = i;
            }
          }
          if (changeIndex !== -1){
            sub_menus_name[changeIndex].count = sub_menus_name[changeIndex].count + 1;
          }
          this.setState({
            sub_menus_name : sub_menus_name,
            sub_menu_dishes: sub_menu_dishes,
            currentDishId: response.data.data.id,
            editDishSaveText: "Save & Proceed",
            subMenuDishesLoaded: true
          });
          this.props.informationBox("Dish added successfully. Please add customisation if required.");
          this.props.updateSubMenuList(sub_menus_name);
        } else {
          if (response.data.status === -1){
            this.props.informationBox(response.data.message);
          } else {
            this.props.informationBox("Network or server error. Please try again later");
          }
          this.setState({
            editDishSaveText: "Save & Proceed"
          });
        }
      }).catch((err) => {
        this.props.informationBox(err.response.data.message);
        this.setState({
          editDishSaveText: "Save & Proceed"
        });
      });
    }

    handleEditDishSave(){
      this.setState({
        editDishSaveText: "Saving Details..."
      })
      var form_data = new FormData();
      if (this.state.currentDishChoice === ""){
        this.props.informationBox("Please enter the dish type i.e. Veg, Non-Veg or Egg.");
        return;
      }
      form_data.append('name', this.state.currentDishName);
      form_data.append('choice', this.state.currentDishChoice);
      form_data.append('cost', this.state.currentDishCost);
      form_data.append('packaging_charge', this.state.currentDishPackaging);
      form_data.append('description', this.state.currentDishDescription);
      form_data.append('is_popular', this.state.currentDishIsPopular);

      let url = CONSTANTS.hostURL + "api/resto-app/dishes/" + this.state.currentDishId + "/";
      let headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }
      axios.patch(url, form_data,  {
        headers: headers
      }).then((response) => {
        if (response.data.status === 1){
          if (this.state.dishOrMenu === "editDish"){
            let sub_menu_dishes = this.state.sub_menu_dishes;
            let dish_index = -1;
            for (var i=0; i<sub_menu_dishes.length; i++){
              if (sub_menu_dishes[i].id === this.state.currentDishId){
                dish_index = i;
              }
            }
            if (dish_index !== -1){
              sub_menu_dishes[dish_index].name = this.state.currentDishName;
            }
            this.setState({
              sub_menu_dishes: sub_menu_dishes,
              subMenuDishesLoaded: true
            });
            this.props.getRestaurantSubMenus();
          } else {
            let best_dishes = this.props.bestDishes;
            best_dishes[this.state.bestDishIndex] = response.data.data;
            this.props.updateBestDishDetails(best_dishes);
          }
          this.setState({
            editDishName: this.state.currentDishName,
            editDishSaveText: "Save & Proceed"
          });
          this.props.informationBox("Dish details updated successfully.");
        } else {
          this.props.informationBox("Network or server error. Please try again later");
          this.setState({
            editDishSaveText: "Save & Proceed"
          });
        }
      }).catch((err) => {
        this.props.informationBox(err.response.data.message);
        this.setState({
          editDishSaveText: "Save & Proceed"
        });
      });
    }

    getSelectionItem (screen_type, index, type) {
      let minSelection = 0;
      let tempList = [];
      if (screen_type === 'editDish' || screen_type === 'editBestDish'){
        tempList = this.state.editDishCustomList;
      } else if (screen_type === 'addDish'){
        tempList = this.state.addDishCustomList;
      }
      let item = tempList[index];
      if (type === 'max' && item.customisationMinSelections){
        minSelection = item.customisationMinSelections;
      }
      let selectionList = [];
      let customisationValues = item.customisationValues;
      while (minSelection <= customisationValues.length){
        selectionList.push(minSelection);
        minSelection += 1;
      }
      return (<FormControl>
        <Select
          labelId={"selection-select"+type}
          className="selection-select"
          displayEmpty
          disableUnderline
          renderValue={(type === 'max' ? item.customisationMaxSelections : item.customisationMinSelections) !== "" ? undefined : () => type === "max" ? "Max selections" : "Min selections"}
          id={"selection-select"+type}
          style={{width:"220px", border: "1px solid rgb(217, 217, 217)", borderRadius: "5px", padding: "4px 0px", marginRight: "20px", textAlign: "center", color: (type === 'max' ? item.customisationMaxSelections : item.customisationMinSelections) === "" ? "#9F9F9F" : "black"}}
          onChange={(e) => {
            let tempList = [];
            if (screen_type === 'editDish' || screen_type === 'editBestDish'){
              tempList = this.state.editDishCustomList;
            } else if (screen_type === 'addDish'){
              tempList = this.state.addDishCustomList;
            }
            let item = tempList[index];
            if (type === 'max'){
              item.customisationMaxSelections = e.target.value;
            } else {
              item.customisationMinSelections = e.target.value;
            }
            tempList[index] = item;
            if (screen_type === 'editDish' || screen_type === 'editBestDish'){
              this.setState({
                editDishCustomList: tempList
              })
            } else if (screen_type === 'addDish'){
              this.setState({
                addDishCustomList: tempList
              })
            }
          }}
          value={type === 'max' ? item.customisationMaxSelections : item.customisationMinSelections}
          defaultValue={type === 'max' ? item.customisationMaxSelections : item.customisationMinSelections}
        >
          {selectionList.map((selection, index) => {
            return (<MenuItem value={selection}>{selection}</MenuItem>)
          })}
        </Select>
      </FormControl>)
    }

    editDishesSection(){
      return(
        <Grid item xs={12} className="menuGridPosition2">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                <div style={{flex: "1"}}>
                  <ArrowBack onClick={() => this.toggleEditDishesSection(this.state.currentDishName, this.state.currentDishID)} style={{transform: 'translateY(5px)', marginRight: '5px', cursor: 'pointer'}}/>
                  <span className="bannerText">{this.state.dishOrMenu === "editDish" ? "Edit Dish" : "Edit Best Dish"} - {this.state.editDishName}</span>
                  <span className="dishAvailabilitySwitch">
                    <span className="inStockButton" onClick={() => this.toggleDishInStock(this.state.currentDishId, this.state.currentDishInStock, "edit_dish")}>
                      <span className="inStockSlider" style={this.state.currentDishInStock === true ? {left:'11px', boxShadow: '2px 0px 2px 0.5px #aaaaaa', background: '#0C950C'} : {left:'-11px', boxShadow: '-2px 0px 2px 0.5px #aaaaaa', background: '#DEE8FF'}}></span>
                    </span>
                  </span>
                  <span className="inStockText">In Stock</span>
                </div>
                <div style={{marginRight: '22px'}} className="SaveProceedText" onClick={() => this.handleDishSave()}>
                  {this.state.editDishSaveText}
                </div>
                {/* <div style={{marginTop: "10px"}}>
                  <span className="backButton" style={{marginRight: '22px'}} onClick={() => this.toggleEditDishesSection(this.state.currentDishName, this.state.currentDishID)}>Back</span>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{background:"#F5F5F5", width:"98%", borderRadius:"10px", padding: "20px 15px"}}>
                <Grid container spacing={0}>
                  <Grid item xs={5}>
                    <div style={{padding:"10px"}}>
                      <div className="editImage1">
                        {this.state.currentDishProfilePic === null ?<div onClick={() => {
                            this.props.history.push("/addDishImage/" + this.state.currentDishId);
                            localStorage.setItem('dish_image_id', this.state.currentDishId);
                            localStorage.setItem('current_menu', this.state.dishOrMenu);
                            localStorage.setItem('current_sub_menu_id', this.state.currentSubMenuId);
                            localStorage.setItem('dish_name', this.state.editDishName);
                            if (this.state.currentSubMenuDish !== null){
                              localStorage.setItem('current_sub_menu', JSON.stringify(this.state.currentSubMenuDish))
                            }
                          }}>
                          <img src={this.state.currentDishProfilePic === null ? SampleImage : this.state.currentDishProfilePic} alt="image1" width="100%" style={{borderRadius:"5px"}}/>
                        </div> : <img src={this.state.currentDishProfilePic === null ? SampleImage : this.state.currentDishProfilePic} alt="image1" width="100%" style={{borderRadius:"5px"}}/>}
                        
                        <Button size="small" className="saveButton" style={{float:"right", top:"30px", zIndex:"1000"}}
                          onClick={() => {
                            this.props.history.push("/addDishImage/" + this.state.currentDishId);
                            localStorage.setItem('dish_image_id', this.state.currentDishId);
                            localStorage.setItem('current_menu', this.state.dishOrMenu);
                            localStorage.setItem('current_sub_menu_id', this.state.currentSubMenuId);
                            localStorage.setItem('dish_name', this.state.editDishName);
                            if (this.state.currentSubMenuDish !== null){
                              localStorage.setItem('current_sub_menu', JSON.stringify(this.state.currentSubMenuDish))
                            }
                          }}>
                          {this.state.currentDishProfilePic === null ? "Add Image" : "Replace Image"}
                        </Button>
                        
                        <div style={{fontSize:"12px", fontFamily:"Poppins-Medium", paddingLeft:"5px", position:"relative", top:"5px"}}>Maximum Size : <span style={{color: "#ED7042"}}>5 MB</span></div>
                        <div style={{fontSize:"12px", fontFamily:"Poppins-Medium", paddingLeft:"5px"}}>Dimensions : <span style={{color: "#ED7042"}}>200 x 200</span></div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={7}>
                    <div style={{paddingLeft:"10px"}}>
                      {this.state.dishOrMenu === "editDish" ? <div className="popularDishSelectDiv" style={{position:"relative", top:"10px"}}>
                        <FormControlLabel
                          className="checkBoxPos"
                          style={{cursor: (this.state.currentDishIsBestDish || (this.props.popularDishesCount === 4 && !this.state.currentDishIsPopular_flag)) ? "no-drop" : "pointer"}}
                          control={
                            <Checkbox
                              disabled={this.state.currentDishIsBestDish || (this.props.popularDishesCount === 4 && !this.state.currentDishIsPopular_flag)}
                              checked={this.state.currentDishIsPopular}
                              onChange={this.handleCheckBoxChangePopDish}
                              name="checkedB"
                              color="primary"
                            />
                          }
                        />
                        <span style={{display:"inline-grid", marginLeft:"-10px"}}>
                          <div className="checkBoxText" style={this.state.currentDishIsBestDish ? {top:"4px"} : {top:"-3px"}}>{this.state.currentDishIsBestDish ? "This is already a Best Dish of your Restaurant" : "Mark as popular dish(" + this.props.popularDishesCount + "/4)"}</div>
                          {this.state.currentDishIsBestDish ? null : <div className="checkBoxSubText">A dish picture is mandatory to mark the dish as "Popular"</div>}  
                        </span>
                      </div> : null}
                      <div className="textBoxLabel">Name of the Dish*</div>
                      <input disabled={this.state.dishOrMenu === "editBestDish" || this.state.currentDishIsBestDish} value={this.state.currentDishName} onChange={(event) => this.setState({currentDishName: event.target.value})} type="text" placeholder="Name of the Dish" className="editTextBoxStyle" style={{width:"99%"}}/>
                      <div style={{marginTop: "15px"}}>
                        <RadioGroup row aria-label="dishType" name="dishType" value={this.state.currentDishChoice} onChange={this.handleDishTypeChange}>
                          <FormControlLabel value="V" control={<Radio color="default"/>} /><FiberManualRecordIcon style={{ fontSize: 17, marginTop: '4px' }} className="vegTag"/><span className="radioLabel">Veg</span>
                          <FormControlLabel value="N" control={<Radio color="default"/>} /><IoTriangleSharp style={{ fontSize: 17, marginTop: '4px' }} className="nonVegTag"/><span className="radioLabel">Non-Veg</span>
                          <FormControlLabel value="E" control={<Radio color="default"/>} /><FiberManualRecordIcon style={{ fontSize: 17, marginTop: '4px' }} className="eggTag"/><span className="radioLabel">Egg</span>
                        </RadioGroup>
                      </div>
                      <div className="textBoxLabel">Price*</div>
                      <input value={this.state.currentDishCost} onChange={(event) => this.setState({currentDishCost: event.target.value})} type="text" placeholder="Rs." className="editTextBoxStyle" style={{width:"40%"}}/>
                      <div className="textBoxLabel">Packaging Charge Per Unit*</div>
                      <input value={this.state.currentDishPackaging} onChange={(event) => this.setState({currentDishPackaging: event.target.value})} type="text" placeholder="Rs." className="editTextBoxStyle" style={{width:"40%"}}/>
                      <div className="textBoxLabel">Description*<span className="wordCounter">{this.state.currentDishDescription?.length??0}/{this.state.currentDishIsBestDish ? "225" : "225"} characters</span></div>
                      <textarea value={this.state.currentDishDescription} onChange={(event) => {
                        if (event.target.value.length <= (this.state.currentDishIsBestDish ? "225" : "225")){
                          this.setState({currentDishDescription: event.target.value})
                        } else {
                          this.setState({currentDishDescription: event.target.value.substring(0,(this.state.currentDishIsBestDish ? "225" : "225"))})
                        }
                      }} type="text" className="dishDescTextArea" rows={8} placeholder='Enter a brief Description Here' />
                      {/* <span style={{float:"right", top:"5px", left :"6px"}} className="backButton">Save</span> */}
                    </div>  
                  </Grid>
                  {this.state.addCustomLabelTextBoxED ? <Grid style={{display: "flex", flexDirection: "row"}} item xs={12}>
                    {/* <div className="buttonOuterCoating" style={{width:"60px", marginLeft:"10px", marginTop:"20px"}} onClick={this.handleAddDishAddOnSwitchChange}>
                      <span className="buttonOnOff" style={this.state.addDishAddOnsSwitchStyle}>+</span>
                    </div> */}
                    <div className="textBoxLabel" style={{color:"#2B2624", paddingLeft:"10px", transform: "translateY(10px)", marginBottom: "10px", marginLeft: "20px", flex: "1", fontSize: "15px"}}>{this.state.currentCustomisationIndex === -1 ? "Add customisation" : "Edit customisation"}</div>
                    <div className="textBoxLabel" onClick={() => {
                      let tempData = this.state.editDishCustomList;
                      if (this.state.currentCustomisationIndex === -1){
                        tempData.pop();
                      }
                      this.setState({
                        editDishCustomList : tempData,
                        addCustomLabelTextBoxED : false,
                        editDishCustomisationVisibility : true,
                      });
                    }} style={{color:"#1E429A", backgroundColor: "#F5F5F5", border: "1px solid #1E429A", borderRadius: "5px", padding: "8px 20px", cursor:"pointer", marginTop: "10px", marginBottom: "10px", marginRight: "20px"}}>
                      Cancel
                    </div>
                    <div className="textBoxLabel" onClick={() => this.handleCustomisationSave("customisation_save", this.state.dishOrMenu)} style={{color:"#FFFFFF", backgroundColor: "#485E9C", border: "1px solid #485E9C", borderRadius: "5px", padding: "8px 20px", cursor:"pointer", marginTop: "10px", marginBottom: "10px", marginRight: "20px"}}>
                      Save
                    </div>
                  </Grid> : <Grid style={{display: "flex", flexDirection: "row"}} item xs={12}>
                    {/* <div className="buttonOuterCoating" style={{width:"60px", marginLeft:"10px", marginTop:"20px"}} onClick={this.handleAddDishAddOnSwitchChange}>
                      <span className="buttonOnOff" style={this.state.addDishAddOnsSwitchStyle}>+</span>
                    </div> */}
                    <div className="textBoxLabel" style={{color:"#2B2624", paddingLeft:"10px", transform: "translateY(10px)", marginBottom: "10px", marginLeft: "20px", flex: "1"}}>Dish Customisation/Add Ons</div>
                    <div className="textBoxLabel" onClick={() => {
                      let newItem = {
                        customisationName : "",
                        customisationMinSelections : "",
                        customisationMaxSelections: 1,
                        customisationDefault: "",
                        customisationValues : [{
                          'name': "",
                          'price': 0,
                          'type':  "V",
                          'unique_code': "",
                          'is_default': false
                        }],
                        customisationCode: ''
                      }
                      let tempData = this.state.editDishCustomList;
                      tempData.push(newItem);
                      this.setState({
                        editDishCustomList : tempData,
                        addCustomLabelTextBoxED : true,
                        editDishCustomisationVisibility : true,
                        currentCustomisationIndex: -1
                      });
                    }} style={{color:"#FFFFFF", backgroundColor: "#485E9C", borderRadius: "5px", padding: "8px 12px", cursor:"pointer", marginTop: "10px", marginBottom: "10px", marginRight: "20px"}}>
                      + Add Customisation
                    </div>
                  </Grid>}
                  {/* <Grid item xs={12}>
                    <span className="textBoxLabel" style={{position:"relative", top:"-7px", color:"#425FA1", paddingLeft:"10px"}}>Dish Customisation/Add Ons</span>
                    <span  disabled={!this.state.editDishAddOnsSwitch} className="textBoxLabel" onClick={() => this.setState({
                      editDishCustomisationVisibility : true,
                      addCustomLabelTextBoxED : true})} style={{position:"relative", padding: "6px 10px", borderRadius: "5px", top:"14px", color:"white", backgroundColor: "#425FA1", float:"right", cursor:"pointer"}}>
                      + Add Options
                    </span>
                  </Grid> */}
                  {(this.state.editDishCustomisationVisibility || this.state.editDishCustomList.length > 0) && <div className="customCards" style={{backgroundColor: "transparent", padding: "0px 10px", marginTop: "0px"}}>
                    <Grid item xs={12}>
                      <Grid container spacing={0} style={{paddingTop: "10px", paddingLeft: "10px"}}>
                      {this.state.addCustomLabelTextBoxED && <Grid container style={{backgroundColor: "white", padding: "20px"}}>
                        <Grid item xs={12}>
                          <div style={{paddingRight:"10px"}}>
                            <div style={{fontFamily: "Poppins-Medium", fontSize: "15px", paddingBottom: "5px"}}>Customisation Label</div>
                            <input value={this.state.editDishCustomList[this.state.currentCustomisationIndex === -1 ? this.state.editDishCustomList.length - 1 : this.state.currentCustomisationIndex].customisationName} onChange={(event) => {
                              let tempData = this.state.editDishCustomList;
                              let changeIndex = this.state.currentCustomisationIndex === -1 ? tempData.length - 1 : this.state.currentCustomisationIndex;
                              let item = tempData[changeIndex];
                              item.customisationName = event.target.value;
                              tempData[changeIndex] = item;
                              this.setState({
                                editDishCustomList: tempData
                              });
                            }} type="text" placeholder='Enter Dish Customisation Label here, Eg "Size" - Regular or Large' className="editTextBoxStyle" style={{width:"100%", border:"#D9D9D9 solid 1px", padding: "10px", marginBottom: "6px", marginTop: "6px"}}/>
                            <RadioGroup style={{marginBottom: "6px"}} row aria-label="dishType" name="dishType" value={this.state.editDishCustomList[this.state.currentCustomisationIndex === -1 ? this.state.editDishCustomList.length - 1 : this.state.currentCustomisationIndex].customisationMinSelections >= 1 ? "mandatory" : this.state.editDishCustomList[this.state.currentCustomisationIndex === -1 ? this.state.editDishCustomList.length - 1 : this.state.currentCustomisationIndex].customisationMinSelections === 0 ? "optional" : ""} onChange={(event) => {
                              let tempData = this.state.editDishCustomList;
                              let changeIndex = this.state.currentCustomisationIndex === -1 ? tempData.length - 1 : this.state.currentCustomisationIndex;
                              let item = tempData[changeIndex];
                              item.customisationMinSelections = event.target.value === 'optional' ? 0 : 1;
                              tempData[changeIndex] = item;
                              this.setState({
                                editDishCustomList: tempData
                              });
                            }}>
                              <FormControlLabel value="mandatory" control={<Radio size="small" color="default"/>} /><span className="radioLabelSmall">Mandatory Selection</span>
                              <FormControlLabel value="optional" control={<Radio size="small" color="default"/>} /><span className="radioLabelSmall">Optional Selection</span>
                            </RadioGroup>
                            <Divider/>
                            <div style={{fontFamily: "Poppins-Medium", fontSize: "15px", paddingBottom: "5px", marginTop: "20px"}}>Select the minimum and maximum number of selections the user can make below:</div>
                            <div style={{marginBottom: "20px"}}>
                              {this.getSelectionItem("editDish", this.state.currentCustomisationIndex === -1 ? this.state.editDishCustomList.length - 1 : this.state.currentCustomisationIndex, "min")}
                              {this.getSelectionItem("editDish", this.state.currentCustomisationIndex === -1 ? this.state.editDishCustomList.length - 1 : this.state.currentCustomisationIndex, "max")}
                            </div>
                          </div>
                        </Grid>
                        <Grid container spacing={2} style={{paddingLeft:"0px", paddingRight:"0px"}}>
                          {this.customTypesList(this.state.editDishCustomList[this.state.currentCustomisationIndex === -1 ? this.state.editDishCustomList.length - 1 : this.state.currentCustomisationIndex], this.state.currentCustomisationIndex === -1 ? this.state.editDishCustomList.length - 1 : this.state.currentCustomisationIndex, "editDish", true)}
                          <Grid item xs={12}>
                            <span onClick={() => this.addCustomType(this.state.currentCustomisationIndex === -1 ? this.state.editDishCustomList.length - 1 : this.state.currentCustomisationIndex, "editDish")} className="textBoxLabel" style={{position:"relative",color:"#EB622F", cursor:"pointer"}}>
                              + Add Customisation type
                            </span>
                          </Grid>
                        </Grid>
                        </Grid>}
                      </Grid>
                    </Grid>
                    {!this.state.addCustomLabelTextBoxED && this.dishCustomisationAccordions("editDish")}
                  </div>}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    handleAddCustomisation(){
      if (this.state.currentDishId === -1){
        this.props.informationBox("Please save the dish details first & try again");
        return;
      } else {
        this.setState({
          addDishCustomisationVisibility : true,
          addCustomLabelTextBoxAD : true
        })
      }
    }

    addDishSection(){
      return(
        <Grid item xs={12} className="menuGridPosition2">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                <ArrowBack onClick={() => this.toggleEditDishesSection("")} style={{marginRight: '5px', cursor: 'pointer'}}/>
                <div style={{flex: "1"}}>
                  <span className="bannerText">Add New Dish</span>
                </div>
                <Button onClick={() => this.handleDishSave()} className="saveButton" style={{left:"-10px"}}>
                  {this.state.editDishSaveText}
                </Button>
                {/* <Button className="saveButton" style={{left:"-10px"}} onClick={() => this.toggleEditDishesSection("")}>
                  Back
                </Button> */}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{background:"#F5F5F5", width:"98%", borderRadius:"10px", padding: "20px 15px"}}>
                <Grid container spacing={0}>
                  <Grid item xs={5}>
                    <div style={{padding:"10px"}}>
                      <div className="editImage1">
                        {this.state.currentDishProfilePic === null ?<div onClick={() => {
                              this.props.history.push("/addDishImage/" + this.state.currentDishId);
                              localStorage.setItem('dish_image_id', this.state.currentDishId);
                              localStorage.setItem('current_menu', this.state.dishOrMenu);
                              localStorage.setItem('current_sub_menu_id', this.state.currentSubMenuId);
                              localStorage.setItem('dish_name', this.state.editDishName);
                              if (this.state.currentSubMenuDish !== null){
                                localStorage.setItem('current_sub_menu', JSON.stringify(this.state.currentSubMenuDish))
                              }
                            }}>
                          <img src={this.state.currentDishProfilePic === null ? SampleImage : this.state.currentDishProfilePic} alt="image1" width="100%" style={{borderRadius:"5px"}}/>
                        </div> : <img src={this.state.currentDishProfilePic === null ? SampleImage : this.state.currentDishProfilePic} alt="image1" width="100%" style={{borderRadius:"5px"}}/>}
                        {this.state.currentDishId !== -1 ? 
                          <Button size="small" className="saveButton" style={{float:"right", top:"30px", zIndex:"1000"}}
                            onClick={() => {
                              this.props.history.push("/addDishImage/" + this.state.currentDishId);
                              localStorage.setItem('dish_image_id', this.state.currentDishId);
                              localStorage.setItem('current_menu', this.state.dishOrMenu);
                              localStorage.setItem('current_sub_menu_id', this.state.currentSubMenuId);
                              localStorage.setItem('dish_name', this.state.editDishName);
                              if (this.state.currentSubMenuDish !== null){
                                localStorage.setItem('current_sub_menu', JSON.stringify(this.state.currentSubMenuDish))
                              }
                            }}>
                            {this.state.currentDishProfilePic === null ? "Add Image" : "Replace Image"}
                          </Button>
                          : 
                          <Button onClick={() => this.props.informationBox("Please save the dish details first & try again")} size="small" className="saveButton" style={{float:"right", top:"30px", zIndex:"1000"}}>
                            {this.state.currentDishProfilePic === null ? "Add Image" : "Replace Image"}
                          </Button>
                        }
                        <div style={{fontSize:"12px", fontFamily:"Poppins-Medium", paddingLeft:"5px", position:"relative", top:"5px"}}>Maximum Size : <span style={{color: "#ED7042"}}>5 MB</span></div>
                        <div style={{fontSize:"12px", fontFamily:"Poppins-Medium", paddingLeft:"5px"}}>Dimensions : <span style={{color: "#ED7042"}}>200 x 200</span></div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={7}>
                    <div style={{paddingLeft:"10px"}}>
                      <div className="popularDishSelectDiv" style={{position:"relative", top:"10px"}}>
                        {/* <FormControlLabel
                          className="checkBoxPos"
                          control={
                            <Checkbox
                              checked={this.state.currentDishIsPopular}
                              onChange={this.handleCheckBoxChangePopDish}
                              name="checkedB"
                              color="primary"
                            />
                          }
                        /> */}
                        <FormControlLabel
                          className="checkBoxPos"
                          style={{cursor: (this.state.currentDishIsBestDish || (this.props.popularDishesCount === 4 && !this.state.currentDishIsPopular_flag)) ? "no-drop" : "pointer"}}
                          control={
                            <Checkbox
                              disabled={this.state.currentDishIsBestDish || (this.props.popularDishesCount === 4 && !this.state.currentDishIsPopular_flag)}
                              checked={this.state.currentDishIsPopular}
                              onChange={this.handleCheckBoxChangePopDish}
                              name="checkedB"
                              color="primary"
                            />
                          }
                        />
                        <span style={{display:"inline-grid", marginLeft:"-10px"}}>
                          <div className="checkBoxText">Mark as popular dish({this.props.popularDishesCount}/4)</div>
                          <div className="checkBoxSubText">This Dish will appear with the dish image below the best dish.</div>  
                        </span>
                      </div>
                      <div className="textBoxLabel">Name of the Dish*</div>
                      <input value={this.state.currentDishName} onChange={(event) => this.setState({currentDishName: event.target.value})} type="text" placeholder="Name of the Dish" className="editTextBoxStyle" style={{width:"99%"}}/>
                      <div style={{marginTop: "15px"}}>
                        <RadioGroup row aria-label="dishType" name="dishType" value={this.state.currentDishChoice} onChange={this.handleDishTypeChange}>
                          <FormControlLabel value="V" control={<Radio color="default"/>} /><FiberManualRecordIcon style={{ fontSize: 17, marginTop: '4px' }} className="vegTag"/><span className="radioLabel">Veg</span>
                          <FormControlLabel value="N" control={<Radio color="default"/>} /><IoTriangleSharp style={{ fontSize: 17, marginTop: '4px' }} className="nonVegTag"/><span className="radioLabel">Non-Veg</span>
                          <FormControlLabel value="E" control={<Radio color="default"/>} /><FiberManualRecordIcon style={{ fontSize: 17, marginTop: '4px' }} className="eggTag"/><span className="radioLabel">Egg</span>
                        </RadioGroup>
                      </div>
                      
                      <div className="textBoxLabel">Price*</div>
                      <input value={this.state.currentDishCost} onChange={(event) => this.setState({currentDishCost: event.target.value})} type="text" placeholder="Rs." className="editTextBoxStyle" style={{width:"40%"}}/>
                      <div className="textBoxLabel">Packaging Charge Per Unit*</div>
                      <input value={this.state.currentDishPackaging} onChange={(event) => this.setState({currentDishPackaging: event.target.value})} type="text" placeholder="Rs." className="editTextBoxStyle" style={{width:"40%"}}/>
                      <div className="textBoxLabel">Description*<span className="wordCounter">{this.state.currentDishDescription?.length??0}/225 characters</span></div>
                      <textarea value={this.state.currentDishDescription} onChange={(event) => {
                        if (event.target.value.length <= 225){
                          this.setState({currentDishDescription: event.target.value})
                        } else {
                          this.setState({currentDishDescription: event.target.value.substring(0,225)})
                        }
                      }} type="text" className="dishDescTextArea" style={{height:"195px"}} rows={8} placeholder='Enter a brief Description Here' />
                      {/* <span style={{float:"right", top:"5px", left :"6px"}} className="backButton">Save</span> */}
                    </div>
                  </Grid>
                  {this.state.addCustomLabelTextBoxED ? <Grid style={{display: "flex", flexDirection: "row"}} item xs={12}>
                    {/* <div className="buttonOuterCoating" style={{width:"60px", marginLeft:"10px", marginTop:"20px"}} onClick={this.handleAddDishAddOnSwitchChange}>
                      <span className="buttonOnOff" style={this.state.addDishAddOnsSwitchStyle}>+</span>
                    </div> */}
                    <div className="textBoxLabel" style={{color:"#2B2624", paddingLeft:"10px", transform: "translateY(10px)", marginBottom: "10px", marginLeft: "20px", flex: "1", fontSize: "15px"}}>{this.state.currentCustomisationIndex === -1 ? "Add customisation" : "Edit customisation"}</div>
                    <div className="textBoxLabel" onClick={() => {
                      let tempData = this.state.addDishCustomList;
                      if (this.state.currentCustomisationIndex === -1){
                        tempData.pop();
                      }
                      this.setState({
                        addDishCustomList : tempData,
                        addCustomLabelTextBoxED : false,
                        editDishCustomisationVisibility : true,
                      });
                    }} style={{color:"#1E429A", backgroundColor: "#F5F5F5", border: "1px solid #1E429A", borderRadius: "5px", padding: "8px 20px", cursor:"pointer", marginTop: "10px", marginBottom: "10px", marginRight: "20px"}}>
                      Cancel
                    </div>
                    <div className="textBoxLabel" onClick={() => this.handleCustomisationSave("customisation_save", this.state.dishOrMenu)} style={{color:"#FFFFFF", backgroundColor: "#485E9C", border: "1px solid #485E9C", borderRadius: "5px", padding: "8px 20px", cursor:"pointer", marginTop: "10px", marginBottom: "10px", marginRight: "20px"}}>
                      Save
                    </div>
                  </Grid> : <Grid style={{display: "flex", flexDirection: "row"}} item xs={12}>
                    {/* <div className="buttonOuterCoating" style={{width:"60px", marginLeft:"10px", marginTop:"20px"}} onClick={this.handleAddDishAddOnSwitchChange}>
                      <span className="buttonOnOff" style={this.state.addDishAddOnsSwitchStyle}>+</span>
                    </div> */}
                    <div className="textBoxLabel" style={{color:"#2B2624", paddingLeft:"10px", transform: "translateY(10px)", marginBottom: "10px", marginLeft: "20px", flex: "1"}}>Dish Customisation/Add Ons</div>
                    <div className="textBoxLabel" onClick={() => {
                      if (this.state.currentDishId === -1){
                        this.props.informationBox("Please save the dish details first & try again");
                        return;
                      }
                      let newItem = {
                        customisationName : "",
                        customisationMinSelections : "",
                        customisationMaxSelections: 1,
                        customisationDefault: "",
                        customisationValues : [{
                          'name': "",
                          'price': 0,
                          'type':  "V",
                          'unique_code': "",
                          'is_default': false
                        }],
                        customisationCode: ''
                      }
                      let tempData = this.state.addDishCustomList;
                      tempData.push(newItem);
                      this.setState({
                        addDishCustomList : tempData,
                        addCustomLabelTextBoxED : true,
                        addDishCustomisationVisibility : true,
                        currentCustomisationIndex: -1
                      });
                    }} style={{color:"#FFFFFF", backgroundColor: "#485E9C", borderRadius: "5px", padding: "8px 12px", cursor:"pointer", marginTop: "10px", marginBottom: "10px", marginRight: "20px"}}>
                      + Add Customisation
                    </div>
                    {/* <div className="textBoxLabel" style={{color:"#425FA1", paddingLeft:"10px", transform: "translateY(10px)", marginBottom: "10px", marginLeft: "20px", flex: "1"}}>Dish Customisation/Add Ons</div>
                    <div className="textBoxLabel" onClick={() => this.handleAddCustomisation()} style={{color:"#EB622F", cursor:"pointer", marginTop: "10px", marginBottom: "10px", marginRight: "20px"}}>
                      <span style={{position: "relative", borderRadius: "50%",padding : "1px 6px", background : "#EB622F", color: "#FFFFFF", left: "-3px", boxShadow: "2px 0px 2px 0.5px #aaaaaa"}}>+</span>
                      Add Customisation
                    </div> */}
                  </Grid>}
                  <div className="customCards" style={{backgroundColor: "transparent", padding: "0px 10px", marginTop: "0px"}}>
                    <Grid item xs={12}>
                      <Grid container spacing={0} style={{paddingTop: "10px", paddingLeft: "10px"}}>
                        {this.state.addCustomLabelTextBoxED && <Grid container style={{backgroundColor: "white", padding: "20px"}}>
                        <Grid item xs={12}>
                          <div style={{paddingRight:"10px"}}>
                            <div style={{fontFamily: "Poppins-Medium", fontSize: "15px", paddingBottom: "5px"}}>Customisation Label</div>
                            <input value={this.state.addDishCustomList[this.state.currentCustomisationIndex === -1 ? this.state.addDishCustomList.length - 1 : this.state.currentCustomisationIndex].customisationName} onChange={(event) => {
                              let tempData = this.state.addDishCustomList;
                              let changeIndex = this.state.currentCustomisationIndex === -1 ? tempData.length - 1 : this.state.currentCustomisationIndex;
                              let item = tempData[changeIndex];
                              item.customisationName = event.target.value;
                              tempData[changeIndex] = item;
                              this.setState({
                                addDishCustomList: tempData
                              });
                            }} type="text" placeholder='Enter Dish Customisation Label here, Eg "Size" - Regular or Large' className="editTextBoxStyle" style={{width:"100%", border:"#D9D9D9 solid 1px", padding: "10px", marginBottom: "6px", marginTop: "6px"}}/>
                            <RadioGroup style={{marginBottom: "6px"}} row aria-label="dishType" name="dishType" value={this.state.addDishCustomList[this.state.currentCustomisationIndex === -1 ? this.state.addDishCustomList.length - 1 : this.state.currentCustomisationIndex].customisationMinSelections === 1 ? "mandatory" : this.state.addDishCustomList[this.state.currentCustomisationIndex === -1 ? this.state.addDishCustomList.length - 1 : this.state.currentCustomisationIndex].customisationMinSelections === 0 ? "optional" : ""} onChange={(event) => {
                              let tempData = this.state.addDishCustomList;
                              let changeIndex = this.state.currentCustomisationIndex === -1 ? tempData.length - 1 : this.state.currentCustomisationIndex;
                              let item = tempData[changeIndex];
                              item.customisationMinSelections = event.target.value === 'optional' ? 0 : 1;
                              tempData[changeIndex] = item;
                              this.setState({
                                addDishCustomList: tempData
                              });
                            }}>
                              <FormControlLabel value="mandatory" control={<Radio size="small" color="default"/>} /><span className="radioLabelSmall">Mandatory Selection</span>
                              <FormControlLabel value="optional" control={<Radio size="small" color="default"/>} /><span className="radioLabelSmall">Optional Selection</span>
                            </RadioGroup>
                            <Divider/>
                            <div style={{fontFamily: "Poppins-Medium", fontSize: "16px", paddingBottom: "5px", marginTop: "20px"}}>Select the minimum and maximum number of selections the user can make below:</div>
                            <div style={{marginBottom: "20px"}}>
                              {this.getSelectionItem("addDish", this.state.currentCustomisationIndex === -1 ? this.state.addDishCustomList.length - 1 : this.state.currentCustomisationIndex, "min")}
                              {this.getSelectionItem("addDish", this.state.currentCustomisationIndex === -1 ? this.state.addDishCustomList.length - 1 : this.state.currentCustomisationIndex, "max")}
                            </div>
                          </div>
                        </Grid>
                        <Grid container spacing={2} style={{paddingLeft:"0px", paddingRight:"0px"}}>
                          {this.customTypesList(this.state.addDishCustomList[this.state.currentCustomisationIndex === -1 ? this.state.addDishCustomList.length - 1 : this.state.currentCustomisationIndex], this.state.currentCustomisationIndex === -1 ? this.state.addDishCustomList.length - 1 : this.state.currentCustomisationIndex, "addDish", true)}
                          <Grid item xs={12}>
                            <span onClick={() => this.addCustomType(this.state.currentCustomisationIndex === -1 ? this.state.addDishCustomList.length - 1 : this.state.currentCustomisationIndex, "addDish")} className="textBoxLabel" style={{position:"relative",color:"#EB622F", cursor:"pointer"}}>
                              + Add Customisation type
                            </span>
                          </Grid>
                        </Grid>
                        </Grid>}
                      </Grid>
                    </Grid>
                    {!this.state.addCustomLabelTextBoxED && this.dishCustomisationAccordions("addDish")}
                  </div>
                  {/* <Grid item xs={12} >
                    <div style={{position:"relative", float:"right", top:"10px", left:"10px"}}>
                      <Button size="small" className="saveButton">
                        Save New Dish
                      </Button>
                      <Button size="small" className="saveButton" onClick={() => this.toggleEditDishesSection("")}>
                        Cancel
                      </Button>
                    </div>
                  </Grid> */}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    handleEditBestDish(dishName, dishId, index){
      this.toggleEditDishesSection(dishName, dishId);
      this.setState({
        bestDishIndex: index
      })
    }

    bestDishListSection(){
      return (
        <div>
          <div style={{fontFamily: 'Bree-Regular', fontSize: "24px", padding: "20px"}}>Best Dish</div>
          <div style={{backgroundColor: "#F5F5F5", padding: "20px 30px", borderRadius: "10px"}}>
            {/* {this.props.bestDishes.map((dish, index) => 
              <div>
                <BestDish
                  dish={dish}
                  restaurant={this.props.restaurant}
                  index={index}
                  handleEditBestDish={this.handleEditBestDish}
                  toggleDishInStock={this.toggleDishInStock}
                />
              </div>
            )} */}

            {this.props.bestDishes[0] !== undefined ? 
              <div>
                <BestDish
                  dish={this.props.bestDishes[0]}
                  restaurant={this.props.restaurant}
                  index={0}
                  handleEditBestDish={this.handleEditBestDish}
                  toggleDishInStock={this.toggleDishInStock}
                />
              </div>
              :
              <div className="BestDishSuperContainer" style={{marginTop: "30px"}}>
                <div className="DishCardWrapper">
                  <div className="BestDishContainer">
                    <span className="Ribbon BestDishRibbon">
                      <div className="RibbonText">
                        <Star style={{color: "#FFFFFF", fontSize: "14px", transform: "translateY(2px)", marginRight: "5px"}}/>
                        Best Dish 1
                      </div>
                    </span>
                    <div className="BestDishImageContainer" style={{borderTopRightRadius:"0px", borderBottomRightRadius:"0px"}}>
                      <img className="noBestDish" src={NoBestDish1} alt="bestDish"/>
                    </div>
                    <div className="BestDishDescription" style={{justifyContent:"center", alignItems:"center", fontFamily:"Poppins-Medium", color:"#777362"}}>
                      <div className="DishDetailsRow" style={{padding: "10px"}}>
                        You don't have your first best dish rated yet.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {this.props.bestDishes[1] !== undefined ? 
              <div>
                <BestDish
                  dish={this.props.bestDishes[1]}
                  restaurant={this.props.restaurant}
                  index={1}
                  handleEditBestDish={this.handleEditBestDish}
                  toggleDishInStock={this.toggleDishInStock}
                />
              </div>
              :
              <div className="BestDishSuperContainer" style={{marginTop: "30px"}}>
                <div className="DishCardWrapper">
                  <div className="BestDishContainer">
                    <span className="Ribbon BestDishRibbon">
                      <div className="RibbonText">
                        <Star style={{color: "#FFFFFF", fontSize: "14px", transform: "translateY(2px)", marginRight: "5px"}}/>
                        Best Dish 2
                      </div>
                    </span>
                    <div className="BestDishImageContainer" style={{borderTopRightRadius:"0px", borderBottomRightRadius:"0px"}}>
                      <img className="noBestDish" src={NoBestDish2} alt="bestDish"/>
                    </div>
                    <div className="BestDishDescription" style={{justifyContent:"center", alignItems:"center", fontFamily:"Poppins-Medium", color:"#777362"}}>
                      <div className="DishDetailsRow" style={{padding: "10px"}}>
                        You don't have your second best dish rated yet.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }


          </div>
        </div>
      )
    }

    render() {
        return(
          <div style={{padding: "10px 30px"}}>
              {this.confirmActionPopup()}
              <Grid container spacing={0}>
              <Grid item xs={12} className="menuGridPosition2">
                <span className="editDishMenuBtnWrapper">
                  <span className={(this.state.dishOrMenu === "bestDish" || this.state.dishOrMenu === "editBestDish") ? "editDishMenuSelected" : "editDishMenuUnSelected"} onClick={this.toggleToEditBestDish}>Edit Best Dish</span>
                  <span className={(this.state.dishOrMenu === "bestDish" || this.state.dishOrMenu === "editBestDish") ? "editDishMenuUnSelected": "editDishMenuSelected"} onClick={this.toggleToEditMenu}>Edit & Add Dishes to Sub-Menus</span>
                </span>
              </Grid>

              {this.state.dishOrMenu === "bestDish" && this.bestDishListSection()}
              {/* {this.state.dishOrMenu === "bestDish" && this.editBestDishSection()} */}
              {this.state.dishOrMenu === "subMenus" && this.editSubMenusSection()}
              {this.state.dishOrMenu === "dishList" && this.editSubMenusDishesSection()}
              {this.state.dishOrMenu === "editBestDish" && this.editDishesSection()}
              {this.state.dishOrMenu === "editDish" && this.editDishesSection()}
              {this.state.dishOrMenu === "addDish" && this.addDishSection()}
            </Grid>
          </div>
        );
    }
}
export default withRouter(ManageMenu);