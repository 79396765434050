import axios from 'axios';
import React, { Component } from 'react';
import CONSTANTS from '../../config/constants';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router';
import SearchIcon from '@material-ui/icons/Search';
import { FiFilter } from "react-icons/fi";
import '../../assets/styles/OrderHistory.css';

class Orders extends Component {
  constructor(){
    super();
    this.toggleOrderDetailsModal = this.toggleOrderDetailsModal.bind(this);
    this.closeOrderDetailsModal = this.closeOrderDetailsModal.bind(this);
    this.orderSearch = this.orderSearch.bind(this);
    this.state = {
      isMobileScreen: false,
      orderList: [],
      tempOrderList: [],
      showOrderDetailsModal: false,
      searchBarText: "",
      orderCount: 0,
      filterType: 'All Time',
      ordersFetching: true
    }
  }

  componentDidMount() {
    this.getOrderList(1, 0);
  }

  getOrderList(pageNo, period){
    if (pageNo === 1){
      this.setState({
        ordersFetching: true
      })
    }
    let url = CONSTANTS.hostURL + "api/resto-app/orders/order-history/?page=" + pageNo;
    if (period !== 0){
      url = url + '&period=' + period;
    }
    let headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    }
    axios.get(url, {
      headers: headers
    }).then(res => {
      if (res.data.status === 1){
        if (pageNo === 1){
          this.setState({
            orderCount: res.data.data.count,
            tempOrderList: [],
            orderList: [],
            ordersFetching: false
          });
        }
        let orderList = this.state.orderList;
        if (res.data.data.results !== null){
          for (var i=0; i<res.data.data.results.length; i++){
            orderList.push(res.data.data.results[i]);
          }
          this.setState({
            orderList: orderList,
            tempOrderList: orderList
          })
          let pageCount = Math.ceil(res.data.data.count/15);
          if (pageNo + 1 < pageCount){
            this.getOrderList(pageNo + 1, period)
          }
        }
      }
    });
  }

  itemsCount(orderItem){
    var itemCount = 0
    var itemVal = Object.values(orderItem.items)
    for(var i=0; i<itemVal.length; i++){
      itemCount = itemCount + itemVal[i].quantity
    }
    return itemCount;
  }

  getOrderedAt(orderedAt){
    var momentTz = require('moment-timezone');
    var date = momentTz.tz(orderedAt, 'Asia/Kolkata').format('llll');
    return date;
  }

  displayOrderList(){
    const listItems = this.state.tempOrderList.map((keyItem) =>
      <Grid key={"orderItem_"+keyItem.id} item xs={12} /*style={{paddingLeft:"150px", paddingRight:"150px"}}*/>
        <div className="ddOrderListBox">
          <Grid container spacing={0} style={{padding: "30px 40px 30px 40px"}}>
            <Grid item xs={9}>
              <div><span style={{color:"#3356AA", fontFamily:"Poppins-Medium", fontSize:"14px"}}>ORDER #{keyItem.order_id}</span><span style={{color:"#83706A", fontFamily:"Poppins-Medium", fontSize:"12px", marginLeft:"5px"}}> | {this.getOrderedAt(keyItem.ordered_at)}</span></div>
              <div><span style={{color:"#EA5B2F", fontFamily:"Poppins-Medium", fontSize:"14px"}}>{this.props.restaurant.name}, {this.props.restaurant.address}</span></div>
              <div style={{marginTop:"10px"}}><span style={{color:"#2B2624", fontFamily:"Poppins-Medium", fontSize:"12px"}}>{this.itemsCount(keyItem) > 1 ? this.itemsCount(keyItem) + " Items" : this.itemsCount(keyItem) + " Item"}</span></div>
              <div><span style={{color:"#2B2624", fontFamily:"Poppins-Medium", fontSize:"12px"}}>Rs. {keyItem.net_total}</span></div>
              <div><span style={{color:"#2B2624", fontFamily:"Poppins-Medium", fontSize:"12px"}}>{keyItem.status_update_logs.ON_THE_WAY !== undefined ? "Delivered by " + keyItem.status_update_logs.ON_THE_WAY.delivery_partner_name : "Picked up from Restaurant"}</span></div>
              <div><span style={{color:"#2B2624", fontFamily:"Poppins-Medium", fontSize:"12px", textDecoration:"underline", cursor:"pointer"}} onClick={() => {this.toggleOrderDetailsModal(keyItem)}}>View Order Details</span></div>
            </Grid>
            <Grid item xs={3}>
              {keyItem.invoice_url === null ? 
                <div className="ddAddCartBtn" onClick={() => this.props.informationBox('Invoice not found for this order.')} style={{float:"right"}}>View Invoice</div> :
                <div className="ddAddCartBtn" onClick={() => {
                  window.open(keyItem.invoice_url);
                }} style={{float:"right"}}>
                  View Invoice
                </div> 
              }
            </Grid>
          </Grid>
          <div className="ddDeliveryStatusBox">
              {keyItem.order_status === "DECLINED" ?
                <span className="ddStatusSticker" style={{background:"red"}}>DECLINED</span>
                  :
                  keyItem.order_status === "FULFILLED" ?
                    <span className="ddStatusSticker" style={{background:"#C5BBC9"}}>DELIVERED</span>
                    :
                    <span className="ddStatusSticker" style={{background:"#000000"}}>{keyItem.order_status}</span>
              }
              {/* <span style={{float:"right"}}><GoPrimitiveDot style={{background: '#E5ACB9 0% 0% no-repeat padding-box', borderRadius:"50%", opacity:"0.25", fontSize:"18px", top:"4px", position:"relative", right:"5px"}}/><span style={{color:"#2B2624", fontFamily:"Poppins-Medium", fontSize:"14px"}}>Delivering in </span><span style={{color:"#E8B47A", fontFamily:"Poppins-Medium", fontSize:"14px"}}>00 Mins</span></span> */}
          </div>
        </div>
    </Grid>
    );
    if (this.state.ordersFetching === true){
      return(
          <div style={{padding:"10px 30px", textAlign: 'center', fontFamily: 'Poppins-Regular'}}>
              Loading orders...
          </div>
      );
    } else {
      if (listItems.length > 0){
        return(
            <div style={{padding:"10px 30px"}}>
                {listItems}
            </div>
        );
      } else {
        return(
          <div style={{padding:"10px 30px", textAlign: 'center', fontFamily: 'Poppins-Regular'}}>
              No orders found...
          </div>
        );
      }
    }
  }


  closeOrderDetailsModal(){
    this.setState({
      showOrderDetailsModal: !this.state.showOrderDetailsModal,
    })
  }

  toggleOrderDetailsModal(keyItem){
    this.props.setOrder(keyItem);
  }

  orderSearch(event){
    if(event.target.value === ""){
      this.setState({
        tempOrderList: this.state.orderList,
        searchBarText: event.target.value
      })
    }
    else{
      this.setState({
        searchBarText: event.target.value
      },() => {
        var searchedOrders = []
        for(let i=0; i<this.state.orderList.length; i++){
          var itemValue = Object.values(this.state.orderList[i].items)
          for(let j = 0; j < itemValue.length; j++){
              if(itemValue[j].name.toLowerCase().includes(this.state.searchBarText.toLowerCase()) || this.state.orderList[i].order_id.toLowerCase().includes(this.state.searchBarText.toLowerCase()) || this.props.restaurant.name.toLowerCase().includes(this.state.searchBarText.toLowerCase())){
                searchedOrders.push(this.state.orderList[i])
              }
          }
        }

        this.setState({
          tempOrderList: [...new Set(searchedOrders)]
        })
      })
    }
  }

  getFilterTabClassName(filterType){
    if (this.state.filterType === filterType){
      return 'ddFilterTabsSelected';
    } else {
      return 'ddFilterTabs';
    }
  }

  changeFilterType(filterType){
    if (this.state.filterType !== filterType){
      this.setState({
        filterType: filterType
      });
      let period = 0;
      if (filterType === 'Today'){
        period = 1;
      } else if (filterType === 'Week'){
        period = 7;
      } else if (filterType === 'Month'){
        period = 30;
      }
      this.getOrderList(1, period);
    }
  }

    render() {
        return (
            <div>
                <Grid container spacing={0} style={{width:"100%", minHeight:"40vw"}}>
                    <Grid item xs={12}>
                        <div style={{display: 'flex', flexDirection: 'row', padding: "20px 30px 0px 30px"}}>
                          <div style={{fontFamily:"Bree-Regular", fontSize:"25px", flex: '1'}}>Order History ({this.state.orderCount})</div>
                          <div className='ddFilterTabsContainer'>
                            <div onClick={() => this.changeFilterType('Today')} className={this.getFilterTabClassName('Today')} style={{marginRight: '1px'}}>Today</div>
                            <div onClick={() => this.changeFilterType('Week')} className={this.getFilterTabClassName('Week')} style={{marginRight: '1px'}}>Week</div>
                            <div onClick={() => this.changeFilterType('Month')} className={this.getFilterTabClassName('Month')} style={{marginRight: '1px'}}>Month</div>
                            <div onClick={() => this.changeFilterType('All Time')} className={this.getFilterTabClassName('All Time')}>All Time</div>
                          </div>
                        </div>
                        <Grid item xs={12} style={{margin:"10px 30px"}}>
                            <div className="ddFoodSearchDiv">
                                <SearchIcon className="ddSearchIcon"/>
                                <input
                                    type="text"
                                    className="ddSearchTextBox"
                                    placeholder="Search for keywords here"
                                    value={this.state.searchBarText}
                                    onChange={this.orderSearch}
                                />
                                <span style={{float:"right", cursor:"pointer"}}>
                                    <span style={{borderLeft:"1px solid #E6E6E6", height:"100%", position:"relative", top:"3px", marginRight:"5px"}}></span>
                                    <FiFilter style={{color:"#EA5B2F", margin:"0px 5px", position:"relative", top:"6px"}}/> <span style={{fontFamily:"Poppins-Medium", color:"#3C3C3C", position:"relative", top: "4px", paddingRight:"5px"}}>Filter</span>
                                </span>
                            </div>
                        </Grid>
                        {this.displayOrderList()}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withRouter(Orders);
